import axios from "axios";

import { API_URL } from "config";

const fetchEmailTemplates = async () => {
  try {
    const res = await axios.get(`${API_URL}/control/emailTemplates`);
    // console.log("rick", res);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchEmailTemplates;
