import axios from "axios";

import { API_URL } from "config";

const createApplication = async ({ data, jobSlug }) => {

    const url = `${API_URL}/jobs/${jobSlug}/apply`
    const res = await axios.post(url, data);
    return res.data;
};

export default createApplication;