// @mui material components
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import postNote from "layouts/pages/applicants/actions/postNote";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";

import JobsTable from "layouts/pages/jobs/components/JobsTable";
import EventsTable from "layouts/pages/events/components/EventsTable";

import ApplicantsFilterCards from "layouts/pages/applicants/components/ApplicantFilterCards";
import ApplicantSourceFilterCards from "layouts/pages/applicants/components/ApplicantSourceFilterCards";
import ApplicantsTable from "layouts/pages/applicants/components/ApplicantsTable";
import ApplicantsMainInfo from "layouts/pages/applicants/components/ApplicantMainInfo";
import ApplicantActivities from "layouts/pages/applicants/components/ApplicantActivities";
import ApplicantVenues from "layouts/pages/applicants/components/ApplicantVenues";
import ApplicantOnboardingEvents from "layouts/pages/applicants/components/ApplicantEvents";
import ApplicantSendMessagePanel from "layouts/pages/applicants/components/ApplicantSendMessagePanel";
import ApplicantNotes from "layouts/pages/applicants/components/ApplicantNotes";
import ApplicantAttachments from "layouts/pages/applicants/components/ApplicantAttachments";
import ApplicantOnBoardingPanel from "layouts/pages/applicants/components/ApplicantOnboardingPanel";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import ApplicantVenuesTable from "layouts/pages/applicants/components/ApplicantVenuesTable";
import ApplicantProcessStepper from "./components/ApplicantProcessStepper";

function Applicants({ mode = "edit" }) {
  const navigate = useNavigate();
  const { currentApplicant, setCurrentApplicant, currentLoggedUser } = useAppContextController();
  const { id, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [open, setOpen] = useState(false);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [panelSelect, setPanelSelect] = useState("activities");
  const [boarding, setBoarding] = useState(false);

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };
    const updateNewNote = async () => {
      let res
      await postNote({ applicantId: currentApplicant?._id, data: newNote })
        .then(response => {
          res = response
          setToastAlert({ isShow: true, message: "Notes Have Been Successfully Updated", status: "success" })
        })
        .catch(response => {
          res = response
          setToastAlert({ isShow: true, message: `Something went wrong! ${response}`, status: "error" })
        });
      if (res?.data?.modifiedCount) {
        const newAppl = await fetchSingleApplicant({ applicantId: currentApplicant?._id });
        if (newAppl?.data) setCurrentApplicant(newAppl.data);
      }

      return res;
    };

    return updateNewNote();
    // const newNotesArr = currentApplicant.attachments ? [...currentApplicant.attachments, newAtt] : [newAtt];
    // await updateApplicantMutation.mutateAsync({
    //     applicantId: currentApplicant._id,
    //     data: {
    //         notes: [...newNotesArr],
    //     }
    // });
  };

  useEffect(() => {
    if (id) setSelectedId(id);
  }, [id]);
  useEffect(async () => {
    if (selectedId && (actionSelected || action)) {
      setFilters({ _id: selectedId });
    }
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
      if (!selectedId) setFilters({});
    }

    if (selectedId && (!currentApplicant || currentApplicant._id !== selectedId)) {
      const appl = await fetchApplicants({ filters: { _id: selectedId } });
      if (appl) setCurrentApplicant(appl.data[0]);
    }
  }, [selectedId, action]);

  function panelSelector(selection) {
    switch (selection) {
      case "activities":
        return <ApplicantActivities />;
      case "venues":
        return <ApplicantVenues />;
      case "note":
        return <ApplicantNotes setOpen={setNoteModalOpen} currentApplicant={currentApplicant} />;
      case "message":
        return <ApplicantSendMessagePanel />;
      case "orientation":
        return <ApplicantOnboardingEvents fetchAll={false} />;
      case "attachments":
        return <ApplicantAttachments setOpen={setOpen} />;
      default:
        return null;
    }
  }

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ApplicantsFilterCards setFilters={setFilters} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ApplicantSourceFilterCards setFilters={setFilters} />
          <ApplicantsTable
            defaultSort="lastName"
            filters={filters}
            setNavigateUrl={setNavigateUrl}
            setFilters={setFilters}
            showFiltersList
            setSelectedId={setSelectedId}
          />
        </Grid>
        {["applicantinfo", "applicantall", ""].includes(actionSelected) &&
          (id || mode === "create") && (
            <>
              {currentApplicant && ["New", "ATC", "Screened", "Pre-Hire"].includes(currentApplicant.status) &&
                <Grid container sx={12} spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <ApplicantProcessStepper applicant={currentApplicant} />
                  </Grid>
                </Grid>
              }
              <Grid container sx={12} spacing={1} mt={2}>
                <Grid item xs={12} sm={6}>
                  <ApplicantsMainInfo
                    setPanelSelect={setPanelSelect}
                    panelSelect={panelSelect}
                    setBoarding={setBoarding}
                    boarding={boarding}
                    setNavigateUrl={setNavigateUrl}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {panelSelector(panelSelect)}
                </Grid>
                {boarding && (
                  <Grid item xs={12} sm={12}>
                    <ApplicantOnBoardingPanel
                      setCurrentApplicant={setCurrentApplicant}
                      currentApplicant={currentApplicant}
                      setOpen={setOpen}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        {["applicantvenues", "applicantall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <ApplicantVenuesTable
                title={`Venues for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                filters={
                  currentApplicant?.venues
                    ? { slug: currentApplicant.venues.map((venue) => venue.venueSlug).join(";") }
                    : {}
                }
                showFiltersList={false}
                showSearchBar={false}
                defaultSort="slug"
                showVenueStatus
                currentApplicant={currentApplicant}
              />
            </Grid>
          </>
        )}
        {["applicantjobs", "applicantall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <JobsTable
                title={`Jobs for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                filters={
                  currentApplicant?.jobs
                    ? { jobId: currentApplicant.jobs.map((job) => job.jobId).join(";") }
                    : {}
                }
                showFiltersList={false}
                showSearchBar={false}
                defaultSort="title"
              />
            </Grid>
          </>
        )}
        {["ApplicantOnboardingEvents", "applicantall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <EventsTable
                showFiltersList={false}
                showSearchBar={false}
                fetchAll
                title={`Events for: ${currentApplicant?.firstName} ${currentApplicant?.lastName} `}
                filters={
                  currentApplicant?.events
                    ? { eventUrl: currentApplicant.events.map((event) => event.eventUrl).join(";") }
                    : {}
                }
                defaultSort="eventDate"
                showEventStatus
              />
            </Grid>
          </>
        )}
        <UploadFileModal currentApplicant={currentApplicant} setCurrentApplicant={setCurrentApplicant} open={open} setOpen={setOpen} />
      </Grid>
      <AddANoteModal
        currentApplicant={currentApplicant}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        saveNote={saveNote}
      />
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
Applicants.defaultProps = {
  // pageName: "Applicants",
};

// Typechecking props for BaseLayout
Applicants.propTypes = {
  // pageName: PropTypes.string,
};

export default Applicants;
