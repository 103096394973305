import axios from "axios";

import { API_URL } from "config";

const updateEvent = async ({ eventId, data }) => {
  // console.log("#", eventId)
  // console.log("data", data)

  const res = await axios.put(`${API_URL}/events/${eventId}`, data);
  return res.data;
};

export default updateEvent;
