// @mui material components
import { Grid, Snackbar, Alert } from "@mui/material";
// prop-types is a library for typechecking of props
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import deleteEvent from "layouts/pages/events/actions/deleteEvent";
import EventsTable from "layouts/pages/events/components/EventsTable";
import EventsInformation from "layouts/pages/events/components/EventsInformation";
import EventRosterAndTime from "layouts/pages/events/components/EventRosterAndTime";
import EventsFilterCards from "layouts/pages/events/components/EventsFilterCards";
import EventsPreviewModal from "layouts/pages/events/components/EventsPreviewModal";
import ExportFieldsModal from "layouts/pages/events/components/EventsInformation/ExportFieldsModal";

function Events({ mode = "edit" }) {
  const { eventUrl, action, venueSlug } = useParams();
  const [searchParams] = useSearchParams();
  const myVenues = searchParams.get('myVenues');
  const [open, setOpen] = useState(false);
  const [eventPreview, setEventPreview] = useState(null);
  const [filters, setFilters] = useState({});
  const [actionSelected, setActionSelected] = useState(action || "");
  const [mongoEventId, setMongoEventId] = useState(null);
  const [roster, setRoster] = useState("signups");
  const [exportFieldModalOpen, setExportFieldModalOpen] = useState(false);
  const [applicantsData, setApplicantsData] = useState([]);
  const [rosterData, setRosterData] = useState([]);
  const { currentEvent, setCurrentEvent, currentLoggedUser, venues } = useAppContextController();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (eventUrl && (actionSelected || action)) {
      setFilters({ eventUrl });
    }
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
    }
    if (venueSlug) {
      // getSelectedEvents(null, venueSlug);
      const filt = { eventType: "Event", venueSlug };
      if (!eventUrl) filt.timeFrame = filters.timeFrame || "Current";
      if (eventUrl && action) filt.eventUrl = eventUrl;
      setFilters(filt);
    } else if (eventUrl && (!currentEvent || currentEvent.eventUrl !== eventUrl)) {
      // getSelectedEvents(eventUrl);
      setFilters({ eventUrl });
    } else { // don't filter by timeFrame if specific event
      const filt = { eventType: "Event", timeFrame: filters.timeFrame || "Current" }
      if (eventUrl && action) filt.eventUrl = eventUrl;
      if (myVenues && currentLoggedUser?.venues?.length) filt.venueSlug = currentLoggedUser.venues.join(";")
      setFilters({ ...filt });
    }
    if (currentEvent) {
      setMongoEventId(currentEvent._id);
    }
  }, [eventUrl, action, venueSlug]);

  const setNavigateUrl = (url) => navigate(url);

  const deleteEventHandler = async (eventId, name) => {
    const res = await deleteEvent(eventId);
    if (res?.data?.success) {
      setToastAlert({ isShow: true, message: `Event ${name} has been deleted`, status: "success" })
      return { success: true }
    }
    setToastAlert({ isShow: true, message: `Something went wrong -- ${res?.data?.message}`, status: "error" })
    return { success: false, message: res?.data?.message }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <EventsFilterCards
            filters={filters}
            setFilters={setFilters}
          // setPage={setPage}
          />
          <EventsTable
            eventUrl={eventUrl}
            fetchAll
            setActionSelected={setActionSelected}
            showFiltersList
            filters={filters}
            setFilters={setFilters}
            setNavigateUrl={setNavigateUrl}
            defaultSort="eventDate"
            setOpen={setOpen}
            setEventPreview={setEventPreview}
          />
        </Grid>
        {["eventsinfo", "eventsall", ""].includes(actionSelected) &&
          (eventUrl || mode === "create") && (
            <>
              <Grid item xs={12} sm={12}>
                <EventsInformation
                  mode={mode}
                  setOpen={setOpen}
                  setEventPreview={setEventPreview}
                  deleteEvent={deleteEventHandler}
                />
              </Grid>
            </>
          )}
        {["eventsroster", "eventstime", "eventsall", ""].includes(actionSelected) && eventUrl && (
          <>
            <Grid item xs={12} sm={12}>
              <EventRosterAndTime id={mongoEventId}
                roster={roster}
                mode={mode}
                setExportFieldModalOpen={setExportFieldModalOpen}
                setApplicantsData={setApplicantsData}
                setRosterData={setRosterData}
              />
            </Grid>
          </>
        )}
        {eventPreview && (
          <EventsPreviewModal open={open} setOpen={setOpen} eventPreview={eventPreview} />
        )}
        <ExportFieldsModal
          exportFieldModalOpen={exportFieldModalOpen}
          setExportFieldModalOpen={setExportFieldModalOpen}
          applicantsData={applicantsData}
          rosterData={rosterData}
          currentEvent={currentEvent}
          venues={venues}
        />
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
// Events.defaultProps = {
//   pageName: "Events",
// };

// // Typechecking props for BaseLayout
// Events.propTypes = {
//   pageName: PropTypes.string,
// };

export default Events;
