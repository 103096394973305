import { useState } from "react";
import { useAppContextController } from "context/AppContext";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/applications/wizard/components/FormField";
import { makeStyles } from "@mui/styles";
import { Icon, Stack, IconButton, Button } from "@mui/material";
import { DateRange, Description, Email, Stadium } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import INine from "./INine";
import Personal from "./Personal";
import FederalW4 from "./FederalW4";
import DirectDeposit from "./DirectDeposit";
import Attachments from "./Attachments";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    border: "1px solid",
  },
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },

  cardBox: {
    overflow: "visible!important",
  },
  button: {
    margin: `${pxToRem(2)}`,
  },
});

const ApplicantOnBoardingPanel = ({ setCurrentApplicant, currentApplicant, userPage, setOpen }) => {
  const classes = useStyle();
  const [bottomPanel, setBottomPanel] = useState("personal");

  return (
    !currentApplicant ? <div>no data</div> : (
      <Card className={classes.cardBox}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="error"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="large">folder_shared</Icon>
          </MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={5} xl={5}>
              <Grid container>
                <Grid item xs={12} sm={10} lg={11} xl={11}>
                  {currentApplicant && (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 2, ml: 2 }}>
                        {`${currentApplicant?.lastName} ${currentApplicant?.firstName}`}
                      </MDTypography>
                      <Button>{`${currentApplicant?.status}`}</Button>
                      <MDTypography variant="h6" color="dark" sx={{ mt: 3, mb: 1, ml: 2 }}>
                        {(currentApplicant.modifiedDate) &&
                          `Last Updated: ${new Date(
                            currentApplicant?.modifiedDate
                          ).toLocaleDateString()} ${new Date(
                            currentApplicant?.modifiedDate
                          ).toLocaleTimeString()}`
                        }
                      </MDTypography>
                    </Stack>
                  )}
                  {/* <Grid item xs={12} sm={12}>
                                        <FormField

                                            key={`user_${currentApplicant?.venueSlug}`}
                                            type="text"
                                            value={`User: ${currentApplicant._id}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormField

                                            key={`lastUpdate_${currentApplicant?.venueSlug}`}
                                            type="text"
                                            value={`Last Updated: ${currentApplicant.modifiedDate}`}
                                        />
                                    </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={4} lg={3} xl={3}>
                            <Stack className={classes.stack} spacing={2} direction="row" alignItems="center">
                                <IconButton color="info" aria-label="stadium">
                                    <Stadium />
                                </IconButton>
                                <IconButton aria-label="description">
                                    <Description />
                                </IconButton>
                                <IconButton color="warning" aria-label="email">
                                    <Email />
                                </IconButton>
                                <IconButton color="success" aria-label="date">
                                    <DateRange />
                                </IconButton>
                            </Stack>
                        </Grid> */}
          </Grid>
        </MDBox>

        <Grid item xs={12} sm={4} lg={5} xl={5} />
        <Grid item xs={12} sm={4} lg={12} xl={12}>
          <MDBox m={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} lg={12} xl={12}>
                <MDBox p={3} className={classes.box}>
                  <MDButton
                    className={classes.button}
                    variant="contained"
                    color={bottomPanel === "personal" ? "info" : "secondary"}
                    onClick={() => {
                      setBottomPanel("personal");
                    }}
                  >
                    Personal
                  </MDButton>
                  {!userPage &&
                    <MDButton
                      className={classes.button}
                      variant="contained"
                      color={bottomPanel === "iNine" ? "info" : "secondary"}
                      onClick={() => {
                        setBottomPanel("iNine");
                      }}
                    >
                      I-Nine
                    </MDButton>}

                  <MDButton
                    className={classes.button}
                    variant="contained"
                    color={bottomPanel === "federalW4" ? "info" : "secondary"}
                    onClick={() => {
                      setBottomPanel("federalW4");
                    }}
                  >
                    Federal W4
                  </MDButton>

                  <MDButton
                    className={classes.button}
                    variant="contained"
                    color={bottomPanel === "directDeposit" ? "info" : "secondary"}
                    onClick={() => {
                      setBottomPanel("directDeposit");
                    }}
                  >
                    Direct Deposit
                  </MDButton>

                  <MDButton
                    className={classes.button}
                    variant="contained"
                    color={bottomPanel === "attachments" ? "info" : "secondary"}
                    onClick={() => {
                      setBottomPanel("attachments");
                    }}
                  >
                    Attachments
                  </MDButton>
                  {!userPage && <>
                    {/* <MDButton
                      className={classes.button}
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        setBottomPanel("resetPassword");
                      }}
                    >
                      Reset Password
                    </MDButton>

                    <MDButton
                      className={classes.button}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setBottomPanel("edit");
                      }}
                    >
                      Edit
                    </MDButton> */}

                    <MDButton
                      className={classes.button}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setBottomPanel("deleteApplicant");
                      }}
                    >
                      Delete Applicant
                    </MDButton>
                  </>}
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4} lg={12} xl={12}>
                <MDBox mt={-3} p={3} className={classes.box}>
                  {(() => {
                    switch (bottomPanel) {
                      case "personal":
                        return <Personal currentApplicant={currentApplicant} />;
                      case "iNine":
                        return <INine currentApplicant={currentApplicant} />;
                      case "federalW4":
                        return <FederalW4 currentApplicant={currentApplicant} setCurrentApplicant={setCurrentApplicant} />;
                      case "directDeposit":
                        return <DirectDeposit currentApplicant={currentApplicant} />;
                      case "attachments":
                        return <Attachments setCurrentApplicant={setCurrentApplicant} currentApplicant={currentApplicant} setOpen={setOpen} attachemntTitle="Applicant Files" />;
                      default:
                        return <></>;
                    }
                  })()}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Card>
    ));
};

export default ApplicantOnBoardingPanel;
