import { useState } from "react";
import moment from "moment-timezone";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import { Grid, Card } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UndoIcon from "@mui/icons-material/Undo";
import EditIcon from "@mui/icons-material/Edit";
import { UnfoldMoreDoubleOutlined } from "@mui/icons-material";

const DisplayTime = ({ mode, setMode, time, field, timeZone, setEdit, undoTime }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setEdit(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "edit-time-popover" : undefined;

  const handleEdit = () => {
    setEdit(true);
    setMode("edit");
  };

  const handlePicker = () => {
    setEdit(false);
    setMode("picker");
  };

  const handleUndo = () => {
    undoTime();
    setMode("display");
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        color="success"
        fontSize={15}
        size="medium"
        sx={{ whiteSpace: "nowrap" }}
      >
        <Typography fontSize="medium">{moment(time)?.format("hh:mm a")}</Typography>
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {/* <Grid item xs="7">
            <Typography onClick={() => setEdit(true)}>
              {moment(time)?.tz(timeZone).format("hh:mm a")}
            </Typography>
          </Grid> */}
        <MenuItem p={3} onClick={handleEdit}>
          <EditIcon color="warning" fontSize="large" />
          &nbsp;
          <Typography>{`Edit ${field === "timeIn" ? "Clock In" : "Clock Out"}`}</Typography>
        </MenuItem>
        <MenuItem p={3} onClick={handlePicker}>
          <AccessTimeIcon color="info" fontSize="large" />
          &nbsp;
          <Typography>
            {`Use TimePicker ${field === "timeIn" ? "Clock In" : "Clock Out"}`}{" "}
          </Typography>
        </MenuItem>
        <MenuItem p={3} onClick={handleUndo}>
          <UndoIcon size="medium" color="error" fontSize="large" />
          &nbsp;
          <Typography>{`Undo/Delete ${field === "timeIn" ? "Clock In" : "Clock Out"}`}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

// if (time !== null && mode === "undoOrEdit") {
//   return <span key={`${field}_id_${id}`} style={{ minWidth: 1200 }}></span>;
// }
export default DisplayTime;
