// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import FormField from "layouts/applications/wizard/components/FormField";

import { Icon } from "@material-ui/core";
import { useAppContextController } from "context/AppContext";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Snackbar } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import ConfirmDialog from "components/ConfirmDialog";
import updateVenue from "layouts/pages/venues/actions/updateVenue";


const VenueSettingsPanel = () => {
  const { currentVenue, setCurrentVenue, setStateVar } = useAppContextController();

  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm();

  // Input Refs
  const venuePrimaryContact = register("venuePrimaryContact");
  const allowAdminChanges = register("allowAdminChanges");
  const makeVenueInactive = register("makeVenueInactive");
  const ShowVenueOnWebsite = register("ShowVenueOnWebsite");
  const notifyWhenAdminUsersMakeChanges = register("notifyWhenAdminUsersMakeChanges");
  const notifyWhenNewJobsAreAddes = register("notifyWhenNewJobsAreAddes");
  const notifyWhenEventsAreAdded = register("notifyWhenEventsAreAdded");
  const notifyWhenApplicantsAreLocked = register("notifyWhenApplicantsAreLocked");
  const dashboardLastName = register("dashboardLastName");
  const dashboardFirstName = register("dashboardFirstName");
  const dashboardPhone1 = register("dashboardPhone1");
  const dashboardPhone2 = register("dashboardPhone2");
  const dashboardEmailAddress = register("dashboardEmailAddress");
  const dashboardHelmEID = register("dashboardHelmEID");
  const dashboardHireDate = register("dashboardHireDate");
  const dashboardBirthDate = register("dashboardBirthDate");
  const dashboardLastDateWorked = register("dashboardLastDateWorked");
  const dashboardRank = register("dashboardRank");
  const dashboardLastUpdate = register("dashboardLastUpdate");
  const dashboardRepName = register("dashboardRepName");
  const dashboardNotes = register("dashboardNotes");
  const dashboardResults = register("dashboardResults");
  const dashboardOtherVenues = register("dashboardOtherVenues");


  const queryClient = useQueryClient();
  const updateJobMutation = useMutation(updateVenue, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("venues");
      setCurrentVenue({ ...currentVenue, ...data });
      setToastAlert({ isShow: true, message: "Venue has been updated!", status: "success" });
    },
  });

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getDefaultValues = () => {
    const values = {};
    Object.keys(watch())?.forEach((key) => {
      values[key] = currentVenue?.settings[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    reset(getDefaultValues());
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes has been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentVenue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    await updateJobMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { settings: values },
    });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confrim to abandon Changes",
      response: false,
    });
  };

  useEffect(() => {
    if (currentVenue) {
      Object.keys(watch())?.forEach((key) => {
        setValue(key, currentVenue?.settings?.[key] || "");
      });
      setIsModified(false);
    }
  }, [currentVenue]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (currentVenue) {
        setIsModified(JSON.stringify(getDefaultValues()) !== JSON.stringify(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, currentVenue]);

  // Executes on modal response!
  useEffect(() => {
    if (confirmModal.response) {
      resetConfirmModal();
      cancelChanges();
    }
  }, [confirmModal.response]);

  return (
    currentVenue && (
      <Card>
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            severity={toastAlert.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>

        <form onSubmit={handleSubmit(saveChanges)}>
          <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="info"
                color="white"
                shadow="md"
                borderRadius="xl"
                mt={-2}
              >
                <Icon fontSize="medium">settings</Icon>
              </MDBox>
              <MDTypography variant="h5" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Venue Settings: {currentVenue?.name}
              </MDTypography>
            </MDBox>
            {isModified && (
              <MDBox display="flex" alignItems="center">
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </MDBox>
            )}
          </MDBox>

          <MDBox p={3}>
            <MDBox mb={3}>
              <Grid container direction="row-reverse">
                <Grid item sm={4}>
                  <Controller
                    {...venuePrimaryContact}
                    control={control}
                    defaultValue={currentVenue ? currentVenue.venuePrimaryContact : "Assign Venue PrimaryInactive"}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_venuePrimaryContact_${venuePrimaryContact?.slug}`}
                        options={["Assign Venue Primary"]}
                        name="venuePrimaryContact"
                        value={field.value}
                        defaultValue={currentVenue ? currentVenue.venuePrimaryContact : "Assign Venue Primary"}
                        onChange={(e) => field.onChange(e.target.innerText)}
                        renderInput={(params) => (
                          <FormField
                            key={`venuePrimaryContact_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="Assign Venue Primary"
                          />)}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <MDTypography variant="h6" color="info">
                    GENERAL SETTINGS
                  </MDTypography>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                      <Controller
                        {...allowAdminChanges}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Allow Admin User to Make Changes
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                      <Controller
                        {...makeVenueInactive}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Make Venue Inactive
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                      <Controller
                        {...ShowVenueOnWebsite}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Show Venue on Website
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <MDTypography variant="h6" color="info">
                    NOTIFICATIONS
                  </MDTypography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                          <Controller
                            {...notifyWhenAdminUsersMakeChanges}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            When Admin Users make changes
                          </MDTypography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                            <Controller
                              {...notifyWhenNewJobsAreAddes}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === "Yes"}
                                  onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                            <MDTypography variant="body2" textTransform="capitalize">
                              When new Jobs are added
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                          <Controller
                            {...notifyWhenEventsAreAdded}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            When events are added
                          </MDTypography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={4} lg={2} xl={2} xxl={2}>
                            <Controller
                              {...notifyWhenApplicantsAreLocked}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === "Yes"}
                                  onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8} lg={10} xl={10} xxl={10}>
                            <MDTypography variant="body2" textTransform="capitalize">
                              When Applicants are locked
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
            <hr />
            <MDBox mt={3}>
              <MDTypography variant="h6" color="info">
                STAFFING POOL SETTINGS
              </MDTypography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={3} lg={3} xl={3} xxl={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardLastName}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Last Name
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardFirstName}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        First Name
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={4} lg={6} xl={6} xxl={5}>
                          <Controller
                            {...dashboardPhone1}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={6} xl={6} xxl={7}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            Phone 1
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={4} lg={6} xl={6} xxl={5}>
                          <Controller
                            {...dashboardPhone2}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={6} xl={6} xxl={7}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            Phone 2
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardEmailAddress}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Email Address
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3} lg={3} xl={3} xxl={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardHelmEID}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Helm EID
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardHireDate}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Hire Date
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardBirthDate}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Birth Date
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardLastDateWorked}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Last Worked Date
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} lg={3} xl={3} xxl={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardRank}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Rank
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardLastUpdate}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Last Update
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardNotes}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Notes
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3} lg={3} xl={3} xxl={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardRepName}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Rep Name
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardResults}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Results
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3} xl={3} xxl={2.5}>
                      <Controller
                        {...dashboardOtherVenues}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9} xl={9} xxl={9.5}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Other Venues
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </form>
        <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
      </Card>
    )
  );
};

export default VenueSettingsPanel;
