import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";

import { makeStyles } from "@mui/styles";

import MDTypography from "components/MDTypography";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",

    border: "2px solid #d2d6da",
    padding: "20px 20px",
    cursor: "pointer",
  },
  text: { color: "#7b809a!important", fontSize: "0.875rem!important" },
});

const FileDropzone = ({ onDrop, className, disabled,
  message = "Drop files here to upload", restrict = {},
  maxSize = 2000000, size, avatar }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled, restrict, maxSize });


  const styles = useStyles();
  return (
    <section>
      <Box {...getRootProps()} className={styles.container} sx={{ background: disabled ? "#c1c1c1" : "white", height: `${size}` }}>
        { /* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        {avatar ||
          <MDTypography variant="p" className={styles.text}>
            {message}
          </MDTypography>
        }
      </Box>
    </section>
  );
};

FileDropzone.defaultProps = {
  onDrop: (acceptedFiles) => console.log("UPLOADED FILES HERE", acceptedFiles),
  className: "",
};
FileDropzone.prototype = {
  onDrop: PropTypes.func,
  className: PropTypes.string,
};

export default FileDropzone;
