import { useEffect, useState } from 'react'
import { TableAction } from "@perimeter-software/react-common";
import MDBox from "components/MDBox";


const ApplicantVenuesActions = ({ createActivity, slug, setStatusRef, startStatus = "" }) => {

  const stateToColor = {
    None: "dark",
    StaffingPool: "success",
    Pending: "warning",
    Locked: "error"
  }

  const statuses = ["None", "StaffingPool", "Pending", "Locked"]
  const [statusIdx, setStatusIdx] = useState(statuses.indexOf(startStatus) !== -1 ? statuses.indexOf(startStatus) : 0)
  const status = startStatus || "None";
  const color = stateToColor[startStatus] || "dark";

  useEffect(() => setStatusIdx(statuses.indexOf(startStatus) !== -1 ? statuses.indexOf(startStatus) : 0))

  const statusChangeHandler = () => {
    const newIdx = (statusIdx + 1) % statuses.length
    setStatusIdx(newIdx)
    setStatusRef(statuses[newIdx], slug)
    if (createActivity)
      createActivity({
        action: "Venue Status",
        description: `Set Venue Status for ${slug} to ${statuses[newIdx]}`
      })
  }

  return (
    <MDBox display="flex" justifyContent="end">
      {/* {`${statusIdx}-${status}-${color}`} */}
      <TableAction
        key={`venue_status_${slug}`}
        color={color}
        value={status}
        clickAction={statusChangeHandler}
        icon="stadium"
      />
    </MDBox>
  );
};
export default ApplicantVenuesActions;

// MyVenuesActions.defaultProps = {
//   slug: "",
//   isFavorite: false,
// };


// MyVenuesActions.propTypes = {
//   slug: PropTypes.string,
//   // isFavorite: PropTypes.bool,
// };
