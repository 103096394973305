import axios from "axios";

import { API_URL } from "config";

const updateVenue = async ({ venueId, data }) => {
  const res = await axios.put(`${API_URL}/venues/${venueId}`, data);
  return res.data;
};

export default updateVenue;
