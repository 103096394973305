import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import { useAppContextController } from "context/AppContext";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import FiltersList from "components/FiltersList";
import VenueIcon from "components/VenueIcon";
import Searchbar from "components/Searchbar";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus"
import VenuesTableActions from "../VenuesTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  }
});

const VenuesTable = ({
  slug,
  fetchAll = true,
  setNavigateUrl,
  title = "Venues Table",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  showVenueStatus = false,
  currentApplicant = null,
  setOpen,
  setVenuePreview
}) => {

  const classes = useStyle();
  const navigate = useNavigate();
  const { venues: venueCache, setVenues, setCurrentVenue, currentVenue } = useAppContextController();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  // const [prevPage, setPrevPage] = useState(1)
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters, setVenues };

  const { data: venues, isLoading } = useQuery(["venues", allOptions], () =>
    fetchVenues(allOptions)
  );

  const localVenues = venueCache ? Object.entries(venueCache).map(item => item[1]) : [];
  useEffect(() => {
    if (!isLoading && localVenues?.length && slug) {

      const ndx = localVenues.findIndex(item => item.slug === slug);
      if (ndx > -1) {
        setCurrentVenue(localVenues[ndx]);
      }
    }
  }, [isLoading, slug]);

  useEffect(() => {
    if (action && filters)
      setPage(1)
  }, [filters]);

  const columns = [
    {
      title: "Logo", field: "logoUrl", imageWidth: "50", imageHeight: "50",
      customCell: (logoUrl, field, row) => <VenueIcon logoUrl={logoUrl} slug={row.slug} name={row.name} imageWidth="50" imageHeight="50" />,
    },
    ...(showVenueStatus && currentApplicant) ?
      [{
        title: "Venue", field: "slug",
        customCell: (vslug, field, row) => <ApplicantVenueStatus
          id={row._id}
          venue={vslug}
          slugField="venueSlug"
          row={currentApplicant}
          invalidQuery={["venues", allOptions]}
        />
      }] : [],
    { title: "Venue ID", field: "slug", customCell: (thisSlug) => <span>{thisSlug?.toUpperCase()}</span> },
    { title: "Venue Name", field: "name" },
    { title: "Venue URL", field: "venueUrl" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    {
      title: "Actions",
      noSort: true,
      field: "slug",
      customCell: (thisSlug, field, row) => (
        <VenuesTableActions
          slug={thisSlug}
          row={row}
          setNavigateUrl={setNavigateUrl}
          setFilters={setFilters}
          setOpen={setOpen}
          setVenuePreview={setVenuePreview}
        />
      ),
    },
  ];

  const handleRowClick = (venue, e) => {
    setCurrentVenue(venue);
    setNavigateUrl(`/venues/${venue.slug}`)
    e.stopPropagation();
  };

  const addNew = (e) => {
    if (!currentVenue) {
      setCurrentVenue({})
    }
    navigate('/venues/create');
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item >
          <MDBox display="flex" mt={0} mb={0}>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">stadium</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>

            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item >
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center" >
          {showSearchBar && <Searchbar
            fetch={fetchVenues}
            fetchAll={fetchAll}
            placeholder="Search Venue/City"
            columns={columns.slice(1, columns.length - 1)}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy={["slug", "name", "city"]}
            filterBy="slug"
            idField="slug"
          />}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={(e) => addNew(e)}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={venues}
          selected={currentVenue}
          onRowClick={(row, e) => handleRowClick(row, e)}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
          defaultSort="name"
          greybar
          divider
        />
      </MDBox>
    </Card >
  );
};

// Setting default values for the props
VenuesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: true,
  setNavigateUrl: () => { },
  title: "Venues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
VenuesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default VenuesTable;
