// Creates the API parameters from the input passed in
import generateFilterString from "./generateFilterString";

const createApiParams = ({ page = 1, limit = 5, order, orderBy, fetchAll, filters }) => {
  const filtersString = generateFilterString(filters);
  const paginationQuery = !fetchAll ? `?page=${page}&limit=${limit}` : "";
  const sortQuery = !fetchAll && orderBy ? `&sort=${orderBy}${order ? `:${order}` : ""}` : "";
  const filterQuery = filtersString ? `${fetchAll ? "?" : "&"}filter=${filtersString}` : "";

  return { paginationQuery, sortQuery, filterQuery };
};

export default createApiParams;
