import { useEffect, useState, useMemo, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Stack } from "@mui/material";

import axios from "axios";
import { API_URL } from "config";
import VenueIcon from "components/VenueIcon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataList from "components/DataList";
import Searchbar from "components/Searchbar";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import MyVenuesActions from "../MyVenuesActions";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const MyVenues = ({ fetchAll = true, title = "Title", filters, setFilters }) => {
  const columns = [
    { title: "Logo", field: "logoUrl", type: "image", imageWidth: "50", imageHeight: "50" },
    { title: "Venue ID", field: "slug", customCell: (slug) => <span>{slug?.toUpperCase()}</span> },
    { title: "Venue Name", field: "name" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
  ];

  const { setCurrentVenue, venues: cachedVenues, currentLoggedUser, setCurrentLoggedUser } = useAppContextController();
  const [isLoading, setIsLoading] = useState(true)

  const [myVenues, setMyVenues] = useState([]);
  const [page, setPage] = useState(1);
  const options = { fetchAll: false };
  const allOptions = { ...options, filters, order: 'slug', orderBy: 'slug' };

  const myVenuesMutation = useMutation(async ({ data, id }) => {
    // eslint-disable-next-line
    if (data._id) delete data._id
    console.log('data', data)
    await axios.put(`${API_URL}/users/${id}`, data)
    setCurrentLoggedUser({ _id: id, ...currentLoggedUser, ...data })
  })

  const setAndRequery = (venueSlug) => {
    const newVenues = [...myVenues].map(venue => venue.slug).filter(item => item.slug !== venueSlug);
    myVenuesMutation.mutate({ data: { venues: [...newVenues] }, id: currentLoggedUser.userId })
  };

  useMemo(() => {

    if (cachedVenues && currentLoggedUser && currentLoggedUser?.venues) {
      setMyVenues(currentLoggedUser.venues.map(item => cachedVenues?.[item]) || [])
      setIsLoading(false)
    }
  }, [currentLoggedUser, currentLoggedUser?.venues, cachedVenues])


  const navigate = useNavigate();
  const addNew = () => navigate("/venues/create")
  const viewAll = () => navigate("/venues?myVenues=true");


  const renderRow = (row) => (
    <>
      <MDBox display="flex" justifyContent="space-between">
        <Grid item xs={12} sm={2} lg={1.5}>
          <VenueIcon logoUrl={row?.logoUrl} slug={row?.slug} name={row?.name} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{row?.name}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2">
                {row?.city}, {row?.state}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={4.5}>
          <MyVenuesActions isFavorite={() => true} setFavorite={setAndRequery} slug={row?.slug} />
        </Grid>
      </MDBox>
    </>
  );

  const searchBarRowClick = item => {
    setCurrentVenue(item);
    navigate(`/venues/${item.slug}/action/venueinfo`);
  }

  const header = (
    <Stack display="flex" flexDirection="row" >
      <Grid container mt={2}>
        <Grid item sm={6.5}  >
          <Searchbar
            fetch={fetchVenues}
            fetchAll={fetchAll}
            placeholder="Search Venue/City"
            columns={columns.slice(1, columns.length - 1)}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy={["name", "city"]}
            filterBy="slug"
            onRowClick={(item) => searchBarRowClick(item)}
          />

        </Grid>
        <Grid item sm={5.5} alignSelf="center">
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>

        </Grid>
      </Grid>
    </Stack >
  );

  return (
    <DataList
      renderRow={renderRow}
      data={myVenues}
      icon="stadium"
      iconColor="info"
      title="My Venues"
      isLoading={isLoading}
      greybar
      divider
      header={header}
      tableHeight={360}
      height={450}
      scrollY
    />
  );
};

export default MyVenues;
