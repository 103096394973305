import { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchActivityById from "layouts/pages/activities/actions/fetchActivityById";
import ActivitiesTable from "layouts/pages/activities/components/ActivitiesTable";
import ActivitiesInformation from "layouts/pages/activities/components/ActivitiesInformation";


function Activities({mode = "edit"}) {

  const navigate = useNavigate();

  const { currentActivity, setCurrentActivity, venues } = useAppContextController();
  const { id, action } = useParams();
  const [actionSelected, setActionSelected] = useState(action || "");

  const [filters, setFilters] = useState({});

  useEffect(async ()=>{
    if (id && (actionSelected || action)) {
      setFilters({ _id : id })
    }
    if (action ) { // && action !== actionSelected) {
      setActionSelected(action)
    } else {
      setActionSelected("");
      setFilters({})
    }

    if (id && (!currentActivity || currentActivity._id !== id)) {
      const activities = await fetchActivityById(id);
      if (activities && activities?.data?.length) setCurrentActivity(activities?.data[0]);
    }
  }, [id, action, currentActivity]);

  const setNavigateUrl = url => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <ActivitiesTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Activities"
          />
        </Grid>
        {["activitiesinfo", "activitiesall", ""].includes(actionSelected) && id && (
          <Grid item xs={12} sm={12}>
            <ActivitiesInformation mode={mode} />
          </Grid>
        )}

      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Activities.defaultProps = {
  mode: "edit",
};

// Typechecking props
Activities.propTypes = {
  mode: PropTypes.string,
};
export default Activities;
