/* eslint-disable consistent-return */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import MDEditor from "components/MDEditor";
import ConfirmDialog from "components/ConfirmDialog";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import { Alert, Snackbar, Button, Stack, Switch, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import states from "assets/stateCodes.json";

import statuses from "assets/statuses.json";
import FormField from "layouts/applications/wizard/components/FormField";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { API_URL, IMAGE_SERVER } from "config";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import createVenue from "layouts/pages/venues/actions/createVenue";
// eslint-disable-next-line import/no-named-as-default-member
import useStyles from "layouts/pages/venues/components/VenueMainInfoPanel/styles";
// eslint-disable-next-line import/no-named-as-default-member
import PhotoDropzone from "layouts/pages/venues/components/VenueMainInfoPanel/PhotoDropzone";
import AddVenueLocationModal from "layouts/pages/venues/components/VenueMainInfoPanel/AddVenueLocationModal"

const imageUrlBase = `${process.env.REACT_APP_IMAGE_SERVER || "http://images.stadiumpeople.com/sp"
  }`;

const useStyle = makeStyles((theme) => ({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 113,
    [theme.breakpoints?.down("xxl")]: {
      height: 50,
    },
  },
  previewButton: {
    padding: 0,
  },
  dropzone: {
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
  },
  blockedDropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  text: {
    fontSize: "0.75rem!important",
  },
  error: {
    fontSize: "0.75rem",
  },
}));

function VenueMainInfoPanel({ mode = "edit", open, setOpen, setVenuePreview }) {
  const styles = useStyles();
  const { venues, currentVenue, setCurrentVenue, currentLoggedUser } = useAppContextController();

  const classes = useStyle();
  // uncomment when having the params as "edit"
  // const { mode } = useParams();

  const [logoNotUploaded, setLogoNotUploaded] = useState(true);
  const [slugDisable, setSlugDisable] = useState(false);
  // Venue Preview Modal

  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [editorDescriptionValue, setEditorDescriptionValue] = useState(`<p></p>`);
  const [editorStadiumPeopleValue, setEditorStadiumPeopleValue] = useState(`<p></p>`);
  // const [currentLogo, setCurrentLogo] = useState(currentVenue?.logoUrl);

  const [filters, setFilters] = useState({});
  const options = { fetchAll: true };
  const allOptions = { ...options, filters, order: "lastName", orderBy: "lastName" };

  const filteredUsers = [];
  const { data: users } = useQuery(["users", allOptions], () => fetchUsers(allOptions));
  users?.data?.filter(item => !["Terminated", "Inactive"].includes(item.status)).map((user) => {
    const obj = filteredUsers.push(`${user.firstName} ${user.lastName}`);
    return filteredUsers;
  });
  filteredUsers.push("Payroll Department");
  filteredUsers.sort();

  useEffect(() => {
    setFilters({ spStatus: "Employee" });
  }, []);

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState();
  const [bannerAnchorRef, setBannerAnchorRef] = useState();
  const [addVenueLocationModalOpen, setAddVenueLocationModalOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationIndex, setLocationIndex] = useState(null)
  const [locationList, setLocationList] = useState([]);





  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({ mode: "onBlur" });


  console.log("current", currentVenue)

  const defaultValues = {
    name: "",
    contact: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: null,
    zip: "",
    longitude: null,
    latitude: null,
    radius: null,
    slug: "",
    venueHelmID: "",
    venueUrl: "",
    status: "Active",
    description: "",
    stadiumPeopleInfo: "",
    selectContact1: null,
    selectContact2: null,
    payrollContact: null,
    locations: []
  };

  const defaultSettings = {
    allowAdminChanges: "Yes",
    makeVenueInactive: "No",
    ShowVenueOnWebsite: "No",
    notifyWhenAdminUsersMakeChanges: "No",
    notifyWhenNewJobsAreAddes: "No",
    notifyWhenEventsAreAdded: "Yes",
    notifyWhenApplicantsAreLocked: "Yes",
    dashboardLastName: "Yes",
    dashboardFirstName: "Yes",
    dashboardPhone1: "Yes",
    dashboardPhone2: "No",
    dashboardEmailAddress: "Yes",
    dashboardHelmEID: "Yes",
    dashboardHireDate: "Yes",
    dashboardBirthDate: "Yes",
    dashboardLastDateWorked: "Yes",
    dashboardRank: "Yes",
    dashboardLastUpdate: "Yes",
    dashboardRepName: "Yes",
    dashboardNotes: "Yes",
    dashboardResults: "No",
    dashboardOtherVenues: "No",
  };

  // Input Refs
  const name = { ...register("name", { required: "Venue name is required" }) };
  const contact = register("contact");
  const phone = {
    ...register("phone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "Your phone is invalid",
      },
    }),
  };
  const email = {
    ...register("email", {
      pattern: {
        // eslint-disable-next-line
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Your email address is invalid",
      },
    }),
  };
  const address = register("address");
  const city = { ...register("city", { required: "City is required" }) };
  const state = {
    ...register("state", {
      required: "State is required",
      validate: { exist: (value) => states.includes(value) || "Insert a valid state" },
    }),
  };
  const zip = {
    ...register("zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Zip code is invalid" },
    }),
  };

  const longitude = register("longitude");
  const latitude = register("latitude");
  const radius = register("radius");
  const displayVenueEvents = register("displayVenueEvents");

  const slug = {
    ...register("slug", {
      required: "Id is required",
      // eslint-disable-next-line
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      validate: {
        // eslint-disable-next-line
        lowerCase: (value) =>
          (value && /^[a-z0-9][a-z0-9]+$/.test(value)) || "Only lowercase letters are allowed",
        // eslint-disable-next-line
        startNumber: (value) =>
          (value && /^[a-zA-Z][a-zA-Z0-9.,$;]+$/.test(value)) ||
          "the slug can't start with a number",
        repeated: (value) => {
          if (mode === "create") {
            return venues[value] === undefined || "Id is already in the list ";
          }
        },
      },
    }),
  };

  const venueHelmID = register("venueHelmID");
  const status = { ...register("status", { required: "Status is required" }) };
  const venueUrl = {
    ...register("venueUrl", {
      required: "Venue url is required",
      pattern: {
        value:
          // eslint-disable-next-line
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        message: "Your url is invalid",
      },
    }),
  };
  const venueDescription = register("venueDescription");
  const stadiumPeopleInfo = register("stadiumPeopleInfo");
  const selectContact1 = register("selectContact1");
  const selectContact2 = register("selectContact2");
  const payrollContact = register("payrollContact");
  const locations = register("locations");

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading } = useMutation(createVenue, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("venues");
      setToastAlert({ isShow: true, message: "Venue has been created!", status: "success" });
      setCurrentVenue({ ...currentVenue, ...data });
      navigate(`/venues/${data.slug}`);
    },
  });

  const updateVenueMutation = useMutation(updateVenue, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("venues");
      const newData = {
        ...data,
        logoUrl: data.logoUrl
          ? `${imageUrlBase}/${currentVenue.slug}/venues/logo/${data?.logoUrl}`
          : currentVenue?.logoUrl,
        logoUrls: data.logoUrls
          ? data.logoUrls.map((logo) => `${imageUrlBase}/${currentVenue.slug}/venues/logo/${logo}`)
          : currentVenue.logoUrls,
        bannerUrl: data.bannerUrl
          ? `${imageUrlBase}/${currentVenue?.slug}/venues/banner/${data.bannerUrl}`
          : currentVenue?.bannerUrl,
        imageUrls: data.imageUrls
          ? data.imageUrls.map(
            (banner) => `${imageUrlBase}/${currentVenue.slug}/venues/banner/${banner}`
          )
          : currentVenue.imageUrls,
      };
      setCurrentVenue({ ...currentVenue, ...newData });
      setToastAlert({ isShow: true, message: "Venue has been updated!", status: "success" });
    },
  });

  const openPreviewModal = () => {
    setVenuePreview(currentVenue);
    setOpen(!open);
  };

  let uploadUrl = "";
  if (mode === "edit") {
    if (currentVenue) {
      uploadUrl = `${API_URL}/upload/${currentVenue?.slug}/venues/logo`;
    }
  }
  if (mode === "create") {
    if (getValues().slug) {
      uploadUrl = `${API_URL}/upload/${getValues()?.slug}/venues/logo`;
    }
  }

  const createVenueHandler = async (values) => {
    // if (evaluate()) {
    const data = {
      settings: {},
    };
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser._id
    Object.keys(defaultSettings).forEach((key) => {
      data.settings[key] = defaultSettings[key];
    });
    await mutateAsync(data);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getVenueValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentVenue?.[key] || "";
    });
    return values;
  };

  const getDefaultValues = () => {
    const defaults = { ...defaultValues, ...defaultSettings };
    Object.keys(watch()).forEach((key) => {
      setValue(key, defaults[key] || "");
    });
    return defaults;
  };

  const cancelChanges = () => {
    reset(getVenueValues());
    setEditorDescriptionValue(currentVenue.description);
    setEditorStadiumPeopleValue(currentVenue.stadiumPeopleInfo);
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes have been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentVenue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: values,
    });
  };

  const saveAdditionalVenues = async (values) => {
    if (!currentVenue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { locations: values.locations },
    });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  const addNewVenueLocation = () => {
    setAddVenueLocationModalOpen(true)
  }

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {
        settings: {},
      };

      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      Object.keys(defaultSettings).forEach((key) => {
        data.settings[key] = defaultSettings[key];
      });

      setCurrentVenue(data);
      reset(getDefaultValues());
      setEditorDescriptionValue(defaultValues.description);
      setEditorStadiumPeopleValue(defaultValues.stadiumPeopleInfo);
      setSlugDisable(false);
    } else {
      setSlugDisable(true);
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      if (currentVenue) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentVenue[key] || "");
        });
        setEditorDescriptionValue(currentVenue.description);
        setEditorStadiumPeopleValue(currentVenue.stadiumPeopleInfo);
        setIsModified(false);
      }

      const subscription = watch((value) => {
        if (currentVenue) {
          setIsModified(JSON.stringify(getVenueValues()) !== JSON.stringify(value));
        }
      });

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
  }, [watch, currentVenue, confirmModal.response]);


  useEffect(() => {
    if (currentVenue?.locations) {
      if (getValues().locations?.length > 0) {
        setLocationList(getValues().locations?.map((location, index) => {
          return ({
            label: location?.locationName,
            index
          })
        }))
      }
      else { setLocationList([]) }
    }
    else {
      setLocationList([])
    }
  }, [getValues().locations])

  const cleaningFunc = () => {
    clearErrors("logo");
    setLogoNotUploaded(false);
    setSlugDisable(true);
  };

  const handleOpenMenu = (e) => {
    e.preventDefault();
    setAnchorRef(e.target);
    setIsOpenMenu(true);
  };

  const handleCloseMenu = (e) => {
    e.preventDefault();
    setIsOpenMenu(false);
  };

  const handleOpenBannerMenu = (e) => {
    e.preventDefault();
    setBannerAnchorRef(e.target);
    setIsBannerOpen(true);
  };

  const handleCloseBannerMenu = (e) => {
    e.preventDefault();
    setIsBannerOpen(false);
  };
  return (
    currentVenue &&
    Object.keys(currentVenue).length && (
      <Card key={currentVenue.id}>
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            severity={toastAlert.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>
        <form
          onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createVenueHandler)}
        >
          <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor={mode === "edit" ? "white" : "info"}
                color="white"
                shadow="md"
                borderRadius="xl"
                mt={-2}
                sx={
                  mode === "edit" && {
                    "&": {
                      transition: "0.2s",
                    },
                    "&:hover": {
                      background: "#D3D3D3",
                    },
                  }
                }
              >
                {mode === "edit" ? (
                  <>
                    <Tooltip title="Click to Change Logo" placement="top">
                      <IconButton
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarIconButton}
                        aria-controls="notification-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={handleOpenMenu}
                      >
                        <img
                          src={currentVenue?.logoUrl}
                          width="50"
                          height="50"
                          className={classes.logoImage}
                          alt="Venue Logo"
                        />
                      </IconButton>
                    </Tooltip>
                    <PhotoDropzone
                      title="Upload Icon"
                      isOpen={isOpenMenu}
                      onClose={handleCloseMenu}
                      anchorElem={anchorRef}
                      listName="logoUrls"
                      attribute="logoUrl"
                      apiUrl={`${API_URL}/upload/${currentVenue?.slug}/venues/logo`}
                    />
                  </>
                ) : (
                  <Icon fontSize="large">stadium</Icon>
                )}
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {mode === "edit" ? <>Venue Information: {currentVenue?.name}</> : <>Create Venue</>}
              </MDTypography>
            </MDBox>
            {mode === "edit" && isModified && (
              <MDBox display="flex" alignItems="center">
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </MDBox>
            )}

            {mode === "create" && (
              <MDBox display="flex" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isLoading}
                >
                  Create
                </Button>
              </MDBox>
            )}
          </MDBox>

          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <MDBox mt={1}>
                  <MDBox mt={1}>
                    <FormField
                      key={`name_${currentVenue?.slug}`}
                      type="text"
                      label="Name"
                      {...name}
                    />
                    {errors?.name && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.name.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`contactName_${currentVenue?.slug}`}
                      type="text"
                      label="Contact Name"
                      {...contact}
                    />
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`contactEmailAddress_${currentVenue?.slug}`}
                      type="text"
                      label="Contact Email Address"
                      placeholder="Contact Email Address"
                      {...email}
                    />
                    {errors?.email && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.email.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`phone_${currentVenue?.slug}`}
                      type="tel"
                      label="Contact Phone"
                      {...phone}
                    />
                    {errors?.phone && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.phone.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`address_${currentVenue?.slug}`}
                      type="text"
                      label="Address"
                      {...address}
                    />
                  </MDBox>
                  <MDBox mt={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <FormField
                          key={`city_${currentVenue?.slug}`}
                          type="text"
                          label="City"
                          {...city}
                        />
                        {errors?.city && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.city.message}
                          </MDTypography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <MDBox mb={3}>
                          <Controller
                            {...state}
                            control={control}
                            defaultValue={currentVenue?.state}
                            render={({ field }) => (
                              <Autocomplete
                                key={`autocomplete_state_${currentVenue?.slug}`}
                                options={states}
                                autoSelect
                                freeSolo
                                name="state"
                                defaultValue={null}
                                value={field.value}
                                onChange={(e, v) => {
                                  field.onChange(v.toUpperCase());
                                }}
                                onBlur={() => trigger("state")}
                                renderInput={(params) => (
                                  <FormField
                                    key={`state_${currentVenue?.slug}`}
                                    {...params}
                                    type="text"
                                    label="State"
                                  />
                                )}
                              />
                            )}
                          />
                          {errors?.state && (
                            <MDTypography className={classes.error} color="error">
                              {errors?.state.message}
                            </MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormField
                          key={`zipCode_${currentVenue?.slug}`}
                          type="text"
                          label="Zip Code"
                          {...zip}
                        />
                        {errors?.zip && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.zip.message}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <FormField
                          {...longitude}
                          key={`longitude_${currentVenue?.slug}`}
                          type="text"
                          label="Longitude"
                        />
                        {errors?.city && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.longitude.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormField
                          {...latitude}
                          key={`latitude_${currentVenue?.slug}`}
                          type="text"
                          label="Latitude"
                        />
                        {errors?.city && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.latitude.message}
                          </MDTypography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormField
                          key={`radius_${currentVenue?.slug}`}
                          type="text"
                          label="Radius"
                          {...radius}
                        />
                        {errors?.zip && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.radius.message}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDTypography
                    variant="body2"
                    color="info"
                    fontWeight="bold"
                    sx={{ textDecoration: "underline" }}
                    onClick={() => {
                      setLocationIndex(null);
                      setCurrentLocation(null)
                      addNewVenueLocation(true)
                    }}
                  >
                    Add Venue Locations
                  </MDTypography>
                  <MDBox mb={3}>
                    <Controller
                      {...locations}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          key={`autocomplete_locations_${currentVenue?.slug}`}
                          options={locationList}
                          value={null}
                          onChange={(e, v) => {
                            setLocationIndex(v.index)
                            setCurrentLocation(field.value[v.index])
                            setAddVenueLocationModalOpen(true)
                          }}

                          renderInput={(params) => (
                            <FormField
                              key={`locations_${currentVenue?.slug}`}
                              {...params}
                              type="text"
                            />
                          )}
                        />
                      )
                      }
                    />
                  </MDBox>

                  <Stack my={0.5} direction="row" alignItems="center">
                    <Controller
                      {...displayVenueEvents}
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                    <MDTypography variant="body2">Display Venue Events</MDTypography>
                  </Stack>
                </MDBox>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3}>
                <MDBox mt={1}>
                  <MDBox mt={1}>
                    <FormField
                      key={`venueSlug_${currentVenue?.slug}`}
                      type="text"
                      label="Venue Id"
                      {...slug}
                      disabled={slugDisable}
                    />
                    {errors?.slug && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.slug.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`helmClientId_${currentVenue?.slug}`}
                      type="text"
                      label="Helm Client Id"
                      placeholder="Helm Client Id"
                      {...venueHelmID}
                    />
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`venueUrl_${currentVenue?.slug}`}
                      type="text"
                      label="Venue URL"
                      placeholder="Venue URL"
                      {...venueUrl}
                    />
                    {errors?.venueUrl && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.venueUrl.message}
                      </MDTypography>
                    )}
                  </MDBox>

                  <Controller
                    {...status}
                    control={control}
                    defaultValue={currentVenue?.status}
                    render={({ field }) => (
                      <Autocomplete
                        key={`status_autocomplete_${currentVenue?.slug}`}
                        options={statuses}
                        name="status"
                        required
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.innerText)}
                        renderInput={(params) => (
                          <FormField
                            key={`status_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="Status"
                          />
                        )}
                      />
                    )}
                  />

                  {errors?.status && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.status.message}
                    </MDTypography>
                  )}
                  <MDBox mt={1}>
                    <Grid
                      container
                      spacing={2}
                      direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12}>
                        <MDTypography
                          component="label"
                          variant="button"
                          fontWeight="bold"
                          color="info"
                        >
                          VENUE BANNER
                        </MDTypography>
                      </Grid>
                      <Grid item xs>
                        {getValues().slug !== "" && !errors.slug ? (
                          <>
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="350px"
                              height="175px"
                              variant="gradient"
                              bgColor={mode === "edit" ? "white" : "info"}
                              color="white"
                              shadow="md"
                              borderRadius="xl"
                              mt={-2}
                              sx={{
                                "&": {
                                  transition: "0.2s",
                                },
                                "&:hover": {
                                  background: "#D3D3D3",
                                },
                              }}
                            >
                              {mode === "edit" ? (
                                <>
                                  <Tooltip title="Click to Change Banner" placement="top">
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      color="inherit"
                                      sx={navbarIconButton}
                                      aria-controls="notification-menu"
                                      aria-haspopup="true"
                                      variant="contained"
                                      onClick={handleOpenBannerMenu}
                                    >
                                      <img
                                        src={`${currentVenue.bannerUrl}`}
                                        width="340"
                                        height="160"
                                        alt="Venue Banner"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <PhotoDropzone
                                    title="Upload Banner"
                                    isOpen={isBannerOpen}
                                    onClose={handleCloseBannerMenu}
                                    anchorElem={bannerAnchorRef}
                                    listName="imageUrls"
                                    attribute="bannerUrl"
                                    urlPath=""
                                    imgHeight='100px'
                                    imgWidth='200px'
                                    apiUrl={`${API_URL}/upload/${currentVenue?.slug}/venues/banner`}
                                  />
                                </>
                              ) : (
                                <Icon fontSize="large">stadium</Icon>
                              )}
                            </MDBox>
                          </>
                        ) : (
                          <MDBox p={1} className={classes.blockedDropzone} alignItems="center">
                            <Grid direction="row" alignItems="center">
                              <MDTypography className={classes.text} component="label">
                                You must set a valid venue Id before you can upload a Logo
                              </MDTypography>
                            </Grid>
                          </MDBox>
                        )}
                      </Grid>
                    </Grid>
                    {errors?.logo && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.logo.message}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <MDBox mt={-5}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography component="label" variant="button" fontWeight="regular">
                      VENUE DESCRIPTION
                    </MDTypography>
                    <Button className={classes.previewButton} onClick={openPreviewModal}>
                      Preview
                    </Button>
                  </Stack>
                  <MDEditor
                    className={classes.editor}
                    {...venueDescription}
                    value={editorDescriptionValue || ""}
                    onChange={(e) => {
                      setEditorDescriptionValue(e);
                      setValue("description", e);
                    }}
                  />
                </MDBox>
                <MDBox mt={10}>
                  <Grid container>
                    <Grid item lg={6}>
                      <MDBox px={1}>
                        <MDTypography component="label" variant="button" fontWeight="regular">
                          STADIUM PEOPLE INFO
                        </MDTypography>
                        <MDEditor
                          className={classes.editor}
                          {...stadiumPeopleInfo}
                          value={editorStadiumPeopleValue || ""}
                          onChange={(e) => {
                            setEditorStadiumPeopleValue(e);
                            setValue("stadiumPeopleInfo", e);
                          }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item lg={6}>
                      <MDBox px={1}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MDTypography component="label" variant="button" fontWeight="regular">
                              EVENT MANAGEMENT
                            </MDTypography>

                            <Controller
                              {...selectContact1}
                              control={control}
                              defaultValue={currentVenue?.selectContact1}
                              render={({ field }) => (
                                <Autocomplete
                                  key={`autocomplete_selectContact2_${currentVenue?.slug}`}
                                  options={filteredUsers}
                                  name="selectContact1"
                                  defaultValue={null}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.target.innerText)}
                                  renderInput={(params) => (
                                    <FormField
                                      key={`autocomplete_selectContact1_${currentVenue?.slug}`}
                                      {...params}
                                      type="text"
                                      label="Select Contact"
                                    />
                                  )}
                                />
                              )}
                            />

                            <Controller
                              {...selectContact2}
                              control={control}
                              defaultValue={currentVenue?.selectContact1}
                              render={({ field }) => (
                                <Autocomplete
                                  key={`autocomplete_selectContact1_${currentVenue?.slug}`}
                                  options={filteredUsers}
                                  name="selectContact2"
                                  defaultValue={null}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.target.innerText)}
                                  renderInput={(params) => (
                                    <FormField
                                      key={`autocomplete_selectContact2_${currentVenue?.slug}`}
                                      {...params}
                                      type="text"
                                      label="Select Contact"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MDTypography component="label" variant="button" fontWeight="regular">
                              PAYROLL CONTACT
                            </MDTypography>

                            <Controller
                              {...payrollContact}
                              control={control}
                              defaultValue={currentVenue?.payrollContact}
                              render={({ field }) => (
                                <Autocomplete
                                  key={`autocomplete_payrollContact_${currentVenue?.slug}`}
                                  options={filteredUsers}
                                  name="payrollContact"
                                  defaultValue={null}
                                  value={field.value}
                                  onChange={(e) => field.onChange(e.target.innerText)}
                                  renderInput={(params) => (
                                    <FormField
                                      key={`autocomplete_payrollContact_${currentVenue?.slug}`}
                                      {...params}
                                      type="text"
                                      label="Select Contact"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </form>

        <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
        <Controller
          {...locations}
          control={control}
          render={({ field }) => (
            < AddVenueLocationModal
              setValue={setValue}
              getValues={getValues}
              saveChanges={saveAdditionalVenues}
              addVenueLocationModalOpen={addVenueLocationModalOpen}
              setAddVenueLocationModalOpen={setAddVenueLocationModalOpen}
              locations={field.value}
              currentVenue={currentVenue}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              locationIndex={locationIndex}
              setLocationIndex={setLocationIndex}
              mode={mode}
            />)}
        />
      </Card>
    )
  );
}

export default VenueMainInfoPanel;
