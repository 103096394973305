import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Icon, Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useQuery, useMutation } from "react-query";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import fetchevents from "layouts/pages/events/actions/fetchEvents";
import deleteEvent from "layouts/pages/events/actions/deleteEvent";
import useSort from "utils/useSort";
import Searchbar from "components/Searchbar";
import FiltersList from "components/FiltersList";
import PartnerTableActions from "../PartnerTableActions";

const useStyle = makeStyles({
    box: {
        marginTop: 20,
        overflow: "visible",
    },
    addButton: {
        fontSize: 40,
    }
});

const PartnerEventTimeClockTable = ({ title = "Partner Time Clock", filters, setFilters, fetchAll, setActionSelected, showFiltersList }) => {
    const classes = useStyle();
    const { venues, setCurrentEvent, setStateVar, } = useAppContextController();

    // Pagination state
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const { order, orderBy, toggleSort } = useSort();

    const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
    const allOptions = { ...options, filters, venues };

    // const { data: partners, isLoading } = useQuery(["partners", allOptions], () =>
    //     fetchpartners(allOptions)
    // );

    const partners = {
        "count": 4,
        "data": [
            {
                "partner": "Schreiner University Baseball",
                "lastName": "Taylor",
                "firstName": "Damon",
                "shirtSize": "L",
                "date": "02/27/2022",
                "timeIn": "05:02 PM",
                "timeOut": "03:00 AM"
            },
            {
                "partner": "Schreiner University Baseball",
                "lastName": "Davis",
                "firstName": "Rick",
                "shirtSize": "L",
                "date": "02/27/2022",
                "timeIn": "05:00 PM",
                "timeOut": "03:00 AM"
            },
            {
                "partner": "Schreiner University Baseball",
                "lastName": "Jackson",
                "firstName": "Duane",
                "shirtSize": "L",
                "date": "02/27/2022",
                "timeIn": "05:00 PM",
                "timeOut": "03:00 AM"
            },
            {
                "partner": "Schreiner University Baseball",
                "lastName": "Valdez",
                "firstName": "Juan",
                "shirtSize": "XL",
                "date": "02/27/2022",
                "timeIn": "04:52 PM",
                "timeOut": "03:00 AM"
            },
        ]
    }

    const columns = useMemo(
        () => [
            { title: "Partner Name", field: "partner", },
            { title: "Last Name", field: "lastName" },
            { title: "First Name", field: "firstName" },
            { title: "Size", field: "shirtSize" },
            {
                title: "Date",
                field: "date",
                customCell: (date) => moment(date).format("MM/DD/YYYY"),
            },
            {
                title: "Time in",
                field: "timeIn",
                customCell: (date) => moment(date).format("MM/DD/YYYY"),
            },
            {
                title: "Time out",
                field: "timeOut",
                customCell: (date) => moment(date).format("MM/DD/YYYY"),
            },
            {
                title: "Actions",
                field: "action",
                customCell: (id) => <PartnerTableActions id={id} setActionSelected={setActionSelected} />,
            },
        ],
        []
    );

    const navigate = useNavigate();
    const addNew = () => {
        navigate('/partners/create');
    };

    console.log(partners)
    return (
        <>
            {/* <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
                <Grid item >
                    <MDBox display="flex">
                        <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="4rem"
                            height="4rem"
                            variant="gradient"
                            bgColor="error"
                            color="white"
                            shadow="md"
                            borderRadius="xl"
                            ml={3}
                            mt={-4}
                        >
                            <Icon fontSize="large">handshake</Icon>
                        </MDBox>
                        <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                            {title}
                        </MDTypography>
                        <MDBox ml={3}>
                            {showFiltersList && (
                                <Grid item xs={6}>
                                    <FiltersList filters={filters} setFilters={setFilters} />
                                </Grid>
                            )}
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item display="flex" alignItems="center">
                    <Searchbar
                        fetch={fetchevents}
                        fetchAll={fetchAll}
                        placeholder="Add Members"
                        columns={columns.slice(1, columns.length - 1)}
                        queryCharacterLimit={1}
                        resultsLimit={10}
                        setFilters={setFilters}
                        setPage={setPage}
                        searchBy={["venueSlug", "eventName"]}
                        filterBy="venueSlug"
                    />
                    <MDBox>
                        <IconButton className={classes.addButton} color="info" onClick={addNew}>
                            <AddCircleRoundedIcon />
                        </IconButton>
                    </MDBox>
                </Grid>
            </Grid> */}
            <MDBox pt={2} pb={2}>
                <DataTable
                    columns={columns}
                    data={partners}
                    onRowClick={(event) => setCurrentEvent(event)}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    setLimit={setLimit}
                    order={order}
                    orderBy={orderBy}
                    toggleSort={toggleSort}
                    fetchAll={fetchAll}
                    // isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
                    greybar
                    divider
                />
            </MDBox>
        </>
    );
};
PartnerEventTimeClockTable.defaultProps = {
    title: "Partner Roster",
    showFiltersList: true,
};

// Typechecking props
PartnerEventTimeClockTable.propTypes = {
    title: PropTypes.string,
    showFiltersList: PropTypes.bool,
};

export default PartnerEventTimeClockTable;
