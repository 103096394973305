import { useState, useEffect } from "react";

import { useAppContextController } from "context/AppContext";
import { useAuth0 } from "@auth0/auth0-react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/bg-mascots.png";
import PictureAvatar from "components/PictureAvatar";

import { API_URL, IMAGE_SERVER } from "config";
// import { Avatar } from "@mui/material";

const { black } = colors;

function Header({ children }) {
  const { currentLoggedUser } = useAppContextController()
  const { user } = useAuth0();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  function stringAvatar(userName) {
    return {
      children: `${userName?.firstName[0]}${userName?.lastName[0]}`,
    };
  }
  return (
    <MDBox position="relative" >
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.1),
              rgba(gradients.info.state, 0.1)
            )}, url( ${currentLoggedUser?.bannerImg ? (`${IMAGE_SERVER}/users/${currentLoggedUser?._id}/banner/${currentLoggedUser?.bannerImg}`) : (backgroundImage)} )`,  // url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <PictureAvatar
              image={user?.picture && user.email.indexOf('@gmail.com') > -1 ? user.picture : currentLoggedUser?.profileImg}
              firstName={currentLoggedUser?.firstName}
              lastName={currentLoggedUser?.lastName}
              userId={currentLoggedUser?._id}
              size="xl"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {currentLoggedUser?.firstName} {currentLoggedUser?.lastName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {currentLoggedUser?.userType}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2} sx={{ ml: "auto" }}>
            <Tab
              label="Settings"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  settings
                </Icon>
              }
            />
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
