// import { useAppContextController } from "context/AppContext";
import { Modal } from "@material-ui/core";
import { Card, CardActions, CardContent, CardHeader, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const GenericModal = ({ open, setOpen, header, body, buttons, media = null }) => {
  const classes = useStyle();
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      {/* //     <Fade > */}
      <Card className={classes.container}>
        <CardContent>
          {media && <CardMedia>{media}</CardMedia>}
          {header && <CardHeader title={header} subheader="" />}
          {body && <CardContent>{body}</CardContent>}
          <CardActions>{buttons}</CardActions>
        </CardContent>
      </Card>
      {/* // </Fade> */}
    </Modal>
  );
};

export default GenericModal;
