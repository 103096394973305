/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TextField } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import moment from "moment-timezone";
import { default as momentParser } from "moment";
// import input from "assets/theme/components/form/input";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import MDBox from "components/MDBox";
import Tooltip from "@mui/material/Tooltip";
import DisplayTime from "./DisplayTime";
import EditTime from "./EditTime";
import { TimePicker } from "@mui/x-date-pickers";

const TimeInOut = ({
  id,
  field,
  clockInOut,
  currentEvent,
  eventDate,
  eventTime,
  timeZone,
  rosterObj,
  disabled,
  editThis = false,
}) => {
  const { currentLoggedUser } = useAppContextController();

  const [edit, setEdit] = useState(false);
  const [mode, setMode] = useState("display");
  const [time, setTime] = useState("");
  const [closePicker, setClosePicker] = useState(false);
  const [update, toggleUpdate] = useState(false);

  const toggleClosePicker = (e) => {
    toggleUpdate((prev) => !prev);
    // console.log("close", time, e);
    setClosePicker((prev) => !prev);
    setMode("display");
  };

  useEffect(() => {
    if (rosterObj) {
      let newTime = null;
      if (rosterObj.status === "Roster") {
        // if (field === "timeIn" || (field === "timeOut" && rosterObj.timeIn)) setDisabled(false);
        if (rosterObj[field]) newTime = moment(rosterObj[field]);
      }
      setTime(newTime);
    } else setTime(null);
  }, [rosterObj]);

  useEffect(() => {
    if (time) {
      // console.log("time", new Date(time).toISOString());
      rosterObj[field] = new Date(time).toISOString();
      rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
      clockInOut(null, rosterObj);
    }
  }, [update]);

  const findPosition = (posName) => {
    const pos = currentEvent.positions.find((item) => posName === item.positionName);
    return pos;
  };
  const onClickHandler = (e) => {
    if (disabled) {
      alert("Cannot Log Time");
      return;
    }
    // if primaryPosition is set on the rosterObj, we need to get the check-in time
    let reportTime = eventTime;
    let fullReportTime = new Date(currentEvent.eventDate).toISOString();
    if (currentEvent?.positions?.length && rosterObj.primaryPosition) {
      const pos = findPosition(rosterObj.primaryPosition);
      if (pos) {
        reportTime = new Date(pos.reportTime).toISOString().split("T")[1];
        fullReportTime = new Date(pos.reportTime).toISOString();
      }
    }
    rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    if (!disabled && !rosterObj?.[field]) {
      // this is where we need to set the clock in time to the report time if earlier
      const currentTime = moment().toISOString().split("T")[1];
      let ISOTime;
      console.log(
        "rick",
        new Date().toISOString(),
        new Date(`${eventDate}T${reportTime}`).toISOString()
      );
      if (
        field === "timeIn" &&
        (new Date().toISOString() < fullReportTime ||
          moment().toISOString().split("T")[0] > eventDate)
      ) {
        // const gmtDate = new Date(`${eventDate}T${reportTime}`);
        // const offset = gmtDate.getTimezoneOffset();
        // const MS_PER_MINUTE = 60000;
        // const localDate = new Date(gmtDate.getTime() - offset * MS_PER_MINUTE);
        // const localDate = new Date(gmtDate);
        // const localTime = localDate.toISOString().split("T")[1];
        ISOTime = fullReportTime;
      } else ISOTime = `${eventDate}T${currentTime}`;
      console.log("rick setting time", ISOTime);
      rosterObj[field] = ISOTime;
      // alert(ISOTime);
      setTime(rosterObj[field]);
      clockInOut(e, rosterObj);
      setEdit(false);
      setMode("display");
    }
  };

  const setManualTime = (tm) => {
    rosterObj[field] = new Date(tm).toISOString();
    // console.log("manual time", rosterObj[field], typeof rosterObj[field]);
    clockInOut(null, rosterObj);
  };

  // useEffect(() => {
  //   const closeTimePicker = (e) => {
  //     rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
  //     rosterObj[field] = new Date(time).toISOString();
  //     clockInOut(e, rosterObj);
  //     setEdit(false);
  //     setMode("display");
  //   };
  //   if (time && mode==="picker") closeTimePicker();
  // }, [closePicker]);

  const changeTimeHandler = (e) => {
    const tm = time;
    rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    const hours = momentParser(tm).hours();
    const mins = momentParser(tm).minutes();
    if (hours && mins) {
      rosterObj[field] = new Date(new Date(rosterObj[field]).setHours(hours, mins)).toISOString();
      clockInOut(e, rosterObj);
    }
    setEdit(false);
    setMode("display");
  };

  const undoTime = (e) => {
    rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    rosterObj[field] = "";
    clockInOut(e, rosterObj);
    setEdit(false);
    setMode("display");
  };

  if (disabled) {
    return (
      <MDBox textAlign="center">
        <Tooltip
          title={`Cannot ${field === "timeIn" ? "Clock In" : "Clock Out"} Employee - Status Is: ${
            rosterObj ? rosterObj.status : "Not On Roster"
          }`}
        >
          <NotInterestedIcon color={disabled ? "disabled" : "success"} fontSize="large" />
        </Tooltip>
      </MDBox>
    );
  }

  if (time !== null && mode === "undoOrEdit") {
    return (
      <DisplayTime
        mode={mode}
        setMode={setMode}
        time={time}
        field={field}
        timeZone={timeZone}
        setEdit={setEdit}
        undoTime={undoTime}
      />
    );
  }

  if (time !== null && mode === "edit") {
    return (
      <EditTime
        mode={mode}
        setMode={setMode}
        eventDate={eventDate}
        time={time}
        field={field}
        timeZone={timeZone}
        setEdit={setEdit}
        setTime={setTime}
        setManualTime={setManualTime}
      />
    );
  }

  if (time !== null && mode === "picker") {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          value={time ? moment(time) : ""}
          onChange={(newTime) => setTime(newTime)}
          onClose={(e) => toggleClosePicker(e)}
          renderInput={(params) => (
            <TextField
              sx={{ input: { textAlign: "center" } }}
              style={{ width: "100%" }}
              onBlur={(e) => changeTimeHandler(e, time)}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (time !== null && mode === "display") {
    return (
      <>
        {time && <div onClick={() => setMode("undoOrEdit")}>{moment(time)?.format("hh:mm a")}</div>}
      </>
    );
  }

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events

  // otherwise, show the clock in/out button
  return (
    <>
      <MDBox
        key={`${field}_button_${id}`}
        onClick={onClickHandler}
        bgColor={field === "timeIn" ? "success" : "error"}
        color="white"
        textAlign="center"
      >
        {field === "timeIn" ? "Clock In" : "Clock Out"}
      </MDBox>
    </>
  );
};

export default TimeInOut;
