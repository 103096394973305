import { useEffect, useState } from "react";
import moment from "moment";
import { API_URL } from "config";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import PropTypes from "prop-types";
import { Grid, Tooltip, InputLabel, MenuItem, FormControl } from "@mui/material";
import Select from '@mui/material/Select';
import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";

import { useAppContextController } from "context/AppContext";
import MDTypography from "components/MDTypography";

const DEFAULT_POSITION = "Event Staff"

const RosterPosition = ({ id, row, currentEvent, eventApplicants, toggleReadRoster }) => {
    const { currentLoggedUser, setCurrentEvent } = useAppContextController();
    const queryClient = useQueryClient();
    const [rosterPosition, setRosterPosition] = useState(DEFAULT_POSITION);
    const [mode, setMode] = useState("display");
    const [open, setOpen] = useState(false);
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        const rec = eventApplicants ? eventApplicants.find((item) => item.id === id) : null;
        if (rec?.primaryPosition) setRosterPosition(rec.primaryPosition);
        else setRosterPosition(DEFAULT_POSITION)
    }, [eventApplicants, currentEvent?.positions]);

    useEffect(() => {
        if (currentEvent?.positions) {
            setPositions(currentEvent.positions.map(item => ({ position: item.position, billRate: item.billRate, payRate: item.payRate })));
        }
    }, []);


    // useEffect(() => {
    //     if (rosterPosition) {
    //         if (rosterPosition === "StaffingPool") setColor("success")
    //         else if (rosterPosition === "Pending") setColor("warning")
    //         else if (rosterPosition === "Locked") setColor("error")
    //         else setColor("dark")
    //     } else setColor("dark");

    // }, [rosterPosition]);

    const rosterMutation = useMutation(
        async (data) => {
            // eslint-disable-next-line no-unused-expressions
            console.log('data', JSON.stringify(data))
            console.log('url', `${API_URL}/events/url/${currentEvent?.eventUrl}/roster`);
            await axios.put(`${API_URL}/events/url/${currentEvent?.eventUrl}/roster`, { ...data });
        },
        {
            // eslint-disable-next-line
            onSuccess: async (_, { data }) => {
                console.log('onSuccess', data);
                toggleReadRoster()
            },
            onError: (error) => alert(error.toString()),
        }
    );


    // console.log('RosterPosition', venue, row.venues, row.firstName, row.lastName, venueRec)

    const handleSelect = async (pos) => {
        console.log('rick', pos);
        //        e.stopPropagation();
        setRosterPosition(pos);
        setMode("display");
        setOpen(false);

        await rosterMutation.mutate({
            id,
            primaryPosition: pos,
            // dateModified: new Date(),
            agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
        });
    }

    const handleModeChange = e => {
        e.stopPropagation();
        if (mode === "display") {
            setMode("edit");
            setOpen(true);
        }
    }
    return (
        <span>
            {(mode !== "edit" || !currentEvent?.positions) ? (
                <Tooltip title={currentEvent?.positions ? 'Click to select a different position' : 'No additional positions available'}>
                    <MDTypography variant="body" onClick={handleModeChange}>{rosterPosition}</MDTypography>
                </Tooltip>
            ) : (
                <FormControl variant="standard" sx={{ m: 1 }}>
                    <InputLabel id="demo-multiple-name-label">Select Position</InputLabel>
                    <Select
                        open={open}
                        id="demo-multiple-name-label"
                        value={rosterPosition}
                        // onClick={handleSelect}
                        label="Position"
                    >
                        <MenuItem value={DEFAULT_POSITION} onClick={() => handleSelect(DEFAULT_POSITION)}>

                            <Grid container flexDirection="row" spacing={0}
                                sx={{ minWidth: 250 }}
                            >
                                <Grid item xs="8"><em>{DEFAULT_POSITION}</em></Grid>
                                {/* <Grid item xs="2.5">Pay-${pos?.payRate}</Grid>
                                    <Grid item xs="2.5">Bill-${pos?.billRate}</Grid> */}
                                <Grid item xs="3">Time {currentEvent?.reportTimeTBD ? currentEvent.reportTimeTBD : moment(currentEvent?.eventDate).format("hh:mm a")}</Grid>
                            </Grid>
                        </MenuItem>
                        {currentEvent?.positions.map(pos => {
                            return <MenuItem
                                value={pos.positionName}
                                onClick={() => handleSelect(pos.positionName)}
                            >
                                <Grid container flexDirection="row" spacing={0}
                                    sx={{ minWidth: 250 }}
                                >
                                    <Grid item xs="8">{pos.positionName}</Grid>
                                    {/* <Grid item xs="2.5">Pay-${pos?.payRate}</Grid>
                                    <Grid item xs="2.5">Bill-${pos?.billRate}</Grid> */}
                                    <Grid item xs="3">Time {moment(pos?.reportTime).format("hh:mm a")}</Grid>
                                </Grid>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            )}
        </span>
    )
}

export default RosterPosition;

RosterPosition.defaultProps = {
    id: "",
};

// Typechecking props for the TableAction
RosterPosition.propTypes = {
    id: PropTypes.string,
    // children: PropTypes.node.isRequired,
};