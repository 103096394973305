import { useAuth0 } from "@auth0/auth0-react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import { makeStyles } from "@mui/styles";
import backgroundImage from "assets/images/bg-mascots.png";
import brandWhite from "assets/images/SP-App-Store-Icon.png";
import MDButton from "components/MDButton";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
});

function Header() {
  const { loginWithRedirect, login, user, isLoading } = useAuth0();
  const classes = useStyle();

  return (
    <MDBox position="relative" flexDirection="row">
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(rgba("#FFF", 0.6), rgba("#FFF", 0.6))}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <Card
          sx={{
            display: "flex",
            maxWidth: "85%",
            my: 15,
            // mx: 20,
            py: 2,
            px: 2,
            opacity: 0.9,
          }}
        >
          <MDBox p={2} alignItems="center" display="flex" justifyContent="space-between">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="white"
              color="white"
              shadow="md"
              borderRadius="xl"
              mt={-2}
            >
              <img
                src={brandWhite}
                width="50"
                height="50"
                className={classes.logoImage}
                alt="Venue Logo"
              />
            </MDBox>
            <MDBox mx={3}>
              <MDTypography variant="body2" fontWeight="medium">
                Find your next gig with Stadium People! Our part time event gigs are the perfect
                supplement to any existing job, excellent and flexible retirement income, or fit
                with a busi school schedule. Once you apply and interview with our staff, you unlock
                hundreds of events per-year to work in one of our manu venues across the United
                States. <br />
                If you already work with Stadium People of have an account, no need to apply, just{" "}
                <MDButton size="small" color="info" onClick={() => loginWithRedirect()}>
                  sign in here
                </MDButton>{" "}
                to work
              </MDTypography>
              <div>{JSON.stringify(user)}</div>
            </MDBox>
            <Icon fontSize="large">person</Icon>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  );
}

export default Header;
