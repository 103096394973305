import { useState, useEffect } from "react";
import { useAppContextController } from "context/AppContext";
import { useQueryClient } from "react-query";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";

import { Button, Modal, Fade, Card, Grid, Autocomplete, Alert, Switch } from "@mui/material";
import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import addPosition from "assets/addPosition.json";
import { NumericFormat } from "react-number-format";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
  error: {
    fontSize: "0.75rem",
  },
});

const AddAditionalPositionModal = ({
  setValue,
  additionalPositionModalOpen,
  setAdditionalPositionModalOpen,
  currentEvent,
  setCurrentEvent,
  currentPosition,
  setCurrentPosition,
  positionIndex,
  setPositionIndex,
}) => {
  const classes = useStyle();
  const queryClient = useQueryClient();
  const handleClose = () => setAdditionalPositionModalOpen(false);
  const [position, setPosition] = useState(null);
  const [positionQty, setPositionQty] = useState(1);
  const [positionBillRate, setPositionBillRate] = useState(null)
  const [positionPayRate, setPositionPayRate] = useState(null)
  const [reportTimeState, setReportTimeState] = useState(null);
  const [makePublicForSignUp, setMakePublicForSignUp] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [validationError, setValidationError] = useState([]);
  const { currentLoggedUser } = useAppContextController();

  const reset = async () => {
    setCurrentPosition(null);
    setPosition(null);
    setPositionQty(1);
    setPositionBillRate(0)
    setPositionPayRate(0)
    setReportTimeState(null);
    setMakePublicForSignUp(false);
    setValidationError([]);
    setPositionIndex(null);
  };

  const onCancel = () => {
    reset();
    setAdditionalPositionModalOpen(false);
  };

  const validation = () => {
    const errorArr = [];
    let newError;
    if (position === null) {
      newError = "position";
      errorArr.push(newError);
    }
    if (currentEvent?.positions) {
      if (
        currentEvent?.positions.findIndex((e) => e.positionName === position) !== -1 &&
        currentEvent?.positions.findIndex((e) => e.positionName === position) !== positionIndex
      ) {
        newError = "repeatedPosition";
        errorArr.push(newError);
      }
    }
    if (positionQty === null) {
      newError = "positionQty";
      errorArr.push(newError);
    }
    if (positionQty < 1) {
      newError = "positionQtynegative";
      errorArr.push(newError);
    }
    if (positionPayRate < 1) {
      newError = "positionPayRateNegative";
      errorArr.push(newError);
    }
    if (!(/^\$?[0-9]+(\.[0-9]?[0-9])?$/.test(positionPayRate))) {
      newError = "positionPayRateFormat";
      errorArr.push(newError);
    }
    if (positionBillRate < 1) {
      newError = "positionBillRateNegative";
      errorArr.push(newError);
    }
    if (!(/^\$?[0-9]+(\.[0-9]?[0-9])?$/.test(positionBillRate))) {
      newError = "positionBillRateFormat";
      errorArr.push(newError);
    }
    if (parseFloat(positionBillRate) < parseFloat(positionPayRate)) {
      newError = "positionBillRateSmallerPay";
      errorArr.push(newError);
    }
    if (reportTimeState === null) {
      newError = "reportTimeState";
      errorArr.push(newError);
    }
    setValidationError(errorArr);
    return errorArr;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    validation();
    if (validation().length === 0) {
      const newPosition = {
        positionName: position,
        numberPositions: positionQty,
        payRate: positionPayRate,
        billRate: positionBillRate,
        reportTime: reportTimeState,
        agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
        modifiedDate: new Date(),
        makePublic: makePublicForSignUp,
      };
      if (positionIndex !== null) {
        /* eslint-disable no-param-reassign */
        currentEvent.positions[positionIndex] = newPosition;
      } else {
        const newPositionArr = currentEvent?.positions ? [...currentEvent?.positions] : [];
        newPositionArr.push(newPosition);
        setValue("positions", newPositionArr);
      }
      reset();
      setAdditionalPositionModalOpen(false);
    }
  };

  useEffect(() => {
    if (currentPosition) {
      setPosition(currentPosition.positionName);
      setPositionQty(currentPosition.numberPositions);
      setPositionPayRate(currentPosition.payRate)
      setPositionBillRate(currentPosition.billRate)
      setReportTimeState(currentPosition.reportTime);
      setMakePublicForSignUp(currentPosition.makePublic);
    }
  }, [currentPosition]);


  useEffect(() => {
    if (validationError.length > 0) {
      validation();
    }
  }, [position, positionQty, positionBillRate, positionPayRate, reportTimeState])


  return (
    <>
      <Snackbar
        additionalPositionModalOpen={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={additionalPositionModalOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={additionalPositionModalOpen}>
          <Card className={classes.container}>
            <form onSubmit={handleSubmit}>
              <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
                <MDTypography>Add Positions</MDTypography>
                <MDBox display="flex" alignItems="center">
                  <Button
                    variant="text"
                    startIcon={<CancelIcon />}
                    onClick={onCancel}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Save
                  </Button>
                </MDBox>
              </MDBox>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item sm={8}>
                    <Autocomplete
                      options={addPosition}
                      name="Position"
                      autoSelect
                      freeSolo
                      defaultValue={currentPosition?.positionName}
                      value={currentPosition?.positionName}
                      onChange={(e, v) => setPosition(v)}
                      renderInput={(params) => (
                        <FormField
                          defaultValue={currentPosition?.positionName}
                          value={currentPosition?.positionName}
                          {...params}
                          type="text"
                          label="Primary Position"
                        />
                      )}
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("position") && "You must enter a position"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("repeatedPosition") &&
                        "There is a position with this name already added"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4} >
                    <NumericFormat
                      defaultValue={currentPosition?.payRate}
                      InputProps={{
                        startAdornment: "$",
                        inputProps: {
                          inputMode: 'numeric',
                        }
                      }}
                      label="Pay Rate"
                      decimalScale={2}
                      onChange={(e) => setPositionPayRate(e.target.value)}
                      customInput={FormField}
                    />

                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionPayRateNegative") && "Pay Rate must be greater than 0"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionPayRateFormat") && "Pay Rate Must Be Numeric - $nnn.nn"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4}>
                    <FormField
                      defaultValue={currentPosition?.numberPositions}
                      onChange={(e) => setPositionQty(e.target.value)}
                      label="Position Qty"
                      InputProps={{
                        inputProps: {
                          defaultValue: 1,
                          value: positionQty,
                          min: 1,
                          type: "number",
                        },
                      }}
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionQty") && "You must select a quantity"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionQtynegative") &&
                        "You must select a value greater than 0"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <TimePicker
                        disablePast
                        label="Report Time"
                        value={reportTimeState}
                        defaultValue={currentPosition ? currentPosition?.reportTime : null}
                        onChange={(time) => {
                          const newDate = new Date(currentEvent.eventDate)
                          newDate.setTime(time)
                          setReportTimeState(newDate);
                        }}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("reportTimeState") &&
                        "You must select a report time"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4} >
                    <NumericFormat
                      defaultValue={currentPosition?.billRate}
                      InputProps={{
                        startAdornment: "$",
                        inputProps: {
                          inputMode: 'numeric',
                        }
                      }}
                      label="Bill Rate"
                      decimalScale={2}
                      onChange={(e) => setPositionBillRate(e.target.value)}
                      customInput={FormField}
                    />

                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionBillRateNegative") && "Bill Rate must be greater than 0"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionBillRateFormat") && "Bill Rate Must Be Numeric - $nnn.nn"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("positionBillRateSmallerPay") && "Bill Rate Must Be greater than the Pay Rate"}
                    </MDTypography>
                  </Grid>

                  <Grid item sm={8} display="flex" alignItems="center">
                    <Switch
                      defaultChecked={currentPosition?.makePublic}
                      onChange={(e) => setMakePublicForSignUp(e.target.checked)}
                    />
                    <MDTypography style={{ fontSize: "1rem" }}> Make Public for Sign-Up</MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default AddAditionalPositionModal;
