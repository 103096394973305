import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CustomTextField from "components/Form/CustomTextField";
import CircleIcon from '@mui/icons-material/Circle';
import MDTypography from "components/MDTypography";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import FormField from "layouts/applications/wizard/components/FormField";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Stack, Button, Checkbox, } from "@mui/material";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import pxToRem from "assets/theme/functions/pxToRem";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { NumericFormat } from "react-number-format";
import { w4Schema } from "data/w4form";
import { isEmpty } from "lodash";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    border: "1px solid",
  },
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  number: {
    textAlign: "end"
  },
  cardBox: {
    overflow: "visible",
  },
  button: {
    margin: `${pxToRem(2)}`,
  },
  label: {
    fontSize: "1rem!important"
  }
});

const FederalW4 = ({ currentApplicant, setCurrentApplicant, mode = "edit" }) => {

  const resolver = useYupValidationResolver(w4Schema);

  const classes = useStyle();
  const [childrensMultiplied, setChildrensMultiplied] = useState(null);
  const [dependentsMultiplied, setDependentsMultiplied] = useState(null);
  const [addedChildrensAndDependents, setAddedChildrensAndDependents] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: "onBlur" });


  const getApplicantFederalW4Values = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentApplicant?.[key] || "";
    });
    return values;
  };

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.id);
  };

  const queryClient = useQueryClient();

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");
      setCurrentApplicant({ ...currentApplicant, ...data });
      setToastAlert({ isShow: true, message: "Applicant has been updated!", status: "success" });
    },
  });

  const saveW4Changes = async (values) => {
    if (!currentApplicant?._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }

    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant._id,
      data: { dataw4Info: { values } },
    });
  };

  useEffect(() => {
    if (currentApplicant) {
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentApplicant[key] || "");
      });
      setIsModified(false);
    }
    const subscription = watch((value) => {
      if (currentApplicant) {
        setIsModified(JSON.stringify(getApplicantFederalW4Values()) !== JSON.stringify(value));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, currentApplicant]);

  useEffect(() => {
    setAddedChildrensAndDependents(childrensMultiplied + dependentsMultiplied);

  }, [childrensMultiplied, dependentsMultiplied])

  return (
    <form onSubmit={handleSubmit(saveW4Changes)} >
      {mode === "edit" && <MDBox display="flex" alignItems="center" justifyContent="end" mb={2}>
        <Button
          variant="text"
          startIcon={<CancelIcon />}
          // onClick={onCancel}
          // disabled={isSubmitting || !isModified}
          sx={{ padding: "0.5rem 1rem" }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          endIcon={<SaveIcon />}
          style={{ color: "white" }}
          // disabled={isSubmitting || !isModified}
          sx={{ padding: "0.5rem 1rem" }}
        >
          Save
        </Button>
      </MDBox>}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                defaultValue={currentApplicant?.firstName}
                label="First Name"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                defaultValue={currentApplicant?.lastName}
                label="Last Name"
                {...field}
              />
            )}
          />

        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="address1"
            control={control}
            render={({ field }) => (
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                defaultValue={currentApplicant?.lastName}
                label="Address"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                label="City"
                showError={!isEmpty(errors.city)}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                label="State"
                {...field} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="zip"
            control={control}
            render={({ field }) => (
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                label="Zip Code"
                {...field} />
            )}
          />
        </Grid>
        <MDBox p={3}>
          <Controller
            name="filingStatus"
            control={control}
            render={({ field }) => (
              <>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Checkbox
                    id="Single"
                    checked={field.value === "Single"}
                    icon={<CircleOutlinedIcon />}
                    checkedIcon={<CircleIcon />}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2"> Single or Married filing separately</MDTypography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Checkbox
                    id="Married"
                    checked={field.value === "Married"}
                    icon={<CircleOutlinedIcon />}
                    checkedIcon={<CircleIcon />}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">Married filing jointly (or Qualifying widow(er))</MDTypography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Checkbox
                    id="Head of Household"
                    checked={field.value === "Head of Household"}
                    icon={<CircleOutlinedIcon />}
                    checkedIcon={<CircleIcon />}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">Head of household (Check only if you&apos;re unmarried and pay more than half the costs of keeping up home for yourself and a qualifying individual) </MDTypography>
                </Stack>

              </>
            )
            }
          />
          <MDTypography variant="h6">Step 3: Claim Dependents</MDTypography>
          <Grid container spacing={1}>
            <Grid item sm={10}>
              <Grid container spacing={1}>
                <Grid item sm={10}>
                  <MDTypography >  Multiply the number of qualifying children under age 17 by $2000</MDTypography>
                </Grid>
                <Grid item sm={2}>
                  <Controller
                    name="numberOfChildren"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        InputLabelProps={{ className: classes.label }}
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            inputMode: "numeric",
                            type: "number",
                            step: 1,
                            min: 0,
                            max: 25,
                          },

                        }}
                        sx={{ input: { textAlign: "end" } }}
                        variant="filled"
                        label="Children"
                        decimalScale={0}
                        onValueChange={(e) => {
                          setChildrensMultiplied(Math.floor(Number(e.value) * 2000))
                          setValue("numberOfChildren", e.value)
                        }}
                        customInput={FormField}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <FormField
                type="number"
                disabled
                variant="filled"
                value={childrensMultiplied}
                sx={{ input: { textAlign: "end" } }}
                InputProps={{
                  inputProps: { tabIndex: -1 },
                  className: classes.input,
                  startAdornment: "$",
                  defaultValue: 0,
                }}
              />
              {errors?.numberOfChildren && (
                <MDTypography className={classes.error} color="error">
                  {errors?.numberOfChildren.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={10}>
              <Grid container spacing={1}>
                <Grid item sm={10}>
                  <MDTypography >  Multiply the number of other dependents by $500</MDTypography>
                </Grid>
                <Grid item sm={2}>
                  <Controller
                    name="otherDependents"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        InputLabelProps={{ className: classes.label }}
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            inputMode: "numeric",
                            type: "number",
                            step: 1,
                            min: 0,
                            max: 25,
                          }
                        }}
                        sx={{ input: { textAlign: "end" } }}
                        variant="filled"
                        label="Dependents"
                        decimalScale={0}
                        onValueChange={(e) => {
                          setDependentsMultiplied(Math.floor(Number(e.value) * 500))
                          setValue("otherDependents", e.value)
                        }}
                        customInput={FormField}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <FormField
                type="number"
                disabled
                variant="filled"
                value={dependentsMultiplied}
                sx={{ input: { textAlign: "end" } }}
                InputProps={{
                  className: classes.input,
                  startAdornment: "$",
                  inputProps: {
                    type: "number",
                    defaultValue: 0,
                  },
                }}
              />
              {errors?.otherDependents && (
                <MDTypography className={classes.error} color="error">
                  {errors?.otherDependents.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={10}>
              <MDTypography > Add the amounts above and enter the total here</MDTypography>
            </Grid>
            <Grid item sm={2}>
              <FormField
                type="number"
                disabled
                value={addedChildrensAndDependents}
                sx={{ input: { textAlign: "end" } }}
                variant="filled"
                InputProps={{
                  startAdornment: "$",
                  className: classes.input,
                  inputProps: {
                    type: "number",
                    defaultValue: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
          <MDTypography variant="h6">Step 4 (optional): Other Adjustments</MDTypography>
          <Grid container>
            <Grid item sm={10}>
              (a) Other income (not from jobs). If you want tax withheld for other income you expect
              this year that won&lsquo;t have whitholding, enter the amount of other income here.
              This may include interes, dividends, and retirement income.
            </Grid>
            <Grid item sm={2}>
              <Controller
                name="otherIncome"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      className: classes.input,
                      inputProps: {
                        inputMode: "numeric",
                        type: "number",
                        step: 0.01,
                        min: 0,
                        max: 25,
                      }
                    }}
                    sx={{ input: { textAlign: "end" } }}
                    variant="filled"
                    decimalScale={2}
                    customInput={FormField}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={10}>
              (b) Deductions. If you expect to claim deductions other than the stantard deduction
              and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter
              the result here
            </Grid>
            <Grid item sm={2}>
              <Controller
                name="deductions"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      className: classes.input,
                      inputProps: {
                        inputMode: "numeric",
                        type: "number",
                        step: 0.01,
                        min: 0,
                        max: 25,
                      }
                    }}
                    sx={{ input: { textAlign: "end" } }}
                    variant="filled"
                    decimalScale={2}
                    customInput={FormField}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={10}>
              (c) Extra withholding. Enter any additional tax you want withheld each pay period.
            </Grid>
            <Grid item sm={2}>
              <Controller
                name="extraWithholding"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      className: classes.input,
                      inputProps: {
                        inputMode: "numeric",
                        type: "number",
                        step: 0.01,
                        min: 0,
                        max: 25,
                      }
                    }}
                    sx={{ input: { textAlign: "end" } }}
                    variant="filled"
                    decimalScale={2}
                    customInput={FormField}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>

      </Grid>
    </form >
  );
};

export default FederalW4;
