import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import GenericModal from "components/GenericModal";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import SendMessage from "components/SendMessage";
import AddANote from "components/AddANote";
import noteTypes from "assets/noteTypes.json";
import MDBox from "components/MDBox";
import postNote from "layouts/pages/applicants/actions/postNote";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";

const EventActionModal = ({ modalInfo, setModalInfo, open, setOpen, setToastAlert, toggleReadApps }) => {
  const { currentLoggedUser, currentEvent } = useAppContextController();
  const applicantId = modalInfo?.data?._id;
  const currentApplicant = modalInfo?.data;
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  let modalBody;
  let header;
  let buttons;

  const saveChanges = notes => {
    const update = async () => {
      let res
      await updateApplicant({ applicantId, data: { notes } })
        .then(response => {
          res = response
          setToastAlert({ isShow: true, message: "Notes Have Been Successfully Updated", status: "success" });
          toggleReadApps();
          setModalInfo({ ...modalInfo, data: { ...modalInfo.data, notes } })
        })
        .catch(response => {
          res = response
          setToastAlert({ isShow: true, message: `Something went wrong! ${response}`, status: "error" })
        });
      return res;
    }
    update();
  }

  const saveNote = (noteRec) => {
    // first, we need to add the note to applicant notes
    const newNote = {
      type: noteRec.type,
      text: `<p><strong>${currentEvent?.venueSlug ? currentEvent.venueSlug.toUpperCase() : ''} - ${currentEvent?.eventName}</strong>:<br />${moment(new Date(currentEvent?.eventDate)).format("ddd MM/DD/YYYY hh:mm a")}:<br /><blockquote>${noteRec.text}</blockquote></p>`,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };

    if (currentEvent?.eventUrl) newNote.eventUrl = currentEvent.eventUrl;

    const updateNewNote = async () => {
      let res
      await postNote({ applicantId, data: newNote })
        .then(response => {
          res = response
          setToastAlert({ isShow: true, message: "Notes Have Been Successfully Updated", status: "success" });
          toggleReadApps();
        })
        .catch(response => {
          res = response
          setToastAlert({ isShow: true, message: `Something went wrong! ${response}`, status: "error" })
        });
      return res;
    }

    return updateNewNote();

    // now we need to update the Event Roster Record with this note
    // TODO: 
  };

  if (modalInfo.type === "applicantMail") {
    header = (
      <Box display="flex" key={`box_sendMessage_${modalInfo.data.id}`}>
        <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
          <MDTypography variant="h5" color="dark">
            Send Message to {modalInfo?.data.lastName} {modalInfo?.data.firstName}
          </MDTypography>
          {email && (
            <MDTypography variant="body2" color="info" fontWeight="bold">
              {`E-mail: ${modalInfo?.data.email}`}
            </MDTypography>
          )}
          {text && (
            <MDTypography variant="body2" color="info" fontWeight="bold">
              {`Phone: ${modalInfo?.data.phone}`}
            </MDTypography>
          )}
        </MDBox>
      </Box>
    );
    modalBody = (
      <Box>
        <SendMessage
          recipient={modalInfo?.data}
          setToastAlert={setToastAlert}
          setOpen={setOpen}
          text={text}
          setText={setText}
          email={email}
          setEmail={setEmail}
        />
      </Box>
    );
  } else if (modalInfo.type === "applicantComment") {
    header = (
      <MDTypography
        variant="h5"
        color="dark"
        sx={{ mt: 2, mb: 1, ml: 2 }}
        key={`text_addNote_${modalInfo.data.id}`}
      >
        Add An Event Note For: {currentApplicant?.firstName} {currentApplicant?.lastName}
      </MDTypography>
    );
    modalBody = <AddANote noteTypes={noteTypes} setOpen={setOpen} saveNote={saveNote}
      saveChanges={saveChanges} applicant={currentApplicant} currentEvent={currentEvent} />;
  }

  // setModalBody(<div>{JSON.stringify(row)}</div>);

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
      />
    </>
  );
};

export default EventActionModal;
