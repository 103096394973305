import { useEffect, useState } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import MDAvatar from "components/MDAvatar";
import { Avatar } from "@mui/material";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import colors from "assets/theme/base/colors";
import { API_URL, IMAGE_SERVER } from "config";
import { makeStyles } from "@mui/styles";
import { useFieldArray } from "react-hook-form";

const useStyle = makeStyles({
  avatar: {
    height: "50px",
    width: "50px",
  },
});

const PictureAvatar = ({ image, firstName, lastName, userId, size = "md" }) => {
  const [avatarImage, setAvatarImage] = useState(image);
  useEffect(() => {
    const getUser = async (id) => {
      const user = await fetchUserById(id);
      console.log(`getting user for ${id}`, user);
      if (user?.profileImg) setAvatarImage(user.profileImg);
    };
    console.log("PictureAvatar", image, firstName, lastName, userId);
    if (!image && userId) getUser(userId);
  }, []);

  let imageUrl = null;
  if (avatarImage && avatarImage.substring(0, 5) === "https") imageUrl = avatarImage;
  else if (avatarImage) imageUrl = `${IMAGE_SERVER}/users/${userId}/photo/${avatarImage}`;
  const classes = useStyle();
  const { primary } = colors;
  function stringAvatar(record) {
    if (record?.firstName && record?.lastName)
      return {
        children: `${record?.firstName[0]}${record?.lastName[0]}`,
      };
    return { children: "" };
  }
  return (
    <>
      {imageUrl ? (
        <>
          <MDAvatar
            src={imageUrl}
            alt="profile-image"
            shadow="lg"
            // className={classes.avatar}
            size={size}
            referrerpolicy="no-referrer"
          />
        </>
      ) : (
        <Avatar
          sx={{ fontWeight: "bold", bgcolor: primary.focus }}
          {...stringAvatar({ firstName, lastName })}
          className={classes.avatar}
        />
      )}
    </>
  );
};

export default PictureAvatar;
