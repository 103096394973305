import PropTypes from "prop-types";
import { useState } from "react";
// import { useAppContextController } from "context/AppContext";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import FilterCard from "components/FilterCard";
import MDBox from "components/MDBox";

function FilterCardBar({ cards, parent, bgColor, color, setFilters, setPage, setCards }) {
  // const { currentVenueSelector, currentJobsSelector, setStateVar } = useAppContextController();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectHandler = (filterBy, filterValue, index, custom) => {
    if (!filterValue)
      setFilters({});
    // else if (custom) {
    //   console.log('here', custom)
    //   setFilters({ [filterBy]: custom() })
    // }
    else
      setFilters({
        [filterBy]: filterValue,
      });

    setSelectedIndex(index);
    if (setPage) setPage(1);
  };

  return (
    <>
      {cards && cards.length && (
        <MDBox>
          <Grid container spacing={3}>
            {cards.map((card, i) => (
              <Grid item key={`item_${card.title}`}>
                <FilterCard
                  // setSelector={parent==='Venue' ? setCurrentVenueSelector : setCurrentJobsSelector}
                  bgColor={bgColor}
                  color={card.color || color}
                  icon={card.icon}
                  title={card.title}
                  number={card.number ? card.number : null}
                  filterField={card.filterField}
                  filterValue={card.filterValue}
                  selectedIndex={selectedIndex}
                  index={i}
                  onSelectHandler={onSelectHandler}
                  customFilterFunction={card.customFilterFunction}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
    </>
  );
}

// Setting default values for the props of FilterCard
FilterCardBar.defaultProps = {
  //  number: null,
  parent: "Venue",
  bgColor: "info",
  color: "white",
};

// Typechecking props for the FilterCard
FilterCardBar.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      number: PropTypes.number,
    })
  ).isRequired,
  parent: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};
export default FilterCardBar;
