import { useState, useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import Menu from "@mui/material/Menu";
import FileDropzone from "components/Dropzone";

import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";
import { Alert, Snackbar, Stack, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button"

import useStyles from "layouts/pages/venues/components/VenueMainInfoPanel/styles";
import { useAppContextController } from "context/AppContext";
import updateVenue from "layouts/pages/venues/actions/updateVenue";

import { API_URL, IMAGE_SERVER } from "config";

import axios from "axios";
import deleteImage from "layouts/pages/venues/actions/deleteImage";


const imageUrlBase = `${process.env.REACT_APP_IMAGE_SERVER || "http://images.stadiumpeople.com/sp"
  }`;

const PhotoDropzone = ({
  isOpen,
  onClose,
  anchorElem,
  listName,
  apiUrl,
  urlBase,
  urlPath = "",
  attribute = "",
  title = "",
  // imgHeight = '50px',
  // imgWidth = '50px'
  widthAttribute
}) => {

  const styles = useStyles();
  const { currentVenue, setCurrentVenue } = useAppContextController();
  const queryClient = useQueryClient();

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const deleteImageMutation = useMutation(deleteImage, {
    onError: () => setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" })
  })

  const updateVenueMutation = useMutation(updateVenue, {
    onError: () => setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("venues");
      const newData = {
        ...data,
        logoUrl: data.logoUrl ? `${imageUrlBase}/${currentVenue?.slug}/venues/logo/${data?.logoUrl}` : currentVenue?.logoUrl,
        logoUrls: data.logoUrls ? data.logoUrls.map(logo => `${imageUrlBase}/${currentVenue?.slug}/venues/logo/${logo}`) : currentVenue?.logoUrls,
        bannerUrl: data.bannerUrl ? `${imageUrlBase}/${currentVenue?.slug}/venues/banner/${data.bannerUrl}` : currentVenue?.bannerUrl,
        imageUrls: data.imageUrls ? data.imageUrls.map(banner => `${imageUrlBase}/${currentVenue?.slug}/venues/banner/${banner}`) : currentVenue?.imageUrls,
      }
      setCurrentVenue({ ...currentVenue, ...newData });
      setToastAlert({ isShow: true, message: "Venue has been updated!", status: "success" });
    },
  });

  const deleteImageFromServer = async (values) => {
    if (!currentVenue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    await deleteImageMutation.mutateAsync({
      venueId: currentVenue._id,
      data: values
    })
  }

  const saveChanges = async (values) => {
    // evaluate();
    if (!currentVenue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: values,
    });
  };

  const getLastNode = path => {
    const pathArr = path.split('/');
    if (pathArr?.length) return pathArr[pathArr.length - 1];
    return path;
  }

  const handleLogoOnClick = (img) => {
    const existingArr = currentVenue[listName].map(item => getLastNode(new URL(item)?.pathname));
    const newLogoUrls = currentVenue[listName] ? Array.from(new Set([...existingArr, img])) : [img];

    const obj = {};

    obj[attribute] = img
    obj[listName] = [...newLogoUrls]

    saveChanges(obj);
  }

  const handleLogoDelete = (idx, e) => {
    e.preventDefault();

    if (currentVenue[listName].length > 1) {
      const spliced = currentVenue[listName].splice(idx, 1)[0]

      const obj = {};

      // if currently selected icon is deleted then move it to the first icon in list
      if (getLastNode(new URL(currentVenue[attribute])?.pathname) === getLastNode(new URL(spliced)?.pathname)) {
        const imageArr = currentVenue[listName]
        obj[attribute] = imageArr.length ? getLastNode(new URL(imageArr[0])?.pathname) : ''
        obj[listName] = currentVenue[listName]?.map(item => getLastNode(new URL(item)?.pathname))
      }
      else {
        obj[listName] = currentVenue[listName]?.map(item => getLastNode(new URL(item)?.pathname))
      }

      // update venue
      saveChanges(obj)

      // delete image from server
      deleteImageFromServer({
        url: getLastNode(new URL(spliced)?.pathname),
        path: `${currentVenue?.slug}/venues/${attribute.substring(0, attribute.indexOf('Url'))}/${getLastNode(new URL(spliced)?.pathname)}`,
        list: listName
      })
    }
    else {
      setToastAlert({ isShow: true, message: `Must have at least one ${attribute.substring(0, attribute.indexOf('Url'))}`, status: "error" })
    }

  }

  const handleOnDrop = async (acceptedFiles, rejectedFiles) => {

    const [file] = acceptedFiles;

    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" })
    }
    if (acceptedFiles.length !== 0) {
      const form = new FormData();
      form.append("file", file);
      await axios.post(apiUrl, form);
      handleLogoOnClick(file.name);
    }
  }

  const LogoList = ({ imageList, height = '50px', width = widthAttribute }) => {
    return (
      <Stack spacing={2} direction="row" sx={{ maxWidth: '315px', minHeight: '130px', overflowX: 'scroll' }}>
        {imageList.map((img, idx) => (
          <MDBox key={img} className={styles.section_image_container} sx={{ width, height }} >
            <IconButton
              color="info"
              sx={{
                position: "absolute",
                right: "-10px",
                top: "0px",
                zIndex: 1,
                padding: 0.5,
                backgroundColor: "#FFF"
              }}
              onClick={(e) => handleLogoDelete(idx, e)}
            >
              <Icon fontSize="small">cancel</Icon>
            </IconButton>
            <MDBox sx={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'padding-top': '10px', '&': { 'transitionDelay': '0.2s' } }}>
              <Card sx={{ width, height: '100px' }} onClick={(e) => handleLogoOnClick(getLastNode(new URL(img)?.pathname), e)} >
                <img src={(!img.startsWith('http://') ? `${urlPath}${img}` : img)} key={img} alt="Not Found" style={{ 'height': '100px', 'width': width }} />
              </Card>
            </MDBox>
          </MDBox>
        ))
        }
      </Stack>
    )
  }

  return (
    <div id={`${attribute}_Menu`}>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>

      <Menu
        anchorEl={anchorElem}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(isOpen)}
        onClose={onClose}
        sx={{ mt: 2 }}
      >
        <MDTypography variant="h5">{title}</MDTypography>

        <Stack spacing={1} direction="column">
          <MDBox>
            <FileDropzone message="Click here to Upload"
              onDrop={handleOnDrop}
              key={`${attribute}_Dropzone`}
              maxSize={5000000}
              restrict={{ 'image/*': [] }} />
          </MDBox>

          {currentVenue[listName] && currentVenue[listName].length > 0 &&
            (<Item>
              <LogoList
                urlBase={urlBase}
                path={urlPath}
                imageList={currentVenue[listName]}
              />
            </Item>)
          }

        </Stack>
      </Menu>
    </div>
  )
};

export default PhotoDropzone;