import fetchApplicantByCode from "layouts/pages/applicants/actions/fetchApplicantByCode";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { useSnackbar } from "notistack";
import { useCallback, useReducer } from "react";
import newApplicantReducer, { newApplicantInitialState } from "../reducers/newApplicantReducer";

const useNewApplicant = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(newApplicantReducer, newApplicantInitialState);

  const onNextStep = useCallback(() => {
    const activeStepId = state.activeStepId >= 7 ? 7 : state.activeStepId + 1;
    dispatch({ type: "SET_REGISTRATION_STEP", data: activeStepId });
  });

  const onPreviousStep = useCallback(() => {
    const activeStepId = state.activeStepId <= 1 ? 1 : state.activeStepId - 1;
    dispatch({ type: "SET_REGISTRATION_STEP", data: activeStepId });
  });

  const setActiveStep = useCallback((stepId) => {
    dispatch({ type: "SET_REGISTRATION_STEP", data: stepId });
  });

  const initializeApplicant = useCallback(async (applicantCode) => {
    try {
      const { data } = await fetchApplicantByCode(applicantCode);
      if (data) {
        dispatch({ type: "UPDATE_APPLICANT", data });
      } else {
        dispatch({ type: "UPDATE_APPLICANT_ERROR", error: "Error finding applicant" });
      }
      return data;
    } catch (err) {
      if (err.message === "Request failed with status code 404") return [];
      throw new Error(err.toString());
    }
  });

  const createApplicant = useCallback((data) => {
    dispatch({ type: "CREATE_APPLICANT", data });
  });

  const createI9Form = useCallback((data) => {
    dispatch({ type: "CREATE_I_9_FORM", data });
  });

  const updateApplicantAction = useCallback(async (applicantId, data) => {
    try {
      const res = await updateApplicant({ applicantId, data });
      if (res.acknowledged) {
        enqueueSnackbar("Applicant info saved.", { variant: "success" });
        dispatch({ type: "UPDATE_APPLICANT", data });
      } else {
        enqueueSnackbar("Error Encountered.", { variant: "error" });
        dispatch({ type: "UPDATE_APPLICANT_ERROR", error: "Updated failed. Error encountered." });
      }
      return data;
    } catch (err) {
      if (err.message === "Request failed with status code 404") return [];
      throw new Error(err.toString());
    }
  });

  const getActiveRegistrationStep = useCallback(
    () => state.registrationSteps.find((item) => item.id === state.activeStepId),
    [state]
  );

  return {
    ...state,
    onNextStep,
    onPreviousStep,
    initializeApplicant,
    createApplicant,
    createI9Form,
    getActiveRegistrationStep,
    setActiveStep,
    updateApplicantAction,
  };
};

export default useNewApplicant;
