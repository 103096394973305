import { useMemo } from "react";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PublicIcon from '@mui/icons-material/Public';
import BoltIcon from '@mui/icons-material/Bolt';
import HistoryIcon from '@mui/icons-material/History';
import FiltersList from "components/FiltersList";

const EventsFilterCards = ({ filters, setFilters, setPage }) => {
  const { setCurrentEvent, currentLoggedUser } = useAppContextController();
  const myVenues = currentLoggedUser?.venues?.length ? currentLoggedUser.venues.join(';') : ""

  const cards = useMemo(() => [
    {
      color: "info",
      icon: <EventAvailableIcon fontSize="large" color="info" />,
      title: "Event",
      number: 0,
      filterField: "eventType",
      filterValue: "Event",
      canCombine: true,
    },
    {
      color: "warning",
      icon: <MeetingRoomIcon fontSize="large" color="warning" />,
      title: "Interview",
      number: 0,
      filterField: "eventType",
      filterValue: "Interview",
      canCombine: true,
    },
    {
      color: "success",
      icon: <BoltIcon fontSize="large" color="success" />,
      title: "Current",
      number: 0,
      filterField: "timeFrame",
      filterValue: "Current",
      canCombine: true,
    },
    {
      color: "error",
      icon: <HistoryIcon fontSize="large" color="error" />,
      title: "Past",
      number: 0,
      filterField: "timeFrame",
      filterValue: "Past",
      canCombine: true,
    },
    {
      color: "info",
      icon: <EventAvailableIcon fontSize="large" color="info" />,
      title: "MyEvents",
      number: 0,
      filterField: "venueSlug",
      filterValue: myVenues,
      canCombine: true,
    },
    {
      color: "dark",
      icon: <PublicIcon fontSize="large" color="black" />,
      title: "All",
      number: 0,
      filterField: "eventType",
      filterValue: "",
    },
  ]);

  const setEventFilters = (filter) => {
    setCurrentEvent(null);
    if (filter !== {}) {
      const filterFromCard = cards.find(item => Object.keys(filter).includes(item.filterField))
      if (filterFromCard?.canCombine) {
        setFilters({ ...filters, ...filter });
      } else setFilters(filter);
    } else setFilters({});
  }
  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Events"
        bgColor="primary"
        setFilters={setEventFilters}
        setPage={setPage} />
    </span>
  )
}

export default EventsFilterCards;

