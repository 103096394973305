import axios from "axios";

import { API_URL } from "config";
import createApiParams from "utils/createApiParams";

const fetchUsers = async ({ page, limit, order, orderBy, fetchAll, filters, venues }) => {

  try {
    const { paginationQuery, sortQuery, filterQuery } = createApiParams({ page, limit, order, orderBy, fetchAll, filters })
    const res = await axios.get(`${API_URL}/users${paginationQuery}${sortQuery}${filterQuery}`);

    if (res?.data?.data?.length) {
      // const retData = res.data.data.map((job) => {
      //   return { ...job, logoUrl: venues?.[job?.venueSlug]?.logoUrl, venueName: venues?.[job?.venueSlug]?.name };
      // });
      // res.data = { ...res.data, data: [...retData] };
      res.data = { ...res.data };
    }

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404")
      return [];
    throw new Error(err.toString());
  }
};

export default fetchUsers;
