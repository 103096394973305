import {
  AccountBalance,
  AccountCircle,
  Badge,
  CheckCircle,
  PersonAdd,
  RequestPage,
  ThumbUp,
} from "@mui/icons-material";

const CommonIconProps = {
  width: "18px",
  height: "18px",
  color: "white",
};

export const NEW_APPLICANT_STEPS = [
  { id: 1, label: "Job Application", icon: <PersonAdd {...CommonIconProps} /> },
  {
    id: 2,
    label: "U.S. I-9 Form",
    icon: <AccountCircle {...CommonIconProps} />,
  },
  {
    id: 3,
    label: `Upload ID's`,
    icon: <Badge {...CommonIconProps} />,
  },
  {
    id: 4,
    label: "W-4 Tax Forms",
    icon: <RequestPage {...CommonIconProps} />,
  },
  {
    id: 5,
    label: "Direct Deposit",
    icon: <AccountBalance {...CommonIconProps} />,
  },
  {
    id: 6,
    label: "Acknowledgement",
    icon: <ThumbUp {...CommonIconProps} />,
  },
  {
    id: 7,
    label: "Complete!",
    icon: <CheckCircle {...CommonIconProps} />,
  },
];
