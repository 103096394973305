import { Cancel, Save } from "@mui/icons-material";
import { Card, CardContent, Modal, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import FormErrors from "components/Form/FormErrors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import postEmailTemplates from "context/actions/postEmailTemplates";
import updateEmailTemplate from "context/actions/updateEmailTemplate";
import FormField from "layouts/applications/wizard/components/FormField";
import { useCallback, useEffect } from "react";
import { parseMessage, parseMessageFromDB } from "utils/helpers/emailTemplate";
import EmailVariableTable from "./EmailVariableTable";

const EmailTemplateModal = ({ isOpen, template, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { control, handleSubmit, formState, reset } = useForm();
  const { errors } = formState;

  const discardModal = useCallback(() => {
    reset(
      { message: "", name: "", subject: "" },
      {
        keepValues: false,
      }
    );
    onClose();
  }, [onClose]);

  const onSubmit = async ({ message, ...data }) => {
    const parsed = parseMessage(message);
    const body = { Message: parsed, ...data };
    try {
      let res;
      if (template?._id) {
        res = await updateEmailTemplate(template._id, body);
      } else {
        res = await postEmailTemplates(body);
      }
      console.log(res);
      if (res.status === 201 || res.acknowledged) {
        discardModal();
        await queryClient.invalidateQueries("messagetemplates");
        enqueueSnackbar("Email template created.", { variant: "success" });
      }
    } catch (err) {
      enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
      throw new Error(err.toString());
    }
  };

  useEffect(() => {
    if (template && isOpen) {
      reset({
        name: template?.name || "",
        subject: template?.subject,
        message: template?.Message ? parseMessageFromDB(template?.Message) : "",
      });
    }
  }, [template, isOpen]);

  return (
    <Modal open={isOpen} onClose={discardModal}>
      <Card
        sx={{
          position: "absolute",
          width: "60%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 50,
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FlexWrapper position="relative" p={3} justifyContent="flex-start" gap="10px">
              <Typography variant="h5" color="dark" sx={{ width: "100%" }}>
                Message Template
              </Typography>
              <Controller
                name="name"
                rules={{ required: "Message title is required" }}
                control={control}
                render={({ field }) => (
                  <FormField label="Message Title" sx={{ maxWidth: 500 }} {...field} />
                )}
              />
              <Controller
                name="subject"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormField label="Subject Line" sx={{ maxWidth: 500 }} {...field} />
                )}
              />

              <EmailVariableTable />

              <MDBox
                mb={8}
                width="100%"
                sx={{
                  "& .quill": {
                    height: "200px",
                  },
                }}
              >
                <MDTypography variant="body2" color="dark">
                  MESSAGE
                </MDTypography>
                <Controller
                  name="message"
                  control={control}
                  defaultValue="<p></p>"
                  render={({ field }) => <MDEditor {...field} />}
                />
              </MDBox>
              <FormErrors errors={errors} />
              <FlexWrapper mt="15px" justifyContent="flex-start">
                {/* <MDTypography variant="body2" color="info">
                  Upload Image
                </MDTypography> */}
              </FlexWrapper>
              <FlexWrapper justifyContent="flex-end">
                <MDButton color="info" variant="contained" endIcon={<Save />} type="submit">
                  {template ? "Update" : "Save"}
                </MDButton>
                <MDButton color="info" variant="text" disabled>
                  Preview
                </MDButton>
                <MDButton
                  onClick={discardModal}
                  color="error"
                  variant="text"
                  endIcon={<Cancel />}
                  sx={{ padding: 0 }}
                >
                  Cancel
                </MDButton>
              </FlexWrapper>
            </FlexWrapper>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default EmailTemplateModal;
