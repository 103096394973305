import { useEffect, useState } from "react";
import { useNewApplicantContext } from "context/NewApplicantContext";
import Attachments from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import MDTypography from "components/MDTypography";


const UploadID = () => {

    const { applicant } = useNewApplicantContext();
    const [attachmentOpen, setAttachmentOpen] = useState(false)

    const attachemntTitle = (<MDTypography component="label" variant="button" fontWeight="bold" color="info">
        Upload any photos and attachments here
    </MDTypography>)

    return (
        <>
            <Attachments currentApplicant={applicant} setOpen={setAttachmentOpen} attachemntTitle={attachemntTitle} />
            <UploadFileModal currentApplicant={applicant} open={attachmentOpen} setOpen={setAttachmentOpen} />
        </>
    )
}

export default UploadID