import * as yup from "yup";

export const w4Schema = yup.object().shape({
  filingStatus: yup.string().default(""),
  numberOfChildren: yup
    .number()
    .moreThan(-1)
    .lessThan(25)
    .default("Value can't be greater than 25")
    .default(0),
  otherDependents: yup
    .number()
    .moreThan(-1)
    .lessThan(25)
    .default("Value can't be greater than 25")
    .default(0),
  otherIncome: yup.number().moreThan(-1).default(0),
  deductions: yup.number().moreThan(-1).default(0),
  extraWithholding: yup.number().moreThan(-1).default(0),
});
