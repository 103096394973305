import { useState } from "react";
import parse from "html-react-parser";
import { Card, Grid, Modal, Typography, Button } from "@material-ui/core";
import { Avatar, Box, Fade, Icon, IconButton, Link, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EventDate from "components/EventDate";
import { useAppContextController } from "context/AppContext";
import { IMAGE_SERVER } from "config";
import DateRangeIcon from "@mui/icons-material/DateRange";
// import moment from 'moment';
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment";
import WaitlistModal from "./WaitlistModal";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 1170,
    bgcolor: "none",
    overflow: "hidden",
    borderRadius: 5,
    px: 4,
    py: 6,
  },

  wideBgImageContainer: {
    width: "100%",
    height: "200px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
    overflow: "hidden",
    borderRadius: 5,
  },
  wideBgImage: { width: "100%", overflowY: "auto", height: "100%" },

  modalBody: {
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    mt: 6,
    px: 6,
    py: 6,
    mx: 15,
  },
};

const searchGoogleMaps = (address, city, state, zip) => {
  const search = `${address || ""}+${city || ""}+${state || ""}+${zip || ""}`;
  window.open(`http://maps.google.com/maps?q=loc:${search}`);
};

const EventsModal = ({ open, setOpen, eventPreview }) => {
  const { venues } = useAppContextController();
  const [openWaitlistModal, setOpenWaitlistModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const textColor = "#7c97a9";

  // const venue = Object.values(venues).find((obj) => obj.name === eventPreview.venueName);
  const venue = venues?.[eventPreview?.venueSlug];
  const navigate = useNavigate();

  const navigateToEdit = () => {
    navigate(`/events/${eventPreview.eventUrl}/action/eventsinfo`);
    setOpen(false);
  };

  const navigateToRoster = () => {
    navigate(`/events/${eventPreview.eventUrl}/action/eventsroster`);
    setOpen(false);
  };


  const uniform = eventPreview?.attachments?.find((attachment) => {
    return attachment.type === "Uniform Guidelines";
  });
  const parking = eventPreview?.attachments?.find((attachment) => {
    return attachment.type === "Employee Parking Map";
  });


  return (
    <div>
      <Modal
        open={open}
        disableAutoFocus
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card>
            <Box sx={styles.container}>
              <Box style={styles.wideBgImageContainer}>
                <img style={styles.wideBgImage}
                  src={eventPreview?.venueSlug ? `${venues[eventPreview?.venueSlug]?.bannerUrl}` : ``}
                  alt={`${eventPreview?.eventName} Event Preview`} />
              </Box>
              <Box sx={styles.modalBody}>
                <Box textAlign="end" mt={-5} mr={-5}>
                  <Button onClick={() => handleClose()}>
                    <CloseIcon fontSize="large" />
                  </Button>
                </Box>
                <Box sx={{ borderBottom: `1px solid ${textColor}`, pb: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item sm={9}>
                      <Stack display="flex" direction="row">
                        <MDBox>
                          <h3 style={{ lineHeight: 1, marginBottom: 5 }}>
                            {eventPreview?.eventName}
                          </h3>
                          <h4>{eventPreview?.venueName}</h4>
                        </MDBox>
                        <Grid item sm={1} />
                        <IconButton onClick={navigateToEdit}>
                          <Icon fontSize="large" color="warning">
                            edit
                          </Icon>
                        </IconButton>
                        <Grid item sm={1} />
                        <IconButton onClick={navigateToRoster}>
                          <Icon fontSize="large" color="error">
                            people
                          </Icon>
                        </IconButton>
                      </Stack>
                      <Grid container>
                        <Grid item sm={6}>
                          <Box style={{ color: textColor }}>
                            <Typography>
                              <EventDate
                                date={eventPreview?.eventDate}
                                reportTimeTBD={eventPreview?.reportTimeTBD}
                              />
                            </Typography>
                            {eventPreview?.eventEndTime && <Typography>
                              {`Est. End Time: ${moment(eventPreview?.eventEndTime).format("hh:mm A")}`}
                            </Typography>}
                          </Box>
                        </Grid>
                        <Grid item sm={6} alignContent="end">
                          <Button startIcon={<DateRangeIcon />} style={{ color: "green" }}>
                            Add to Calendar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={3}>
                      <MDBox display="flex" alignContent="center">
                        <Box>
                          <img
                            alt="logo"
                            height="86px"
                            // src={`${IMAGE_SERVER}/${eventPreview?.venueSlug}/jobs/${eventPreview?.venueSlug}-jobslogo.png`}
                            src={eventPreview?.logoUrl}
                          />
                        </Box>
                        <Box alignSelf="center">
                          <Button
                            startIcon={<Avatar src="/google-maps-icon.jpg" />}
                            onClick={() =>
                              searchGoogleMaps(eventPreview.address, eventPreview.venueCity, eventPreview.venueState, eventPreview.zip)
                            }
                          />
                        </Box>
                      </MDBox>
                      <Box style={{ color: "green" }}>
                        <Typography>{eventPreview?.address}</Typography>
                        <Typography>
                          {eventPreview?.venueCity} {eventPreview?.venueState} {eventPreview?.zip}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item sm={6}>
                    <Box sx={{ overflowY: "auto", "max-height": "40vh", mt: 2 }}>
                      <Typography
                        style={{ color: textColor }}
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                      >
                        {eventPreview?.description ? parse(eventPreview?.description) : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <MDBox p={3} textAlign="end">
                      <img
                        src={
                          eventPreview?.eventImage
                            ? `${IMAGE_SERVER}/${eventPreview?.venueSlug}/events/${eventPreview?.eventUrl}/${eventPreview?.eventImage}`
                            : "/cutx-jobsbanner2.jpg"
                        }
                        alt={
                          eventPreview?.eventImage
                            ? `${eventPreview.eventName} Event Image`
                            : "No Event Image Set"
                        }
                        style={{ width: "100%" }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid container>
                    <Grid item sm={6}>
                      <MDButton
                        variant="contained"
                        fullWidth
                        endIcont="DateRangeIcon"
                        onClick={() => setOpenWaitlistModal(true)}
                      >
                        {" "}
                        Sign Up for this Event{" "}
                      </MDButton>
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <Box display="flex" direction="row" mt={3} justifyContent="space-between">
                      <Button
                        variant="text"
                        disabled={!parking}
                        href={`${IMAGE_SERVER}/${eventPreview?.venueSlug}/events/${eventPreview?.eventUrl}/${parking?.filename}`}
                        target="_blank"
                        pr={5}
                      >
                        <MDTypography
                          color={parking ? "info" : "grey"}
                          style={{ textDecoration: "underline", fontWeight: "700" }}
                        >
                          Employee Parking Map
                        </MDTypography>
                      </Button>

                      <Button
                        disabled={!uniform}
                        href={`${IMAGE_SERVER}/${eventPreview?.venueSlug}/events/${eventPreview?.eventUrl}/${uniform?.filename}`}
                        target="_blank"
                      >
                        <MDTypography
                          color={parking ? "info" : "grey"}
                          style={{ textDecoration: "underline", fontWeight: "700" }}
                        >
                          Uniform Guidelines
                        </MDTypography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <WaitlistModal
              openWaitlistModal={openWaitlistModal}
              setOpenWaitlistModal={setOpenWaitlistModal}
            />
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of EventsModal
EventsModal.defaultProps = {
  open: false,
  setOpen: () => { },
  eventPreview: {
    eventName: "",
    venueName: null,
    secondaryLocation: "",
    address: "",
    venueCity: "",
    venueState: "",
    zip: "",
    eventId: "",
    eventUrl: "",
    maknePublicAndSendNotification: "No",
    sendConfirmationToSignUps: "No",
    eventDate: null,
    reportDate: null,
    positionsRequested: "",
    eventLogo: "No",
    jobLogo: "No",
    venueBanner: "No",
    googleMap: "No",
    allowPartners: "No",
    allowWaitList: "No",
    allowNonHired: "No",
    reminder48Hour: "No",
    reminder24Hour: "No",
    description: "",
    logoUrl: "",
    venueSlug: "",
  },
};

// Typechecking props of the EventsModal
EventsModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  eventPreview: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    venueState: PropTypes.string,
    venueCity: PropTypes.string,
    googleMap: PropTypes.string,
    venueName: PropTypes.string,
    venueHelmID: PropTypes.string,
    venueLogoUrl: PropTypes.string,
  }),
};

export default EventsModal;
