import { useNewApplicantContext } from "context/NewApplicantContext";
import Acknowledgement from "./Acknowledgement";
import Congratulations from "./Congratulations";
import DirectDeposit from "./DirectDeposit";
import I9Form from "./I9Form";
import JobApplicationForm from "./JobApplicationForm";
import UploadID from "./UploadID";
import W4TaxForm from "./W4TaxForm";

const NewApplicantForms = () => {
  const { activeStepId } = useNewApplicantContext();

  if (activeStepId === 1) {
    return <JobApplicationForm />;
  }
  if (activeStepId === 2) {
    return <I9Form />;
  }
  if (activeStepId === 3) {
    return <UploadID />;
  }
  if (activeStepId === 4) {
    return <W4TaxForm />;
  }
  if (activeStepId === 5) {
    return <DirectDeposit />;
  }
  if (activeStepId === 6) {
    return <Acknowledgement />;
  }
  if (activeStepId === 7) {
    return <Congratulations />;
  }
  return "";
};

export default NewApplicantForms;
