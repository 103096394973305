import * as yup from "yup";
import states from "assets/stateCodes.json";

export const applicantSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[A-Za-z\s]*$/, "First Name contains invalid characters."),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(/^[A-Za-z\s]*$/, "Last Name contains invalid characters."),
  maidenName: yup
    .string()
    .default("")
    .matches(/^[A-Za-z\s]*$/, "Maiden Name contains invalid characters."),
  alternateNumber: yup.string().default(""),
  applicationDate: yup.date().default(new Date()),
  birthDate: yup.date().required("Birth date is required").nullable().default(null),
  city: yup.string().default(""),
  criminalHistoryDisclosure: yup.string().default(""),
  driverLicense: yup.string().default(""),
  emergencyContactName: yup.string().default(""),
  emergencyContactNumber: yup.string().default(""),
  middleInitial: yup.string().default(""),
  mobileNumber: yup.string().default(""),
  socialSecurity: yup.string().default(""),
  state: yup.string().trim().test({
    name: "state exist",
    test(value, ctx) {
      if (!states.includes(value)) {
        return ctx.createError({ message: "Insert a valid state" })
      }
      return true
    }
  }).default(""),
  address: yup.string().default(""),
  zipCode: yup.string().default(""),
});
