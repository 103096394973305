import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/applications/wizard/components/FormField";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { Autocomplete, Icon, Button, Switch, Alert, Snackbar } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import SendMessage from "components/SendMessage";

const useStyle = makeStyles({
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
});

const ApplicantSendMessagePanel = () => {
  const { currentApplicant, setStateVar } = useAppContextController();
  const classes = useStyle();
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [toastAlert, setToastAlert] = useState(false);

  return (
    <SnackbarProvider
      maxSnack={3}
      open={toastAlert}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => setToastAlert(false)}
      key="top-center"
    >
      <Card className={classes.box} key={`card_sendMessage_${currentApplicant.id}`}>
        <MDBox display="flex" key={`box_sendMessage_${currentApplicant.id}`}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="warning"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="large">email</Icon>
          </MDBox>
          <MDBox sx={{ mt: 2, ml: 2 }}>
            <MDTypography variant="h5" color="dark">
              Send Message to {currentApplicant.lastName} {currentApplicant.firstName}
            </MDTypography>
            {email && (
              <MDTypography variant="body2" color="info" fontWeight="bold">
                {`E-mail: ${currentApplicant.email}`}
              </MDTypography>
            )}
            {text && (
              <MDTypography variant="body2" color="info" fontWeight="bold">
                {`Phone: ${currentApplicant.phone}`}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>

        <SendMessage
          recipient={currentApplicant}
          setToastAlert={setToastAlert}
          text={text}
          setText={setText}
          email={email}
          setEmail={setEmail}
          applicant={currentApplicant}
        />
      </Card>
    </SnackbarProvider>
  );
};

export default ApplicantSendMessagePanel;
