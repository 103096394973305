import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import { Switch } from "@mui/material";
import { Input } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { useAppContextController } from "context/AppContext";
import pxToRem from "assets/theme/functions/pxToRem";



const useStyle = makeStyles({
    fontSizeMedium: {
        fontSize: pxToRem(16),
    }
});




function PartnersInformation() {

    const { currentPartner, setStateVar } = useAppContextController();

    const classes = useStyle();

    const [editorValue, setEditorValue] = useState(
        `<p>
     
        </p>`
    );

    return (currentPartner &&
        <Card>
            <MDBox display="flex">

                <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>Partner Information</MDTypography>
            </MDBox>

            <MDBox p={3}>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={8} >
                        <MDBox mt={1}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={6} >
                                    <Input
                                        variant="standard"
                                        fullWidth
                                        className={classes.fontSizeMedium}
                                        type="text"
                                        placeholder="Name"
                                        value={`${currentPartner.firstName} ${currentPartner.lastName}`} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        variant="standard"
                                        fullWidth
                                        className={classes.fontSizeMedium}
                                        type="text"
                                        placeholder="Apply Date"
                                        value={currentPartner.applyDate}
                                    />
                                </Grid>
                            </Grid>

                        </MDBox>
                        <MDBox mt={1} >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        variant="standard"
                                        fullWidth
                                        className={classes.fontSizeMedium}
                                        type="text"
                                        placeholder="Email Address"
                                        value={currentPartner.emailAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        variant="standard"
                                        fullWidth
                                        className={classes.fontSizeMedium}
                                        type="text"
                                        placeholder="Phone"
                                        value={currentPartner.phone1}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <MDBox mb={3}>

                                        <Autocomplete

                                            defaultValue="New"
                                            options={[
                                                "New", "Old"
                                            ]}
                                            renderInput={(params) => <MDInput {...params} variant="standard" />}
                                            classes={{ input: classes.fontSizeMedium }}
                                        />
                                    </MDBox>
                                </Grid>
                            </Grid>



                        </MDBox>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                    <MDTypography component="label" variant="button" fontWeight="regular" color="info">
                                        PARTNER DESCRIPTION

                                    </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>

                        <MDEditor value={editorValue} onChange={setEditorValue} />
                    </Grid>
                </Grid>
            </MDBox>


        </Card >


    );
}
export default PartnersInformation;
