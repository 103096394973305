import { Box, Grid, styled } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import HeaderItem from "./HeaderItem";

const HeaderText = styled(MDTypography)(({ theme }) => ({
  [theme.breakpoints.down("sDesktop")]: {
    fontSize: "2rem",
  },
}));

const NewHeader = () => {
  const [HEADER_ITEM_LIST] = useState([
    "Find a job at a venue below and apply online",
    "Talk to a recruiter and attend a group interview",
    "Start searching events & sign up for gigs online",
  ]);

  return (
    <Box sx={{ alignSelf: "flex-start", display: "flex", flexDirection: "column" }}>
      <Box position="relative" gap="5px" maxWidth={{ sDesktop: "1000px" }}>
        <HeaderText
          component="span"
          variant="h1"
          sx={(theme) => ({
            [theme.breakpoints.up("sDesktop")]: {
              display: "flex",
            },
          })}
        >
          Not hired with Stadium People yet?&nbsp;
        </HeaderText>
        <HeaderText component="span" variant="h1">
          Scoring your
        </HeaderText>
        <HeaderText
          component="span"
          variant="h1"
          sx={{
            color: "red",
          }}
        >
          &nbsp;next gig
        </HeaderText>
        <HeaderText component="span" variant="h1">
          &nbsp;is as easy as
        </HeaderText>
        <HeaderText component="span" variant="h1" sx={{ color: "red" }}>
          &nbsp;1
        </HeaderText>
        <HeaderText component="span" variant="h1">
          -
        </HeaderText>
        <HeaderText component="span" variant="h1" sx={{ color: "red" }}>
          2
        </HeaderText>
        <HeaderText component="span" variant="h1">
          -
        </HeaderText>
        <HeaderText component="span" variant="h1" sx={{ color: "red" }}>
          3
        </HeaderText>
        <HeaderText component="span" variant="h1">
          !
        </HeaderText>
      </Box>
      <Grid
        container
        sx={{ my: "2.5rem" }}
        direction={{ sDesktop: "row", xs: "column" }}
        alignItems={{ xs: "flex-start", sDesktop: "center" }}
        gap={{ xs: "30px", sDesktop: "0px" }}
      >
        {HEADER_ITEM_LIST.map((item, index) => (
          <Grid item sm={4}>
            <HeaderItem index={index + 1} text={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NewHeader;
