import axios from "axios";

import { API_URL } from "config";

const deleteEvent = async (eventId) => {

  if (!eventId) throw new Error("eventId required");
  const res = await axios.delete(`${API_URL}/events/${eventId}`);
  return res;
};

export default deleteEvent;
