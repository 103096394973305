import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import { Icon, Stack, Button, Grid, IconButton } from "@mui/material";
import Searchbar from "components/Searchbar";
import MDTypography from "components/MDTypography";
import DataList from "components/DataList";

import moment from "moment";

import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchEventsCurrent from "layouts/pages/events/actions/fetchEventsCurrent";
import MyEventsActions from "layouts/pages/dashboard/components/MyEventsActions";
import EventDate from "components/EventDate";
import VenueIcon from "components/VenueIcon";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const MyEvents = ({ fetchAll, setActionSelected = "info", title = "Title", filters, setFilters }) => {

  const columns = useMemo(
    () => [
      { title: "", field: "logoUrl", type: "image" },
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Event Url", field: "eventUrl" },
      { title: "Event", field: "eventName" },
      {
        title: "Event Date",
        field: "eventDate",
        customCell: (date) => moment(date).format("MM/DD/YYYY"),
      },
      {
        title: "Report Time",
        field: "eventDate",
        customCell: (reportTime) => {
          if (reportTime) {
            const nonFormattedTime = reportTime.split(" ")[0];
            const hours = Number(nonFormattedTime.split(":")[0]);
            const minutes = nonFormattedTime.split(":")[1];

            let formattedTime = "";
            let time = "";

            if (hours < 12) {
              time = `${hours}:${minutes} AM`;
            }
            if (hours === 12) {
              time = `${hours}:${minutes} AM`;
            }
            if (hours > 12) {
              time = `${hours - 12}:${minutes} PM`;
            }

            if (time.split(":")[0].length === 1) formattedTime = `0${time}`;
            else formattedTime = time;

            return formattedTime;
          }
          return "";
        },
      },

      { title: "Status", field: "status" },

    ],
    []
  );

  const { venues, setCurrentEvent } = useAppContextController();

  const classes = useStyle();
  const [page, setPage] = useState(1);
  const options = { fetchAll: false }
  const allOptions = { ...options, filters: { ...filters, timeFrame: "Current" }, page, limit: 200, order: 'asc', orderBy: 'eventDate' };

  const { data: fetchedEvents, isLoading } = useQuery(["myevents", allOptions], () => {

    if (allOptions.filters?.venueSlug) {
      return fetchEvents(allOptions)
    }
    return {}
  });

  const navigate = useNavigate();
  const addNew = () => navigate('/events/create');
  const viewAll = () => navigate('/events?myVenues=true');

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={1.5}>
          <VenueIcon logoUrl={venues[row.venueSlug]?.logoUrl} slug={row.venueSlug} name={row.venueName} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="subtitle2">{row.eventName}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2">
                <EventDate date={row?.eventDate} reportTimeTBD={row?.reportTimeTBD} />
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <MyEventsActions eventUrl={row?.eventUrl} setNavigateUrl={navigate} />
        </Grid>
      </Grid>
    </>
  );

  const searchBarRowClick = item => {
    setCurrentEvent(item);
    navigate(`/events/${item.eventUrl}/action/eventsinfo`);
  }

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2}>
        <Grid item sm={6.5} >
          <Searchbar
            fetch={fetchEventsCurrent}
            fetchAll={fetchAll}
            placeholder="Search Event"
            columns={columns.slice(1, columns.length - 1)}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy={["venueSlug", "eventName"]}
            filterBy="venueSlug"
            onRowClick={(item) => searchBarRowClick(item)}
          />
        </Grid>
        <Grid item sm={5.5}>

          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <DataList
      renderRow={renderRow}
      data={fetchedEvents?.data}
      icon="event-seat"
      iconColor="success"
      title="My Events"
      isLoading={isLoading}
      greybar
      divider
      header={header}
      tableHeight={360}
      height={450}
      scrollY
    />
  );
};

export default MyEvents;
