import { useEffect, useState, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";


import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { Card } from "@material-ui/core";
import { Icon, Stack, Button, Grid, IconButton, Box } from "@mui/material";

import VenueIcon from "components/VenueIcon";
import MDTypography from "components/MDTypography";
import DataList from "components/DataList";
import moment from "moment";
import Searchbar from "components/Searchbar";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import MyJobsActions from "../MyJobsActions";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const MyJobs = ({ fetchAll, setActionSelected = "info", title = "Title", filters, setFilters }) => {
  const columns =
    useMemo(() =>
      [
        { title: "", field: "logoUrl", type: "image", imageWidth: "50", imageHeight: "50" },
        {
          title: "Venue",
          field: "venueSlug",
          customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
        },
        { title: "Job Url", field: "jobSlug" },
        { title: "Title", field: "title" },
        {
          title: "Pay Rate",
          field: "payRate",
          customCell: (rate) => `$${rate?.toFixed(2)}`,
        },
        {
          title: "Start Date",
          field: "startDate",
          customCell: (date) => moment(date).format("MM/DD/YYYY"),
        },
        { title: "Status", field: "status" },

      ]);


  const classes = useStyle();
  const [page, setPage] = useState(1);
  const { venues, setCurrentJob } = useAppContextController();
  const options = { fetchAll: false }
  const allOptions = { ...options, filters, order: 'venueSlug', orderBy: 'venueSlug' };

  const { data: fetchedJobs, isLoading } = useQuery(["jobs", allOptions], () => {
    if (allOptions.filters?.venueSlug) {
      return fetchJobs(allOptions)
    }
    return {}
  });

  const navigate = useNavigate();
  const addNew = () => navigate('/jobs/create');
  const viewAll = () => navigate('/jobs');


  const renderRow = (row) => (
    <>
      <Grid container alignItems="center" key={row._id}>
        <Grid item xs={12} sm={1.5}>
          <VenueIcon logoUrl={venues?.[row?.venueSlug]?.logoUrl} slug={row.venueSlug}  name={venues?.[row?.venueSlug]?.name}/>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="subtitle2" sx={{ fontWeight: 'bold' }} key={`${row._id}_${row.jobSlug}`}>{row.title}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2" key={`${row._id}_${row.venueName}`}>
                {venues?.[row?.venueSlug]?.name} - {venues?.[row?.venueSlug]?.city}, {venues?.[row?.venueSlug]?.state}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Box sx={{ textAlign: "right" }} pr={2} >
            <MyJobsActions slug={row.jobSlug} />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const searchBarRowClick = item => {
    setCurrentJob(item);
    navigate(`/jobs/${item.jobSlug}/action/jobinfo`);
  }

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2}>
        <Grid item sm={6.5} >
          <Searchbar
            fetch={fetchJobs}
            fetchAll={fetchAll}
            placeholder="Search Job/Venue"
            columns={columns.slice(1, columns.length - 1)}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy="title,venueSlug,venueName"
            filterBy="venueSlug"
            onRowClick={(item) => searchBarRowClick(item)}
          />

        </Grid>
        <Grid item sm={5.5}>
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>

        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <DataList
      renderRow={renderRow}
      data={fetchedJobs?.data}
      filters={filters}
      icon="description"
      iconColor="error"
      title="My Jobs"
      isLoading={isLoading}
      greybar
      divider
      header={header}
      tableHeight={360}
      height={500}
      scrollY
    />
  );
};

export default MyJobs;
