import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import fetchEventApplicants from "layouts/pages/events/actions/fetchEventApplicants";

export const useRosterApplicants = ({ options = {} }) => {
  const { eventUrl } = useParams();

  return useQuery(["rosterApplicants", eventUrl], () => fetchEventApplicants(eventUrl), options);
};
