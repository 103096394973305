import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import {
  Public,
  ConnectWithoutContact,
  PersonAdd,
  FolderShared,
  QuestionAnswer,
  ThumbDown,
} from "@mui/icons-material";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

const cards = [
  {
    icon: <PersonAdd {...CommonIconProps} color="secondary" fontSize="large" />,
    title: "New",
    number: 0,
    filterField: "status",
    filterValue: "New",
  },
  {
    icon: <ConnectWithoutContact {...CommonIconProps} color="warning" fontSize="large" />,
    title: "ATC",
    number: 0,
    filterField: "status",
    filterValue: "ATC",
  },
  {
    icon: <QuestionAnswer {...CommonIconProps} color="info" fontSize="large" />,
    title: "Screened",
    number: 0,
    filterField: "status",
    filterValue: "Screened",
  },
  {
    icon: <FolderShared {...CommonIconProps} color="success" fontSize="large" />,
    title: "Pre-Hire",
    number: 0,
    filterField: "status",
    filterValue: "Pre-Hire",
  },
  {
    icon: <ThumbDown {...CommonIconProps} color="error" fontSize="large" />,
    title: "Declined",
    number: 0,
    filterField: "status",
    filterValue: "Declined",
  },
  {
    icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
    title: "All",
    number: 0,
    filterField: "status",
    filterValue: "",
  },
];

const ApplicantsFilterCards = ({ setFilters, setPage }) => {
  const { setCurrentApplicant } = useAppContextController();

  const setApplicantFilters = (filter) => {
    setCurrentApplicant(null);
    setFilters(filter);
  }
  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setApplicantFilters}
        setPage={setPage}
      />
    </span>
  )
};

export default ApplicantsFilterCards;

ApplicantsFilterCards.defaultProps = {
  setFilters: () => { },
  setPage: () => { },
};

// Typechecking props for the TableAction
ApplicantsFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
