import axios from "axios";

import { API_URL } from "config";
import createApiParams from "utils/createApiParams";

const fetchApplicants = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
}) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {
    const res = await axios.get(
      `${API_URL}/applicants${paginationQuery}${filterQuery}${sortQuery}${
        useOr ? "&useOr=true" : ""
      }`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchApplicants;
