import { SnackbarProvider } from "notistack";

import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import { NewApplicantContextProvider } from "context/NewApplicantContext";
import FormContainer from "../FormContainer";
import MessageSection from "../MessageSection";
import NewApplicantHeader from "../NewApplicantHeader";

const NewApplicantPageContent = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      key="top-center"
    >
      <NewApplicantContextProvider>
        <ContainerWrapper sx={{ px: "20px", pt: "15px" }} position="relative">
          <NewApplicantHeader />
          <MessageSection />
          <FormContainer />
        </ContainerWrapper>
      </NewApplicantContextProvider>
    </SnackbarProvider>
  );
};

export default NewApplicantPageContent;
