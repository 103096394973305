// import React from "react";
import { useAppContextController } from "context/AppContext";
import { Button, Modal, Fade, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import { useQuery, useMutation } from "react-query";
// import fetchNotifications from "layouts/pages/dashboard/actions/fetchNotifications";
import moment from "moment";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  message: {
    border: "1px solid black",
  },
});

const ActivitiesDetailModal = ({
  activity,
  detailActivityModalOpen,
  setDetailActivityModalOpen,
}) => {
  const classes = useStyle();
  const { setUnreadNotifications } = useAppContextController();
  const handleClose = () => setDetailActivityModalOpen(false);
  console.log(".data");
  console.log(activity);

  return (
    (activity?.msgId !== undefined || activity?.messageId !== undefined) && (
      <Modal
        open={detailActivityModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={detailActivityModalOpen}>
          <Card className={classes.container}>
            <MDBox p={4}>
              <MDTypography variant="h5">System Message</MDTypography>
              <MDTypography variant="body2" mt={2}>
                <strong>From: </strong>{" "}
                {` ${activity.detail.sender.firstName} ${activity.detail.sender.lastName} ${
                  activity.detail.sender.fromEmail ? `<${activity.detail.sender.fromEmail}>` : ""
                } `}
              </MDTypography>
              <MDTypography variant="body2" mt={2}>
                <strong> To:</strong>
                {` ${activity.detail.recipient.firstName} ${activity.detail.recipient.lastName} ${
                  activity.detail.recipient.toEmail ? `<${activity.detail.recipient.toEmail}>` : ""
                } `}
              </MDTypography>
              <MDTypography variant="body2" mt={2}>
                <strong> Send Date: </strong>{" "}
                {moment(activity.activityDate).format("ddd YYYY-MM-DD hh:mm A")}
              </MDTypography>
              <MDTypography variant="body2" my={2}>
                <strong> Subject: </strong> {activity.subject}
              </MDTypography>
              <MDTypography variant="body2" my={2}>
                <strong> Description: </strong> {activity.description}
              </MDTypography>
              <MDBox className={classes.message} p={2} mb={2}>
                <MDTypography variant="body2">{parse(activity.detail?.messageBody)}</MDTypography>
              </MDBox>
              <Button variant="contained" style={{ color: "white" }} onClick={handleClose}>
                Close
              </Button>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    )
  );
};

export default ActivitiesDetailModal;
