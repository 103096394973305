import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/applications/wizard/components/FormField";
import accountTypes from "assets/accountTypes.json";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Stack, Autocomplete, Checkbox, } from "@mui/material";
import { DateRange, Description, Email, Stadium } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import { useNavigate } from "react-router-dom";
import CustomTextField from "components/Form/CustomTextField";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    border: "1px solid",
  },
  input: {
    fontSize: `${pxToRem(8)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },

  cardBox: {
    overflow: "visible",
  },
  button: {
    margin: `${pxToRem(2)}`,
  },
});

const DirectDeposit = ({ currentApplicant, setCurrentApplicant, mode = "edit" }) => {
  const classes = useStyle();
  const navigate = useNavigate();

  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const getApplicantDirectDepositValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentApplicant?.[key] || "";
    });
    return values;
  };

  const handleCheckStubOptionsChange = (e) => {
    setValue("checkStubOptions", e.target.id);
  };

  const handlePaymentTypehange = (e) => {
    setValue("paymentType", e.target.id);
  };

  useEffect(() => {
    if (currentApplicant) {
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentApplicant[key] || "");
      });
      setIsModified(false);
    }
    const subscription = watch((value) => {
      if (currentApplicant) {
        setIsModified(JSON.stringify(getApplicantDirectDepositValues()) !== JSON.stringify(value));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, currentApplicant]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Bank One Information</MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="bankName1"
            control={control}
            render={({ field }) => (
              <CustomTextField
                variant="outlined"
                label="Bank Name"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="routing1"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  }
                }}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                label="Routing #"
                customInput={CustomTextField}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="account1"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  }
                }}
                label="Account #"
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="accountType1"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={accountTypes}
                name="accountType1"
                autoSelect
                freeSolo
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("state", v.toUpperCase());
                }}
                sx={{ maxHeight: "44px" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    type="text"
                    label="Account Type"
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#f0f0f0",
                        py: "5px",
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="amountPercentage1"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  }
                }}
                label="Amount % "
                customInput={CustomTextField}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item sm={12}>
          <MDTypography variant="h5"> Bank Two Information</MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="bankName2"
            control={control}
            render={({ field }) => (
              <CustomTextField
                variant="outlined"
                label="Bank Name"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="routing2"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  }
                }}
                label="Routing #"
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="account2"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  }
                }}
                label="Account #"
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="accountType2"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={accountTypes}
                name="accountType1"
                autoSelect
                freeSolo
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("state", v.toUpperCase());
                }}
                sx={{ maxHeight: "44px" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    type="text"
                    label="Account Type"
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#f0f0f0",
                        py: "5px",
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="amountPercentage2"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  }
                }}
                label="Amount % "
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Check Stub Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="checkStubOptions"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="PrintStubOnly"
                        checked={field.value === "PrintStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2"> Print Stub Only</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmailStubOnly"
                        checked={field.value === "EmailStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">Email Stub Only</MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )
            } />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <CustomTextField
                variant="outlined"
                label="Email Address"
                {...field}
              />
            )}
          />

        </Grid>
        <Grid sm={8} />
        <Grid item xs={12} sm={4}>
          <Controller
            name="employeeName"
            control={control}
            render={({ field }) => (
              <CustomTextField
                variant="outlined"
                label="Employee Name"
                {...field}
              />
            )}
          />

        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="socialSecurity"
            control={control}
            render={({ field }) => (
              <CustomTextField
                variant="outlined"
                label="Social Security"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date"
                  renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />

        </Grid>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Payment type election.</MDTypography>
        </Grid>
        <Grid item sm={12}>

          <Controller
            name="paymentType"
            control={control}
            render={({ field }) => (
              <>

                <Grid container>
                  <Grid item sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="DirectDeposit"
                        checked={field.value === "DirectDeposit"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypehange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">Direct deposit</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="MoneyNetworkService"
                        checked={field.value === "MoneyNetworkService"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypehange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">Money Network Service</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmployerIssuedPaperCheck"
                        checked={field.value === "EmployerIssuedPaperCheck"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypehange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">Employer-Issued Paper Check</MDTypography>
                    </Stack>
                  </Grid>
                </Grid>

              </>
            )
            }
          />

        </Grid>
      </Grid>
    </form >
  );
};

export default DirectDeposit;
