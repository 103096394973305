import axios from "axios";

import { API_URL } from "config";

const updateUser = async ({ userId, data }) => {

  console.log('updateUser data', data)
  const res = await axios.put(`${API_URL}/users/${userId}`, data);
  console.log('updateUser', res);
  return res.data;
};

export default updateUser;