import BugReportIcon from '@mui/icons-material/BugReport';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// eslint-disable-next-line import/no-unresolved
// import Placeholder from "layouts/pages/placeholder";
import Venues from "layouts/pages/venues";
import Dashboard from "layouts/pages/dashboard";
import Jobs from "layouts/pages/jobs";
import Applicants from "layouts/pages/applicants";
import Events from "layouts/pages/events";
// import Campaigns from "layouts/pages/campaigns";
import Users from "layouts/pages/users";
import Partners from "layouts/pages/partners";
import Activities from "layouts/pages/activities";
import Issues from "layouts/pages/issues";
import Setup from "layouts/pages/setup";

// import SignInBasic from "layouts/authentication/sign-in/basic";
import Logout from "layouts/authentication/logout"

// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
// import JobResults from "layouts/pages/web/JobResults";

const routes = [
  {
    type: "divider",
    key: "divider-2",
    userType: ["Master", "Admin", "User"],
  },
  {
    type: "title",
    key: "title-user",
    title: "User",
    userType: ["Master", "Admin", "User"],
  },
  {
    type: "link",
    name: "Dashboard",
    key: "analytics",
    route: "/dashboards/analytics",
    icon: <Icon>dashboard</Icon>,
    component: <Dashboard />,
    userType: ["Master", "Admin", "User"],
  },
  {
    type: "link",
    name: "Venues",
    key: "venues",
    route: "/venues",
    component: <Venues />,
    icon: <Icon fontSize="medium">stadium</Icon>,
    userType: ["Master", "Admin", "User"],
  },
  {
    type: "link",
    name: "Jobs",
    key: "jobs",
    route: "/jobs",
    component: <Jobs mode="edit" />,
    icon: <Icon fontSize="medium">work</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Events",
    key: "events",
    route: "/events",
    component: <Events />,
    icon: <Icon fontSize="medium">event-seat</Icon>,
    userType: ["Master", "Admin", "User"],
  },
  // Admin pages
  { type: "divider", key: "divider-1", userType: ["Master", "Admin"] },
  { type: "title", key: "title-admin", title: "Admin", userType: ["Master", "Admin"] },
  {
    type: "link",
    name: "Applicants",
    key: "applicants",
    route: "/applicants",
    component: <Applicants mode="" />,
    icon: <Icon fontSize="medium">people</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Partners",
    key: "partners",
    route: "/partners",
    component: <Partners />,
    icon: <Icon fontSize="medium">handshake</Icon>,
    userType: ["Master", "Admin"],
  },
  // {
  //   type: "link",
  //   name: "Campaigns",
  //   key: "campaigns",
  //   route: "/campaigns",
  //   component: <Campaigns />,
  //   icon: <Icon fontSize="medium">campaign</Icon>,
  //   userType: ["Master","Admin"],
  // },
  {
    type: "link",
    name: "Users",
    key: "users",
    route: "/users",
    component: <Users mode="" />,
    icon: <Icon fontSize="medium">account_circle</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Activities",
    key: "activities",
    route: "/activities",
    component: <Activities mode="" />,
    icon: <Icon fontSize="medium">timeline</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Issues",
    key: "issues",
    route: "/issues",
    component: <Issues mode="" />,
    icon: <BugReportIcon fontSize="medium" />,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Setup",
    key: "setup",
    route: "/setup",
    component: <Setup mode="" />,
    icon: <SettingsApplicationsIcon fontSize="medium" />,
    userType: ["Master"],
  },
  {
    type: "divider",
    key: "divider-3",
    userType: ["Master", "Admin", "Internet"],
  },
  {
    type: "title",
    key: "title-temp",
    title: "Internet",
    userType: ["Master", "Admin", "Internet"],
  },
  // {
  //   type: "link",
  //   name: "Job Results",
  //   key: "webjobsresults",
  //   route: "/web/jobs/results",
  //   icon: <Icon>tablerows</Icon>,
  //   component: <JobResults />,
  //   userType: ["Master", "Admin", "Internet"],
  // },
  {
    type: "divider",
    key: "divider-4",
  },
  {
    type: "link",
    name: "Logout",
    key: "logout",
    icon: <LogoutIcon />,
    route: "/authentication/logout",
    component: <Logout />,
    //    userType: ["Master", "Admin", "User"]
  },
];

export default routes;
