
import { useAppContextController } from "context/AppContext";
import MDBox from 'components/MDBox'
import MDTypography from "components/MDTypography";
import DataList from "components/DataList"
import { makeStyles } from "@mui/styles";
import AttachFile from '@mui/icons-material/AttachFile';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Button, Grid, Icon, IconButton } from "@mui/material";
import moment from 'moment';
import { useEffect, useState } from "react";
import { API_URL, IMAGE_SERVER } from "config";

const useStyle = makeStyles({
    card: {
        height: 350,
    },
    stack: {
        padding: 10,
    },
    editor: {
        height: 100,
    },
    box: {
        overflow: "visible",
    },
    addButton: {
        fontSize: 40,
        marginRight: 15
    },
    section_image_delete: {
        position: "absolute!important",
        left: "150px",
        top: "-15px",
        backgroundColor: "white",
        padding: 5
    }
});


const ApplicantAttachments = ({ setOpen }) => {
    const classes = useStyle();
    const { currentApplicant } = useAppContextController();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (currentApplicant?.attachments.length) {
            setFiles(
                currentApplicant?.attachments.map((file) => {
                    const { docType, filename, title, type, uploadDate } = file;
                    return ({ docType, filename, title, type, uploadDate })
                })
            );
        }
    }, [currentApplicant]);

    const addNew = (e) => {
        setOpen(true)
    };


    const renderRow = (row) => {
        return (<>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={2} overflow="auto" mr={2} >
                    <MDBox
                        component="img"
                        sx={{
                            height: 90,
                            display: 'block',
                            overflow: 'hidden',
                            borderRadius: 5,
                            padding: 1
                        }}
                        src={(row.docType === "jpeg" || row.docType === "jpg" || row.docType === "png" || row.docType === "webp") &&
                            (`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${row.type}/${row.filename}`)
                            || (row.docType === "pdf") && (`${process.env.PUBLIC_URL}/pdf-icon.png`)
                            || (row.docType === "docx") && (`${process.env.PUBLIC_URL}/word-icon.png`)
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <MDTypography variant="h6" >
                                {row.type}
                                <Button onClick={() => window.open(`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${row.type}/${row.filename}`)}>
                                    view
                                </Button>
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <MDTypography variant="body2" >
                                {moment(row.uploadDate).format("dddd MMM Do HH:mm")}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <MDTypography variant="body2" >
                                {row.filename}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <MDTypography variant="body2" >
                                {row.title}
                            </MDTypography>
                        </Grid>


                    </Grid>

                </Grid>
                <Grid item={2}>
                    <MDBox >
                        <IconButton
                            color="info"
                            className={classes.maps_section_image_delete}
                        // onClick={(e) => deleteMapAndPolicyHandler(e, idx)}
                        >
                            <Icon fontSize="large">cancel</Icon>
                        </IconButton>
                    </MDBox>
                </Grid>
            </Grid>
        </>)
    }

    const header = <>
        <IconButton className={classes.addButton} color="info" onClick={addNew}>
            <AddCircleRoundedIcon />
        </IconButton>
    </>
    return (
        <>
            <DataList
                renderRow={renderRow}
                data={files}
                onRowClick={() => { }}
                icon={<AttachFile />}
                iconColor="error"
                title="Attachments"
                isLoading={false}
                greybar
                divider
                header={header}
                tableHeight={480}
                height={550}
                scrollY
            />
        </>
    )
}

export default ApplicantAttachments