import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Autocomplete, Checkbox, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import accountTypes from "assets/accountTypes.json";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { directDepositSchema } from "data/directDeposit";
import useYupValidationResolver from "hooks/useYupValidationResolver";

const DirectDepositFormFields = ({ onSubmit }) => {
  const resolver = useYupValidationResolver(directDepositSchema);
  const { applicant } = useNewApplicantContext();

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: "onBlur" });

  const handleCheckStubOptionsChange = (e) => {
    setValue("checkStubOptions", e.target.id);
  };

  const handlePaymentTypeChange = (e) => {
    setValue("paymentType", e.target.id);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Bank One Information</MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="bankName1"
            control={control}
            render={({ field }) => (
              <CustomTextField variant="outlined" label="Bank Name" {...field} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="routing1"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                }}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                label="Routing #"
                customInput={CustomTextField}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="account1"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                }}
                label="Account #"
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="accountType2"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={accountTypes}
                autoSelect
                freeSolo
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("accountType1", v.toUpperCase());
                }}
                sx={{ maxHeight: "44px" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    type="text"
                    label="Account Type"
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#f0f0f0",
                        py: "5px",
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="amountPercentage1"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                }}
                label="Amount %"
                customInput={CustomTextField}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item sm={12}>
          <MDTypography variant="h5">Bank Two Information</MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="bankName2"
            control={control}
            render={({ field }) => (
              <CustomTextField variant="outlined" label="Bank Name" {...field} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="routing2"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                }}
                label="Routing #"
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="account2"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                }}
                label="Account #"
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="accountType2"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={accountTypes}
                autoSelect
                freeSolo
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("accountType2", v.toUpperCase());
                }}
                sx={{ maxHeight: "44px" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    type="text"
                    label="Account Type"
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#f0f0f0",
                        py: "5px",
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="amountPercentage2"
            control={control}
            render={({ field }) => (
              <NumericFormat
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                }}
                label="Amount % "
                {...field}
                customInput={CustomTextField}
              />
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Check Stub Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="checkStubOptions"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="PrintStubOnly"
                        checked={field.value === "PrintStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        {" "}
                        Print Stub Only
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmailStubOnly"
                        checked={field.value === "EmailStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Email Stub Only
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Email Address"
            defaultValue={applicant?.email || ""}
          />
        </Grid>
        <Grid sm={8} />
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Employee Name"
            defaultValue={`${applicant?.firstName} ${applicant?.lastName}`}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Social Security"
            defaultValue={applicant?.socialSecurity || ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date"
                  renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Payment Type Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="paymentType"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="DirectDeposit"
                        checked={field.value === "DirectDeposit"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypeChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Direct deposit
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="MoneyNetworkService"
                        checked={field.value === "MoneyNetworkService"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypeChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Money Network Service
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmployerIssuedPaperCheck"
                        checked={field.value === "EmployerIssuedPaperCheck"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypeChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Employer-Issued Paper Check
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid>
      </Grid>
      <FormErrors errors={errors} />
    </form>
  );
};

export default DirectDepositFormFields;
