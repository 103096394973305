import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import {
  QuestionMark,
} from "@mui/icons-material";

const CommonIconProps = {
  width: "30px",
  height: "30px",
  color: "white",
};

const cards = [
  {
    icon: "web",
    title: "Website",
    color: "success",
    number: 0,
    filterField: "referralSource",
    filterValue: "Website",
  },
  {
    icon: "work",
    title: "Indeed",
    color: "warning",
    number: 0,
    filterField: "referralSource",
    filterValue: "Indeed",
  },
  {
    icon: "facebook",
    title: "Facebook",
    color: "blue",
    number: 0,
    filterField: "referralSource",
    filterValue: "Facebook",
  },
  {
    icon: "linkedin",
    title: "LinkedIn",
    color: "primary",
    number: 0,
    filterField: "referralSource",
    filterValue: "LinkedIn",
  },
  {
    icon: "handshake",
    title: "Handshake",
    color: "secondary",
    number: 0,
    filterField: "referralSource",
    filterValue: "Handshake",
  },
  {
    icon: <QuestionMark {...CommonIconProps} color="error" fontSize="large" />,
    color: "error",
    title: "Other",
    number: 0,
    filterField: "referralSource",
    filterValue: "Other",
  },
];

const ApplicantsFilterCards = ({ setFilters, setPage }) => {
  const { setCurrentApplicant } = useAppContextController();

  const setApplicantFilters = (filter) => {
    setCurrentApplicant(null);
    setFilters(filter);
  }
  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setApplicantFilters}
        setPage={setPage}
      />
    </span>
  )
};

export default ApplicantsFilterCards;

ApplicantsFilterCards.defaultProps = {
  setFilters: () => { },
  setPage: () => { },
};

// Typechecking props for the TableAction
ApplicantsFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  // children: PropTypes.node.isRequired,
};
