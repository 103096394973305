import axios from "axios";

import { API_URL } from "config";

const deleteJob = async (data) => {

  const { _id: jobId } = data;

  if (!jobId) throw new Error("jobId required");

  const res = await axios.delete(`${API_URL}/jobs/${jobId}`);
  return res;
};

export default deleteJob;
