
import React from 'react';
import MDBox from "components/MDBox";
import { IMAGE_SERVER } from "config";
import { Grid, Button } from "@material-ui/core";
import { Avatar } from "@mui/material";
import MDButton from 'components/MDButton';


const VenueMapsAndPolicies = ({ address, city, state, zip, slug, otherUrls }) => {



  // const searchWaze = () => {
  //   const search = `${state || ""}%${city || ""}%${address || ""}%${zip || ""}`;
  //   console.log(search)
  //   window.open(`http://waze.com`)
  // }
  // https://waze.com/ul?q=some%20address


  const searchGoogleMaps = () => {
    const search = `${address || ""}+${city || ""}+${state || ""}+${zip || ""}`;
    window.open(`http://maps.google.com/maps?q=loc:${search}`)
  }


  console.log(otherUrls)
  return (
    <MDBox >
      <MDBox p={3} >
        <h3 style={{ lineHeight: 1, marginBottom: 5, textAlign: "center" }}>Venue Maps & Policies</h3>
      </MDBox>
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item sm={12}>
          <MDBox
            component="img"
            sx={{
              height: "7rem",
              display: 'block',
              overflow: 'hidden',
              width: '100%',
              borderRadius: 5,
              padding: 1,
              objectFit: "contain"
            }}
            src='/google_maps_icon_square.png'
            onClick={() => searchGoogleMaps()}
          />

        </Grid>

        <MDBox px={2} sx={{
          height: 180,
          display: 'block',
          overflow: 'auto',
        }}>
          {otherUrls?.map((file) =>
            <div>
              <Grid item sm={12} onClick={() => window.open(`${IMAGE_SERVER}/${slug}/venues/other/${file}`)}>
                <MDBox display="flex" direction="row">
                  <MDBox
                    component="img"
                    sx={{
                      height: 90,
                      display: 'block',
                      overflow: 'hidden',
                      padding: 1
                    }}
                    src='/pdf-icon.png'
                  />
                  <MDButton display="flex">{file}</MDButton>
                </MDBox>
              </Grid>
            </div>
          )}
        </MDBox>

      </Grid>



    </MDBox>
  );
}

export default VenueMapsAndPolicies;
