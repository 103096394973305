import { useState } from "react";

const useSort = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const toggleSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
  };

  return { order, orderBy, toggleSort };
};

export default useSort;
