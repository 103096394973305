import axios from "axios";

import { API_URL } from "config";

const createApplicant = async (data) => {
  const res = await axios.post(`${API_URL}/applicants`, data);
  return res.data;
};

export default createApplicant;
