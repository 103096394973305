import { Box, Card, CardContent, Modal, Typography } from "@mui/material";
import MDButton from "components/MDButton";

import FlexWrapper from "components/Wrapper/FlexWrapper";

const ConfirmationModal = ({ title, isOpen, onClose, onConfirm }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          width: "25%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CardContent>
          <FlexWrapper sx={{ py: "10px" }}>
            <Typography sx={{ mb: "20px" }}>{title}</Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <MDButton onClick={onConfirm} color="info" variant="contained">
                Confirm
              </MDButton>
              <MDButton onClick={onClose} color="error" variant="text">
                Cancel
              </MDButton>
            </Box>
          </FlexWrapper>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ConfirmationModal;
