import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import axios from "axios";

import { API_URL } from "config";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MyVenues from "layouts/pages/dashboard/components/MyVenues";
import MyEvents from "layouts/pages/dashboard/components/MyEvents";
import MyJobs from "layouts/pages/dashboard/components/MyJobs";
import MyNotifications from "layouts/pages/dashboard/components/MyNotifications";
import { makeStyles } from "@mui/styles";
// eslint-disable-next-line import/named
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContextController } from "../../../context/AppContext";
import Header from "./components/Header";

const useStyle = makeStyles({
  box: {
    overflow: "visible",
  },
});

function Dashboard() {

  // If there is no current user, let's route the user to login
  const navigate = useNavigate();
  const { user, isLoading } = useAuth0();

  const { currentLoggedUser, setCurrentLoggedUser, userType, isLoggedIn } = useAppContextController();

  const classes = useStyle();
  const [filters, setFilters] = useState('')
  const setNavigateUrl = (url) => navigate(url, { replace: true })

  // const userIdLS = localStorage.getItem("userId");
  // const userId = userIdLS || 'ajackson@stadiumpeople.com';
  // const { data: user, isLoading } = useQuery("myVenues", async () => {
  //   const res = await axios.get(`${API_URL}/users/userId/${userId}`);
  //   return res;
  // });

  // useEffect(() => {
  //   if (!isLoading && user?.data) {
  //     localStorage.setItem("userId", (user.data?.userId || "ajackson@stadiumpeople.com"))
  //     localStorage.setItem("MyVenues", user.data?.venues || [])
  //     setCurrentLoggedUser(user.data)
  //   }
  // }, [isLoading, user, user?.data])

  // TODO: convert localStorage to DB read
  // read the localStorage (for now) and get Venue Array
  useEffect(() => {
    if (currentLoggedUser && currentLoggedUser?.venues)
      setFilters({ venueSlug: currentLoggedUser.venues?.join(';') })
  }, [currentLoggedUser, currentLoggedUser?.venues, user]);

  console.log('API_URL', API_URL, process.env.NODE_ENV, process.env.REACT_APP_API_LOCAL)

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <MDBox mb={5}>
        <Header />
      </MDBox>
      <Grid container spacing={3}>
        <Grid sm={6} item>
          <MDBox mb={4}>
            <MyVenues />
          </MDBox>
          <MDBox mb={4}>
            <MyJobs filters={filters} fetchAll />
          </MDBox>
        </Grid>
        <Grid sm={6} item>
          <MDBox mb={4}>
            <MyEvents filters={filters} setNavigateUrl={setNavigateUrl} fetchAll />
          </MDBox>
          <MDBox mb={4}>
            <MyNotifications
              currentLoggedUser={currentLoggedUser}
            />
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Dashboard;