const getApiUrl = () => {
    let API_URL = process.env.REACT_APP_API;
    if (process.env.REACT_APP_ENV === 'production')
        API_URL = process.env.REACT_APP_API
    else if (process.env.REACT_APP_ENV === 'local')
        API_URL = process.env.REACT_APP_API_LOCAL
    else if (process.env.REACT_APP_ENV === 'staging')
        API_URL = process.env.REACT_APP_API_STAGING
    console.log('config.js', API_URL, process.env.REACT_APP_ENV, process.env.REACT_APP_API_STAGING)
    return API_URL;
}
export const IMAGE_SERVER = process.env.REACT_APP_IMAGE_SERVER; // || "http://localhost";

export const API_URL = getApiUrl();
