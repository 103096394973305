import { useEffect, useState } from "react";
import { Grid, Icon, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import moment from "moment";


const IndividualComment = ({ setValue, comments, row, idx }) => {
    const [user, setUser] = useState(null);
    // const [showBody, setShowBody] = useState(false);
    const handleRowOnClick = (e) => {
        e.stopPropagation();
        // setShowBody((prev) => !prev);

    };
    const { userType } = useAppContextController();

    const handleDelete = (e) => {
        e.stopPropagation();
        // setShowBody((prev) => !prev);
        const temporaryPositions = comments;
        temporaryPositions.splice((comments.length - idx - 1), 1);
        setValue("comments", temporaryPositions);
    };


    useEffect(() => {
        const getUser = async (id) => {
            const fetchedUser = await fetchUserById(id)
            if (fetchedUser) {
                setUser(fetchedUser)
            }
        }
        if (row.userId) {
            getUser(row.userId);
        }

    }, [row?.userId])

    return (
        <Grid container alignItems="center" onClick={handleRowOnClick}>
            <Grid item xs={12} sm={10}>
                <Grid container flexDirection="row">
                    <Grid item xs={1.5} xm={1.5}>
                        <PictureAvatar
                            image={user?.profileImg}
                            firstName={user?.firstName}
                            lastName={user?.lastName}
                            userId={user?.userId}
                            size="md"
                        />
                    </Grid>
                    <Grid item xs={10} sm={10}>
                        <Grid container flexDirection="row">
                            <Grid item xs={6} sm={6}>
                                <MDTypography
                                    variant="h5"
                                >
                                    {user?.firstName} {user?.lastName}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <MDTypography
                                    variant="h6"
                                >
                                    {moment(row?.date).format("ddd YYYY-MM-DD hh:mm A")}
                                    {/* {new Date(row.sendTime).toLocaleDateString()} {new Date(row.sendTime).toLocaleTimeString()} */}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        {/* {showBody && ( */}
                        <Grid item xs={12} sm={12} pt={1}>
                            <hr />
                            <MDTypography variant="body1" pt={1}>
                                {row?.commentText ? parse(row.commentText) : ""}
                            </MDTypography>
                        </Grid>
                        {/* )} */}
                    </Grid>
                </Grid>
            </Grid>
            {userType === "Master" &&
                <Grid item sm={2} textAlign="end">
                    <Grid item xs={12} sm={4} lg={4} textAlign="end">
                        <Icon onClick={handleDelete} fontSize="large" color="error">delete-forever</Icon>
                    </Grid>
                </Grid>}
        </Grid>
    );
};


export default IndividualComment