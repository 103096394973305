import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
import PreviewIcon from '@mui/icons-material/Preview';

const JobsTableActions = ({ jobSlug, setNavigateUrl, row, setFilters, setOpen, setJobPreview, }) => {
  const onClickHandler = (action, e) => {
    e.stopPropagation();
    setNavigateUrl(`/jobs/${jobSlug}/action/${action}`)
  };
  const setPreviewOpen = (job, e) => {
    e.stopPropagation();
    setJobPreview(job)
    setOpen(true);
  }
  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("jobinfo", e)}
        tooltip="Job Info"
      />
      &nbsp;&nbsp;
      <TableAction
        color="success"
        icon="event-seat"
        showBadge
        tooltip="Job Interviews"
        clickAction={(e) => onClickHandler("jobevents", e)}
      />
      &nbsp;&nbsp;
      <TableAction
        icon="people"
        showBadge
        color="error"
        tooltip="Job Applicants"
        clickAction={(e) => onClickHandler("jobapplicants", e)}
      />
      &nbsp;&nbsp;
      <TableAction
        color="info"
        icon={<PreviewIcon />}
        clickAction={(e) => setPreviewOpen(row, e)}
        tooltip="Venue Preview"
      />  &nbsp;&nbsp;
      <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("joball", e)}
        tooltip="All Job Information"
      />
    </span>
  )
};

JobsTableActions.defaultProps = {
  jobSlug: "",
  setNavigateUrl: () => { },
};

// Typechecking props for the TableAction
JobsTableActions.propTypes = {
  jobSlug: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};

export default JobsTableActions;