// import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContextController } from '../../../context/AppContext';

const Logout = () => {
    const { logout } = useAuth0();
    const { setCurrentLoggedUser } = useAppContextController();
    const basePath = new URL(window.location);
    console.log('basePath', basePath);
    setCurrentLoggedUser(null);
    logout({ returnTo: basePath.origin });
    return null;
}

export default Logout;