import { Box, Grid, styled } from "@mui/material";
import backgroundImage from "assets/images/Homepage-Background.jpg";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import NewHeader from "layouts/pages/web/components/NewHeader";
import Results from "layouts/pages/web/components/Results";
import LateralSignUp from "../components/LateralSignUp";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(195deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7)),url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  width: "100%",
  height: "100%",

  [theme.breakpoints.up(1680)]: {
    height: "100vh",
  },
}));

const TableContainer = styled(FlexWrapper)(({ theme }) => ({
  "& .MuiPaper-root .MuiBox-root .MuiPaper-root": {
    paddingBottom: "20px",
    "& > div:last-child": {
      height: "100%",
      maxHeight: "550px",
      [theme.breakpoints.up(1680)]: {
        // maxHeight: "750px",
        maxHeight: "calc(100vh - 550px)",
      },
    },
  },
}));

const Homepage = () => {
  return (
    <StyledBox>
      <Grid
        container
        sx={{
          height: "100%",
          margin: 0,
          padding: 0,
          position: "relative",
        }}
        flexWrap={{ sDesktop: "nowrap" }}
        gap={5}
      >
        <Grid item md={12} sDesktop={4} sx={{ backgroundColor: "rgb(255,0,0,0.75)" }}>
          <LateralSignUp />
        </Grid>
        <Grid item md={12} sDesktop={8}>
          <FlexWrapper px={{ xs: 3, sm: 5, sDesktop: 0 }} mt={{ xs: "0px", sDesktop: "40px" }}>
            <NewHeader />
            <TableContainer px={{ sDesktop: 10 }} py={3}>
              <Results />
            </TableContainer>
          </FlexWrapper>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default Homepage;
