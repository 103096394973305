import { useMemo } from "react";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PublicIcon from '@mui/icons-material/Public';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StadiumIcon from '@mui/icons-material/Stadium';

const VenuesFilterCards = ({ setFilters, setPage }) => {
  const { setCurrentVenue, currentLoggedUser } = useAppContextController();
  const myVenues = currentLoggedUser?.venues?.length ? currentLoggedUser.venues.join(';') : ""

  const cards = useMemo(() => [
    {
      color: "info",
      icon: <ToggleOnIcon fontSize="large" color="info" />,
      title: "Active",
      number: 0,
      filterField: "status",
      filterValue: "Active",
      canCombine: true,
    },
    {
      color: "success",
      icon: <AttachMoneyIcon fontSize="large" color="success" />,
      title: "Prospect",
      number: 0,
      filterField: "status",
      filterValue: "Prospect"
    },
    {
      color: "error",
      icon: <ToggleOffIcon fontSize="large" color="error" />,
      title: "Inactive",
      number: 0,
      filterField: "status",
      filterValue: "Inactive"
    },
    {
      color: "info",
      icon: <StadiumIcon fontSize="large" color="info" />,
      title: "MyVenues",
      number: 0,
      filterField: "slug",
      filterValue: myVenues,
      canCombine: true,
    },
    {
      color: "dark",
      icon: <PublicIcon fontSize="large" color="black" />,
      title: "All",
      number: 0,
      filterField: "status",
      filterValue: "",
    },
  ]);


  const setVenueFilters = (filter) => {
    setCurrentVenue(null);
    setFilters(filter);
  }
  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setVenueFilters}
        setPage={setPage}
      />
    </span>
  )
}

export default VenuesFilterCards;

// VenuesFilterCards.defaultProps = {
//   slug: "",
//   setActionSelected: () => {},
// };

// Typechecking props for the TableAction
// VenuesFilterCards.propTypes = {
//   slug: PropTypes.string,
//   setActionSelected: PropTypes.func,
//   // children: PropTypes.node.isRequired,
// };
