import axios from "axios";

import { API_URL } from "config";

const updateProfile = async ({ data, userId }) => {
  const res = await axios.put(`${API_URL}/users/${userId}`, data);
  return res.data;
};

export default updateProfile;
