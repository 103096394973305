import axios from "axios";

import { API_URL } from "config";

const postNote = async ({ applicantId, data }) => {

  try {
    const res = await axios.post(`${API_URL}/applicants/${applicantId}/note`, data);
    return res;
  } catch (err) {
    throw err.toString();
  }

};

export default postNote;
