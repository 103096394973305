import axios from "axios";

import { API_URL } from "config";

const createActivity = async (data) => {
  const res = await axios.post(`${API_URL}/activity`, data);
  return res.data;
};

export default createActivity;
