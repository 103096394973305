// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
// import ChatIcon from '@mui/icons-material/Chat';

const IssuesTableActions = ({ id, row, setCurrentIssue, setGenericModalOpen, setModalInfo }) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    setCurrentIssue(row);
    switch (action) {
      case "issuesinfo":
        navigate(`/issues/${id}/action/${action}`);
        break;
      case "issuesall":
        navigate(`/issues/${id}/action/${action}`);
        break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("issuesinfo", e)}
        tooltip="Issue Info"
      />
      <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("issuesall", e)}
        tooltip="All Issue Panels"
      />
    </div>
  );
};

export default IssuesTableActions;

IssuesTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
IssuesTableActions.propTypes = {
  id: PropTypes.string,
};
