import { useQuery } from "react-query";

import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useAppContextController } from "context/AppContext";

// eslint-disable-next-line import/no-unresolved
import { useRosterApplicants } from "hooks/useRosterApplicants";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { filterApplicants } from "utils/helpers/applicant";

export const ApplicantsTable = ({ filter, setSelectedRecipients }) => {
  const { currentEvent } = useAppContextController();

  const { data: rosterApplicants, refetch } = useRosterApplicants({});

  const applicantOptions = {
    fetchAll: true,
    filters: { "venues.venueSlug": currentEvent?.venueSlug },
  };

  const { data: applicants, isLoading: appLoading } = useQuery(
    ["applicants", applicantOptions],
    () => fetchApplicants(applicantOptions),
    {
      onSuccess: (retValue) => {
        refetch();
      },
    }
  );

  const filteredApplicants = useMemo(() => {
    const rosterIds = rosterApplicants.result.map((item) => item.id);
    return filterApplicants({ applicants: applicants?.data, filter: filter.filt, rosterIds }).map((item) => ({
      ...item,
      id: item._id,
    }));
  }, [rosterApplicants, applicants, filter]);

  const columns = useMemo(
    () => [
      {
        headerName: "First Name",
        field: "firstName",
        flex: 1,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "Last Name",
        field: "lastName",
        flex: 1,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      { headerName: "City", field: "city", flex: 1 },
      { headerName: "State", field: "state", flex: 1 },
      {
        headerName: "Position",
        field: "primaryPosition",
        flex: 1,
        customCell: ({ value }) => (
          <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
            {value}
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <Box
      sx={{
        maxHeight: "300px",
        height: "300px",
        overflow: "scroll",
        width: "100%",
      }}
    >
      <DataGrid
        rows={filteredApplicants}
        columns={columns}
        checkboxSelection
        onSelectionModelChange={(val) => setSelectedRecipients(val)}
        disableSelectionOnClick
        hideFooter
        loading={appLoading}
      />
    </Box>
  );
};
