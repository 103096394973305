import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";

const HeaderItem = ({ index, text }) => {
  return (
    <FlexWrapper sx={{ flexWrap: "nowrap", height: "100%", gap: "20px" }}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={{ xs: "4rem", md: "5rem" }}
        height={{ xs: "4rem", md: "5rem" }}
        bgColor="error"
        borderRadius="100%"
        sx={{
          flexShrink: 0,
        }}
      >
        <MDTypography
          variant="h1"
          sx={(theme) => ({
            color: "#FFF",
            [theme.breakpoints.down("sDesktop")]: {
              fontSize: "2rem",
            },
          })}
        >
          {index}
        </MDTypography>
      </MDBox>
      <MDTypography variant="h4" px="5px">
        {text}
      </MDTypography>
    </FlexWrapper>
  );
};

export default HeaderItem;
