import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { API_URL, IMAGE_SERVER } from "config";
import axios from "axios";
import colors from "assets/theme/base/colors";
import { useAppContextController } from "context/AppContext";
import states from "assets/stateCodes.json";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
// @mui material components
import { Grid, Card, Switch, Button, Autocomplete, Alert, Snackbar, Avatar, IconButton, Stack } from "@mui/material";
import LandscapeIcon from "@mui/icons-material/Landscape";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
// Material Dashboard 2 PRO React components
import FileDropzone from "components/Dropzone";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import { makeStyles } from "@mui/styles";
import FormField from "layouts/applications/wizard/components/FormField";
import userTypes from "assets/userTypes.json";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import Attachments from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments";

const { black } = colors;

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 170,
  },
  dropzone: {
    minHeight: 70,
  },
  previewButton: {
    padding: 0,
  },
  words: {
    wordWrap: "break-word",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15
  }
});

function ProfileInfo({ userType: currentUserType, currentApplicant, setCurrentApplicant, setBottomPannel }) {
  const classes = useStyle();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [isModified, setIsModified] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(false)
  // If there is no current user, let's route the user to login
  const navigate = useNavigate();
  const { currentLoggedUser, setCurrentLoggedUser, isLoggedIn } = useAppContextController();

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const defaultValues = {
    firstName: "",
    lastName: "",
    userType: "User",
    userId: "",
    emailAddress: "",
    primaryPhone: "",
    secondaryPhone: "",
    address1: "",
    city: "",
    state: null,
    zip: "",
    master: "No",
    venues: "No",
    applicants: "No",
    adminUsers: "No",
    events: "No",
    employees: "No",
    jobs: "No",
  };

  const firstName = register("firstName", { required: true });
  const lastName = register("lastName", { required: true });

  const userType = {
    ...register("userType", {
      disabled: currentUserType === "User",
      validate: { exist: (value) => userTypes.includes(value) || "Insert a valid user type" },
    }),
  };
  const emailAddress = {
    ...register("emailAddress", {
      pattern: {
        // eslint-disable-next-line
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Your email address is invalid",
      },
    }),
  };
  const primaryPhone = {
    ...register("primaryPhone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };
  const secondaryPhone = {
    ...register("secondaryPhone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };

  const secondaryLocation = register("secondaryLocation");
  const address1 = register("address1");
  const city = register("city");
  const state = {
    ...register("state", {
      validate: { exist: (value) => states.includes(value) || "Insert a valid state" },
    }),
  };
  const zip = {
    ...register("zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Zip code is invalid" },
    }),
  };
  const master = register("master");
  const venues = register("venues");
  const applicants = register("applicants");
  const adminUsers = register("adminUsers");
  const events = register("events");
  const jobs = register("jobs");
  const employees = register("employees");

  const queryClient = useQueryClient();

  const uploadImgMutation = useMutation(
    async (imgForm) => {
      await axios.post(
        `${API_URL}/upload/users/${currentLoggedUser._id}/${imgForm.type}`,
        imgForm.form
      );
      await updateProfile({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, data) => {
        setCurrentLoggedUser({ ...currentLoggedUser, ...data.data });
        setToastAlert({ isShow: true, message: "Picture has been updated!", status: "success" });
      },
    }
  );

  const updateProfileMutation = useMutation(updateProfile, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("profiles");
      setToastAlert({ isShow: true, message: "Profile has been updated!", status: "success" });
      setCurrentLoggedUser({ ...currentLoggedUser, ...data });
    },
  });

  const updateProfileHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });

    const oldUserId = currentLoggedUser.userId;
    if (data?.userId !== data?.emailAddress) {
      data.userId = data.emailAddress;
    }

    await updateProfileMutation.mutateAsync({ data, userId: oldUserId });
  };

  const onCancel = () => {
    clearErrors();

    Object.keys(watch())?.forEach((key) => {
      setValue(key, currentLoggedUser[key] || "");
    });
    setIsModified(false);
  };

  useEffect(() => {
    clearErrors();
    if (currentLoggedUser) {
      Object.keys(watch())?.forEach((key) => {
        setValue(key, currentLoggedUser[key] || "");
      });
      setIsModified(false);
    }

    const subscription = watch((value) => {
      if (currentLoggedUser) {
        setIsModified(JSON.stringify(currentLoggedUser) !== JSON.stringify(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, currentLoggedUser, confirmModal.response]);

  const onDropImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { ...currentLoggedUser, profileImg: file.name },
      type: "photo",
    });
  };

  const onDropBannerHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { ...currentLoggedUser, bannerImg: file.name },
      type: "banner",
    });
  };

  function stringAvatar(userName) {
    return {
      children: `${userName?.firstName[0]}${userName?.lastName[0]}`,
    };
  }

  const avatar = currentLoggedUser?.profileImg ? (
    <MDAvatar
      src={`${IMAGE_SERVER}/users/${currentLoggedUser?._id}/photo/${currentLoggedUser?.profileImg}`}
      alt="profile-image"
      size="xl"
      shadow="sm"
    />
  ) : (
    <Avatar
      sx={{ bgcolor: black.light, width: 75, height: 75 }}
      {...stringAvatar(currentLoggedUser)}
    />
  );
  const bannerAvatar = currentLoggedUser?.bannerImg ? (
    <Card>
      <img
        src={`${IMAGE_SERVER}/users/${currentLoggedUser?._id}/banner/${currentLoggedUser?.bannerImg}`}
        alt="Profile Banner"
        style={{ width: "100%" }}
      />
    </Card>
  ) : (
    <LandscapeIcon sx={{ width: "5em", height: "5em" }} />
  );

  const openChangeMyW4 = () => {
    setBottomPannel("changeMyW4")
  }
  const openChangeDeposit = () => { setBottomPannel("changeDirectDeposit") }

  const attachemntTitle = (<MDTypography component="label" variant="button" fontWeight="bold" color="info">
    UPLOAD ATTACHMENTS: ID PHOTO, DOCUMENTS
  </MDTypography>)

  return (
    <Card>
      <form onSubmit={handleSubmit(updateProfileHandler)}>
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            severity={toastAlert.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>

        <MDBox display="flex" justifyContent="space-between" px={3}>
          <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1 }}>
            My Profile
          </MDTypography>

          <MDBox display="flex" alignItems="center">
            <Button
              variant="text"
              startIcon={<CancelIcon />}
              onClick={onCancel}
              disabled={isSubmitting || !isModified}
              sx={{ padding: "0.5rem 1rem" }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              endIcon={<SaveIcon />}
              style={{ color: "white" }}
              disabled={isSubmitting || !isModified}
              sx={{ padding: "0.5rem 1rem" }}
            >
              Save
            </Button>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MDBox mt={1}>
                    <FormField
                      {...firstName}
                      key={`ProfileInfo_${firstName}`}
                      type="text"
                      label="First Name"
                      InputLabelProps={{ shrink: getValues().firstName }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox mt={1}>
                    <FormField
                      {...lastName}
                      key={`ProfileInfo_${lastName}`}
                      type="text"
                      label="Last Name"
                      InputLabelProps={{ shrink: getValues().lastName }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox mt={1}>
                    <Controller
                      {...userType}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          disabled={currentUserType === "User"}
                          freeSolo
                          options={userTypes}
                          defaultValue="User"
                          value={field.value}
                          onChange={(e, v) => field.onChange(v)}
                          renderInput={(params) => (
                            <FormField
                              // key={`state_${currentProfile?.venueSlug}`}
                              {...params}
                              InputLabelProps={{ shrink: getValues().userType }}
                              type="text"
                              label="User Type"
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.userType && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.userType.message}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox mt={1}>
                    <FormField
                      {...emailAddress}
                      type="text"
                      label="Email"
                      InputLabelProps={{ shrink: getValues().emailAddress }}
                    />
                    {errors?.emailAddress && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.emailAddress.message}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <MDBox mt={1}>
                        <FormField
                          {...primaryPhone}
                          // key={`firstScreen_primaryPhone_`}
                          type="text"
                          label="Primary Phone"
                          InputLabelProps={{ shrink: getValues().primaryPhone }}
                        />
                        {errors?.primaryPhone && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.primaryPhone.message}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <MDBox mt={1}>
                        <FormField
                          {...secondaryPhone}
                          // key={`firstScreen_secondaryPhone_`}
                          type="text"
                          label="Secondary Phone"
                          InputLabelProps={{ shrink: getValues().secondaryPhone }}
                        />
                        {errors?.secondaryPhone && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.secondaryPhone.message}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} >
                      <MDBox mt={1}>
                        <FormField
                          {...address1}
                          // key={`firstScreen_address1_`}
                          type="text"
                          label="Address 1"
                          InputLabelProps={{ shrink: getValues().address1 }}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormField
                        {...city}
                        // key={`firstScreen_city_`}
                        type="text"
                        label="City"
                        InputLabelProps={{ shrink: getValues().city }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <MDBox mb={3}>
                        <Controller
                          {...state}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              freeSolo
                              options={states}
                              defaultValue={null}
                              name="State"
                              value={field.value || states[0]}
                              onChange={(e, v) => {
                                field.onChange(v.toUpperCase());
                              }}
                              onBlur={() => trigger("state")}
                              renderInput={(params) => (
                                <FormField
                                  // key={`state_${currentProfile?.venueSlug}`}
                                  {...params}
                                  type="text"
                                  label=" "
                                />
                              )}
                            />
                          )}
                        />
                        {errors?.state && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.state.message}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        {...zip}
                        // key={`firstScreen_zip_`}
                        type="text"
                        label="Zip Code"
                        InputLabelProps={{ shrink: getValues().zip }}
                      />
                      {errors?.zip && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.zip.message}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {currentUserType === "Master" && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <Grid container>
                        <Grid item xs={12} sm={12} display="flex" alignItems="center">
                          <Controller
                            {...master}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                inputProps={{ "aria-label": "" }}
                              />
                            )}
                          />

                          <MDTypography variant="body2" textTransform="capitalize">
                            Master
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} display="flex" alignItems="center">
                          <Controller
                            {...venues}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                inputProps={{ "aria-label": "" }}
                              />
                            )}
                          />

                          <MDTypography variant="body2" textTransform="capitalize">
                            Venues
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} display="flex" alignItems="center">
                          <Controller
                            {...applicants}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                inputProps={{ "aria-label": "" }}
                              />
                            )}
                          />
                          <MDTypography
                            variant="body2"
                            textTransform="capitalize"
                            style={classes.word}
                          >
                            Applicants
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container>
                        <Grid item xs={12} sm={12} display="flex" alignItems="center">
                          <Controller
                            {...adminUsers}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                inputProps={{ "aria-label": "" }}
                              />
                            )}
                          />
                          <MDTypography variant="body2" textTransform="capitalize">
                            Admin Users
                          </MDTypography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6} display="flex" alignItems="center">
                        <Controller
                          {...events}
                          control={control}
                          render={({ field }) => (
                            <Switch
                              checked={field.value === "Yes"}
                              onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              inputProps={{ "aria-label": "" }}
                            />
                          )}
                        />
                        <MDTypography variant="body2" textTransform="capitalize">
                          Events
                        </MDTypography>
                      </Grid>

                      <Grid item xs={12} sm={6} display="flex" alignItems="center">
                        <Controller
                          {...employees}
                          control={control}
                          render={({ field }) => (
                            <Switch
                              checked={field.value === "Yes"}
                              onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              inputProps={{ "aria-label": "" }}
                            />
                          )}
                        />
                        <MDTypography variant="body2" textTransform="capitalize">
                          Employees
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container>
                        <Grid item xs={12} sm={6} display="flex" alignItems="center">
                          <Controller
                            {...jobs}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                inputProps={{ "aria-label": "" }}
                              />
                            )}
                          />
                          <MDTypography variant="body2" textTransform="capitalize">
                            Jobs
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                Upload or change Profile Photo
              </MDTypography>
              <FileDropzone
                className={classes.dropzone}
                onDrop={onDropImageHandler}
                user={currentLoggedUser}
                avatar={avatar}
              />
              <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                Upload or change banner
              </MDTypography>
              <FileDropzone
                className={classes.dropzone}
                onDrop={onDropBannerHandler}
                avatar={bannerAvatar}
              />
            </Grid>
          </Grid>
          {currentUserType === "User" && <Grid container spacing={4}>
            <Grid item sm={6} display="flex" direction="column" justifyContent="space-around" >
              <Stack display="flex" direction="row" justifyContent="space-between" alignItems="center" >
                <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                  CHANGE MY W-4 WITHHOLDINGS
                </MDTypography>
                <IconButton className={classes.addButton} color="info" onClick={openChangeMyW4}>
                  <AddCircleRoundedIcon />
                </IconButton>
              </Stack>
              <hr />
              <Stack display="flex" direction="row" justifyContent="space-between" alignItems="center" >
                <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                  CHANGE MY DIRECT DEPOSIT
                </MDTypography>
                <IconButton className={classes.addButton} color="info" onClick={openChangeDeposit}>
                  <AddCircleRoundedIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <Attachments currentApplicant={currentApplicant} setOpen={setAttachmentOpen} attachemntTitle={attachemntTitle} />
            </Grid>
          </Grid>}
        </MDBox>
      </form>
      <UploadFileModal currentApplicant={currentApplicant} setCurrentApplicant={setCurrentApplicant} open={attachmentOpen} setOpen={setAttachmentOpen} />
    </Card >
  );
}
export default ProfileInfo;
