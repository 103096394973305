import { TableAction } from "@perimeter-software/react-common";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const MyJobsActions = ({ slug }) => {

    const navigate = useNavigate();

    const onClickHandler = (action, e) => {
        e.stopPropagation();
        navigate(`/jobs/${slug}/action/${action}`)
    }

    return (
        <MDBox display="flex" justifyContent="end">
            <TableAction
                color="error"
                icon="people"
                showBadge
                clickAction={(e) => onClickHandler("jobapplicants", e)}
                tooltip="Jobs Applicants"
            />
            &nbsp;&nbsp;
            <TableAction
                color="secondary"
                icon="edit"
                clickAction={(e) => onClickHandler("jobinfo", e)}
                tooltip="Edit Job"
            />

        </MDBox>
    );
};
export default MyJobsActions;

MyJobsActions.defaultProps = {
    slug: "",
};


MyJobsActions.propTypes = {
    slug: PropTypes.string,
};
