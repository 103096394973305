import axios from "axios";

import { API_URL } from "config";

const updateIssue = async ({ id, data }) => {

    console.log("datos", data)


    const res = await axios.put(`${API_URL}/issues/${id}`, data);
    return res.data;
};

export default updateIssue;
