import { Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from 'react-router-dom';

const VenueIcon = ({ logoUrl, slug, name, imageWidth, imageHeight, title }) => {

    const navigate = useNavigate()
    const handleClick = (e) => {
        e.stopPropagation();
        navigate(`/venues/${slug}/action/venueinfo`);
    }
    return (
        <Tooltip title={`Go to ${name} information`}>
            <button type="button" onClick={(e) => handleClick(e)} style={{ border: "none", outline: "none", background: "none", cursor: "pointer" }} >
                <img
                    width={imageWidth || "50"}
                    height={imageHeight || "50"}
                    src={(logoUrl || "")}
                    alt={title || ""}
                />
            </button>
        </Tooltip>
    )
}

export default VenueIcon