import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "react-query";

import parse from "html-react-parser";

import { Card, Grid, Icon, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import Header from "layouts/pages/web/components/Header";
import useSort from "utils/useSort";
import Apply from "../components/Apply";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
    width: "85%",
  },
});

const JobApply = ({
  fetchAll,
  setActionSelected = "info",
  title = "Title",
  filters,
  setFilters,
  showFiltersList,
}) => {
  const classes = useStyle();
  //    const { user, isLoading: isAuthLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const { setJobs, setCurrentJob, currentJob, venues } = useAppContextController();
  const [page, setPage] = useState(1);
  const [venueInfo, setVenueInfo] = useState(null);
  const { order, orderBy, toggleSort } = useSort();
  const [limit, setLimit] = useState(null);
  const options = fetchAll ? { fetchAll } : { page, order, limit, orderBy };
  const allOptions = { ...options, filters };

  const { data, isLoading } = useQuery(["jobs", allOptions], () => fetchJobs(allOptions));

  const { jobSlug } = useParams();
  const jobs = data?.data?.find((job) => job.jobSlug === jobSlug);

  useEffect(() => {
    if (venues && jobs) {
      setVenueInfo(venues[jobs?.venueSlug]);
    }
  }, [venues, jobs]);

  return (
    // (!user) ?
    //     <CircularProgress />
    //     :
    venues && (
      <>
        <MDBox mb={5}>
          <Header />
        </MDBox>
        <MDBox display="flex" justifyContent="center" mt={-10}>
          <Card className={classes.box} shadow="md">
            <Grid container spacing={3} alignItems="center" py={1}>
              <Grid item>
                <MDBox display="flex" mt={0} mb={0}>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-4}
                  >
                    <Icon fontSize="large">stadium</Icon>
                  </MDBox>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                      Job Search Results
                    </MDTypography>
                  </Stack>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox p={5}>
              <table width="100%">
                <tr>
                  <th align="left" width="18%">
                    <MDTypography variant="h6" color="dark" sx={{ mb: 1 }}>
                      VENUE
                    </MDTypography>
                  </th>
                  <th align="left">
                    <MDTypography variant="h6" color="dark" sx={{ mb: 1 }}>
                      JOB TITLE
                    </MDTypography>
                  </th>
                  <th align="left">
                    <MDTypography variant="h6" color="dark" sx={{ mb: 1 }}>
                      VENUE NAME
                    </MDTypography>
                  </th>
                  <th align="left" width="15%">
                    <MDTypography variant="h6" color="dark" sx={{ mb: 1 }}>
                      CITY
                    </MDTypography>
                  </th>
                  <th align="left" width="5%">
                    <MDTypography variant="h6" color="dark" sx={{ mb: 1 }}>
                      STATE
                    </MDTypography>
                  </th>
                </tr>
                <tr>
                  <td>
                    <img
                      width="50"
                      height="50"
                      // eslint-disable-next-line
                      src={venueInfo?.logoUrl}
                      alt={venueInfo?.logoUrl}
                    />
                  </td>
                  <td>
                    <MDTypography variant="h6" color="dark" sx={{ mt: 3, mb: 1 }}>
                      {jobs?.title}
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="h6" color="dark" sx={{ mt: 3, mb: 1 }}>
                      {venueInfo?.name}
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="h6" color="dark" sx={{ mt: 3, mb: 1 }}>
                      {venueInfo?.city}
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="h6" color="dark" sx={{ mt: 3, mb: 1 }}>
                      {venueInfo?.state}
                    </MDTypography>
                  </td>
                </tr>
              </table>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox display="flex" justifyContent="center" mt={3}>
          <MDBox className={classes.box}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Card shadow="md">
                  <MDBox px={5} my={4} sx={{ height: "35rem", overflow: "auto" }}>
                    <MDTypography>{jobs?.description ? parse(jobs?.description) : ""}</MDTypography>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Apply job={jobs} />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </>
    )
  );
};

export default JobApply;
