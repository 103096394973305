// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
// import ChatIcon from '@mui/icons-material/Chat';

const UsersTableActions = ({ id, row, setCurrentUser, setGenericModalOpen, setModalInfo }) => {
    const navigate = useNavigate();

    const onClickHandler = (action, e) => {
        setCurrentUser(row);
        switch (action) {
            case "userinfo":
                navigate(`/users/${id}/action/${action}`);
                break;
            case "userall":
                navigate(`/users/${id}/action/${action}`);
                break;
            case "usermail":
                setModalInfo({ type: action, data: row });
                setGenericModalOpen(true);
                break;
            case "usercomments":
                setModalInfo({ type: action, data: row });
                setGenericModalOpen(true);
                break;
            default:
                break;
        }
        e.stopPropagation();
    };

    return (
        <div style={{ textAlign: "end" }}>
            <TableAction
                color="info"
                icon="info"
                clickAction={(e) => onClickHandler("userinfo", e)}
                tooltip="User Info"
            />
            &nbsp;&nbsp;
            <TableAction
                color="error"
                icon="mail"
                clickAction={(e) => onClickHandler("usermail", e)}
                tooltip="User Mail"
            />&nbsp;&nbsp;
            <TableAction
                color="dark"
                icon="description"
                clickAction={(e) => onClickHandler("usercomments", e)}
                tooltip="User Notes"
            />&nbsp;&nbsp;
            <TableAction
                color="dark"
                icon="public"
                clickAction={(e) => onClickHandler("userall", e)}
                tooltip="All User Panels"
            />&nbsp;&nbsp;
        </div>
    )
}

export default UsersTableActions;

UsersTableActions.defaultProps = {
    id: "",
};

// Typechecking props for the TableAction
UsersTableActions.propTypes = {
    id: PropTypes.string,

};
