import axios from "axios";

import { API_URL } from "config";

const deleteIssue = async (issueId) => {

  if (!issueId) throw new Error("issueId required");
  const res = await axios.delete(`${API_URL}/issues/${issueId}`);
  return res;
};

export default deleteIssue;
