import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Icon, Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { makeStyles } from "@mui/styles";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";

import { IMAGE_SERVER } from "config";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import { useAppContextController } from "context/AppContext";
import useSort from "utils/useSort";

import moment from "moment";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import FiltersList from "components/FiltersList";
import JobsTableActions from "../JobsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  }
});

const JobsTable = ({
  jobSlug,
  fetchAll,
  setNavigateUrl,
  filters,
  setFilters,
  setOpen,
  setJobPreview,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  title = "Stadium People Jobs"
}) => {
  const classes = useStyle();
  const { currentJob, setCurrentJob, venues } = useAppContextController();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters, venues };

  const { data: jobs, isLoading } = useQuery(["jobs", allOptions], () => fetchJobs(allOptions));

  useEffect(() => {
    if (!isLoading && jobs?.data?.length && jobSlug) {
      const ndx = jobs.data.findIndex(item => item.jobSlug === jobSlug);
      if (ndx > -1) {
        setCurrentJob(jobs.data[ndx]);
      }
    }
  }, [isLoading, jobSlug]);

  useEffect(() => {
    if (action && filters)
      setPage(1)
  }, [filters]);

  const columns =
    useMemo(() =>
      [
        {
          title: "", field: "logoUrl",
          customCell: (logoUrl, field, row) => <VenueIcon logoUrl={logoUrl} slug={row.venueSlug} name={row.venueName} />,
        },
        {
          title: "Venue",
          field: "venueSlug",
          customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
        },
        { title: "Job Url", field: "jobSlug" },
        { title: "Title", field: "title" },
        {
          title: "Pay Rate",
          field: "payRate",
          customCell: (rate) => `$${rate?.toFixed(2)}`,
        },
        {
          title: "Start Date",
          field: "startDate",
          customCell: (date) => moment(date).format("MM/DD/YYYY"),
        },
        { title: "Status", field: "status" },
        {
          title: "Job Actions",
          field: "jobSlug",
          customCell: (thisJobSlug, field, row) => {
            return (<JobsTableActions
              jobSlug={`${thisJobSlug}`}
              setNavigateUrl={setNavigateUrl}
              row={row}
              setActionSelected={setActionSelected}
              setOpen={setOpen}
              setJobPreview={setJobPreview}
            />)
          }
        }
      ], [page, limit, order]);

  const handleRowClick = (job, e) => {
    e.stopPropagation()
    setCurrentJob(job)
    setNavigateUrl(`/jobs/${job.jobSlug}`)
  };

  const addNew = () => {
    if (!currentJob) {
      setCurrentJob({});
    }
    setNavigateUrl('/jobs/create')
  }

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item >
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="warning"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">work</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center" >
          {showSearchBar &&
            <Searchbar
              fetch={fetchJobs}
              fetchAll={fetchAll}
              placeholder="Search Job/Venue"
              columns={columns.slice(1, columns.length - 1)}
              queryCharacterLimit={1}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["title", "venueName"]}
              filterBy="venueSlug"
            />
          }
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={jobs}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading}
          defaultSort="jobSlug"
          idField="jobSlug"
        />
      </MDBox>
    </Card>
  );
};

// Setting default values for the props
JobsTable.defaultProps = {
  fetchAll: true,
  setNavigateUrl: () => { },
  title: "Jobs Table",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
JobsTable.propTypes = {
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default JobsTable;
