import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import colors from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import parse from "html-react-parser";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import GetIcons from "components/Activities/GetIcons";
import { makeStyles } from "@mui/styles";
import { Table, TableRow, TableCell, Box, } from "@mui/material";
import EntityName from "components/Activities/EntityName";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import { isObject } from "formik";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    overflow: "visible",
  },
  status: {
    fontWeight: 700,
    color: "#1A73E8",

    width: "100%",
  },
  label: {
    display: "grid",
    lineHeight: 1.5,
    fontSize: "0.75rem",
  },
  logoImage: {
    objectFit: "contain",
  },
  previewButton: {
    padding: 0,
  },
  dropzone: {
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
  },
  blockedDropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  text: {
    fontSize: "0.75rem!important",
  },
  error: {
    fontSize: "0.75rem",
  },
});

function ActivitiesInformation({ mode = "edit", setNavigateUrl }) {
  const classes = useStyle();
  const { currentActivity } = useAppContextController();
  const [activityApplicant, setActivityApplicant] = useState(null);
  const [activityJob, setActivityJob] = useState(null);
  const [activityVenue, setActivityVenue] = useState(null);
  const [activityTemplate, setActivityTemplate] = useState(null)
  const [activityEvent, setActivityEvent] = useState(null)

  useEffect(() => {
    if (currentActivity?.applicantId) {
      fetchApplicants({ filters: { _id: currentActivity?.applicantId } })
        .then((applicant) => setActivityApplicant(applicant.data[0]))
    }
    else { setActivityApplicant(null) }
    if (currentActivity?.jobId) {
      fetchJobs({ filters: { _id: currentActivity?.jobId } })
        .then((job) => setActivityJob(job.data[0]))
    }
    else { setActivityJob(null) }
    if (currentActivity?.venueId) {
      fetchVenues({ filters: { _id: currentActivity?.venueId } })
        .then((venue) => setActivityVenue(venue.data[0]))
    }
    else { setActivityVenue(null) }
    if (currentActivity?.templateId) {
      // to be modified when having a fetchTemplate
      setActivityTemplate(`${currentActivity.userFirstName} ${currentActivity.userLastName}`)
    }
    else { setActivityTemplate(null) }
    if (currentActivity?.eventId) {
      if (currentActivity.action !== "Delete Event")
        fetchEvents({ filters: { _id: currentActivity?.eventId } })
          .then((event) => setActivityEvent(event.data[0]))
      else
        setActivityEvent({ ...currentActivity.detail })
    }
    else { setActivityEvent(null) }
  }, [currentActivity])


  console.log("currentActivity", currentActivity?.jobId)
  const array = [];
  const recursiveDetails = (details, level) => {
    Object.entries(details).forEach((values) => {
      if (isObject(values[1]) && Object.entries(values[1])) {
        array.push(
          <TableRow>
            <TableCell sx={{ backgroundColor: "#FAFAFA" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                {values[0]}
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }} />

            </TableCell>
          </TableRow>)
        recursiveDetails(values[1], level + 1)
      }
      else {
        array.push(
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
                pl={2 * level}
              >
                {values[0]}
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {(values[0] === "messageBody") ? parse(values[1]) : values[1]}
              </Box>
            </TableCell>
          </TableRow>
        )
      }
    })
    return array
  }


  return (
    <Card className={classes.box} key={`card_${currentActivity?._id}`}>
      <MDBox display="flex" justifyContent="space-between" px={3}>
        <MDBox display="flex" pt={2}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            color="info"
            borderRadius="xl"
          >
            <GetIcons action={currentActivity?.action} activity={currentActivity} />
          </MDBox>
          {/* {JSON.stringify(activityEvent)} */}
          <MDBox >
            <EntityName
              activity={currentActivity}
              action={currentActivity?.action}
              activityApplicant={activityApplicant}
              activityJob={activityJob}
              activityEvent={activityEvent}
              activityVenue={activityVenue}
              activityTemplate={activityTemplate}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <Table>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Activity Date
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {moment(currentActivity?.activityDate).format("ddd, MM/DD/YYYY hh:mm:ss A")}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Description
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {currentActivity?.description}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                User
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {currentActivity?.detail?.agent || `${currentActivity.userFirstName} ${currentActivity.userLastName}`}
              </Box>
            </TableCell>
          </TableRow>

          {activityApplicant && (<TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Applicant
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {activityApplicant?.firstName} {activityApplicant?.lastName}
              </Box>
            </TableCell>
          </TableRow>)
          }
          {activityVenue && (<TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Venue
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {activityApplicant?.firstName}
              </Box>
            </TableCell>
          </TableRow>)
          }
          {activityJob && (<TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Job
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {activityApplicant?.firstName}
              </Box>
            </TableCell>
          </TableRow>)
          }
          {activityEvent && (<TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Event
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {activityEvent?.eventName}
              </Box>
            </TableCell>
          </TableRow>)
          }
          {currentActivity?.details && (
            <>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                  <MDTypography
                    sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                    color="dark"
                  >
                    Details
                  </MDTypography>
                </TableCell>
                <TableCell sx={{ backgroundColor: "#dee2e6" }} />

              </TableRow>
              {recursiveDetails(currentActivity?.details, 0)}
            </>
          )}
          {currentActivity?.detail && (
            <>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                  <MDTypography
                    sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                    color="dark"
                  >
                    Details
                  </MDTypography>
                </TableCell>
                <TableCell sx={{ backgroundColor: "#dee2e6" }} />

              </TableRow>
              {recursiveDetails(currentActivity?.detail, 0)}
            </>
          )}

        </Table>
      </MDBox>
    </Card >
  );
}
export default ActivitiesInformation;
