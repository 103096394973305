
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@mui/styles";
import { Grid, Icon, Button, IconButton } from "@mui/material";

import moment from "moment";
import parse from "html-react-parser";


// const useStyle = makeStyles({
//   editor: {
//     height: 150,
//   },
//   box: {
//     overflow: "visible",
//     height: 550,
//   },
// });

  const NotesRow = ({row}) => {

    const [viewDetail, setViewDetail] = useState(false);
    const toggleRowExpand = (e) => {e.stopPropagation(); setViewDetail(prev=>!prev)}
    
    return (
      <>
        <Grid container alignItems="center" onClick={toggleRowExpand}>
          <Grid item xs={10} sm={10}>
            <Grid container>
              <Grid item xs={3.5} sm={3.5}>
                <MDTypography variant="h6">
                  {row.firstName} {row.lastName}
                </MDTypography>
              </Grid>
              <Grid item xs={5} sm={5}>
                <MDTypography variant="body2">
                  {moment(row.date).format("ddd MM/DD/YYYY hh:mm A")}
                </MDTypography>
              </Grid>
              <Grid item xs={3.5} sm={3.5}>
                <MDTypography variant="h6">{row.type}</MDTypography>
              </Grid>
              {viewDetail ? (
                <Grid item xs={12} sm={12}>
                  <hr />
                  <MDTypography variant="body2">{row.text ? parse(row.text) : ""}</MDTypography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} pr={0} mr={0} sx={{textAlign: 'end'}}>
            <MDBox>
              <Button onClick={toggleRowExpand}>{viewDetail ? 'Hide' : 'Show'}</Button>
            </MDBox>
          </Grid>
        </Grid>
      </>
    )
              }

              export default NotesRow;