import { useLocation, useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const FiltersList = ({ filters, setFilters }) => {

  const navigate = useNavigate()
  const location = useLocation()

  const isFilters = filters ? Object.keys(filters).length > 0 : false;

  // const deleteFilter = (filter) => {
  //   const newObject = {};

  //   Object.keys(filters || {}).forEach((key) => {
  //     if (key !== filter) {
  //       newObject[key] = filters[key];
  //     }
  //   });

  //   setFilters?.(newObject);
  //   navigate(location.pathname.split('/').splice(0,3).join('/'))
  // };

  const deleteFilter = (filter) => {
    const fils = { ...filters }
    delete fils[filter];
    setFilters({ ...fils });
  }

  return (
    isFilters && (
      <MDBox display="flex">
        <MDBox mr={2}>
          <MDTypography variant="h5">Filters:</MDTypography>
        </MDBox>
        <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
          {Object.keys(filters || {}).map((key) => (
            <Chip
              key={key}
              color="info"
              sx={{ textTransform: "uppercase", mx: 0.5, mb: 0.5 }}
              label={`${key}: ${filters[key]}`}
              onDelete={() => deleteFilter(key)}
            />
          ))}
        </MDBox>
      </MDBox>
    )
  );
};

export default FiltersList;
