import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";

const CustomCard = ({ children, cardSx, cardTitle, cardActions, icon }) => {
  return (
    <Card
      sx={{
        px: "1.5rem",
        pt: "1rem",
        pb: "2rem",
        ...cardSx,
      }}
    >
      <MDBox display="flex" justifyContent="space-between" mb="25px" flexDirection="column">
        <FlexWrapper justifyContent="initial" alignItems="initial" columnGap="1rem">
          {icon && (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              shadow="md"
              borderRadius="xl"
              mt={-5}
              sx={{
                backgroundColor: "#ec293b",
                "& svg": {
                  width: "40px",
                  height: "40px",
                },
              }}
            >
              {icon}
            </MDBox>
          )}
          <MDTypography variant="h5" color="dark">
            {cardTitle}
          </MDTypography>
        </FlexWrapper>
        {cardActions && <FlexWrapper justifyContent="end">{cardActions}</FlexWrapper>}
      </MDBox>
      {children}
    </Card>
  );
};

export default CustomCard;
