import { useEffect, useState } from "react";
import { useMutation, QueryClient } from "react-query";
import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
import setFavorite from "utils/setFavorite"
import { useAppContextController } from "context/AppContext";
import updateUser from "layouts/pages/users/actions/updateUser";
import PreviewIcon from '@mui/icons-material/Preview';

const VenuesTableActions = ({ slug, row, setNavigateUrl, setOpen, setVenuePreview }) => {

  const { currentLoggedUser, setCurrentLoggedUser } = useAppContextController()

  const queryClient = new QueryClient();

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("myVenues")
      localStorage.setItem("myVenues", data.venues)
      setCurrentLoggedUser(data)
    }
  })

  // const [favorites, setFavorites] = useState(currentLoggedUser?.venues || []);
  const isFavorite = venueSlug => currentLoggedUser?.venues && currentLoggedUser?.venues?.length && currentLoggedUser.venues.includes(venueSlug);

  const setFavoritesAndRequery = (venueSlug, e) => {
    e.stopPropagation();

    let faves = currentLoggedUser?.venues ? [...currentLoggedUser.venues] : []
    faves = isFavorite(venueSlug) ? faves.filter(item => item !== venueSlug) : Array.from(new Set([...faves, venueSlug]))

    const loggedUser = { ...currentLoggedUser }
    loggedUser.venues = faves

    updateUserMutation.mutate({ userId: loggedUser.userId, data: loggedUser })
  }

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    setNavigateUrl(`/venues/${slug}/action/${action}`)
  };

  const setPreviewOpen = (venue, e) => {
    e.stopPropagation();
    setVenuePreview(venue)
    setOpen(true);
  }
  return (
    <span key={`table_actions_span_${slug}`}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("venueinfo", e)}
        tooltip="Venue Info"
      />
      &nbsp;&nbsp;
      <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickHandler("venuejobs", e)}
        tooltip="Venue Jobs"
      />
      &nbsp;&nbsp;
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler("venueevents", e)}
        tooltip="Venue Events"
      />
      &nbsp;&nbsp;
      <TableAction
        color="error"
        icon="people"
        showBadge
        clickAction={(e) => onClickHandler("venueapplicants", e)}
        tooltip="Staffing Pool"
      />
      &nbsp;&nbsp;
      <TableAction
        color="secondary"
        icon="movie_filter"
        clickAction={(e) => onClickHandler("venuemedia", e)}
        tooltip="Venue Media"
      />
      &nbsp;&nbsp;
      <TableAction
        color="info"
        icon={<PreviewIcon />}
        clickAction={(e) => setPreviewOpen(row, e)}
        tooltip="Venue Preview"
      />
      &nbsp;&nbsp;
      <TableAction
        color={isFavorite(slug) ? "warning" : "light"}
        icon="staroutline"
        clickAction={(e) => setFavoritesAndRequery(slug, e)}
        tooltip="Add/Remove from My Venues"
      />
      &nbsp;&nbsp;
      <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("venueall", e)}
        tooltip="All Venue Information"
      />
    </span>
  );
};
export default VenuesTableActions;

VenuesTableActions.defaultProps = {
  slug: "",
  setNavigateUrl: () => { },
};

// Typechecking props for the TableAction
VenuesTableActions.propTypes = {
  slug: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
