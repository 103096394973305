import { Checkbox, Stack } from '@mui/material';
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Controller, useForm } from "react-hook-form";

const Acknowledgement = () => {

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors, isSubmitting },
    } = useForm({ mode: "onBlur" });

    const agreedTerms = (v) => {
        setValue("AgreedTerm", v)
    }

    return (
        <>
            <MDBox p={3} style={{ border: "solid black 1px" }}>
                <MDBox style={{ height: "30rem", overflow: "scroll" }}>
                    <MDTypography variant="body2">
                        Application Acknowledgement Verbiage
                        <br />
                        <br />
                        EQUAL OPPORTUNITY EMPLOYMENT. It is our corporate responsibility to promote
                        equal employment opportunity to all employees and applicants for employment
                        without regard to race, color, religion, national origin, physical and/or mental
                        handicap, age, or sex.  We shall pursue this course of action in all employment and
                        pre-employment practices.  All applications of employment will be retained for at
                        least one year.
                        <br />
                        <br />
                        BACKGROUND, SUBSTANCE SCREENING AND WORK SITE POLICIES. Innovative client
                        companies may require that an applicant take and pass a drug screen, and
                        therefore as such, contract assignment may be subject to termination based on
                        results of drug screen. To ensure a safe workplace for all employees, any employee
                        involved in an on the job injury will be required to take and pass a drug screen
                        immediately.  Failure to comply with any part of this policy will result in termination
                        of employment.  Copies of our drug policy are available at any Innovative office.
                        Accepting employment with our organization and signing this application means
                        that I have been made aware of and will follow all Worksite Safety Rules and aware
                        of the working conditions at assigned company work sites. I also agree to adhere to
                        any Employee Policies as required by the work site company including but not
                        restricted to any handling of cash or other property belonging to our organization or
                        our work site company, sexual harassment, abusive behavior, cell phone and
                        Internet procedures, and acknowledge that any violation of work site policies may
                        result in criminal charges or legal remedies necessary to recover damages or loss to
                        property. I agree that if I am involved in any violation of employee policy at a work
                        site, regardless of my involvement that I must report any known incident to a
                        supervisor immediately so that a formal report can be documented to ensure
                        appropriate action is taken.
                        <br />
                        <br />
                        REPORTING AND PAYMENT FOR TIME WORKED. Time must be accurately recorded
                        at each event or work week or Innovative cannot guarantee a payroll date or
                        amount. Any employee who fails to sign or clock out at their work site or event will
                        be paid for minimum time period for the event, even if the employee works longer
                        than that period. I acknowledge that payment for time is for the scheduled period
                        only, and that Innovative and its clients will only be responsible for the scheduled or
                        “post” time for a work shift and no hours before or after the post time and cIock-out
                        periods. I also understand and acknowledge that by enrolling in direct deposit with
                        Innovative, I am authorizing Innovative to make deposits to my account and also
                        authorizing Innovative to make withdrawals from my account in the event I am paid
                        incorrectly. I also understand that Innovative charges a per-instance fee of $30.00
                        for any cancelled check fees resulting from the loss or misplacement of a check by
                        its employees.
                        <br />
                        <br />
                        STATUS OF WORK AND NUMBER OF HOURS.  I understand that this employment is
                        with Innovative and not its client company where I may be assigned to work. My
                        work status is temporary, part-time, or seasonal that is on either an as-needed or
                        event-basis only and that Innovative does not guarantee a minimum number of
                        hours per week. Any event work hours are dependent on the schedule and time
                        frame set forth by the work site company and not by Innovative, and I understand
                        that each event may require a different amount of hours available and may also
                        provide work at varying pay rates based on available positions. I understand and
                        have been made aware that I must make myself available for work in order to be
                        scheduled but that making myself available does not guarantee me a position on
                        every schedule. Work schedules are created by the work site company and
                        selection is based on the number of positions available for that day or shift. I further
                        understand and have been made aware by an Innovative representative that
                        working in the events industry will require that I stand for long periods of time, work
                        in extreme hot or cold weather, climb stairs, and interact at times with irate
                        individuals and that by signing this application I am able to perform my job under
                        these conditions. Innovative may provide but does not guarantee assignments for
                        individuals with certain physical or mental impairments.
                        <br />
                        <br />
                        DEDUCTIONS FOR UNIFORMS AND LICENSES. I understand that my position may
                        require the cost of uniform apparel or State licensing and that the cost of these
                        items may be deducted from my paycheck. I acknowledge and understand that
                        these requirements are not those of Innovative but those of the worksite company
                        and the State Licensing Bureaus and receipt of any licensing and the length of time
                        it takes to receive the license is not determined once Innovative submits paperwork
                        into the State. Any uniform item or apparel that is not deducted from my payroll
                        must be returned to a local Innovative office. Return of uniform or cancellation of
                        interest in licensing does not necessitate a credit or refund for these items as they
                        are required to perform the position. My signature on this application authorizes
                        Innovative to deduct where applicable any amounts from my paycheck for uniforms,
                        equipment, health insurance, errors in payroll, check reissue fees, over payments,
                        or any other work-related deductions allowable by law.
                        <br />
                        <br />
                        NON-COMPETITION AT WORK SITE. I understand and agree that I will not take a
                        position with another staffing company at an assigned work site during the term of
                        my employment with Innovative and for a period of twelve (12) months after my
                        assignment has terminated. I agree that if I am approached by a representative of
                        another company at a work site about employment during the term of my
                        assignment with Innovative that I am to immediately report to my Innovative
                        Supervisor or employment representative.
                        <br />
                        <br />
                        TRAINING AND ORIENTATIONS. I agree that my initial interview may be held in a
                        group setting to learn more about working at an assigned venue or work site and
                        that attendance at a group interview is voluntary and not in any way a guarantee of
                        employment nor is it a paid employee function. Following the group interview I will
                        be notified of my employment status by a representative of Innovative. For
                        assignments that require mandatory training sessions, I understand that the pay for
                        training will be included with my payroll following my initial week of employment. I
                        further understand and acknowledge that failure to begin work at the assigned job
                        will result in training hours paid at minimum wage.
                        <br />
                        <br />
                        PAY RATE VARIATIONS BY ASSIGNMENT. I understand that my pay rate offered may
                        vary based on the assignment I accept. I also have been made aware that pay rates
                        at certain event venues change based on the event type, such as concerts, sporting
                        events, special one-time events, overnight work, or pre-event office work and that
                        my Innovative representative has made me aware of these variations prior to my
                        accepting work in these instances.
                        <br />
                        <br />
                        WAGE DISPUTES. I understand and agree that the client company is solely obligated
                        to pay any wages for which the obligation to pay is created by an agreement,
                        contract, plan or policy between the client company and myself and that Innovative
                        has not contracted to pay.
                        <br />
                        <br />
                        ARBITRATION. I agree that my sole recourse for resolving any dispute with
                        Innovative arising under my employment, including but not limited to wage claims,
                        shall be to arbitrate such dispute. Such arbitration shall be pursuant to the laws of
                        the State of Texas and the rules, then containing, of the American Arbitration
                        Association. Venue of any action shall be in Dallas County, Texas.
                        <br />
                        <br />
                        I understand that I am to contact Innovative after completing an assignment and 3
                        times weekly thereafter to give notice of availability for work. I also agree to comply
                        with the reporting guidelines set forth for upcoming events. Failure to make myself
                        available for work assignments may affect unemployment benefits. Failure to give
                        ten business days notice upon resignation may also affect my pay.
                        <br />
                        <br />
                        I understand that as directed by company policy and consistent with the job
                        described, you may be requesting information from public and private sources
                        about my workers compensation injuries, driving record, criminal record, education,
                        employment history, credentials, credit, and references.  I consent for Innovative to
                        obtain the information as described in the aforementioned statements.
                        <br />
                        <br />
                        I understand that if offered a position I will be a temporary part-time employee of
                        Innovative and that Innovative and its client companies and affiliates do not provide
                        its temporary employees paid holidays, or paid personal leave. Healthcare options
                        are available and employees may inquire with their Innovative Representative
                        regarding eligibility.
                        <br />
                        <br />
                        *In submitting this application for employment, I authorize investigation of all
                        statements contained here-in, and it is understood and agreed that any
                        misrepresentation (including omission of information) by me in this application will
                        be sufficient cause for cancellation of the application and/or for separation from the
                        company’s service if I have been employed.  Innovative does not require a pre-
                        employment drug screen; however, client companies may require that an applicant
                        take and pass a drug screen.
                    </MDTypography>
                </MDBox>
            </MDBox>

            <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                    id="AgreedTerm"
                    checked={getValues().agreedTerms}
                    onChange={(e, v) => agreedTerms(v)}
                />
                <MDTypography fontWeight="bold" variant="body2">I agree to all terms and conditions</MDTypography>
            </Stack>


        </>
    )
}

export default Acknowledgement