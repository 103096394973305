
import { Box, Button, Modal, Fade, Card, Grid, Alert } from "@mui/material";
import { API_URL, IMAGE_SERVER } from "config";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
    container: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "auto",
        padding: "1rem"
    },
    dropzone: {
        height: 170,
    },
});

const ImagePreviewModal = ({ imagePreviewOpen, setImagePreviewOpen, currentEvent, previewFile }) => {
    const classes = useStyle();
    const handleClose = () => setImagePreviewOpen(false);

    return (
        <Modal
            open={imagePreviewOpen}
            onClose={(event, reason) => {
                if (reason === "escapeKeyDown") {
                    handleClose();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={imagePreviewOpen}>
                <Card className={classes.container} >
                    <img
                        src={`${IMAGE_SERVER}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${previewFile?.name}`}
                        alt="previews"
                    />
                    <Box mt={2} display="flex" justifyContent="space-between" >
                        <Button variant="text" onClick={() => {
                            window.open(
                                `${IMAGE_SERVER}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${previewFile?.name}`
                            )
                        }} >Open in another tab</Button>
                        <Button variant="contained" style={{ color: "white" }} onClick={() => { handleClose() }}>OK</Button>
                    </Box>



                </Card>
            </Fade>
        </Modal>
    )
}

export default ImagePreviewModal