import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";

import MDBox from "components/MDBox";

const useStyle = makeStyles({
  tableRow: {
    cursor: "pointer",
    "& td, & th": { border: 0 },
    "&:hover": { background: "rgba(0, 0, 0, 0.04)" },
  },
});

const Searchbar = ({
  fetch,
  fetchAll,
  columns,
  queryCharacterLimit,
  resultsLimit,
  setFilters,
  setPage,
  placeholder,
  searchBy,
  filterBy,
  filterByColumn = null,
  scrollLimit = 0,
  sortFunction = null,
  onRowClick,
}) => {
  const [allData, setAllData] = useState([]);
  const [fetchedData, setFetchedData] = useState({
    isFetched: false,
    isLoading: false,
    data: [],
  });
  const [input, setInput] = useState("");
  const classes = useStyle();
  const tableColumns = columns;

  const createFilters = (inp) => {
    if (typeof searchBy === "string") return { [searchBy]: inp };
    const options = {};
    searchBy.forEach((key) => {
      options[key] = inp;
    });
    return options;
  };

  const onFetchHandler = async () => {
    if (fetchedData.isLoading) return;
    if (input.length >= queryCharacterLimit) {
      setFetchedData({ ...fetchedData, isLoading: true });

      let data = [];

      // console.log('fetchAll', fetchAll, input, createFilters(input));
      if (!fetchAll) {
        const res = await fetch({
          fetchAll: false,
          filters: createFilters(input),
          page: 1,
          limit: !scrollLimit ? resultsLimit : scrollLimit,
          useOr: true,
        });
        data = res.data;
      }

      if (fetchAll) {
        if (!allData?.length) {
          const res = await fetch({ fetchAll: true });
          data = res.data;
        } else {
          data = allData;
        }
      }

      const filtered =
        data?.filter((item) => {
          let found = false;
          const searchArr = typeof searchBy === "string" ? searchBy.split(",") : searchBy;
          if (searchArr?.length) {
            searchArr?.forEach((field) => {
              if (item[field]?.toLowerCase().includes(input.toLowerCase())) {
                found = true;
              }
            });
          }
          return found;
        }) || [];

      setFetchedData({
        isFetched: true,
        data: sortFunction
          ? filtered.sort(sortFunction).slice(0, scrollLimit || resultsLimit)
          : filtered.slice(0, scrollLimit || resultsLimit),
        isLoading: false,
      });
      setAllData(data)
    } else {
      setFetchedData({ isFetched: false, data: [], isLoading: false });
    }
  };

  const resetSearch = () => {
    setInput("");
    setFetchedData({ data: [], isFetched: false, isLoading: false });
  };

  const onSelectHandler = (item, e) => {
    setInput("");
    if (onRowClick) {
      onRowClick(item, e);
      // return;
    }

    resetSearch();

    setFilters({
      [filterBy]: item[filterByColumn || filterBy],
    });

    setPage(1);
  };

  useEffect(() => {
    onFetchHandler();

    return () =>
      setFetchedData({
        isFetched: false,
        data: [],
        isLoading: false,
      });
  }, [input]);

  const boxStyle = {
    width: "max-content",
    position: "absolute",
    zIndex: "999",
    background: "#fff",
    right: 0,
    top: "4rem",
    boxShadow: 3,
  }
  if (scrollLimit) {
    boxStyle.maxHeight = 800;
    boxStyle.overflowY = 'scroll';
  }
  return (
    <MDBox sx={{ width: "100%", position: "relative" }} pr={2}>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          background: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, p: "2px" }}
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        {input.length !== 0 && (
          <IconButton onClick={resetSearch}>
            <CloseIcon />
          </IconButton>
        )}

        <IconButton onClick={onFetchHandler}>
          <SearchIcon />
        </IconButton>
      </Paper>
      {fetchedData.isFetched && (
        <MDBox
          sx={boxStyle}
        >
          <Table>
            <MDBox component="thead">
              <TableRow
                sx={({ typography: { size, fontWeightBold } }) => ({
                  td: {
                    fontSize: size.xs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                  },
                })}
              >
                {tableColumns.map((col) => (
                  <TableCell key={col.field}>{col.title}</TableCell>
                ))}
              </TableRow>
            </MDBox>
            <TableBody>
              {!fetchedData?.isLoading &&
                fetchedData?.data?.length > 0 &&
                fetchedData?.data?.map((item) => (
                  <TableRow
                    className={classes.tableRow}
                    onClick={(e) => onSelectHandler(item, e)}
                    key={item._id}
                  >
                    {tableColumns.map((col) => (
                      <TableCell key={col.field}>
                        {col.customCell ? col.customCell(item[col.field], item) : item[col.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}{" "}
              {!fetchedData?.isLoading && fetchedData?.data?.length === 0 && (
                <h5 style={{ padding: "10px" }}>No results found!</h5>
              )}
              {fetchedData?.isLoading && <h5 style={{ padding: "10px" }}>Searching...</h5>}
            </TableBody>
          </Table>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default values for the props
Searchbar.defaultProps = {
  // currentVenue: {},
  //  setFilters: () => { },
  columns: [],
  fetchAll: false,
  resultsLimit: 10,
  queryCharacterLimit: 3,
  placeholder: "",
  filterBy: "slug",
  onRowClick: null,
};

// Typechecking props
Searchbar.propTypes = {
  //  setFilters: PropTypes.func,
  fetchAll: PropTypes.bool,
  resultsLimit: PropTypes.number,
  queryCharacterLimit: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  //  searchBy: PropTypes.arrayOf(PropTypes.string) || PropTypes.string,
  filterBy: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default Searchbar;
