import { TableAction } from "@perimeter-software/react-common";
import MDBox from "components/MDBox";
import { navbarDesktopMenu } from "examples/Navbars/DashboardNavbar/styles";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";

const MyVenuesActions = ({ slug, isFavorite, setFavorite }) => {

  const { userType } = useAppContextController()
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    if (action === "venueevents") navigate(`/events/venue/${slug}`);
    else navigate(`/venues/${slug}/action/${action}`);
  }

  return (
    <MDBox display="flex" justifyContent="end">
      {userType !== "User" ?
        <>
          <TableAction
            color="error"
            icon="people"
            showBadge
            clickAction={(e) => onClickHandler("venueapplicants", e)}
            tooltip="Staffing Pool"
          />
          &nbsp;&nbsp;
        </>
        : null
      }
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler("venueevents", e)}
        tooltip="Venue Events"
      />
      &nbsp;&nbsp;
      <TableAction
        color="secondary"
        icon="edit"
        clickAction={(e) => onClickHandler("venueinfo", e)}
        tooltip="Edit Venue"
      />
      &nbsp;&nbsp;
      <TableAction
        color={isFavorite() ? "warning" : "light"}
        icon="star"
        clickAction={() => setFavorite(slug)}
        tooltip="Add/Remove From MyVenues"
      />
    </MDBox>
  );
};
export default MyVenuesActions;

MyVenuesActions.defaultProps = {
  slug: "",
  isFavorite: false,
};


MyVenuesActions.propTypes = {
  slug: PropTypes.string,
  isFavorite: PropTypes.bool,
};
