import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";

const ApplyButton = ({ row }) => {
    console.log('ApplyButton', row)
    return (
        <MDButton
            component={NavLink}
            to={`/jobs/apply/${row.jobSlug}`}
            variant="contained"
            color="error"
            fullWidth
        >
            Apply
        </MDButton>
    )
}

export default ApplyButton