import DirectDepositFormFields from "components/FormFields/DirectDepositFormFields";
import { useNewApplicantContext } from "context/NewApplicantContext";

const DirectDeposit = () => {
  const { applicant, updateApplicantAction } = useNewApplicantContext();

  const onSubmit = async (data) => {
    const parsedData = {
      directDeposit: data,
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  return <DirectDepositFormFields onSubmit={onSubmit} />;
};

export default DirectDeposit;
