import { Autocomplete, CircularProgress, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import FormField from "layouts/applications/wizard/components/FormField";
import {
  messageEmail,
  messageSystem,
  messageText,
  templateSubstitution,
} from "layouts/pages/applicants/actions/sendMessageAction";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
// import templateTypes from "assets/templateTypes.json";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

const useStyle = makeStyles({
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
});

const SendMessage = ({
  recipient,
  setToastAlert,
  setOpen,
  setText,
  text,
  email,
  setEmail,
  applicant,
  eventId = null,
  jobId = null,
  venueId = null,
  isButtonDisabled = false,
}) => {
  const sb = useSnackbar();
  const enqueueSnackbar = sb?.enqueueSnackbar || null;
  const classes = useStyle();
  const { currentLoggedUser, emailTemplates, getEmailTemplates } = useAppContextController();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [system, setSystem] = useState(false);
  const [error, setError] = useState(false);
  const [noFormatSelected, setNoFormatSelected] = useState(false);
  const [noTemplateSelected, setNoTemplateSelected] = useState(false);
  const [noSubject, setNoSubject] = useState(false);
  const [noMessage, setNoMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customSubject, setCustomSubject] = useState("");
  const [templateTypes, setTemplateTypes] = useState([]);
  const { _id: userId, emailAddress } = currentLoggedUser;
  const { _id: applicantId, email: applicantEmail, phone, firstName, lastName } = recipient;

  useEffect(() => {
    console.log("stuff", applicantId, applicantEmail);
    const getMessageTemplateSubstitution = async () => {
      const result = await templateSubstitution({
        selectedTemplate,
        applicantId,
        user: currentLoggedUser,
        eventId,
        jobId,
        venueId,
      });
      if (result?.data?.messageText) setEditorValue(result.data.messageText);
      //      else setEditorValue('<p></p>')
    };
    setNoFormatSelected(!(system || text || email));
    setNoTemplateSelected(selectedTemplate === null);
    if (selectedTemplate === "Custom Message") {
      setNoSubject(customSubject.trim() === "");
    }
    setNoMessage(typeof parse(editorValue, { trim: true }).props?.children === "object");

    // This is where we need to call the API endpoint to do the string substitution
    if (selectedTemplate && !selectedTemplate === "Custom Message")
      getMessageTemplateSubstitution();
  }, [system, text, email, selectedTemplate, customSubject, editorValue]);

  useEffect(() => {
    if (!emailTemplates?.length) getEmailTemplates();
  }, []);

  useEffect(() => {
    if (emailTemplates?.length) {
      setTemplateTypes(["Custom Message", ...emailTemplates.map((item) => item.name)]);
    }
  }, [emailTemplates?.length]);

  const handleTemplateChange = (v) => {
    setSelectedTemplate(v);
    if (selectedTemplate) {
      setNoTemplateSelected(false);
    }
  };
  function handleChange(v, format) {
    switch (format) {
      case "system":
        setSystem(v);
        break;
      case "text":
        setText(v);
        break;
      case "email":
        setEmail(v);
        break;
      default:
        break;
    }
  }
  const handleSubjectChange = (value) => {
    setCustomSubject(value);
  };

  const sendMessage = () => {
    setError(true);
    if (!(noFormatSelected || noTemplateSelected || noSubject || noMessage)) {
      setButtonDisabled(true);
      if (enqueueSnackbar)
        enqueueSnackbar("Sending Your Message... Please Wait", { variant: "warning" });

      if (system === true) {
        const data = {
          sender: {
            userId,
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
          },
          recipient: {
            applicantId,
            firstName: recipient.firstName,
            lastName: recipient.lastName,
          },
          sendTime: new Date(),
          templateName: selectedTemplate,
          status: "unread",
          subject: selectedTemplate === "Custom Message" ? customSubject : selectedTemplate,
          messageBody: editorValue,
        };

        messageSystem(data)
          .then(() => {
            setButtonDisabled(false);
            setSystem(false);
            setText(false);
            setEmail(true);
            if (enqueueSnackbar) enqueueSnackbar("Message Sent to System", { variant: "success" });
            setError(false);
            setEditorValue(`<p></p>`);
            setSelectedTemplate(null);
            if (setOpen) {
              setOpen(false);
            }
          })
          .catch((e) => {
            setButtonDisabled(false);
            if (enqueueSnackbar)
              enqueueSnackbar(`Something went wrong on system error:${e}`, { variant: "error" });
            if (setOpen) {
              setOpen(true);
            }
          });
      }
      if (text === true) {
        const data = {
          sender: {
            userId,
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
          },
          recipient: {
            applicantId,
            firstName,
            lastName,
            toSms: phone,
          },
          templateName: selectedTemplate,
          subject: selectedTemplate === "Custom Message" ? customSubject : selectedTemplate,
          messageBody: editorValue,
        };

        messageText(data)
          .then(() => {
            setButtonDisabled(false);
            setSystem(false);
            setText(false);
            setEmail(true);
            if (enqueueSnackbar) enqueueSnackbar("Message Sent to SMS", { variant: "success" });
            setError(false);
            setEditorValue(`<p></p>`);
            setSelectedTemplate(null);
            if (setOpen) {
              setOpen(false);
            }
          })
          .catch((e) => {
            setButtonDisabled(false);
            if (enqueueSnackbar)
              enqueueSnackbar(`Something went wrong on SMS error:${e}`, { variant: "error" });
            if (setOpen) {
              setOpen(true);
            }
          });
      } else {
        setNoFormatSelected(true);
      }
      if (email === true) {
        const data = {
          sender: {
            userId,
            fromEmail: "noreply@stadiumpeople.com",
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
          },
          recipient: {
            firstName,
            lastName,
            applicantId,
            toEmail: applicantEmail,
          },
          templateName: selectedTemplate,
          subject: selectedTemplate === "Custom Message" ? customSubject : selectedTemplate,
          messageBody: editorValue,
        };
        messageEmail(data)
          .then(() => {
            setButtonDisabled(false);
            setSystem(false);
            setText(false);
            setEmail(true);
            if (enqueueSnackbar) enqueueSnackbar("Message Sent to Email", { variant: "success" });
            setError(false);
            setEditorValue(`<p></p>`);
            setSelectedTemplate(null);
            if (setOpen) {
              setOpen(false);
            }
          })
          .catch((e) => {
            setButtonDisabled(false);
            if (enqueueSnackbar)
              enqueueSnackbar(`Something went wrong on email error:${e}`, { variant: "error" });
            if (setOpen) {
              setOpen(true);
            }
          });
      }
    }
  };

  return (
    <>
      <MDBox px={3}>
        <Grid container spacing={1} alignItems="center">
          <Grid item lg={8}>
            <Autocomplete
              value={selectedTemplate}
              options={templateTypes}
              onChange={(e, v) => handleTemplateChange(v)}
              renderInput={(params) => (
                <FormField {...params} type="text" label="Select a Message" />
              )}
            />
          </Grid>
          <Grid item lg={4} display="flex" justifyContent="flex-end" />
          {selectedTemplate === "Custom Message" && (
            <Grid item sm={12} lg={9} xl={9} xxl={8}>
              <FormField
                value={customSubject}
                onChange={(e, v) => {
                  handleSubjectChange(e.target.value);
                }}
                label="Enter a Subject"
              />
            </Grid>
          )}
          <Grid item sm={12} lg={9} xl={9} xxl={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4} lg={4} xl={4}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={4} lg={5} xl={5} xxl={5}>
                    <Switch
                      disabled={recipient.status !== "Employee"}
                      checked={recipient.status !== "Employee" ? false : system}
                      onChange={(e, v) => handleChange(v, "system")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} lg={7} xl={7} xxl={7}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      textTransform="capitalize"
                      color={recipient.status !== "Employee" ? "secondary" : "info"}
                    >
                      System
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} lg={4} xl={4}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} lg={5} xl={5} xxl={5}>
                    <Switch
                      disabled={recipient.phone === ""}
                      checked={recipient.phone === "" ? false : text}
                      onChange={(e, v) => handleChange(v, "text")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} lg={7} xl={7} xxl={7}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      textTransform="capitalize"
                      color={recipient.phone === "" ? "secondary" : "info"}
                    >
                      Text
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} lg={4} xl={4}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} sm={4} lg={5} xl={5} xxl={5}>
                    <Switch checked={email} onChange={(e, v) => handleChange(v, "email")} />
                  </Grid>
                  <Grid item xs={12} sm={8} lg={7} xl={7} xxl={7}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      textTransform="capitalize"
                      color="info"
                    >
                      Email
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              {error && (
                <MDBox px={2}>
                  {noTemplateSelected && (
                    <MDTypography style={{ fontSize: "1rem" }} color="error">
                      You must select a template
                    </MDTypography>
                  )}
                  {noFormatSelected && (
                    <MDTypography style={{ fontSize: "1rem" }} color="error">
                      You must select a medium
                    </MDTypography>
                  )}
                  {noSubject && (
                    <MDTypography style={{ fontSize: "1rem" }} color="error">
                      You must imput a subject
                    </MDTypography>
                  )}
                  {noMessage && (
                    <MDTypography style={{ fontSize: "1rem" }} color="error">
                      You must imput a message
                    </MDTypography>
                  )}
                </MDBox>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {buttonDisabled && (
        <MDBox textAlign="center">
          <CircularProgress />
        </MDBox>
      )}
      <MDBox p={3} mb={3}>
        <MDTypography variant="body2" color="dark">
          MESSAGE
        </MDTypography>
        <MDEditor
          className={classes.editor}
          value={editorValue}
          onChange={(e) => setEditorValue(e)}
        />
      </MDBox>
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <MDButton
              disabled={buttonDisabled || isButtonDisabled}
              color="info"
              variant="contained"
              fullWidth
              onClick={sendMessage}
            >
              <MDTypography variant="body2" color="white">
                Send Message
              </MDTypography>
            </MDButton>
          </Grid>
          <Grid item sm={3}>
            <MDButton
              disabled={buttonDisabled}
              color="error"
              variant="contained"
              fullWidth
              onClick={() => {
                setOpen(false);
              }}
            >
              <MDTypography variant="body2" color="white">
                Cancel
              </MDTypography>
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

SendMessage.defaultProps = {
  messageTypes: [],
  system: false,
  text: false,
  email: false,
};

SendMessage.propTypes = {
  messageTypes: PropTypes.array,
  system: PropTypes.bool,
  text: PropTypes.bool,
  email: PropTypes.bool,
};

export default SendMessage;
