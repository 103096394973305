import React from "react";
import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";

const ApplicantsTableActions = ({ id, row, setCurrentApplicant, setNavigateUrl, setGenericModalOpen, setModalInfo }) => {

    const onClickHandler = (action, e) => {
        if (action === "applicantmail") {
            setCurrentApplicant(row);
            setModalInfo({ type: action, data: row });
            setGenericModalOpen(true);
        }
        else {
            e.stopPropagation();
            setNavigateUrl(`/applicants/${id}/action/${action}`)
        }
    };

    return (
        <span>
            <TableAction
                color="info"
                icon="info"
                clickAction={(e) => onClickHandler("applicantinfo", e)}
                tooltip="Applicant Info"
            />&nbsp;&nbsp;
            <TableAction
                color="info"
                icon="stadium"
                clickAction={(e) => onClickHandler("applicantvenues", e)}
                tooltip="Applicant Venues"
            />&nbsp;&nbsp;
            <TableAction
                color="warning"
                icon="work"
                clickAction={(e) => onClickHandler("applicantjobs", e)}
                tooltip="Applicant Jobs"
            />
            &nbsp;&nbsp;
            <TableAction
                color="success"
                icon="event-seat"
                clickAction={(e) => onClickHandler("applicantevents", e)}
                tooltip="Applicant Event"
            />&nbsp;&nbsp;
            <TableAction
                color="error"
                icon="mail"
                clickAction={(e) => onClickHandler("applicantmail", e)}
                tooltip="Applicant Mail"
            />&nbsp;&nbsp;
            <TableAction
                color="dark"
                icon="public"
                clickAction={(e) => onClickHandler("applicantall", e)}
                tooltip="All Applicant Information"
            />
        </span>
    )
}

export default ApplicantsTableActions;

ApplicantsTableActions.defaultProps = {
    id: "",
    setNavigateUrl: () => { },
};

// Typechecking props for the TableAction
ApplicantsTableActions.propTypes = {
    id: PropTypes.string,
    setNavigateUrl: PropTypes.func,
};