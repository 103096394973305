import axios from "axios";
import { API_URL } from "config";

const updateApplicant = async ({ applicantId, data }) => {

  const res = await axios.put(`${API_URL}/applicants/${applicantId}`, data);
  return res.data;
};

export default updateApplicant;
