
import { useState } from "react";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import { Card, Icon, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import EventCalendar from "layouts/pages/events/components/EventCalendar"

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
// import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
    card: {
        height: 350,
    },
    stack: {
        padding: 10,
    },
    editor: {
        height: 100,
    },
    box: {
        overflow: "visible!important",
        height: "100%"
    },
});



const UpcomingEvents = ({ events, isLoading, header, ...rest }) => {
    const classes = useStyle();

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [showEventModal, setShowEventModal] = useState(false);

    const sortByEventDate = (a, b) => {
        if (new Date(a.eventDate) > new Date(b.eventDate)) return 1;
        return -1;
    }

    return (
        <Card className={classes.box} >
            <MDBox display="flex">
                <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="success"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    mx={3}
                    mt={-2}
                >
                    <Icon fontSize="large">
                        date_range
                    </Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Upcoming Events
                </MDTypography>
            </MDBox>


            <MDBox pt={header.title || header.date ? 2 : 0} px={2} lineHeight={1}>
                {header.title ? (
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {header.title}
                    </MDTypography>
                ) : null}
                {header.date ? (
                    <MDTypography component="p" variant="button" color="text" fontWeight="regular">
                        {header.date}
                    </MDTypography>
                ) : null}
            </MDBox>
            <CalendarRoot p={2} ownerState={{ darkMode }}>
                {/* <FullCalendar
                    {...rest}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={events}
                    height="100%"
                /> */}
                <EventCalendar
                    events={events}
                    showEventModal={showEventModal}
                    setShowEventModal={setShowEventModal}
                    isLoading={isLoading}
                    sortByEventDate={sortByEventDate}
                    calendarListView='Calendar'
                />
            </CalendarRoot>
        </Card>

    );
}

// Setting default values for the props of Calendar
UpcomingEvents.defaultProps = {
    header: {
        title: "",
        date: "",
    },
};

// Typechecking props for the Calendar
UpcomingEvents.propTypes = {
    header: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
    }),
};

export default UpcomingEvents