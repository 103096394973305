import axios from "axios";
import { API_URL } from "config";
import createApiParams from "utils/createApiParams";

const fetchNotifications = async ({
  setUnreadNotifications,
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
}) => {

  const userId = filters.userId ? filters.userId : null;
  // eslint-disable-next-line no-param-reassign
  if (userId) delete filters.userId;
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page: page || 1,
    limit: limit || 100,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {

    const url = (userId)
      ? `${API_URL}/notifications/userId/${userId}${paginationQuery}${filterQuery}${sortQuery}${useOr ? "&useOr=true" : ""}`
      : `${API_URL}/notifications${paginationQuery}${filterQuery}${sortQuery}${useOr ? "&useOr=true" : ""}`

    const res = await axios.get(url);

    console.log('res', res?.data?.data)
    if (res?.data?.data) {
      const ur = res?.data?.data?.filter(item => item.status === 'unread');
      setUnreadNotifications([...ur]);
    } else setUnreadNotifications([]);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchNotifications;
