// Filter String Generator according to API requirement
const generateFilterString = (filtersObject) => {
  const filtersArray = [];
  if (filtersObject) {
    Object.keys(filtersObject).forEach((key) => {
      filtersArray.push(`${key}:${filtersObject[key]}`);
    });
    return filtersArray.join(",");
  }
  return null;
};

export default generateFilterString;
