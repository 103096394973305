import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import deleteIssue from "layouts/pages/issues/actions/deleteIssue";
import PropTypes from "prop-types";

import fetchIssueById from "layouts/pages/issues/actions/fetchIssueById";

import { Alert, Snackbar } from "@mui/material";
import IssuesInformation from "layouts/pages/issues/components/IssuesInformation";
import MessageTemplatesTable from "layouts/pages/setup/components/MessageTemplatesTable";
import { SnackbarProvider } from "notistack";

function Setup({ mode = "edit" }) {
  const navigate = useNavigate();

  const { currentIssue, setCurrentIssue, venues } = useAppContextController();
  const { issueId, action } = useParams();
  const [actionSelected, setActionSelected] = useState(action || "");
  const [filters, setFilters] = useState({});
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const queryClient = useQueryClient();

  const deleteIssueHandler = async (deleteIssueId, issueTitle) => {
    const res = await deleteIssue(deleteIssueId);

    if (res?.status === 200) {
      await queryClient.invalidateQueries("issues");

      setToastAlert({
        isShow: true,
        message: `Issue ${issueTitle} has been deleted`,
        status: "success",
      });
      return { success: true };
    }

    setToastAlert({
      isShow: true,
      message: `Something went wrong -- ${res?.data?.message}`,
      status: "error",
    });
    return { success: false, message: res?.data?.message };
  };

  useEffect(async () => {
    if (issueId && (actionSelected || action)) {
      setFilters({ _id: issueId });
    }
    if (action) {
      // && action !== actionSelected) {
      setActionSelected(action);
    } else {
      setActionSelected("");
      // setFilters({ status: "Open" });
    }

    if (issueId && (!currentIssue || currentIssue._id !== issueId)) {
      const issues = await fetchIssueById(issueId);
      if (issues && issues?.data?.length) setCurrentIssue(issues?.data[0]);
    }
  }, [issueId, action, currentIssue]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={12} xl={12} xxl={12}>
          {/* <IssuesFilterCards
            setFilters={setFilters}
            // setPage={setPage}
          /> */}
          <SnackbarProvider
            maxSnack={3}
            open={toastAlert.isShow}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            key="top-center"
          >
            <MessageTemplatesTable
              id={issueId}
              filters={filters}
              setFilters={setFilters}
              actionSelected={actionSelected}
              setNavigateUrl={setNavigateUrl}
              showFiltersList
              title="Message Templates"
            />
          </SnackbarProvider>
        </Grid>
        {["issuesinfo", "issuesall", ""].includes(actionSelected) &&
          (issueId || mode === "create") && (
            <Grid item xs={12} sm={12}>
              <IssuesInformation mode={mode} deleteIssue={deleteIssueHandler} />
            </Grid>
          )}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Setup.defaultProps = {
  mode: "edit",
};

// Typechecking props
Setup.propTypes = {
  mode: PropTypes.string,
};
export default Setup;
