import { useEffect, useState } from 'react';
import { useAppContextController } from "context/AppContext";
import { Button, Modal, Fade, Card, Grid, Autocomplete, Alert } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from "react-query";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MDBox from 'components/MDBox';
import AttachFile from '@mui/icons-material/AttachFile';
import { makeStyles } from "@mui/styles";
import MDTypography from 'components/MDTypography';
import attachmentTypes from "assets/attachmentTypes.json";
import { API_URL } from "config";
import FormField from "layouts/applications/wizard/components/FormField";
import axios from "axios";
import FileDropzone from "components/Dropzone";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { Snackbar } from '@material-ui/core';

const useStyle = makeStyles({

    container: {
        position: "absolute",
        width: "25%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    dropzone: {
        height: 170,
    },
});

const UploadFileModal = ({ currentApplicant, setCurrentApplicant, open, setOpen }) => {
    const classes = useStyle();
    // const { currentApplicant, setCurrentApplicant } = useAppContextController();
    const handleClose = () => setOpen(false);
    const [attachmentTitle, setAttachmentTitle] = useState("")
    const [attachmentType, setAttachmentType] = useState(null);
    const [missingMeta, setMissingMeta] = useState(true)

    const [toastAlert, setToastAlert] = useState({
        isShow: false,
        message: "",
    });

    const queryClient = useQueryClient();
    const handleInputChange = (e) => {
        e.preventDefault()
        setAttachmentTitle(e.target.value)
    }
    const updateApplicantMutation = useMutation(updateApplicant, {
        onError: () => setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
        onSuccess: async (_, { data }) => {
            await queryClient.invalidateQueries("applicants");
            setCurrentApplicant({ ...currentApplicant, ...data });
            setToastAlert({ isShow: true, message: "Attachment has been upload!", status: "success" });
        },
    });
    const onDropHandler = async (acceptedFiles, rejectedFiles) => {

        if (rejectedFiles.length !== 0) {
            setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" })
        }

        if (acceptedFiles.length !== 0) {
            const uploadFile = async (form, fileName, fileType) => {
                await axios.post(
                    `${API_URL}/upload/applicants/${currentApplicant?._id}/${attachmentType}`,
                    form
                );
                const newAtt = {
                    "title": attachmentTitle,
                    "type": attachmentType,
                    "docType": fileType,
                    "filename": fileName,
                    "uploadDate": new Date()
                };
                const newAttachmentsArr = currentApplicant.attachments ? [...currentApplicant.attachments, newAtt] : [newAtt];
                await updateApplicantMutation.mutateAsync({
                    applicantId: currentApplicant._id,
                    data: {
                        attachments: [...newAttachmentsArr],
                    }
                });
                // applicantId: currentApplicant._id, data: {
                //     // ...currentApplicant, 
                //     attachments: [...newAttachmentsArr]
            }

            const file = acceptedFiles[0];
            const fileType = file.path.split('.').pop()
            const form = new FormData();
            form.append("file", file);
            if (currentApplicant._id) {
                uploadFile(form, file.path, fileType);
            }
            setAttachmentTitle("")
            setAttachmentType(null)
            setOpen(false)
        }
    };

    useEffect(() => {

        if (attachmentTitle !== "" && attachmentType !== null) {
            setMissingMeta(false)
        }
        if (attachmentTitle === "" || attachmentType === null) {
            setMissingMeta(true)
        }


    }, [attachmentType, attachmentTitle])
    return (
        <>
            <Snackbar
                open={toastAlert.isShow}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
                key="top-center"
            >
                <Alert
                    onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
                    severity={toastAlert.status}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {toastAlert.message}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={(event, reason) => {
                    if (reason === "escapeKeyDown") {
                        handleClose();
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Fade in={open}>
                    <Card className={classes.container}>
                        <MDBox p={4}>
                            <MDBox display="flex" justifyContent="space-between">
                                <MDTypography>
                                    <AttachFile color="error" /> Upload File
                                </MDTypography>
                                <MDBox display="flex" alignItems="center">
                                    <Button
                                        variant="text"
                                        startIcon={<CancelIcon />}
                                        onClick={() => {
                                            setAttachmentTitle("")
                                            setAttachmentType(null)
                                            setOpen(false)
                                        }}
                                    >
                                        Discard
                                    </Button>

                                </MDBox>
                            </MDBox>
                            <Grid item lg={8}>

                                <Autocomplete
                                    options={attachmentTypes}
                                    onChange={(e, v) => { setAttachmentType(v) }}
                                    renderInput={(params) => (
                                        <FormField
                                            label="Attachment Type"
                                            {...params} type="text" />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={8}>
                                <FormField
                                    variant="standard"
                                    label="Name or Title"
                                    value={attachmentTitle}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <MDBox mt={2}>

                                <MDTypography variant="h5" color="info">Upload File</MDTypography>
                                <MDBox
                                    onClick={() => {
                                        if (missingMeta) setToastAlert({ isShow: true, message: "You need fill an Attachment Type and a Name or Title", status: "error" })
                                    }
                                    }
                                >
                                    <FileDropzone disabled={missingMeta}
                                        message={
                                            missingMeta ?
                                                "you must fill Attachment Type and Name in order to upload a File" :
                                                "Drop files here to upload"
                                        }
                                        className={classes.dropzone}
                                        onDrop={onDropHandler}
                                    />
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Fade>
            </Modal>
        </>
    );
};


export default UploadFileModal