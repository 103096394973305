import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Icon, Grid, IconButton, Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider } from "notistack";
import ActivitiesTableActions from "layouts/pages/activities/components/ActivitiesTableActions";
import GetIcons from "components/Activities/GetIcons";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import fetchActivities from "../../actions/fetchActivities";
// import ActivitiesActionModal from "../ActivitiesActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const ActivitiesTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
}) => {
  const { setCurrentActivity, currentActivity } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentActivity });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const { data: users, isLoading } = useQuery(["activities", allOptions], () =>
    fetchActivities(allOptions)
  );

  useEffect(() => {
    if (action && filters) setPage(1);
  }, [filters]);

  const columns = [
    {
      title: "Icon",
      field: "type",
      customCell: (image, field, row) => (
        <GetIcons action={row?.action} activity={row} />
      ),
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "Date",
      field: "activityDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "User",
      field: "userFirstName",
      customCell: (id, field, row) => (
        <MDTypography variant="body">
          {row.userFirstName} {row.userLastName}
        </MDTypography>
      ),
    },
    {
      title: "Applicant",
      field: "applicantFirstName",
      customCell: (id, field, row) => (
        <MDTypography variant="body">
          {row.applicantFirstName} {row.applicantLastName}
        </MDTypography>
      ),
    },
    {
      title: "Action",
      field: "action",
      customCell: (subject) => (
        <MDTypography variant="body">
          {subject?.length > 15 ? `${subject.substring(0, 15)}...` : subject}
        </MDTypography>
      ),
    },
    {
      title: "Description",
      field: "description",
      customCell: (desc) => (
        <MDTypography variant="body">
          {desc?.length > 50 ? `${desc.substring(0, 50)}...` : desc}
        </MDTypography>
      ),
    },
    {
      title: "Activiy Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <ActivitiesTableActions
          id={id}
          row={row}
          setCurrentActivity={setCurrentActivity}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/User ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
  ];

  const columnsForApplicantSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email", field: "email" },
    { title: "Status", field: "status" },
  ];

  const handleRowClick = (activity, e) => {
    setCurrentActivity({ ...activity });
    setNavigateUrl(`/activities/${activity._id}`);
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="error"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <TimelineIcon fontSize="large" />
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Activities
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchApplicants}
                placeholder="Search Applicants"
                columns={columnsForApplicantSearch}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "email"]}
                filterBy="applicantId"
                filterByColumn="_id"
              />
            )}
            {/* <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox> */}

            {showSearchBar && (
              <Searchbar
                fetch={fetchUsers}
                placeholder="Search Users"
                columns={columnsForSearch}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "emailAddress"]}
                filterBy="userId"
                filterByColumn="_id"
              />
            )}
            {/* <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox> */}
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={users}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={fetchAll}
            defaultSort="activityDate:desc"
          />
        </MDBox>
        {/* <ActivitiesActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
      </SnackbarProvider>
    </Card>
  );
};

// Setting default values for the props
ActivitiesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Users",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
ActivitiesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default ActivitiesTable;
