import { useEffect, useState } from "react";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import ApplicantOnBoardingPanel from "layouts/pages/applicants/components/ApplicantOnboardingPanel";
import FederalW4 from "layouts/pages/applicants/components/ApplicantOnboardingPanel/FederalW4";
import ProfileInfo from "layouts/pages/profile/components/ProfileInfo";
import Header from "layouts/pages/profile/components/Header";
import ChangeMyW4 from "layouts/pages/profile/components/ChangeMyW4";
import DirectDepositRequest from "./components/DirectDepositRequest";

function NewProfile() {
  const { userType, currentLoggedUser } = useAppContextController();
  const [applicant, setApplicant] = useState({});
  const [bottomPannel, setBottomPannel] = useState(null)

  useEffect(() => {
    const getApplicant = async () => {
      const appl = await fetchSingleApplicant({ applicantId: currentLoggedUser?.applicantId });
      if (appl) setApplicant(appl.data);
    }
    if (userType === "User" && currentLoggedUser?.applicantId) {
      getApplicant();
    }
  }, [userType, currentLoggedUser?.applicantId]);
  console.log(setBottomPannel)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={8}>
            <Header />
          </Grid>
          {["Master", "Admin", "User"].includes(userType) && (
            <Grid item xs={12} lg={8}>
              <ProfileInfo userType={userType} currentApplicant={applicant} setCurrentApplicant={setApplicant} setBottomPannel={setBottomPannel} />
            </Grid>
          )}
          {["User"].includes(userType) && (
            <>
              <Grid item xs={12} lg={8}>
                {/* <ApplicantOnBoardingPanel currentApplicant={applicant} userPage /> */}
                {bottomPannel === "changeMyW4" &&
                  <Card >
                    <MDBox p={3}>
                      <FederalW4 currentApplicant={applicant} setCurrentApplicant={setApplicant} />
                    </MDBox>
                  </Card>
                }
              </Grid>
              <Grid item xs={12} lg={8}>
                {bottomPannel === "changeDirectDeposit" &&
                  <DirectDepositRequest setBottomPannel={setBottomPannel} currentApplicant={applicant} setCurrentApplicant={setApplicant} />
                }
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewProfile;
