import { useEffect, useState, useCallback } from "react";
import { useAppContextController } from "context/AppContext";
import { useNavigate, useParams } from "react-router-dom";

import DataList from "components/DataList"
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import MDBox from 'components/MDBox'
import MDTypography from "components/MDTypography";
import { Stack, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";

import ApplicantVenuesActions from "layouts/pages/applicants/components/ApplicantVenuesActions";
import { useMutation } from "react-query";
import createActivity from "layouts/pages/activities/actions/createActivity"
import updateApplicant from "../../actions/updateApplicant";

const useStyle = makeStyles({
    card: {
        height: 350,
    },
    stack: {
        padding: 10,
    },
    editor: {
        height: 100,
    },
    box: {
        overflow: "visible",
    },
    tableRowBordered: {
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: "pointer",
    },
    tableRow: {
        "& td, & th": { border: 0 },
        cursor: "pointer",
    },
    addButton: {
        fontSize: 40,
    }
});

const Venues = ({ fetchAll = true, title = "Title", filters, setFilters }) => {

    const columns = [
        { title: "Logo", field: "logoUrl", type: "image", imageWidth: "50", imageHeight: "50" },
        { title: "Venue ID", field: "slug", customCell: (slug) => <span>{slug?.toUpperCase()}</span> },
        { title: "Venue Name", field: "name" },
        { title: "City", field: "city" },
        { title: "State", field: "state" },
    ];

    const { currentApplicant, setCurrentApplicant, venues } = useAppContextController();

    const [allVenues, setAllVenues] = useState(Object.values(venues));  // an array of all of the venues
    const [page, setPage] = useState(1);

    const changeStatusMutation = useMutation(updateApplicant, {
        onSuccess: (_, { data }) => setCurrentApplicant({ ...currentApplicant, ...data }),
        mutationKey: 'venueStatusMutation',
    })

    // useEffect(() => {
    //     setAllVenues(Object.values(venues));

    // const newVenues = currentApplicant?.venues ? convertArrayToObject(currentApplicant?.venues, 'venueSlug') : {}
    // setApplicantVenues(newVenues)

    // if (currentApplicant?.venues && currentApplicant.venues?.length) {
    //     const filteredVenues = convertArrayToObject(Object.values(venues).filter(venue => !newVenues?.[venue.venueSlug]), 'slug')
    //     const statVenues = convertArrayToObject(currentApplicant?.venues?.map(item => venues?.[item.venueSlug]), 'slug')
    //     setAllVenues(Object.values({ ...statVenues, ...filteredVenues }))
    // }
    // else {
    //     console.log('here')
    //     setAllVenues(Object.values(venues))
    // }
    // }, [appId, venues]);

    const setAndRequery = useCallback((status, slug) => {
        let appVenues = currentApplicant?.venues

        if (appVenues) {
            if (status !== "None") {

                const [findVenue] = appVenues.filter(ven => ven.venueSlug === slug)

                if (findVenue) findVenue.status = status
                else {
                    appVenues.push({
                        status,
                        venueSlug: slug,
                        dateModified: new Date()
                    })
                }
            }
            else appVenues = appVenues.filter(item => item.venueSlug !== slug)

        }
        else {
            appVenues = [{
                status,
                venueSlug: slug,
                dateModified: new Date()
            }]
        }

        const newApp = { ...currentApplicant, venues: appVenues }
        if (newApp._id) delete newApp._id

        setCurrentApplicant({ ...currentApplicant, ...newApp })

        changeStatusMutation.mutate({
            applicantId: currentApplicant._id,
            data: { ...newApp }
        })
    }, [currentApplicant?.venues])

    const navigate = useNavigate()

    const getVenueStatus = useCallback((arr, slug) => {
        const venueRec = arr.find(item => item.venueSlug === slug);
        return (venueRec) ? venueRec.status : "None";
    }, [currentApplicant, currentApplicant?.venues])

    const createActivityAction = (activity, row) => {
        const activityRecord = {
            ...activity,
            venueId: row._id,
            applicantId: currentApplicant?._id
        }
        alert(JSON.stringify(activityRecord));
        createActivity(activityRecord);
    }
    const renderRow = (row) => (
        <>
            <MDBox key={`${row.name}_${row.slug}`} display="flex" justifyContent="space-between">
                <Grid item xs={12} sm={2} lg={1.5}>
                    <VenueIcon logoUrl={row.logoUrl} slug={row.slug} name={row.name} />
                </Grid>
                <Grid item xs={12} sm={6} lg={8.5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <MDTypography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{row.name}</MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <MDTypography variant="body2">
                                {row.city}, {row.state}
                            </MDTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <Stack direction="row" justifyContent="right">
                        <ApplicantVenuesActions
                            setStatusRef={setAndRequery}
                            slug={row.slug}
                            startStatus={getVenueStatus(currentApplicant?.venues, row.slug)}
                            createActivity={createActivityAction}
                        />
                    </Stack>
                </Grid>
            </MDBox>
        </>
    );

    const header = (
        <Stack display="flex" flexDirection="row" p={2} >
            <Searchbar
                fetch={fetchVenues}
                fetchAll={false}
                placeholder="Search Venue/City"
                columns={columns.slice(1, columns.length - 1)}
                queryCharacterLimit={1}
                resultsLimit={10}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["name", "city"]}
                filterBy="slug"
            // onRowClick={(row) => navigate(`/venues/${row.slug}`)}
            />

        </Stack >
    );

    return (
        <DataList
            renderRow={renderRow}
            data={allVenues}
            icon="stadium"
            iconColor="info"
            title="Venues"
            isLoading={false}
            greybar
            divider
            header={header}
            tableHeight={450}
            height={550}
            scrollY
        />
    )
}

export default Venues