import { useMemo } from "react";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PublicIcon from '@mui/icons-material/Public';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StadiumIcon from '@mui/icons-material/Stadium';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BugReportIcon from '@mui/icons-material/BugReport';
import DoneIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/Pending';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

const IssuesFilterCards = ({ setFilters, setPage }) => {
  const { setCurrentVenue, currentLoggedUser } = useAppContextController();
  const myVenues = currentLoggedUser?.venues?.length ? currentLoggedUser.venues.join(';') : ""

  const cards = useMemo(() => [
    {
      color: "info",
      icon: <PendingIcon fontSize="large" color="info" />,
      title: "Open",
      number: 0,
      filterField: "status",
      filterValue: "Open",
      canCombine: true,
    },
    {
      color: "success",
      icon: <DoneIcon fontSize="large" color="success" />,
      title: "Closed",
      number: 0,
      filterField: "status",
      filterValue: "Closed"
    },
    {
      color: "error",
      icon: <BugReportIcon fontSize="large" color="error" />,
      title: "Bugs",
      number: 0,
      filterField: "type",
      filterValue: "Bug",
      canCombine: true,
    },
    {
      color: "warning",
      icon: <QuestionMarkIcon fontSize="large" color="warning" />,
      title: "Questions",
      number: 0,
      filterField: "type",
      filterValue: "Question",
      canCombine: true,
    },
    {
      color: "info",
      icon: <EnhancedEncryptionIcon fontSize="large" color="info" />,
      title: "Enhance",
      number: 0,
      filterField: "type",
      filterValue: "Enhancement",
      canCombine: true,
    },
    {
      color: "dark",
      icon: <PublicIcon fontSize="large" color="black" />,
      title: "All",
      number: 0,
      filterField: "status",
      filterValue: "",
    },
  ]);

  const setIssueFilters = (filter) => {
    setCurrentVenue(null);
    setFilters(filter);
  }
  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setIssueFilters}
        setPage={setPage}
      />
    </span>
  )
}

export default IssuesFilterCards;
