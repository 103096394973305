import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from '@mui/icons-material/Delete';
import parse from "html-react-parser";
import SaveIcon from "@mui/icons-material/Save";
import FormField from "layouts/applications/wizard/components/FormField";
import MDEditor from "components/MDEditor";
import ConfirmDialog from "components/ConfirmDialog";
import createIssue from "layouts/pages/issues/actions/createIssue";
// import updateIssue from "layouts/pages/issues/actions/updateIssue";
import { API_URL, IMAGE_SERVER } from "config";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import UserActionModal from "layouts/pages/users/components/UserActionModal";
import PictureAvatar from "components/PictureAvatar";
import BugReportIcon from '@mui/icons-material/BugReport';
import EmailIcon from '@mui/icons-material/Email';

import FileDropzone from "components/Dropzone";
import issuesCategories from "assets/issuesCategories.json";
import issuesTypes from "assets/issuesTypes.json";
import issuesStatuses from "assets/issuesStatuses.json";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Grid,
  Icon,
  Link,
  InputAdornment,
  Snackbar,
  Stack,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommentSection from "layouts/pages/issues/components/IssuesInformation/CommentSection";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import createUser from "layouts/pages/users/actions/createUser";
import updateIssue from "../../actions/updateIssue";
import fetchIssueById from "../../actions/fetchIssueById";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 175,
  },
  dropzone: {
    height: 370,
  },
  previewButton: {
    padding: 0,
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
  },
});

function IssuesInformation({ mode = "edit", deleteIssue }) {
  const classes = useStyle();
  const { currentIssue, setCurrentIssue, currentLoggedUser, userType } = useAppContextController();
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: {} });
  const { issueId } = useParams();
  const [createdByUser, setCreatedByUser] = useState(null);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [isModified, setIsModified] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [createConfirmModal, setCreateConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    setError,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const defaultValues = {
    issueTitle: "",
    category: "",
    modifiedDate: null,
    type: "",
    status: "Open",
    comments: [],
    // issueDescription: `<p></p>`

  }

  const issueTitle = { ...register("issueTitle", { required: "Issue title is required" }) };
  const category = {
    ...register("category", {
      required: "Issue category is required",
      validate: { exist: (value) => issuesCategories.includes(value) || "Insert a valid category" },
    }),
  };
  const type = {
    ...register("type", {
      required: "Issue type is required",
      validate: { exist: (value) => issuesTypes.includes(value) || "Insert a valid type" },
    }),
  };
  const status = {
    ...register("status", {
      required: "Issue status is required",
      validate: { exist: (value) => issuesStatuses.includes(value) || "Insert a valid status" },
    }),
  };
  const issueDescription = {
    ...register("issueDescription", {
      validate: {
        requiredDescription: (value) => {
          const finalValue = (value !== "" && value !== "<p><br></p>")
          return finalValue || "Description is required"
        }
      }
    })
  };
  const comments = register("comments");
  const issueNumber = register("issueNumber");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading } = useMutation(createIssue, {
    onError: (err) =>
      setToastAlert({ isShow: true, message: `Something went wrong!  ${err.toString()}`, status: "error" }),
    onSuccess: async (_, data) => {

      await queryClient.invalidateQueries("issues");
      setToastAlert({ isShow: true, message: "Issue has been created!", status: "success" });
      setCurrentIssue({ ...currentIssue, ...data });
      setCreatedByUser(currentLoggedUser)
      navigate(`/issues/${_.insertedId}`);
    },
  });

  const updateIssueMutation = useMutation(updateIssue, {
    onError: (err) =>
      setToastAlert({ isShow: true, message: `Something went wrong!  ${err.toString()}`, status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("issues");
      setCurrentIssue({ ...currentIssue, ...data });
      setToastAlert({ isShow: true, message: "Issue has been updated!", status: "success" });
    },
  });


  const uploadImgMutation = useMutation(
    async (imgForm) => {
      await axios.post(
        `${API_URL}/upload/issues/${currentIssue.category}/${currentIssue._id}/`,
        imgForm.form
      );
      await updateIssue({ id: currentIssue._id, data: imgForm.data, });
    },
    {
      onError: (err) =>
        setToastAlert({ isShow: true, message: `Something went wrong!  ${err.toString()}`, status: "error" }),
      onSuccess: (_, data) => {
        setCurrentIssue({ ...currentIssue, ...data.data });
        setToastAlert({ isShow: true, message: "Picture has been updated!", status: "success" });
      },
    }
  );



  const createIssueHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser._id
    data.modifiedDate = new Date();
    data.issueDate = new Date();
    data.userFirstName = currentLoggedUser.firstName;
    data.userLastName = currentLoggedUser.lastName;
    await mutateAsync(data);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  function diffObj(obj1, obj2) {

    const result = {};
    if (Object.is(obj1, obj2)) return undefined;
    if (!obj2 || typeof obj2 !== 'object') return obj2;
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {

      let val;
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) val = obj2[key];

      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {

        const value = diffObj(obj1[key], obj2[key]);
        if (value !== undefined) val = value;

      } else if (val !== undefined) {

        result[key] = val;

      }

    });
    return result;

  }

  const resetDeleteConfirmModal = () => {
    setDeleteConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };
  const resetCreateConfirmModal = () => {
    setCreateConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getIssueValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentIssue?.[key] || "";
    });
    return values;
  };

  const getDefaultValues = () => {
    const defaults = { ...defaultValues };
    Object.keys(watch()).forEach((key) => {
      setValue(key, defaults[key] || "");
    });
    return defaults;
  };

  const cancelChanges = () => {
    reset(getIssueValues());
    setEditorValue(currentIssue.issueDescription);
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes have been abandoned", status: "success" });
  };

  const saveChanges = async () => {
    if (!currentIssue._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!, no Id", status: "error" });
      return;
    }

    const values = diffObj(getIssueValues(), watch())

    if (JSON.stringify(getIssueValues().comments) !== JSON.stringify(watch().comments)) {
      values.comments = watch().comments
    }

    // eslint-disable-next-line
    const data = { ...values, modifiedDate: new Date() }
    await updateIssueMutation.mutateAsync({
      id: currentIssue._id,
      data,
    });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  const cancelCreate = () => {
    setCreateConfirmModal({
      isOpen: true,
      title: "You are leaving create mode",
      bodyText: "Please Confirm to abandon new Issue",
      response: false,
    });
  }

  const onDelete = () => {
    setDeleteConfirmModal({
      isOpen: true,
      title: "Delete Issue",
      bodyText: "Please confirm you want to DELETE this Issue!",
      response: false,
    });
  };

  const onDropHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    if (file) {
      uploadImgMutation.mutate({
        form,
        data: { snapShot: file.name },

      });
    }
  };


  const avatar = currentIssue?.snapShot && (
    <Card>
      <img
        src={`${IMAGE_SERVER}/issues/${currentIssue?.category}/${currentIssue?._id}/${currentIssue?.snapShot}`}
        alt={
          currentIssue?.snapShot ? `${currentIssue.issueTitle} Issue Image` : "No Issue Image Set"
        }
        style={{ width: "100%" }}
      />
    </Card>
  )

  useEffect(() => {
    const fetchCurrentIssue = async (id) => {
      const res = await fetchIssueById(id);
      if (res) setCurrentIssue(res);
    }
    if (issueId) {
      setCreatedByUser(null);
      fetchCurrentIssue(issueId);
    }
  }, [])

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};

      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentIssue(data);
      reset(getDefaultValues());
      setEditorValue(defaultValues.issueDescription);

    }
  }, [mode]);

  useEffect(() => {
    const getCreateUser = async (id) => {
      if (!id) {
        setCreatedByUser(null);
      };
      const res = await fetchUserById(id);
      if (res) setCreatedByUser(res)
    }
    if (mode === "edit") {
      if (currentIssue) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentIssue[key] || "");
        });
        setEditorValue(currentIssue.issueDescription);
        setIsModified(false);
      }

      const subscription = watch((value) => {
        if (currentIssue) {
          setIsModified(JSON.stringify(getIssueValues()) !== JSON.stringify(value));
        }
      });

      // lets fetch the user that opened the issue

      if (!createdByUser || createdByUser._id !== currentIssue?.createAgent) {
        getCreateUser(currentIssue?.createAgent);
      }

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
    return null;
  }, [watch, currentIssue, confirmModal.response]);

  useEffect(() => {
    const del = async () => {
      const deleteIssuetitle = currentIssue?.issueTitle;
      const res = await deleteIssue(currentIssue._id, deleteIssuetitle);
      if (res.success) {
        setCurrentIssue(null);
        navigate(`/issues`);
      }
    }
    if (deleteConfirmModal.response) {
      resetDeleteConfirmModal();
      del();
    }
  }, [deleteConfirmModal.response])

  useEffect(() => {
    if (createConfirmModal.response) {
      resetCreateConfirmModal();
      navigate(`/issues`);
    }
  }, [createConfirmModal.response])

  return (
    <Card className={classes.box} key={currentIssue?.id}>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <form
        onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createIssueHandler)}
      >
        <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              shadow="md"
              borderRadius="xl"
              mt={-2}
            >
              <Icon fontSize="large">
                <BugReportIcon />
              </Icon>
            </MDBox>
            {currentIssue &&
              <MDBox>
                <Grid container flexDirection="row" >
                  <Grid item sm={12}>
                    <MDTypography variant="h6" color="dark" sx={{ mt: 2, ml: 2 }}>
                      {(mode === "create") ? "System Issues Submission" : `Issue: ${currentIssue?.issueTitle}`}
                    </MDTypography>
                  </Grid>
                  {mode !== "create" && <>
                    <Grid item sm={12} display="flex" flexDirection="row" alignItems="center" >
                      <MDTypography variant="h6" color="dark" sx={{ ml: 2, mr: 2 }}>
                        Reported By:
                      </MDTypography>
                      {currentIssue && createdByUser && currentIssue.createAgent === createdByUser._id && <PictureAvatar
                        // image={(currentIssue?.createAgent === currentLoggedUser?._id && currentLoggedUser?.profileImg)
                        //   ? currentLoggedUser.profileImg : null}
                        image={createdByUser?.profileImg}
                        firstName={createdByUser?.firstName}
                        lastName={createdByUser?.lastName}
                        userId={createdByUser?._id}
                        size="md"
                      />}
                      <Link href={`mailto:${createdByUser?.emailAddress}`}>
                        <MDTypography variant="h6" color="dark" mx={2}>
                          {`${currentIssue?.userFirstName} ${currentIssue?.userLastName} <${createdByUser?.emailAddress}>`}
                        </MDTypography>
                      </Link>
                      <EmailIcon
                        fontSize="large"
                        onClick={async () => {
                          // get user rec from createAgent id
                          if (mode === "edit" && currentIssue?.createAgent) {
                            const user = await fetchUserById(currentIssue.createAgent)

                            // set it to modalInfo data and open the dialog
                            if (user) {
                              setModalInfo({ data: user, type: "usermail" });
                              setGenericModalOpen(true);
                            }
                          }
                        }}
                      />
                    </Grid>
                  </>
                  }
                </Grid>
              </MDBox>
            }
          </MDBox>
          <MDBox display="flex" alignItems="center">
            {mode === "edit" && !isModified && userType === "Master" &&
              <Button
                variant="text"
                endIcon={<DeleteIcon />}
                onClick={onDelete}
                style={{ color: "red" }}
                disabled={isSubmitting}
                sx={{ padding: "0.5rem 1rem" }}
              >
                Delete Issue
              </Button>
            }

            {(isModified && mode === "edit") && (
              <>
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </>
            )}

            {mode === "create" && (
              <>
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={cancelCreate}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isLoading}
                >
                  Create
                </Button>
              </>

            )}
          </MDBox>
        </MDBox>

        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <MDBox>
                      <FormField
                        {...issueTitle}
                        key={`title_${currentIssue?._id}`}
                        type="text"
                        label="Issue Title"
                      />
                      {errors?.issueTitle && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.issueTitle.message}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox my={3}>
                      <Controller
                        {...category}
                        control={control}
                        defaultValue={currentIssue?.category}
                        render={({ field }) => (
                          <Autocomplete
                            key={`autocomplete_category_${currentIssue?._id}`}
                            options={issuesCategories}
                            autoSelect
                            freeSolo
                            name="Section/Category"
                            defaultValue={null}
                            value={field.value}
                            onChange={(e, v) => {
                              field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                            }}
                            onBlur={() => trigger("category")}
                            renderInput={(params) => (
                              <FormField
                                key={`category_${currentIssue?._id}`}
                                {...params}
                                type="text"
                                label="Section/Category"
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.category && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.category.message}
                        </MDTypography>
                      )}
                    </MDBox>

                    <MDBox mt={3}>
                      <MDTypography component="label" variant="button" >
                        Issue Snapshot
                      </MDTypography>

                      <FileDropzone
                        disabled={mode !== "edit"}
                        message={mode !== "edit" ? "You can add Snapshots after you create the Issue" : "Drop files here to upload"}
                        className={classes.dropzone}
                        onDrop={onDropHandler}
                        avatar={avatar}
                      />

                    </MDBox>
                  </Grid>
                  <Grid item sm={6}>
                    <MDBox>
                      <FormField
                        disabled
                        value={currentIssue?.modifiedDate ? moment(currentIssue?.modifiedDate).format("MM-DD-YYYY hh:mm A") : ""}
                        key={`modifiedDate_${currentIssue?._id}`}
                        type="text"
                        label="Last Updated"
                        InputLabelProps={{ shrink: getValues().modifiedDate || currentIssue?.modifiedDate }}
                      />
                    </MDBox>
                    <MDBox my={3}>
                      <Controller
                        {...type}
                        control={control}
                        defaultValue={currentIssue?.type}
                        render={({ field }) => (
                          <Autocomplete
                            key={`autocomplete_type_${currentIssue?._id}`}
                            autoSelect
                            freeSolo
                            options={issuesTypes}
                            name="Type"
                            defaultValue={null}
                            value={field.value}
                            onChange={(e, v) => {
                              field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                            }}
                            onBlur={() => trigger("type")}
                            renderInput={(params) => (
                              <FormField
                                key={`type_${currentIssue?._id}`}
                                {...params}
                                type="text"
                                label="Type"
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.type && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.type.message}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox my={3}>
                      <Controller
                        {...status}
                        control={control}
                        defaultValue={currentIssue?.status}
                        render={({ field }) => (
                          <Autocomplete
                            key={`autocomplete_status_${currentIssue?._id}`}
                            options={issuesStatuses}
                            autoSelect
                            freeSolo
                            name="Status"
                            defaultValue={null}
                            value={field.value}
                            onChange={(e, v) => {
                              field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                            }}
                            onBlur={() => trigger("status")}
                            renderInput={(params) => (
                              <FormField
                                key={`status_${currentIssue?._id}`}
                                {...params}
                                type="text"
                                label="Status"
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.status && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.status.message}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox>
                      <FormField
                        disabled
                        value={currentIssue?.issueNumber}
                        key={`issueNumber_${currentIssue?._id}`}
                        type="text"
                        label="Issue Number"
                        InputLabelProps={{ shrink: getValues().modifiedDate || currentIssue?.modifiedDate }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={6}>
                <MDBox >
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography component="label" variant="button" fontWeight="regular">
                      Issue Description
                    </MDTypography>
                  </Stack>
                  <MDEditor
                    className={classes.editor}
                    {...issueDescription}
                    value={editorValue || ""}
                    onChange={(e) => {
                      setEditorValue(e);
                      setValue("issueDescription", e);
                    }}
                  />
                  {errors?.issueDescription && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.issueDescription.message}
                    </MDTypography>
                  )}
                </MDBox>

              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Controller
              {...comments}
              control={control}
              render={({ field }) => (
                <CommentSection
                  setValue={setValue}
                  getValues={getValues}
                  saveChanges={saveChanges}
                  comments={field.value}
                  currentIssue={currentIssue}
                  currentLoggedUser={currentLoggedUser}
                  mode={mode}
                />
              )
              }
            />
          </MDBox>
        </MDBox>


      </form>
      <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
      <ConfirmDialog state={deleteConfirmModal} setState={setDeleteConfirmModal} />
      <ConfirmDialog state={createConfirmModal} setState={setCreateConfirmModal} />
      <UserActionModal
        open={genericModalOpen}
        setOpen={setGenericModalOpen}
        modalInfo={modalInfo}
        setToastAlert={setToastAlert}
      />

    </Card >
  );
}
export default IssuesInformation;
