const textColor = "#7c97a9";

const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    overflowY: "scroll",
    height: "100%",
    borderRadius: 5,
    px: 6,
    pt: 20,
    pb: 6,
  },
  longBgImageContainer: {
    width: "100%",
    height: "300px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
  },
  mainContentContainer: {
    borderRadius: 5,
  },
  leftContent: {
    padding: "30px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    borderBottom: `1px solid ${textColor}`,
    height: "100%",
    pb: 1,
  },
  rightContent: {
    padding: "20px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    height: "100%",
  },
  bottomContent: {
    padding: "20px",
    background: "#FFF",
    boxShadow: 24,
    borderRadius: "15px",
    overflow: "hidden",
    height: "100%",
  },
  longBgImage: { width: "100%", height: "100%" },
  descriptionContainer: { overflowY: "auto", "max-height": "20vh", mt: 4 },
};

export default styles;
