import { TableAction } from "@perimeter-software/react-common";
import PropTypes from "prop-types";
// import AvTimerIcon from '@mui/icons-material/AvTimer';
import { useNavigate } from "react-router-dom";

const EventRosterActions = ({ id, setRoster, setFilters, setExportFieldModalOpen, currentEvent, toggleBulkMessageModal }) => {
  const navigate = useNavigate();
  const handleClick = (which) => {
    if (which === "Editor") {
      const nodes = new URL(window.location).pathname.split('/');
      const route = (nodes?.length >= 4 && nodes[2] === 'venue')
        ? `/events/venue/${currentEvent?.venueSlug}/${currentEvent?.eventUrl}/action/eventsinfo`
        : `/events/${currentEvent?.eventUrl}/action/eventsinfo`;
      navigate(route);
      return;
    }
    if (["Signups", "Partners"].includes(which)) {
      setRoster(which);
    }
    if (which === "exportroster") {
      setExportFieldModalOpen(true)
    }
  };


  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={() => handleClick("Editor")}
        tooltip="Go To Event Editor"
      />
      &nbsp;
      <TableAction
        color="error"
        icon="people"
        clickAction={() => handleClick("Signups")}
        tooltip="Show Signup Roster"
      />
      &nbsp;
      <TableAction
        color="secondary"
        icon="handshake"
        clickAction={() => handleClick("Partners")}
        tooltip="Show Partner Roster"
      />
      &nbsp;
      <TableAction
        color="error"
        icon="mail"
        clickAction={() => toggleBulkMessageModal(true)}
        tooltip="Bulk Messaging"
      />
      &nbsp;
      <TableAction
        color="info"
        icon="screen_share"
        clickAction={() => handleClick("exportroster")}
        tooltip="Export Event Roster"
      />
      {/* &nbsp;
      <TableAction
        color="success"
        icon="save"
        clickAction={() => handleClick("eventsave")}
        tooltip="Save Event Roster"
      /> */}
    </span>
  );
};

export default EventRosterActions;

EventRosterActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
EventRosterActions.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
