import { Grid, Link } from '@mui/material'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React from 'react'

const Congratulations = () => {
    return (
        <><Grid container p={3}>
            <Grid item sx={12}>
                <MDTypography variant="h3">
                    Congratulations!
                </MDTypography>
            </Grid>
            <Grid item sx={12} mt={3}>
                <MDTypography variant="body2">
                    You have completed the onboarding forms and will receive an email from Stadium People as soon as your background screening is complete, and you can begin
                </MDTypography>
            </Grid>
            <Grid item sx={12} mt={3}>
                <MDTypography variant="body2">
                    In the meantime, we thank you for applying with our organization and look forward to working together.
                </MDTypography>
            </Grid>
            <Grid item sx={12} mt={3}>
                <MDTypography variant="body2">
                    If you have not received your email after 48 hours from completion you may contact your recruiter or send us a message&nbsp;
                    <Link variant="body2" fontWeight="bold" href="/" color="info" >
                        here
                    </Link>{" "}
                </MDTypography>
            </Grid>
        </Grid>
        </>
    )
}

export default Congratulations