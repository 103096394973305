import axios from "axios";

import { API_URL } from "config";

const updateEvent = async ({ id, data }) => {

  //  alert(JSON.stringify(data));
  const res = await axios.put(`${API_URL}/events/id/${id}/roster`, { ...data });
  console.log(res);
  return res.data;
};

export default updateEvent;
