import { styled } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import { forwardRef } from "react";

const StyledInput = styled(FormField)(() => ({
  "& fieldset": {
    // borderColor: "#000",
  },
  "& input": {
    backgroundColor: "#f0f0f0",
  },
}));

const CustomTextField = forwardRef((props, ref) => {
  return <StyledInput {...props} ref={ref} InputLabelProps={{ shrink: true }} />;
});

export default CustomTextField;
