import { Modal } from "@material-ui/core";
import { Card, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import brandWhite from "assets/images/SP-App-Store-Icon.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  logoImage: {
    objectFit: "contain",
  },
});

const ThanksForApplyingModal = ({ applicantCode, open, setOpen }) => {
  const navigate = useNavigate();
  const classes = useStyle();


  const handleOnboarding = useCallback(() => {
    setOpen(false);
    navigate(`/applicants/code/${applicantCode}`);
  }, [applicantCode]);

  const handleNoOnboarding = () => {
    setOpen(false);
    navigate(`/`)
  }

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open}>
          <Card className={classes.container}>
            <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
              <Box display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="white"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  mt={-2}
                >
                  <img
                    src={brandWhite}
                    width="50"
                    height="50"
                    className={classes.logoImage}
                    alt="Venue Logo"
                  />
                </MDBox>
                <MDBox p={3}>
                  <MDTypography variant="h5" color="dark">
                    Application Successful
                  </MDTypography>
                </MDBox>
              </Box>
            </MDBox>
            <Box display="flex">
              <MDBox p={3}>
                <MDTypography variant="h5" color="dark">
                  Thank you for applying with Stadium People. One of our Recruiters will be in
                  contact with you very soon. In the meantime, you may begin completing out Job
                  Application at any time to expedite the hiring process.
                </MDTypography>
              </MDBox>
            </Box>
            <MDBox p={3}>
              <MDButton
                color="info"
                variant="contained"
                onClick={() => {
                  handleOnboarding();
                }}
              >
                <MDTypography variant="body2" color="white">
                  BEGIN ONBOARDING
                </MDTypography>
              </MDButton>
              <MDButton
                color="info"
                variant="text"
                onClick={() => {
                  handleNoOnboarding();
                }}
              >
                <MDTypography variant="body2" color="blue">
                  No thanks, I&apos;ll do it later
                </MDTypography>
              </MDButton>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default ThanksForApplyingModal;
