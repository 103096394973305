import axios from "axios";

import { API_URL } from "config";

const deleteImage = async ({ venueId, data }) => {
  const { url, path, list } = data;
  const res = await axios.delete(`${API_URL}/venues/${venueId}/media`, {
    headers: { 'Authorization': '***' },
    data: { url, path, list }
  });

  return res.data;
};

export default deleteImage;
