import React from 'react'
import { Button, Fade, Modal } from '@mui/material';
import { Card } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';


const useStyle = makeStyles({
    card: {
        padding: "1rem",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: 400,
        backgroundColor: "red",
        textAlign: "center",
        borderRadius: 5,
    },
    text: {
        color: "white"
    },
    button: {
        color: "red",
        backgroundColor: "#FFF"
    },
    button2: {
        color: "white",

    },

})


const WaitlistModal = ({ openWaitlistModal, setOpenWaitlistModal }) => {

    const classes = useStyle();
    const handleClose = () => setOpenWaitlistModal(false);

    return (
        <div>
            <Modal
                disableAutoFocus
                open={openWaitlistModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openWaitlistModal}>
                    <Card className={classes.card} >
                        <MDBox>
                            <MDTypography variant="h5" className={classes.text}>
                                This event is full.
                            </MDTypography>
                            &nbsp;
                            <MDTypography variant="h5" className={classes.text}>
                                Would you like to be added to the wait list in case someone cancels their shift?
                            </MDTypography>
                        </MDBox>
                        <MDBox my={4}>
                            <Button variant="contained" className={classes.button} onClick={handleClose}>
                                Yes! Add to Waitlist
                            </Button>
                        </MDBox>
                        <MDBox>
                            <Button variant="text" className={classes.button2} onClick={handleClose}>
                                no, cancel
                            </Button>
                        </MDBox>
                    </Card>
                </Fade>
            </Modal>
        </div >
    )
}

export default WaitlistModal