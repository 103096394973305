import axios from "axios";
import { API_URL } from "config";

import createApiParams from "utils/createApiParams";

const imageUrlBase = `${process.env.REACT_APP_IMAGE_SERVER || "http://images.stadiumpeople.com/sp"
  }`;

const fetchVenues = async ({ page, limit, order, orderBy, fetchAll, filters, setVenues }) => {
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {

    const res = await axios.get(`${API_URL}/venues${paginationQuery}${sortQuery}${filterQuery}`);
    // we need to preprocess the data to format the image URLs and other fields
    if (res?.data?.data?.length) {
      const retData = res.data.data.map((venue) => {
        const logoUrl = venue.logoUrl ? `${imageUrlBase}/${venue.slug}/venues/logo/${venue.logoUrl}` : `${imageUrlBase}/stadiumpeople/SP-App-Store-Icon.png`;
        const logoUrls = (venue.logoUrls && Array.isArray(venue.logoUrls)) ? venue.logoUrls.map(logo => `${imageUrlBase}/${venue.slug}/venues/logo/${logo}`) : [];
        const bannerUrl = venue.bannerUrl ? `${imageUrlBase}/${venue.slug}/venues/banner/${venue.bannerUrl}` : `${imageUrlBase}/stadiumpeople/banner.png`;
        const bannerUrls = (venue.imageUrls && Array.isArray(venue.imageUrls)) ? venue.imageUrls.map(banner => `${imageUrlBase}/${venue.slug}/venues/banner/${banner}`) : [];
        const jobs = (venue.jobs && Array.isArray(venue.jobs)) ? venue.jobs : [];

        return { ...venue, jobs, logoUrl, logoUrls, bannerUrl, imageUrls: bannerUrls };
      });
      res.data = { ...res.data, data: [...retData] };

      const venuesForContext = {};
      // res.data?.data.forEach(({ name, slug, state, address, zip, city, logoUrl }) => {
      //   venuesForContext[slug] = {
      //     name,
      //     slug,
      //     state,
      //     address,
      //     zip,
      //     city,
      //     logoUrl,
      //   };
      // });
      res.data?.data.forEach((record) => {
        venuesForContext[record.slug] = { ...record };
      });
      setVenues?.(venuesForContext);

    }

    return res.data;
  }
  catch (err) {
    if (err.message === "Request failed with status code 404")
      return [];
    throw new Error(err.toString());
  }


};

export default fetchVenues;
