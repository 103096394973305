import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Icon, IconButton, TextField, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import FileDropzone from "components/Dropzone";
import useStyles from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments/styles";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL, IMAGE_SERVER } from "config";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AddIcon from "@mui/icons-material/Add";

const Attachments = ({ currentApplicant, setCurrentApplicant, setOpen, attachemntTitle }) => {
  const styles = useStyles();
  const [files, setFiles] = useState([]);
  const [deletePolicyErr, setDeletePolicyErr] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const queryClient = useQueryClient();
  const uploadUrl = `${API_URL}/upload/${currentApplicant?.slug}/venues/banner`;

  const saveUpdate = async (values) => {
    // if (!currentApplicant._id) {
    //   setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
    //   return;
    // }
    // await updateVenueMutation.mutateAsync({
    //   venueId: currentApplicant._id,
    //   data: values,
    // });
  };

  const renameFileMutation = useMutation(
    async ({ rename, original }) => {
      const url = new URL(`${API_URL}/upload/rename/${currentApplicant?.slug}/venues/other`);
      url.searchParams.append("rename", rename);
      url.searchParams.append("original", original);
      await axios.patch(url.toString());
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: () => {
        setToastAlert({ isShow: true, message: "Successfully renamed", status: "success" });
      },
    }
  );

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");
      setCurrentApplicant({ ...currentApplicant, ...data });
      setToastAlert({ isShow: true, message: "Applicant has been updated!", status: "success" });
    },
  });


  const addNew = (e) => {
    setOpen(true)
  };


  const deleteFileHandler = async (e, idx) => {
    const policies = [...files];
    let delFile;

    if (idx !== -1) {
      [delFile] = policies.splice(idx, 1);
    }

    // // delete from DB
    // await deletePolicyMutation.mutateAsync({
    //   venueId: currentApplicant._id,
    //   data: {
    //     url: delFile.name,
    //     path: `${currentApplicant?.slug}/venues/other/${delFile.name}`,
    //     list: "attachments",
    //   },
    // });

    if (!deletePolicyErr) {
      setFiles(policies);
    }
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];

    // get the image extension
    const imgExt = newFiles[idx].name.substring(newFiles[idx].name.lastIndexOf("."));

    // check if edited
    const removeExt = newFiles[idx].name.substring(0, newFiles[idx].name.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;

    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].name;

    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const confirmFileNameChanges = async (e, idx) => {
    await renameFileMutation.mutateAsync({
      original: files[idx].name,
      rename: files[idx].editedName,
    });

    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], name: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);

    await updateApplicant.mutateAsync({
      applicantId: currentApplicant._id,
      data: { attachments: newFiles.map((img) => img.name) },
    });
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );

  useEffect(() => {
    if (!files?.length && currentApplicant?.attachments?.length) {
      setFiles(
        currentApplicant?.attachments.map((file) => {
          const { docType, filename: name, title, type, uploadDate } = file;
          return ({ docType, name, title, type })
        })
      );
    }
  }, [currentApplicant?.attachments, files]);


  return (
    <MDBox className={styles.otherSection}>
      <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
        <MDTypography variant="h6" color="dark" className={styles.titleBar_title}>
          {attachemntTitle}
        </MDTypography>
      </MDBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={1.5}>
          <MDBox mb={2}>
            <IconButton className={styles.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
        <Grid item sm={10.5}>
          <MDBox className={styles.section_images_wapper}>
            {files.map((file, idx) => {

              return (
                <MDBox className={styles.section_image_container} key={file.name} >
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid>
                      <MDBox style={{
                        position: "absolute", right: "-15px", top: "-15px", backgroundColor: "white",
                        padding: 1, borderRadius: "25px"
                      }}>
                        <IconButton
                          color="info"
                          // className={styles.maps_section_image_delete}
                          onClick={(e) => deleteFileHandler(e, idx)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                      </MDBox>
                    </Grid>

                    <Grid>
                      <MDBox
                        onClick={() => window.open(`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.name}`)}
                      >
                        {

                          ['jpeg', 'jpg', 'png', 'gif'].includes(file.docType.toLowerCase()) && < img
                            src={`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.name}`}
                            alt="previews"
                            // className={styles.section_image}
                            height={100}

                          />
                        }
                        {
                          file.docType.toLowerCase() === "pdf" && < img
                            src={`${process.env.PUBLIC_URL}/pdf-icon.png`}
                            alt="preview"
                            width={100}
                            height={100}
                          />
                        }
                        {
                          file.docType.toLowerCase() === "docx" && < img
                            src={`${process.env.PUBLIC_URL}/word-icon.png`}
                            alt="preview"
                            width={100}
                            height={100}
                          />
                        }
                      </MDBox>
                    </Grid>

                    <Grid>
                      <TextField
                        defaultValue={file.name.substring(0, file.name.lastIndexOf("."))}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        value={files[idx].editedName?.substring(
                          0,
                          file.editedName.lastIndexOf(".")
                        )}
                        onInput={(e) => editFileNameHandler(e, idx)}
                      />

                      {file.isEdited && (
                        <>
                          <Tooltip title="Confirm" placement="bottom">
                            <CheckIcon
                              color="success"
                              onClick={(e) => confirmFileNameChanges(e, idx)}
                              fontSize="large"
                              sx={{
                                "&": { transition: "0.1s" },
                                "&:hover": { background: "#D3D3D3" },
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom">
                            <CloseIcon
                              color="error"
                              onClick={(e) => cancelFileNameChanges(e, idx)}
                              fontSize="large"
                              sx={{
                                "&": { transition: "0.1s" },
                                "&:hover": { background: "#D3D3D3" },
                              }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              )
            })}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox >
  )
};

export default Attachments;
