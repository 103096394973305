import axios from "axios";

import { API_URL } from "config";

const postEmailTemplates = async (body) => {
  try {
    const res = await axios.post(`${API_URL}/control/emailTemplates`, body);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default postEmailTemplates;
