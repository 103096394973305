import DataList from "components/DataList";
import { useEffect, useState } from "react";
import AddAComment from "../AddAComment";
import IndividualComment from "../IndividualComment";

const CommentSection = ({ setValue, getValues, saveChanges, comments, currentLoggedUser, mode }) => {
    const [reverseComments, setReverseComments] = useState(null)
    const renderRow = (row, idx) => <IndividualComment setValue={setValue} comments={comments} row={row} idx={idx} />

    useEffect(() => {
        if (comments) {
            setReverseComments([...comments]?.reverse());
        }
    }, [comments])

    return (
        <>
            <AddAComment
                setValue={setValue}
                getValues={getValues}
                saveChanges={saveChanges}
                comments={comments}
                currentLoggedUser={currentLoggedUser}
                mode={mode} />
            {reverseComments ?
                <DataList
                    renderRow={renderRow}
                    data={reverseComments}
                    onRowClick={() => { }}
                    title="Comments"
                    greybar
                    divider
                    tableHeight={420}
                    height={500}
                    scrollY
                />
                :
                <div>No Comments</div>
            }
        </>
    )

}

export default CommentSection;