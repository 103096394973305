import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import EmailIcon from "@mui/icons-material/Email";
import { Card, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import ConfirmationModal from "components/ConfirmationModal";
import DataTable from "components/DataTable";
import EmailTemplateModal from "components/EmailTemplateModal";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import deleteEmailTemplate from "context/actions/deleteEmailTemplate";
import { useAppContextController } from "context/AppContext";
import fetchMessageTemplates from "layouts/pages/setup/actions/fetchMessageTemplates";
import MessageTemplatesTableActions from "layouts/pages/setup/components/MessageTemplatesTableActions";
import { useSnackbar } from "notistack";
import useSort from "utils/useSort";
// import IssuesActionModal from "../IssuesActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const MessageTemplatesTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  showEventStatus = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { currentLoggedUser } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [isEmailTemplateOpen, toggleEmailTemplateModal] = useState(false);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentTemplate });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const { data: templates, isLoading } = useQuery(["messagetemplates", allOptions], () =>
    fetchMessageTemplates(allOptions)
  );

  const columns = [
    {
      title: "Template Name",
      field: "name",
      customCell: (subject) => (
        <MDTypography variant="body">
          {subject?.length > 35 ? `${subject.substring(0, 35)}...` : subject}
        </MDTypography>
      ),
    },
    {
      title: "Subject",
      field: "subject",
      customCell: (desc) => {
        const text = desc?.length > 40 ? `${desc.substring(0, 40)}...` : desc;
        return <MDTypography variant="body">{text ? parse(text) : ""}</MDTypography>;
      },
    },
    {
      title: "Last Updated",
      field: "dateModified",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Message Template Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <MessageTemplatesTableActions
          id={id}
          row={row}
          setCurrentTemplate={setCurrentTemplate}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          toggleEditModal={toggleEmailTemplateModal}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "Name", field: "name" },
    { title: "Subject", field: "subject" },
    { title: "Modified", field: "dateModified" },
  ];

  const handleRowClick = (row, e) => {
    e.stopPropagation();
    setCurrentTemplate(row);
    toggleEmailTemplateModal(true);
  };

  const handleDelete = useCallback(async () => {
    if (currentTemplate) {
      try {
        const res = await deleteEmailTemplate(currentTemplate);
        console.log(res);
        if (res.success) {
          await queryClient.invalidateQueries("messagetemplates");
          enqueueSnackbar(res.message || "Email template deleted.", { variant: "success" });
        } else {
          enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
        }
        toggleConfirmationModal(false);
      } catch (err) {
        toggleConfirmationModal(false);
        enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
      }
    }
  }, [currentTemplate]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <EmailIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Message Templates
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={6}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {showSearchBar && (
            <Searchbar
              fetch={fetchMessageTemplates}
              placeholder="Search Templates"
              columns={columnsForSearch}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name", "subject"]}
              //                filterBy="userId"
              filterByColumn="_id"
            />
          )}
          <MDBox>
            <IconButton
              className={classes.addButton}
              color="info"
              onClick={() => toggleEmailTemplateModal(true)}
            >
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={templates}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={false}
          defaultSort="issueDate:desc"
        />
      </MDBox>
      <EmailTemplateModal
        isOpen={isEmailTemplateOpen}
        template={currentTemplate}
        onClose={() => {
          setCurrentTemplate(null);
          toggleEmailTemplateModal(false);
        }}
      />
      <ConfirmationModal
        title={`Are you sure you want to delete ${
          currentTemplate?.name ? `'${currentTemplate?.name}'` : "this Template"
        }?`}
        isOpen={isConfirmationOpen}
        onClose={() => {
          toggleConfirmationModal(false);
          setCurrentTemplate(null);
        }}
        onConfirm={() => handleDelete()}
      />
    </Card>
  );
};

// Setting default values for the props
MessageTemplatesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Issues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
MessageTemplatesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default MessageTemplatesTable;
