import { useState } from "react";
import { Grid, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import VenueIcon from "components/VenueIcon";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";
import ApplyButton from "../ApplyButton";

const useStyle = makeStyles({
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  button: {
    minWidth: 0,
    minHeight: 0,
    padding: 0,
  },
  itemHeight: {
    height: "90px",
  },
});

const JobResultsRow = ({ row, venues }) => {
  const classes = useStyle();
  const [showMore, setShowMore] = useState(false);
  const expandText = () => setShowMore(!showMore);

  let shortDescription = "";
  if (row?.description) {
    shortDescription = row.description;
  }

  return (
    <>
      <Grid
        container
        display="flex"
        className={
          (shortDescription?.length < 250 || shortDescription?.length === undefined) &&
          classes.itemHeight
        }
      >
        <Grid item xs={12} sm={1}>
          <VenueIcon
            logoUrl={venues?.[row?.venueSlug]?.logoUrl}
            slug={row?.venueSlug}
            name={venues?.[row?.venueSlug]?.name}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Button className={classes.button}>{row?.title}</Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDTypography variant="body2">
                <Box fontWeight="fontWeightMedium" display="inline">
                  {venues?.[row?.venueSlug]?.name}
                </Box>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body2">{venues?.[row?.venueSlug]?.city}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={1}>
              <MDTypography variant="body2">{venues?.[row?.venueSlug]?.state}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2" pr={1}>
                {!showMore && shortDescription?.length
                  ? parse(shortDescription?.substring(0, 250))
                  : parse(shortDescription)}
                {shortDescription?.length > 250 && (
                  <Button onClick={expandText}>{showMore ? "Show Less" : "Show More..."}</Button>
                )}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} pt={4}>
          <ApplyButton row={row} />
        </Grid>
      </Grid>
    </>
  );
};

export default JobResultsRow;
