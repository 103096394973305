import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import UsersTable from "layouts/pages/users/components/UsersTable";
import UsersInformation from "layouts/pages/users/components/UsersInfo";

function Users({ mode = "edit" }) {

  const navigate = useNavigate();

  const { currentUser, setCurrentUser, venues } = useAppContextController();
  const { id, action } = useParams();
  const [actionSelected, setActionSelected] = useState(action || "");

  const [filters, setFilters] = useState({});

  useEffect(async () => {
    if (id && (actionSelected || action)) {
      setFilters({ _id: id })
    }
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
    }

    if (id && (!currentUser || currentUser._id !== id)) {
      setFilters({ _id: id })
      const users = await fetchUserById(id);
      if (users && users?.data?.length) setCurrentUser(users?.data[0]);
    }
  }, [id, action]);

  const setNavigateUrl = url => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <UsersTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Users"
          />
        </Grid>
        {["userinfo", "userall", ""].includes(actionSelected) && (id || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <UsersInformation mode={mode} />
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Users.defaultProps = {
  mode: "edit",
};

// Typechecking props
Users.propTypes = {
  mode: PropTypes.string,
};
export default Users;
