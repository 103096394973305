import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import axios from "axios";
import colors from "assets/theme/base/colors";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import states from "assets/stateCodes.json";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConfirmDialog from "components/ConfirmDialog";
import FormField from "layouts/applications/wizard/components/FormField";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDropzone from "components/Dropzone";
import MDAvatar from "components/MDAvatar";
// NewProduct page components
import { makeStyles } from "@mui/styles";
import { Alert, Icon, Stack, Snackbar, Button, Autocomplete, Avatar } from "@mui/material";
import LandscapeIcon from "@mui/icons-material/Landscape";
import { API_URL, IMAGE_SERVER } from "config";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import createUser from "layouts/pages/users/actions/createUser";
import updateUser from "layouts/pages/users/actions/updateUser";
import userTypes from "assets/userTypes.json";

const { black } = colors;
const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    overflow: "visible",
  },
  status: {
    fontWeight: 700,
    color: "#1A73E8",

    width: "100%",
  },
  label: {
    display: "grid",
    lineHeight: 1.5,
    fontSize: "0.75rem",
  },
  logoImage: {
    objectFit: "contain",
  },
  previewButton: {
    padding: 0,
  },
  dropzone: {
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
  },
  blockedDropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  text: {
    fontSize: "0.75rem!important",
  },
  error: {
    fontSize: "0.75rem",
  },
});

function UsersInformation({ mode = "edit", setNavigateUrl }) {
  const classes = useStyle();
  const navigate = useNavigate();

  const { currentUser, setCurrentUser, currentLoggedUser } = useAppContextController();
  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });


  const {
    register,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const defaultValues = {
    firstName: "",
    lastName: "",
    userType: null,
    emailAddress: "",
    primaryPhone: "",
    secondaryPhone: "",
    address1: "",
    address2: "",
    city: "",
    state: null,
    zip: "",
    userId: "",
  };

  const firstName = { ...register("firstName", { required: "First name is required" }) };
  const lastName = { ...register("lastName", { required: "Last name is required" }) };
  const userType = {
    ...register("userType", {
      required: "User type is required",
      validate: { exist: (value) => userTypes.includes(value) || "Insert a valid user type" },
    }),
  };
  const emailAddress = {
    ...register("emailAddress", {
      required: "Email is required",
      validate: {
        // eslint-disable-next-line
        pattern: (value) =>
          (value && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) || "Invalid Email Address",
        repeated: async (value) => {
          const filteredUsers = await fetchUsers({
            filters: { emailAddress: value },
            fetchAll: true,
          });
          return (
            filteredUsers === "" ||
            filteredUsers?.data[0]?._id === currentUser?._id ||
            "that email is already in use "
          );
        },
      },
    }),
  };
  const primaryPhone = {
    ...register("primaryPhone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };

  const secondaryPhone = {
    ...register("secondaryPhone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };


  const address1 = register("address1");
  const address2 = register("address2");
  const city = register("city");
  const state = {
    ...register("state", {
      validate: {
        exist: (value) => {
          if (value === null || value === undefined || value === "") {
            return true
          }
          return states.includes(value) || "Insert a valid state"
        }
      },
    }),
  };
  const zip = {
    ...register("zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Your zip number is invalid" },
    }),
  };
  const queryClient = useQueryClient();

  const uploadImgMutation = useMutation(
    async (imgForm) => {
      await axios.post(`${API_URL}/upload/users/${currentUser._id}/${imgForm.type}`, imgForm.form);
      await updateUser({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, data) => {
        setCurrentUser({ ...currentUser, ...data.data });
        setToastAlert({ isShow: true, message: "Picture been updated!", status: "success" });
      },
    }
  );

  const { mutate, mutateAsync, isLoading } = useMutation(createUser, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      navigate(`/users/${data.userId}`);
      setToastAlert({ isShow: true, message: "User has been created!", status: "success" });
    },
  });

  const updateUserMutation = useMutation(updateUser, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      setToastAlert({ isShow: true, message: "User has been updated!", status: "success" });
    },
  });

  const createUserHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser._id;
    console.log('insert user', JSON.stringify(data));
    await mutateAsync(data);
    setIsModified(false);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getUserValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentUser?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    reset(getUserValues());
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes has been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentUser?.userId) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }

    // eslint-disable-next-line
    if (values.userId !== values.emailAddress) values.userId = values.emailAddress;

    await updateUserMutation.mutateAsync({
      userId: currentUser.userId,
      data: values,
    });
  };
  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  const onDropImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { ...currentUser, profileImg: file.name },
      type: "photo",
    });
  };

  const onDropBannerHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { ...currentUser, bannerImg: file.name },
      type: "banner",
    });
  };

  function stringAvatar(userName) {
    if (userName?.firstName) {
      return {
        children: `${userName?.firstName[0]}${userName?.lastName[0]}`,
      };
    }
    return "";
  }

  const avatar = currentUser?.profileImg ? (
    <MDAvatar
      src={`${IMAGE_SERVER}/users/${currentUser?._id}/photo/${currentUser?.profileImg}`}
      alt="profile-image"
      size="xl"
      shadow="sm"
    />
  ) : (
    <Avatar sx={{ bgcolor: black.light, width: 75, height: 75 }} {...stringAvatar(currentUser)} />
  );
  const bannerAvatar = currentUser?.bannerImg ? (
    <Card>
      <img
        src={`${IMAGE_SERVER}/users/${currentUser?._id}/banner/${currentUser?.bannerImg}`}
        alt="Not Found"
        style={{ width: "100%" }}
      />
    </Card>
  ) : (
    <LandscapeIcon sx={{ width: "5em", height: "5em" }} />
  );

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};

      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentUser(data);
      reset(defaultValues);
    }
  }, [watch, mode]);

  useEffect(() => {
    if (mode === "edit") {
      if (currentUser) {
        clearErrors();

        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentUser[key] || "");
        });
        setIsModified(false);
      }

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
    }
    return "";
  }, [getValues, currentUser, confirmModal.response]);

  useEffect(() => {

    const subscription = watch((value) => {
      if (currentUser) {
        setIsModified(JSON.stringify(getUserValues()) !== JSON.stringify(value));
      }
    });
    return () => subscription.unsubscribe();

  }, [watch, currentUser]);

  return (
    <Card className={classes.box} key={`card_${currentUser?._id}`}>
      <form
        onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createUserHandler)}
      >
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            // severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>

        <MDBox display="flex" justifyContent="space-between" px={3}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="large">account_circle</Icon>
            </MDBox>
            <MDBox>
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, ml: 2, mr: -6 }}>
                {mode === "edit" ? (
                  `${currentUser?.firstName} ${currentUser?.lastName}`
                ) : (
                  <>Create User</>
                )}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Stack
            className={classes.stack}
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {mode === "edit" && isModified && (
              <MDBox display="flex" alignItems="center">
                <Button
                  variant="text"
                  startIcon={<CancelIcon />}
                  onClick={onCancel}
                  disabled={isSubmitting}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isSubmitting}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Save
                </Button>
              </MDBox>
            )}
            {mode === "create" && (
              <MDBox display="flex" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  style={{ color: "white" }}
                  disabled={isLoading}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Create
                </Button>
              </MDBox>
            )}
          </Stack>
        </MDBox>

        <MDBox px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <MDBox display="flex" mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      key={`firstName_${currentUser?._id}`}
                      label="First Name"
                      type="text"
                      {...firstName}
                    />
                    {errors?.firstName && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.firstName.message}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      key={`lastName_${currentUser?._id}`}
                      type="text"
                      label="Last Name"
                      {...lastName}
                    />
                    {errors?.lastName && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.lastName.message}
                      </MDTypography>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      {...userType}
                      control={control}
                      defaultValue={currentUser.userType}
                      render={({ field }) => (
                        <Autocomplete
                          autoSelect
                          freeSolo
                          options={userTypes}
                          defaultValue={null}
                          value={field.value}
                          onChange={(e, v) => {
                            const value = v ? v.charAt(0).toUpperCase() + v.slice(1) : "";
                            field.onChange(value);
                          }}
                          onBlur={() => trigger("userType")}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="User Type"
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.userType && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.userType.message}
                      </MDTypography>
                    )}

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      key={`email_${currentUser?._id}`}
                      type="text"
                      label="Email Address"
                      placeholder="Contact Email Address"
                      {...emailAddress}
                    />
                    {errors?.emailAddress && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.emailAddress.message}
                      </MDTypography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <MDBox mt={1}>
                        <FormField
                          {...primaryPhone}
                          // key={`firstScreen_primaryPhone_`}
                          type="text"
                          label="Primary Phone"
                          InputLabelProps={{ shrink: getValues().primaryPhone }}
                        />
                        {errors?.primaryPhone && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.primaryPhone.message}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <MDBox mt={1}>
                        <FormField
                          {...secondaryPhone}
                          // key={`firstScreen_secondaryPhone_`}
                          type="text"
                          label="Secondary Phone"
                          InputLabelProps={{ shrink: getValues().secondaryPhone }}
                        />
                        {errors?.secondaryPhone && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.secondaryPhone.message}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <MDBox mt={1}>
                        <FormField
                          {...address1}
                          // key={`firstScreen_address1_`}
                          type="text"
                          label="Address 1"
                          InputLabelProps={{ shrink: getValues().address1 }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <MDBox mt={1}>
                        <FormField
                          {...address2}
                          // key={`firstScreen_address2_`}
                          type="text"
                          label="Address 2"
                          InputLabelProps={{ shrink: getValues().address2 }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        {...city}
                        // key={`firstScreen_city_`}
                        type="text"
                        label="City"
                        InputLabelProps={{ shrink: getValues().city }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDBox mb={3}>
                        <Controller
                          {...state}
                          control={control}
                          defaultValue={currentUser?.state}
                          render={({ field }) => (
                            <Autocomplete
                              key={`state_${currentUser?._id}`}
                              options={states}
                              autoSelect
                              freeSolo
                              name="state"
                              defaultValue={null}
                              value={field.value}
                              onChange={(e, v) => {
                                const value = v ? v.toUpperCase() : '';
                                field.onChange(value);
                              }}
                              onBlur={() => trigger("state")}
                              renderInput={(params) => (
                                <FormField
                                  key={`state_${currentUser?._id}`}
                                  {...params}
                                  type="text"
                                  label="State"
                                />
                              )}
                            />
                          )}
                        />
                        {errors?.state && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.state.message}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        {...zip}
                        // key={`firstScreen_zip_`}
                        type="text"
                        label="Zip Code"
                        InputLabelProps={{ shrink: getValues().zip }}
                      />
                      {errors?.zip && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.zip.message}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                Upload or change User Photo
              </MDTypography>
              <FileDropzone
                className={classes.dropzone}
                onDrop={onDropImageHandler}
                user={currentUser}
                avatar={avatar}
              />
              <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                Upload or change banner
              </MDTypography>
              <FileDropzone
                className={classes.dropzone}
                onDrop={onDropBannerHandler}
                avatar={bannerAvatar}
              />
            </Grid>
          </Grid>
        </MDBox>
      </form>
      <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
    </Card>
  );
}
export default UsersInformation;
