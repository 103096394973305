import { createContext, useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useQuery } from "react-query";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import axios from "axios";
import fetchEmailTemplates from "context/actions/fetchEmailTemplates";
import fetchUsers from 'layouts/pages/profile/actions/fetchUsers';
import { API_URL } from "config";
import { useAuth0 } from "@auth0/auth0-react";
import { LayersTwoTone } from "@mui/icons-material";
import updateUserById from "layouts/pages/users/actions/updateUserById";

// The Soft UI Dashboard PRO Material main context
export const AppContext = createContext();

// Material Dashboard 2 PRO React context provider
function AppContextProvider({ children }) {

  const { isLoading, email, user } = useAuth0();

  const initialState = {
    currentVenue: null,
    currentJob: null,
    currentApplicant: null,
    currentEvent: null,
    currentCampaign: null,
    currentPartner: null,
    currentActivity: null,
    currentIssue: null,
    currentLoggedUser: null,
    currentUser: null,
    // TODO:  set this to 'U' after login is working
    backendEnv: "P",
    userType: "Master",
    venues: null,
    unreadNotifications: [],
    emailTemplates: [],
  };

  const [state, setState] = useState(initialState);
  const {
    currentVenue,
    currentJob,
    currentApplicant,
    currentEvent,
    currentCampaign,
    currentPartner,
    currentLoggedUser,
    currentActivity,
    currentIssue,
    currentUser,
    backendEnv,
    userType,
    venues,
    unreadNotifications,
    emailTemplates,
  } = state;

  const setStateVar = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const setVenues = (value) => setStateVar("venues", venues ? { ...venues, ...value } : value);
  const setUnreadNotifications = (value) => setStateVar("unreadNotifications", [...value]);

  const setCurrentVenue = (value) => setStateVar("currentVenue", value);
  const setCurrentJob = (value) => setStateVar("currentJob", value);
  const setCurrentApplicant = (value) => setStateVar("currentApplicant", value);

  const setCurrentEvent = (value) => setStateVar("currentEvent", value);
  const setCurrentCampaign = (value) => setStateVar("currentEvent", value);
  const setCurrentPartner = (value) => setStateVar("currentEvent", value);

  const setCurrentUser = (value) => setStateVar("currentUser", value);
  const setCurrentLoggedUser = (value) => setStateVar("currentLoggedUser", value);
  const setCurrentActivity = (value) => setStateVar("currentActivity", value);
  const setCurrentIssue = (value) => setStateVar("currentIssue", value);

  const setBackendEnv = (value) => setStateVar("backendEnv", value);
  const setUserType = (value) => setStateVar("userType", value);
  const setEmailTemplates = (value) => setStateVar("emailTemplates", value);

  const options = { fetchAll: true, setVenues };
  useQuery(["venues", options], () => fetchVenues(options));

  const getEmailTemplates = () => {
    const getTemplates = async () => {
      const res = await fetchEmailTemplates();
      if (res?.data?.data) setEmailTemplates(res.data.data);
    };
    getTemplates();
  };

  const getUserFromEmail = async (em) => {
    const users = await fetchUsers({ fetchAll: true, filters: { emailAddress: em.toLowerCase() } });
    if (users && users.data?.length) return users.data[0];
    return null;
  }

  useEffect(() => {
    const getUser = async (em) => {
      const dbUser = await getUserFromEmail(em);
      // if this user's gmail image hasn't been set yet, let's do it now
      if (user?.picture && user?.email.indexOf('@gmail.com') > -1 && (!dbUser?.profileImg || user.picture !== dbUser?.profileImg)) {
        await updateUserById({ id: dbUser._id, data: { profileImg: user.picture } });
        dbUser.profileImg = user.picture;
      }

      if (dbUser) {
        setCurrentLoggedUser(dbUser);
        setUserType(dbUser.userType);
      }
    }
    if (!isLoading && user?.email) {
      getUser(user.email)
    }
  }, [user, isLoading]);

  // Custom hook for checking login and redirecting to the sign-in page if not logged in
  const isLoggedIn = ({ currUser }) => {
    const [loggedIn, setLoggedIn] = useState(undefined);
    useEffect(() => {
      if (currUser) setLoggedIn(true);
    }, [currUser]);
    return { loggedIn }
  }

  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        venues,
        setVenues,
        currentVenue,
        setCurrentVenue,
        currentJob,
        setCurrentJob,
        currentApplicant,
        setCurrentApplicant,
        currentEvent,
        setCurrentEvent,
        currentCampaign,
        setCurrentCampaign,
        currentPartner,
        setCurrentPartner,
        currentLoggedUser,
        currentActivity,
        setCurrentActivity,
        currentIssue,
        setCurrentIssue,
        setCurrentLoggedUser,
        currentUser,
        setCurrentUser,
        setStateVar,
        setState,
        state,
        backendEnv,
        setBackendEnv,
        userType,
        setUserType,
        unreadNotifications,
        setUnreadNotifications,
        emailTemplates,
        setEmailTemplates,
        getEmailTemplates,
        user,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

// Material Dashboard 2 PRO React custom hook for using context
function useAppContextController() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContextController should be used inside the AppContextProvider.");
  }

  return context;
}

// Typechecking props for the AppContextProvider
AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContextProvider, useAppContextController };
