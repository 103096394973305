/*
Missing these for now:
  Event Report Time (from position on Roster)
  Event Map URL
*/
export const MESSAGE_VARIABLES = [
  {
    name: "Applicant First Name",
    key: "{{ applicantFirstName }}",
    variable: "this.applicant.firstName",
  },
  {
    name: "Applicant Last Name",
    key: "{{ applicantLastName }}",
    variable: "this.applicant.lastName",
  },
  {
    name: "Applicant Email",
    key: "{{ applicantEmail }}",
    variable: "this.applicant.email",
  },
  {
    name: "Applicant Phone",
    key: "{{ applicantPhone }}",
    variable: "this.applicant.phone",
  },
  {
    name: "Event Venue Name",
    key: "{{ eventVenue }}",
    variable: "this.event.venueName",
  },
  {
    name: "Event Name",
    key: "{{ eventName }}",
    variable: "this.event.eventName",
  },
  {
    name: "Event Venue Logo",
    key: "{{ eventVenueLogo }}",
    variable: "this.event.eventImage",
  },
  {
    name: "Event Preview URL",
    key: "{{ eventUrl }}",
    variable: "this.event.eventUrl",
  },
  {
    name: "Event Description",
    key: "{{ eventDescription }}",
    variable: "this.event.description",
  },
  {
    name: "Event Date",
    key: "{{ eventDate }}",
    variable: "this.event.eventDate",
  },
  {
    name: "Event Address",
    key: "{{ eventAddress }}",
    variable: "this.event.address",
  },
  {
    name: "Event City",
    key: "{{ eventCity }}",
    variable: "this.event.venueCity",
  },
  {
    name: "Event State",
    key: "{{ eventState }}",
    variable: "this.event.venueState",
  },
  {
    name: "Event Zip Code",
    key: "{{ eventZipCode }}",
    variable: "this.event.zip",
  },
  {
    name: "First Name",
    key: "{{ firstName }}",
    variable: "this.user.firstName",
  },
  {
    name: "Last Name",
    key: "{{ lastName }}",
    variable: "this.user.lastName",
  },
  {
    name: "Email",
    key: "{{ email }}",
    variable: "this.user.emailAddress",
  },
  {
    name: "Phone",
    key: "{{ phone }}",
    variable: "this.user.primaryPhone",
  },
];
