import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Icon, Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useQuery, useMutation } from "react-query";

import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { SnackbarProvider } from "notistack";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import PictureAvatar from "components/PictureAvatar";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";
import ApplicantsTableActions from "../ApplicantsTableActions";
import fetchApplicants from "../../actions/fetchApplicants";
import ApplicantActionModal from "../ApplicantActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const ApplicantsTable = ({
  fetchAll = false,
  title = "Title",
  filters,
  pageSize = 5,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setSelectedId,
}) => {
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const { currentApplicant, setCurrentApplicant, currentVenue } = useAppContextController();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize);

  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };

  const { data: applicants, isLoading } = useQuery(["applicants", allOptions], () =>
    fetchApplicants(allOptions)
  );

  // useEffect(() => {
  //   if (applicants?.length && !currentApplicant) setCurrentApplicant(applicants[0]);
  // }, [currentApplicant, applicants]);

  // useEffect(() => {
  //   if (action && filters) setPage(1);
  // }, [filters]);

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action]);

  const columns = [
    {
      title: "Avatar",
      field: "profileImg",
      customCell: (image, field, row) => (
        <PictureAvatar
          image={row?.profileImg}
          firstName={row?.firstName}
          lastName={row?.lastName}
          userId={row?._id}
          size="md"
        />
      ),
    },
    { title: "Status", field: "status" },
    ...(venueSlug && currentVenue
      ? [
        {
          title: "Venue Status",
          field: "slug",
          customCell: (_id, field, row) => (
            <ApplicantVenueStatus
              id={row._id}
              venue={venueSlug}
              slugField="venueSlug"
              row={row}
              invalidQuery={["applicants", allOptions]}
            />
          ),
        },
      ]
      : []),
    { title: "Last Name", field: "lastName" },
    { title: "First Name", field: "firstName" },
    {
      title: "Hire Date",
      field: "hireDate",
      customCell: (date) => moment(date).format("YYYY-MM-DD"),
    },
    // { title: "Rep", field: "" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Rank", field: "rank" },
    { title: "Source", field: "systemSource" },
    {
      title: "Date",
      field: "applyDate",
      customCell: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Applicant Actions",
      field: "_id",
      customCell: (rowId, field, row) => (
        <ApplicantsTableActions
          id={rowId}
          row={row}
          setCurrentApplicant={setCurrentApplicant}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      `/applicants/${applicant._id}${navigateToAction ? `/action/${navigateToAction}` : ""}`
    );
  };

  const addNew = (e) => {
    if (!currentApplicant) {
      setCurrentApplicant({});
    }
    setNavigateUrl("/applicants/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {

    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  }

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="error"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">people</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {title}
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchApplicants}
                fetchAll={false}
                placeholder="Search Applicants"
                columns={columns.slice(1, columns.length - 1)}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "email"]}
                filterBy="_id"
                scrollLimit={200}
                sortFunction={sortLastFirst}
                onRowClick={(item) => {
                  if (item?._id) setSelectedId(item._id);
                }}
              />
            )}
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={applicants}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={fetchAll}
            defaultSort="lastName"
            idField="_id"
          />
        </MDBox>
        <ApplicantActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
          currentApplicant={currentApplicant}
        />
      </SnackbarProvider>
    </Card>
  );
};

// Setting default values for the props
ApplicantsTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
ApplicantsTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default ApplicantsTable;
