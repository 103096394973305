import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConfirmDialog from "components/ConfirmDialog";
import FormField from "layouts/applications/wizard/components/FormField";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

// NewProduct page components
import { makeStyles } from "@mui/styles";
import { Alert, Icon, Stack, Snackbar, Button, IconButton, Switch } from "@mui/material";
import { DateRange, Description, Email, Stadium } from "@mui/icons-material";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachFile from "@mui/icons-material/AttachFile";
import states from "assets/stateCodes.json";
import licenseTypes from "assets/licenseTypes.json";
import licenseStatuses from "assets/licenseStatuses.json";
import applicantStatusField from "assets/applicantStatusField.json";
import employmentStatusesField from "assets/employmentStatusesField.json";
import systemSources from "assets/referralSources.json";
import ranks from "assets/ranks.json";
import primaryPositions from "assets/primaryPositions.json";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
  status: {
    fontWeight: 700,
    color: "#1A73E8",

    width: "100%",
  },
  label: {
    display: "grid",
    lineHeight: 1.5,
    fontSize: "0.75rem",
  },
  logoImage: {
    objectFit: "contain",
  },
  previewButton: {
    padding: 0,
  },
  dropzone: {
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
  },
  blockedDropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  text: {
    fontSize: "0.75rem!important",
  },
  error: {
    fontSize: "0.75rem",
  },
});

function ApplicantsInformation({
  mode = "edit",
  setPanelSelect,
  panelSelect,
  boarding,
  setBoarding,
  setNavigateUrl,
}) {
  const classes = useStyle();
  const navigate = useNavigate();

  const { currentApplicant, setCurrentApplicant, currentLoggedUser } =
    useAppContextController();
  const [isModified, setIsModified] = useState(false);
  const [currentLicense, setCurrentLicense] = useState("securityLicense");
  const [licenseStatus, setLicenseStatus] = useState(null);
  const [licenseExpiration, setLicenseExpiration] = useState(null);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    rank: null,
    primaryPosition: "",
    status: "New",
    employmentStatus: "Active",
    systemSource: "",
    phone: "",
    altPhone: "",
    email: "",
    hireDate: "",
    address: "",
    birthDate: "",
    city: "",
    state: null,
    zip: "",
    recordLocked: "No",
    securityLicense: null,
    securityLicenseExpDate: null,
    gamingLicense: null,
    gamingLicenseExpDate: null,
    tabcLicense: null,
    tabcLicenseExpDate: null,
    thirtyDayAlert: "Yes",
    attachments: [],
    notes: [],
  };

  const {
    register,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({ mode: "onBlur" });

  const handleClick = (selection) => {
    setPanelSelect(selection);
    setBoarding(false);
  };
  const handleBoarding = () => {
    setBoarding(true);
    setPanelSelect("activities");
  };
  const firstName = { ...register("firstName", { required: "First name is required" }) };
  const lastName = { ...register("lastName", { required: "Last name is required" }) };
  const primaryPosition = {
    ...register("primaryPosition", {
      validate: {
        exist: (value) => {
          return (
            primaryPositions.includes(value) ||
            value === "" ||
            value === null ||
            value === undefined ||
            "Insert a Primary Position"
          );
        },
      },
    }),
  };
  const status = {
    ...register("status", {
      validate: {
        exist: (value) =>
          applicantStatusField.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Status",
      },
    }),
  };
  const systemSource = {
    ...register("systemSource", {
      validate: {
        exist: (value) =>
          systemSources.includes(value) ||
          value === "" ||
          value === null ||
          value === undefined ||
          "Insert a valid System Sorce",
      },
    }),
  };
  const rank = register("rank");
  const userId = register("userId");
  const employeeID = register("employeeID");
  const employmentStatus = {
    ...register("employmentStatus", {
      validate: {
        exist: (value) =>
          employmentStatusesField.includes(value) ||
          value === "" ||
          value === null ||
          value === undefined ||
          "Insert a valid Employment Status",
      },
    }),
  };
  const phone = {
    ...register("phone", {
      // eslint-disable-next-line
      pattern: {
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
        message: "Your mobile phone is invalid",
      },
    }),
  };
  const altPhone = {
    ...register("altPhone", {
      // eslint-disable-next-line
      pattern: {
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
        message: "Your alternate phone is invalid",
      },
    }),
  };
  const email = {
    ...register("email", {
      required: "Email is required",
      validate: {
        // eslint-disable-next-line
        pattern: (value) =>
          (value && /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value)) || "Invalid Email Address",
        repeated: async (value) => {
          const filteredApplicants = await fetchApplicants({
            filters: { email: value },
            fetchAll: true,
          });
          return (
            filteredApplicants === "" ||
            filteredApplicants.data[0]?._id === currentApplicant?._id ||
            "that email is already in use "
          );
        },
      },
    }),
  };
  const hireDate = register("hireDate");
  const address = register("address1");
  const birthDate = register("birthDate");
  const city = register("city");
  const state = {
    ...register("state", {
      validate: {
        exist: (value) => {
          return states.includes(value) || value === "" || value === null || "Insert a valid state";
        },
      },
    }),
  };

  const zip = {
    ...register("zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Your zip number is invalid" },
    }),
  };
  const recordLocked = register("recordLocked");
  const securityLicense = { ...register("securityLicense") };
  const securityLicenseExpDate = register("securityLicenseExpDate");
  const gamingLicense = { ...register("gaminingLicense") };
  const gamingLicenseExpDate = register("gaminingLicenseExpDate");
  const tabcLicense = { ...register("tabcLicense") };
  const tabcLicenseExpDate = register("tabcLicenseExpDate");
  const thirtyDayAlert = register("thirtyDayAlert");

  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading } = useMutation(createApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("applicants");
      setCurrentApplicant({ ...currentApplicant, ...data, _id: _.insertedId });
      navigate(`/applicants/${_.insertedId}`);
      setToastAlert({ isShow: true, message: "Applicant has been created!", status: "success" });
    },
  });

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");
      setCurrentApplicant({ ...currentApplicant, ...data });
      setToastAlert({ isShow: true, message: "Applicant has been updated!", status: "success" });
    },
  });

  const createApplicantHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.jobs = [];
    data.events = [];
    data.venues = [];
    data.createAgent = currentLoggedUser._id
    await mutateAsync(data);
    setIsModified(false);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getApplicantValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentApplicant?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    reset(getApplicantValues());

    if (!(currentApplicant?.securityLicense === "" || currentApplicant?.securityLicense === undefined || currentApplicant?.securityLicense === null)) {
      setCurrentLicense("securityLicense")
      setLicenseStatus(currentApplicant?.securityLicense)
      setLicenseExpiration(currentApplicant?.securityLicenseExpDate)
    }
    else if (!(currentApplicant?.gamingLicense === "" || currentApplicant?.gamingLicense === undefined || currentApplicant?.gamingLicense === null)) {
      setCurrentLicense("gamingLicense")
      setLicenseStatus(currentApplicant?.gamingLicense)
      setLicenseExpiration(currentApplicant?.gamingLicenseExpDate)
    }
    else if (!(currentApplicant?.tabcLicense === "" || currentApplicant?.tabcLicense === undefined || currentApplicant?.tabcLicense === null)) {
      setCurrentLicense("tabcLicense")
      setLicenseStatus(currentApplicant?.tabcLicense)
      setLicenseExpiration(currentApplicant?.tabcLicenseExpDate)
    }
    else {

      setCurrentLicense("securityLicense");
      setLicenseStatus(null)
      setLicenseExpiration(null)
    }
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes has been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentApplicant?._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }

    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant._id,
      data: values,
    });
  };
  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };


  const handleLicenseChange = (value) => {

    if (value === "securityLicense") {
      setLicenseStatus(currentApplicant?.securityLicense)
      setLicenseExpiration(currentApplicant?.securityLicenseExpDate)
    }
    else if (value === "gamingLicense") {
      setLicenseStatus(currentApplicant?.gamingLicense)
      setLicenseExpiration(currentApplicant?.gamingLicenseExpDate)
    }
    else if (value === "tabcLicense") {
      setLicenseStatus(currentApplicant?.tabcLicense)
      setLicenseExpiration(currentApplicant?.tabcLicenseExpDate)
    }

    else {
      setLicenseStatus(null)
      setLicenseExpiration(null)
    }

  }

  const handleLicenseStatusChange = (value) => {
    if (currentLicense === "securityLicense") {
      setValue("securityLicense", value)
    }
    if (currentLicense === "gamingLicense") {
      setValue("gamingLicense", value)
    }
    if (currentLicense === "tabcLicense") {
      setValue("tabcLicense", value)
    }
  }

  const handleLicenseExpDateChange = (value) => {

    if (currentLicense === "securityLicense") {
      setValue("securityLicenseExpDate", value)
    }
    if (currentLicense === "gamingLicense") {
      setValue("gamingLicenseExpDate", value)
    }
    if (currentLicense === "tabcLicense") {
      setValue("tabcLicenseExpDate", value)
    }
  }

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};

      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentApplicant(data);
      reset(defaultValues);
      setCurrentLicense("securityLicense");
      setLicenseStatus(null)
      setLicenseExpiration(null)

    }

    else {
      // eslint-disable-next-line
      if (!(currentApplicant?.securityLicense === "" || currentApplicant?.securityLicense === undefined || currentApplicant?.securityLicense === null)) {
        setCurrentLicense("securityLicense")
        setLicenseStatus(currentApplicant?.securityLicense)
        setLicenseExpiration(currentApplicant?.securityLicenseExpDate)
      }
      else if (!(currentApplicant?.gamingLicense === "" || currentApplicant?.gamingLicense === undefined || currentApplicant?.gamingLicense === null)) {
        setCurrentLicense("gamingLicense")
        setLicenseStatus(currentApplicant?.gamingLicense)
        setLicenseExpiration(currentApplicant?.gamingLicenseExpDate)
      }
      else if (!(currentApplicant?.tabcLicense === "" || currentApplicant?.tabcLicense === undefined || currentApplicant?.tabcLicense === null)) {
        setCurrentLicense("tabcLicense")
        setLicenseStatus(currentApplicant?.tabcLicense)
        setLicenseExpiration(currentApplicant?.tabcLicenseExpDate)
      }
      else {

        setCurrentLicense("securityLicense");
        setLicenseStatus(null)
        setLicenseExpiration(null)
      }
    }
  }, [watch, mode]);

  useEffect(() => {
    if (mode === "edit") {
      if (currentApplicant) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentApplicant[key] || "");
        });
        setIsModified(false);
      }

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
    }
    return "";
  }, [getValues, currentApplicant, confirmModal.response]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (currentApplicant) {
        setIsModified(JSON.stringify(getApplicantValues()) !== JSON.stringify(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, currentApplicant]);


  useEffect(() => {

    if (!(currentApplicant?.securityLicense === "" || currentApplicant?.securityLicense === undefined || currentApplicant?.securityLicense === null)) {
      setCurrentLicense("securityLicense")
      setLicenseStatus(currentApplicant?.securityLicense)
      setLicenseExpiration(currentApplicant?.securityLicenseExpDate)
    }
    else if (!(currentApplicant?.gamingLicense === "" || currentApplicant?.gamingLicense === undefined || currentApplicant?.gamingLicense === null)) {
      setCurrentLicense("gamingLicense")
      setLicenseStatus(currentApplicant?.gamingLicense)
      setLicenseExpiration(currentApplicant?.gamingLicenseExpDate)
    }
    else if (!(currentApplicant?.tabcLicense === "" || currentApplicant?.tabcLicense === undefined || currentApplicant?.tabcLicense === null)) {
      setCurrentLicense("tabcLicense")
      setLicenseStatus(currentApplicant?.tabcLicense)
      setLicenseExpiration(currentApplicant?.tabcLicenseExpDate)
    }
    else {

      setCurrentLicense("securityLicense");
      setLicenseStatus(null)
      setLicenseExpiration(null)
    }

  }, [currentApplicant])


  return !currentApplicant ? (
    <div>no data</div>
  ) : (
    <Card className={classes.box} key={`card_${currentApplicant?._id}`}>
      <form
        onSubmit={
          mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createApplicantHandler)
        }
      >
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            // severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>
        <Grid container spacing={0}>
          <Grid item sm={12} xl={5.5}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="error"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="large">account_circle</Icon>
              </MDBox>
              <MDBox>
                <MDTypography variant="h5" color="dark" sx={{ mt: 2, ml: 2, mr: -6 }}>
                  {mode === "edit" ? (
                    `${currentApplicant?.firstName} ${currentApplicant?.lastName}`
                  ) : (
                    <>Create Applicant</>
                  )}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item sm={12} xl={6.5}>
            <Stack
              className={classes.stack}
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDBox
                variant="gradient"
                borderRadius="xl"
                shadow={panelSelect === "activities" && !boarding ? "emd" : ""}
              >
                <IconButton
                  color="error"
                  aria-label="timeLine"
                  sx={{ padding: "8px 1px" }}
                  onClick={() => handleClick("activities")}
                >
                  <TimelineIcon />
                </IconButton>
              </MDBox>
              <MDBox
                variant="gradient"
                borderRadius="xl"
                shadow={panelSelect === "venues" ? "emd" : ""}
              >
                <IconButton
                  color="info"
                  aria-label="stadium"
                  sx={{ padding: "8px 1px" }}
                  onClick={() => handleClick("venues")}
                >
                  <Stadium />
                </IconButton>
              </MDBox>
              <MDBox
                variant="gradient"
                borderRadius="xl"
                shadow={panelSelect === "note" ? "emd" : ""}
              >
                <IconButton
                  aria-label="description"
                  sx={{ padding: "8px 1px" }}
                  onClick={() => handleClick("note")}
                >
                  <Description />
                </IconButton>
              </MDBox>
              <MDBox
                variant="gradient"
                borderRadius="xl"
                shadow={panelSelect === "message" ? "emd" : ""}
              >
                <IconButton
                  color="warning"
                  aria-label="Email"
                  sx={{ padding: "8px 1px" }}
                  onClick={() => handleClick("message")}
                >
                  <Email />
                </IconButton>
              </MDBox>
              <MDBox
                variant="gradient"
                borderRadius="xl"
                shadow={panelSelect === "orientation" ? "emd" : ""}
              >
                <IconButton
                  color="success"
                  aria-label="date"
                  sx={{ padding: "8px 1px" }}
                  onClick={() => handleClick("orientation")}
                >
                  <DateRange />
                </IconButton>
              </MDBox>
              <MDBox variant="gradient" borderRadius="xl" shadow={boarding ? "emd" : ""}>
                <IconButton
                  color="error"
                  aria-label="folderShared"
                  sx={{ padding: "8px 1px" }}
                  onClick={handleBoarding}
                >
                  <Icon>folder_shared</Icon>
                </IconButton>
              </MDBox>
              <MDBox
                variant="gradient"
                borderRadius="xl"
                shadow={panelSelect === "attachments" ? "emd" : ""}
              >
                <IconButton
                  color="dark"
                  aria-label="attachments"
                  sx={{ padding: "8px 1px" }}
                  onClick={() => handleClick("attachments")}
                >
                  <AttachFile />
                </IconButton>
              </MDBox>
              {mode === "edit" && isModified && (
                <MDBox display="flex" alignItems="center">
                  <Button
                    variant="text"
                    startIcon={<CancelIcon />}
                    onClick={onCancel}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Save
                  </Button>
                </MDBox>
              )}
              {mode === "create" && (
                <MDBox display="flex" alignItems="center">
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isLoading}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Create
                  </Button>
                </MDBox>
              )}
            </Stack>
          </Grid>
        </Grid>

        <MDBox px={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <Controller
                  {...status}
                  control={control}
                  defaultValue={currentApplicant?.status}
                  render={({ field }) => (
                    <Autocomplete
                      key={`autocomplete_status_${currentApplicant?._id}`}
                      options={applicantStatusField}
                      autoSelect
                      freeSolo
                      name="status"
                      defaultValue={currentApplicant?.status}
                      value={field.value}
                      className={classes.status}
                      color="info"
                      onChange={(e, v) => {
                        return field.onChange(v);
                      }}
                      onBlur={() => trigger(["rank", "primaryPosition", "status"])}
                      renderInput={(params) => (
                        <FormField
                          label="Applicant Status"
                          key={`status_${currentApplicant?._id}`}
                          {...params}
                          type="text"
                        />
                      )}
                      classes={{ input: classes.status }}
                    />
                  )}
                />
                {errors?.status && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.status.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item sm={4}>
                <Controller
                  {...systemSource}
                  control={control}
                  defaultValue={currentApplicant?.systemSource}
                  render={({ field }) => (
                    <Autocomplete
                      key={`autocomplete_systemSource_${currentApplicant?._id}`}
                      options={systemSources}
                      freeSolo
                      autoSelect
                      name="systemSource"
                      value={field.value}
                      defaultValue={currentApplicant?.systemSource}
                      onChange={(e, v) => field.onChange(v)}
                      onBlur={() => trigger("systemSource")}
                      renderInput={(params) => (
                        <FormField
                          key={`systemSource_${currentApplicant?._id}`}
                          {...params}
                          type="text"
                          label="Applicant Source"
                        />
                      )}
                    />
                  )}
                />
                {errors?.systemSource && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.systemSource.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item sm={4}>
                <Controller
                  {...rank}
                  control={control}
                  defaultValue={currentApplicant ? currentApplicant?.rank?.toString() : null}
                  render={({ field }) => (
                    <Autocomplete
                      key={`autocomplete_rank_${currentApplicant?._id}`}
                      disabled={getValues().status !== "Employee"}
                      options={ranks}
                      name="rank"
                      value={field.value?.toString()}
                      defaultValue={
                        currentApplicant ? currentApplicant?.rank?.toString() : null
                      }
                      onChange={(e) => field.onChange(e.target.innerText)}
                      renderInput={(params) => (
                        <FormField
                          key={`rank_${currentApplicant?._id}`}
                          {...params}
                          type="text"
                          label="Rank"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox display="flex" mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  key={`firstName_${currentApplicant?._id}`}
                  label="First Name"
                  type="text"
                  {...firstName}
                />
                {errors?.firstName && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.firstName.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  key={`lastName_${currentApplicant?._id}`}
                  type="text"
                  label="Last Name"
                  {...lastName}
                />
                {errors?.lastName && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.lastName.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  {...primaryPosition}
                  control={control}
                  defaultValue={currentApplicant?.primaryPosition}
                  render={({ field }) => (
                    <Autocomplete
                      key={`autocomplete_primaryPosition_${currentApplicant?._id}`}
                      disabled={getValues().status !== "Employee"}
                      options={primaryPositions}
                      name="primaryPosition"
                      autoSelect
                      freeSolo
                      value={field.value}
                      defaultValue={currentApplicant?.primaryPosition}
                      onChange={(e, v) => field.onChange(v)}
                      onBlur={() => trigger("primaryPosition")}
                      renderInput={(params) => (
                        <FormField
                          key={`primaryPosition_${currentApplicant?._id}`}
                          {...params}
                          type="text"
                          label="Primary Position"
                        />
                      )}
                    />
                  )}
                />
                {errors?.primaryPosition && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.primaryPosition.message}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox display="flex" mt={2}>
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <FormField
                  label="User"
                  key={`user_${currentApplicant?._id}`}
                  type="text"
                  {...userId}
                />
              </Grid>
              <Grid item sm={4}>
                <FormField
                  label="Employee ID"
                  key={`employeeId_${currentApplicant?._id}`}
                  type="text"
                  {...employeeID}
                />
              </Grid>
              <Grid item sm={4}>
                <Controller
                  {...employmentStatus}
                  control={control}
                  defaultValue={currentApplicant?.employmentStatus}
                  render={({ field }) => (
                    <Autocomplete
                      key={`autocomplete_employmentStatus_${currentApplicant?._id}`}
                      options={employmentStatusesField}
                      autoSelect
                      freeSolo
                      name="employmentStatus"
                      defaultValue={currentApplicant?.employmentStatus}
                      value={field.value}
                      onChange={(e, v) => field.onChange(v)}
                      onBlur={() => trigger("employmentStatus")}
                      renderInput={(params) => (
                        <FormField
                          key={`employmentStatus_${currentApplicant?._id}`}
                          {...params}
                          type="text"
                          label="Employment Status"
                        />
                      )}
                    />
                  )}
                />
                {errors?.employmentStatus && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.employmentStatus.message}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  key={`mob_phone_${currentApplicant?._id}`}
                  label="Mobile Phone"
                  type="text"
                  {...phone}
                />
                {errors?.phone && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.phone.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  key={`alt_phone_${currentApplicant?._id}`}
                  label="Alternate Phone"
                  type="text"
                  {...altPhone}
                />
                {errors?.altPhone && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.altPhone.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  key={`email_${currentApplicant?._id}`}
                  type="text"
                  label="Email Address"
                  placeholder="Contact Email Address"
                  {...email}
                />
                {errors?.email && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.email.message}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Controller
                  {...hireDate}
                  control={control}
                  defaultValue={currentApplicant?.hireDate}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Hire Date"
                        value={field.value === "" ? null : field.value}
                        onChange={(date) => {
                          setValue("hireDate", new Date(date));
                        }}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormField
                  key={`address_${currentApplicant?._id}`}
                  type="text"
                  label="Address"
                  {...address}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Controller
                  {...birthDate}
                  control={control}
                  defaultValue={currentApplicant?.birthDate}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Birthdate"
                        value={field.value === "" ? null : field.value}
                        onChange={(date) => {
                          setValue("birthDate", new Date(date));
                        }}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  key={`city_${currentApplicant?._id}`}
                  type="text"
                  label="City"
                  {...city}
                />
                {errors?.city && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.city.message}
                  </MDTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <MDBox mb={3}>
                  <Controller
                    {...state}
                    control={control}
                    defaultValue={currentApplicant?.state}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_state_${currentApplicant?._id}`}
                        options={states}
                        autoSelect
                        freeSolo
                        name="state"
                        defaultValue={null}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value.toUpperCase());
                        }}
                        onBlur={() => trigger("state")}
                        renderInput={(params) => (
                          <FormField
                            key={`state_${currentApplicant?._id}`}
                            {...params}
                            type="text"
                            label="State"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.state && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.state.message}
                    </MDTypography>
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormField
                  key={`zipCode_${currentApplicant?._id}`}
                  label="Zip Code"
                  type="text"
                  {...zip}
                />
                {errors?.zip && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.zip.message}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Stack direction="row" alignItems="center">
                  <Controller
                    {...recordLocked}
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value === "Yes"}
                        onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        inputProps={{ "aria-label": "" }}
                      />
                    )}
                  />
                  <MDTypography variant="body2">
                    {getValues().recordLocked === "Yes" ? "Locked" : "UnLocked"}
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <MDBox mb={3}>

                      <Autocomplete
                        key={`autocomplete_licenseType_${currentApplicant?._id}`}
                        options={licenseTypes}
                        name="licenseType"
                        value={currentLicense}
                        onChange={(e, v) => {
                          setCurrentLicense(v.value)
                          handleLicenseChange(v.value)
                        }}
                        renderInput={(params) => (
                          <FormField
                            key={`licenseType_${currentApplicant?._id}`}
                            {...params}
                            type="text"
                            label="License Type"
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MDBox mb={3}>

                      <Autocomplete
                        key={`autocomplete_licenseStatus_${currentApplicant?._id}`}
                        options={licenseStatuses}
                        name="licenseStatus"
                        value={licenseStatus}
                        onChange={(e) => {
                          setLicenseStatus(e.target.innerText)
                          handleLicenseStatusChange(e.target.innerText)
                        }}
                        renderInput={(params) => (
                          <FormField
                            key={`licenseStatus_${currentApplicant?._id}`}
                            {...params}
                            type="text"
                            label="Status"
                          />
                        )}
                      />

                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        disabled={licenseStatus === null}
                        label="Expiration"
                        value={licenseExpiration}
                        onChange={(date) => {
                          handleLicenseExpDateChange(new Date(date))
                          setLicenseExpiration(new Date(date))
                        }}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>


                  </Grid>
                  <Grid item className={classes.label} xs={12} sm={3}>
                    {/* eslint-disable-next-line */}
                    <label>30-Day Alert</label>
                    <MDBox mt={-3}>
                      <Controller
                        {...thirtyDayAlert}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            inputProps={{ "aria-label": "" }}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </form>
      <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
    </Card>
  );
}
export default ApplicantsInformation;
