import { useMemo, useState } from "react";

import { Card, Grid } from "@mui/material";

import { useAppContextController } from "context/AppContext";
import moment from "moment";
import { useQuery } from "react-query";

import DataList from "components/DataList";
import MDBox from "components/MDBox";
import Searchbar from "components/Searchbar";

import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import ApplyButton from "../ApplyButton";
import JobResultsRow from "./JobResultsRow";

const Results = ({ fetchAll, filters, setFilters, tableHeight }) => {
  // const classes = useStyle();
  const { venues } = useAppContextController();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(null);

  const options = fetchAll ? { fetchAll } : { page, limit };
  const allOptions = { ...options, filters: { ...filters, hideThisJob: "No" }, venues };
  const { data: jobs, isLoading } = useQuery(["jobs", allOptions], () => fetchJobs(allOptions));

  const columns = useMemo(
    () => [
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      // { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Start Date",
        field: "startDate",
        customCell: (date, field, row) => moment(date).format("MM/DD/YYYY"),
      },
      { title: "Status", field: "status" },
      {
        field: "row",
        customCell: (element, field) => <ApplyButton row={field} />,
      },
    ],
    [page, limit]
  );

  const renderRow = (row) => {
    return <JobResultsRow row={row} venues={venues} />;
  };

  const header = (
    <>
      <Grid container mt={0} pt={0}>
        <Grid item xl={6}>
          <Searchbar
            fetch={fetchJobs}
            fetchAll
            placeholder="Search Job/Venue"
            columns={columns}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy={["title", "venueSlug"]}
            filterBy="jobSlug"
          />
        </Grid>
        <Grid item xl={6}>
          <Searchbar
            // fetch={fetchVenues}
            // fetchAll={fetchAll}
            placeholder="City state or zip code near you"
            // columns={columns.slice(1, columns.length - 1)}
            // queryCharacterLimit={1}
            // resultsLimit={10}
            // setFilters={setFilters}
            // setPage={setPage}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Card // className={classes.box}
      shadow="md"
    >
      <MDBox p={5}>
        {!isLoading && (
          <DataList
            renderRow={renderRow}
            data={jobs?.data}
            onRowClick={() => {}}
            title=" Jobs Search Results"
            limit={null}
            isLoading={isLoading}
            greybar
            divider
            header={header}
            borderRadius="xl"
            tableHeight={tableHeight}
            height={tableHeight + 75}
            scrollY
          />
        )}
      </MDBox>
    </Card>
  );
};

export default Results;
