import { useState, useEffect, useCallback } from "react";
import { useAppContextController } from "context/AppContext";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from 'components/MDBox'
import MDTypography from "components/MDTypography";
import DataList from "components/DataList"
import Searchbar from "components/Searchbar";

import VenueIcon from "components/VenueIcon";
import { makeStyles } from "@mui/styles";
import { Stack, Grid } from "@mui/material";
import { useQueryClient, useMutation, useQuery } from "react-query";
import ApplicantEventActions from "layouts/pages/applicants/components/ApplicantEventActions"
import EventDate from "components/EventDate";
import fetchEventsCurrent from "layouts/pages/events/actions/fetchEventsCurrent";
import updateApplicant from "../../actions/updateApplicant";


const useStyle = makeStyles({
    card: {
        height: 350,
    },
    stack: {
        padding: 10,
    },
    editor: {
        height: 10,
    },
    box: {
        overflow: "visible",
        height: 550
    },
});

const ApplicantOnboardingEvents = ({ fetchAll, setActionSelected = 'info', title = "Title", filters, setFilters }) => {
    const { currentApplicant, setCurrentApplicant, venues } = useAppContextController();
    const [applicantVenues, setApplicantVenues] = useState(currentApplicant?.venues ? currentApplicant.venues.map(item => item.venueSlug).join(';') : '');
    const allOptions = { fetchAll, filters: { ...filters, venueSlug: applicantVenues }, page: 1, limit: 200 }

    // console.log('allOptions', allOptions);
    // const queryClient = useQueryClient();
    const { data: events, isLoading, refetch } = useQuery(["events", currentApplicant],
        () => fetchEventsCurrent(allOptions));

    const fetchOnlyMyEventsCurrent = async () => {
        const results = await fetchEventsCurrent({ fetchAll: true, filters: { venueSlug: applicantVenues }, page: 1, limit: 200 })
        return results;
    }

    const changeStatusMutation = useMutation(updateApplicant, {
        onSuccess: async (_, { data }) => setCurrentApplicant({ ...currentApplicant, ...data }),
        mutationKey: 'eventStatusMutation'
    })

    const [page, setPage] = useState(1);
    const [allEvents, setAllEvents] = useState([]);

    const sortByEventDate = (a, b) => {
        console.log('eventDate', a.eventDate);
        if (a.eventDate > b.eventDate) return 1;
        if (a.eventDate < b.eventDate) return -1;
        return 0;
    }

    useEffect(() => {
        if (!isLoading) {
            setAllEvents(events?.data?.sort(sortByEventDate))
        }
    }, [isLoading])

    useEffect(() => {
        if (currentApplicant?.venues) {
            setApplicantVenues(currentApplicant.venues.map(item => item.venueSlug).join(';'))
        } else setApplicantVenues('');
    }, [currentApplicant])

    const setAndRequery = async (status, slug) => {
        let eveVenues = currentApplicant?.events
        if (eveVenues) {
            if (status !== "None") {

                const [findVenue] = eveVenues.filter(eve => eve.eventUrl === slug)

                if (findVenue) findVenue.status = status
                else {
                    eveVenues.push({
                        status,
                        eventUrl: slug,
                        dateModified: new Date()
                    })
                }
            }
            else eveVenues = eveVenues.filter(item => item.eventUrl !== slug)
        }
        else {
            eveVenues = [{
                status,
                eventUrl: slug,
                dateModified: new Date()
            }]
        }

        const newEve = { ...currentApplicant, events: eveVenues }
        if (newEve._id) delete newEve._id

        setCurrentApplicant({ ...newEve })

        await changeStatusMutation.mutateAsync({
            applicantId: currentApplicant._id,
            data: { ...newEve }
        })
    }

    const getEventStatus = (slug) => {
        const eventRec = currentApplicant?.events?.find(item => item.eventUrl === slug);
        return (eventRec) ? eventRec.status : "None";
    }

    const searchBarColumns = [
        {
            title: "Roster", field: "venueSlug",
            customCell: (vslug, field, row) => <ApplicantEventActions
                setStatusRef={setAndRequery}
                slug={row?.eventUrl}
                startStatus={(slug) => getEventStatus(slug)}
            />
        },
        { title: "Event URL", field: "eventUrl", customCell: (slug) => <span>{slug?.toUpperCase()}</span> },
        { title: "Event Name", field: "eventName" },
        { title: "City", field: "venueCity" },
        { title: "State", field: "venueState" },
    ];

    const renderRow = (row) => (
        <>
            <MDBox key={`${row.eventName}_${row.eventUrl}`} display="flex" justifyContent="space-between">
                <Grid item xs={12} sm={2} lg={1.5}>
                    <VenueIcon logoUrl={venues[row.venueSlug]?.logoUrl} slug={row.venueSlug} name={row.venueName} />
                </Grid>
                <Grid item xs={12} sm={6} lg={8.5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <MDTypography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{row.eventName}</MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} display="flex">
                            <MDTypography variant="body2">
                                {row.venueCity}, {`${row.venueState}   `}
                            </MDTypography>
                            &nbsp;
                            <MDTypography variant="body2">
                                - <EventDate date={row?.eventDate} reportTimeTBD={row?.reportTimeTBD} />
                            </MDTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <Stack direction="row" justifyContent="right">
                        <ApplicantEventActions
                            setStatusRef={setAndRequery}
                            slug={row.eventUrl}
                            startStatus={(slug) => getEventStatus(slug)}
                        />
                    </Stack>
                </Grid>
            </MDBox>
        </>
    );

    const header = (
        <Stack display="flex" flexDirection="row" p={2} >
            <Searchbar
                fetch={fetchOnlyMyEventsCurrent}
                fetchAll={false}
                placeholder="Search Event/Venue Slug"
                columns={searchBarColumns}
                queryCharacterLimit={1}
                resultsLimit={10}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["eventName", "venueSlug"]}
                filterBy="eventUrl"
                sortFunction={sortByEventDate}
                scrollLimit={200}
            />

        </Stack >
    );

    return (
        <DataList
            renderRow={renderRow}
            data={allEvents}
            icon="event-seat"
            iconColor="success"
            title="Events"
            isLoading={isLoading}
            greybar
            divider
            header={header}
            tableHeight={450}
            height={550}
            scrollY
        />
    )
}

export default ApplicantOnboardingEvents