import { useState } from "react";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MyNotificationsActions from "layouts/pages/dashboard/components/MyNotifications/components/MyNotificationsActions";
import parse from "html-react-parser";
import moment from "moment";
import PictureAvatar from "components/PictureAvatar";

const Notification = ({ row }) => {
  const [showBody, setShowBody] = useState(false);
  const handleRowOnClick = (e) => {
    e.stopPropagation();
    setShowBody((prev) => !prev);
    // eslint-disable-next-line no-param-reassign
    row.status = row.status === "read" ? "unread" : "read";
  };

  return (
    <Grid container alignItems="center" onClick={handleRowOnClick}>
      <Grid item xs={12} sm={10}>
        <Grid container flexDirection="row">
          <Grid item xs={1.5} xm={1.5}>
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.fromFirstName}
              lastName={row?.fromLastName}
              userId={row?.fromUserId}
              size="md"
            />
          </Grid>
          <Grid item xs={10} sm={10}>
            <Grid container flexDirection="row">
              <Grid item xs={6} sm={6}>
                <MDTypography
                  variant="h5"
                  sx={row.status === "unread" ? { fontStyle: "italic" } : {}}
                >
                  {row.fromFirstName} {row.fromLastName}
                </MDTypography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <MDTypography
                  variant="h6"
                  sx={row.status === "unread" ? { fontStyle: "italic" } : {}}
                >
                  {moment(row.sendTime).format("ddd YYYY-MM-DD hh:mm A")}
                  {/* {new Date(row.sendTime).toLocaleDateString()} {new Date(row.sendTime).toLocaleTimeString()} */}
                </MDTypography>
              </Grid>
            </Grid>
            {row.subject && (
              <Grid item xs={12} sm={12}>
                <MDTypography
                  variant="h6"
                  sx={row.status === "unread" ? { fontStyle: "italic" } : {}}
                >
                  {row.subject}
                </MDTypography>
              </Grid>
            )}
            {showBody && (
              <Grid item xs={12} sm={12} pt={1}>
                <hr />
                <MDTypography variant="body2" pt={1}>
                  {row?.body ? parse(row.body) : ""}
                </MDTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={2}>
        <Grid item xs={12} sm={4} lg={4}>
          <MyNotificationsActions row={row} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notification;
