import FilterCardBar from "components/FilterCardBar";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupsIcon from '@mui/icons-material/Groups';
import FilterCard from "components/FilterCard";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import PaperItem from "components/FilterCard/Paper";

const ExportFieldsModalFilterCards = ({ setMode, mode }) => {
    console.log(mode)
    return (
        <>
            <MDBox mb={3} mt={0}>
                <MDBox mb={1}>
                    <Grid container spacing={3}>
                        <Grid item onClick={() => setMode("eventRoster")}>
                            <PaperItem elevation={mode === "eventRoster" ? 16 : 0} >
                                <FilterCard
                                    icon={<GroupsIcon fontSize="large" color="error" />}
                                    title="Event Roster"
                                />
                            </PaperItem>
                        </Grid>
                        <Grid item onClick={() => setMode("timeSheet")}>
                            <PaperItem elevation={mode === "timeSheet" ? 16 : 0} >
                                <FilterCard
                                    icon={<AccessTimeIcon fontSize="large" color="success" />}
                                    title="Time Sheet"
                                    shadow="md"
                                />
                            </PaperItem>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </>
    );
};

export default ExportFieldsModalFilterCards;