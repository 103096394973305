import { useState } from "react";
import { useAppContextController } from "context/AppContext";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataList from "components/DataList";
import { makeStyles } from "@mui/styles";
import { Card } from "@material-ui/core";
import { Icon, Stack, Button, Grid, IconButton } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachFile from "@mui/icons-material/AttachFile";
import moment from "moment";
import { useQuery, useMutation } from "react-query";
import fetchApplicantActivities from "components/Activities/actions/fetchApplicantActivities";
import ActivitiesDetailModal from "components/Activities/ActivitiesDetailModal";
import GetIcons from "components/Activities/GetIcons";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
});

const Activities = ({
  activitiesParent,
  fetchAll,
  setActionSelected = "info",
  title = "Title",
  filters,
  height,
}) => {
  const classes = useStyle();
  const options = { fetchAll: false, orderBy: "activityDate", order: "desc", limit: 100 };
  const allOptions = { ...options, filters: { ...filters }, applicantId: activitiesParent?._id };
  const { data: activities, isLoading } = useQuery(["activitiesapplicant", allOptions], () =>
    fetchApplicantActivities(allOptions)
  );
  const [activity, setActivity] = useState(null);
  const [detailActivityModalOpen, setDetailActivityModalOpen] = useState(false);

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <GetIcons action={row.action} activity={row} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="h6" key={`button_${row.action}_${activitiesParent?._id}`}>
                {row.action}
                <Button
                  onClick={() => {
                    setDetailActivityModalOpen(true);
                    setActivity(row);
                  }}
                >
                  view
                </Button>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2" key={`date_${row.action}_${activitiesParent?._id}`}>
                {moment(row.activityDate).format("ddd YYYY-MM-DD hh:mm A")}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2" key={`position_${row.action}_${activitiesParent?._id}`}>
                {row.description}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const header = <></>;

  return !activitiesParent ? (
    <div>no data</div>
  ) : (
    <>
      <DataList
        renderRow={renderRow}
        data={activities?.data}
        onRowClick={() => { }}
        icon={<TimelineIcon />}
        iconColor="error"
        title="Activities"
        isLoading={isLoading}
        greybar
        divider
        header={header}
        tableHeight={475}
        height={height}
        scrollY
      />
      <ActivitiesDetailModal
        activity={activity}
        detailActivityModalOpen={detailActivityModalOpen}
        setDetailActivityModalOpen={setDetailActivityModalOpen}
      />
    </>
  );
};

export default Activities;
