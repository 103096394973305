import { useState } from "react";
// import parse from "html-react-parser";
import { Card, Grid, Modal, Button } from "@material-ui/core";
import { Box, Fade, Icon } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EventDate from "components/EventDate";
// import IconButton from "assets/theme/components/iconButton";
import { useAppContextController } from "context/AppContext";
import MDTypography from "components/MDTypography";
// import { IMAGE_SERVER } from "config";
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import moment from 'moment';
import DataList from "components/DataList";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import EventsPreviewModal from "../EventsPreviewModal";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxWidth: 1170,
    bgcolor: "none",
    overflow: "hidden",
    borderRadius: 5,
    px: 4,
    py: 6,
  },

  wideBgImageContainer: {
    width: "100%",
    height: "200px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
    overflow: "hidden",
    borderRadius: 5,
  },
  wideBgImage: { width: "100%", overflowY: "auto", height: "100%" },

  modalBody: {
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    mt: 6,
    px: 6,
    py: 6,
    mx: 15,
  },
};

const CalendarDayModal = ({ showDayModal, setShowDayModal, dateSelected, dayEvents }) => {
  const { currentEvent, setCurrentEvent } = useAppContextController();
  const handleOpen = () => setShowDayModal(true);
  const handleClose = () => setShowDayModal(false);
  const [open, setOpen] = useState(false);
  const [eventPreview, setEventPreview] = useState(currentEvent);
  const openModal = (event) => {
    setEventPreview(event);
    setOpen(true);
  };

  const renderRow = (row) => {
    return (
      <>
        <MDBox display="flex" justifyContent="space-between">
          <Grid item xs={12} sm={2} lg={2}>
            <img src={row.logoUrl} alt="Venue Slug" width="50" height="50" />
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row.eventName}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  {row.venueName}, {row.venueCity}, {row.venueState}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  Report Date/Time:{" "}
                  <EventDate date={row?.eventDate} reportTimeTBD={row?.reportTimeTBD} />
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Box sx={{ textAlign: "right" }} pr={2}>
              <Button
                onClick={() => {
                  openModal(row);
                }}
              >
                <Icon fontSize="large" color="info">
                  info
                </Icon>
              </Button>
            </Box>
          </Grid>
        </MDBox>
      </>
    );
  };
  const header = (
    <Box textAlign="end">
      <Button onClick={() => handleClose()}>
        <CloseIcon fontSize="large" />
      </Button>
    </Box>
  );
  console.log(showDayModal);
  return (
    <div>
      <Modal
        open={showDayModal}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={showDayModal}>
          <Card>
            <MDBox sx={styles.container}>
              <DataList
                renderRow={renderRow}
                data={dayEvents}
                onRowClick={() => {}}
                dateSelected={dateSelected}
                iconColor="success"
                title="Event Listing"
                isLoading={false}
                greybar
                divider
                header={header}
                tableHeight={438}
                height={550}
                scrollY
              />
            </MDBox>
            <EventsPreviewModal
              open={open}
              setOpen={setOpen}
              currentEvent={currentEvent}
              eventPreview={eventPreview}
            />
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of CalendarDayModal
CalendarDayModal.defaultProps = {
  showDayModal: false,
  setShowDayModal: () => {},
  dateSelected: new Date(),
  dayEvents: [],
};

// Typechecking props of the CalendarDayModal
CalendarDayModal.propTypes = {
  showDayModal: PropTypes.bool,
  setShowDayModal: PropTypes.func,
  dateSelected: PropTypes.string,
  dayEvents: PropTypes.array,
};

export default CalendarDayModal;
