import axios from "axios";

import { API_URL } from "config";

export const messageSystem = async (data) => {

    const res = await axios.post(`${API_URL}/notifications`, data)
    return res
};

export const messageText = async (data) => {
    const res = await axios.post(`${API_URL}/sendmessage/type/sms`, data)
    return res
};

export const messageEmail = async (data) => {
    const res = await axios.post(`${API_URL}/sendmessage/type/email`, data)
    return res
};

export const templateSubstitution = async (data) => {
    const res = await axios.post(`${API_URL}/sendmessage/substitution`, data)
    return res
};
