import { Autocomplete, Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";

import states from "assets/stateCodes.json";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { applicantSchema } from "data/applicant";
import useYupValidationResolver from "hooks/useYupValidationResolver";

const JobApplicationForm = () => {
  const { applicant, updateApplicantAction } = useNewApplicantContext();
  const resolver = useYupValidationResolver(applicantSchema);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = async ({
    applicationDate,
    phone,
    socialSecurity,
    altPhone,
    emergencyContactNumber,
    ...data
  }) => {
    const parsedData = {
      applicationDate: applicationDate.toLocaleDateString(),
      phone: phone ? phone.replace(/[^0-9]/g, "") : "",
      altPhone: altPhone ? altPhone.replace(/[^0-9]/g, "") : "",
      emergencyContactNumber: emergencyContactNumber
        ? emergencyContactNumber.replace(/[^0-9]/g, "")
        : "",
      socialSecurity: socialSecurity ? socialSecurity.replace(/[^0-9]/g, "") : "",
      firstName: data.firstName,
      lastName: data.lastName,
      middleInitial: data.middleInitial,
      maidenName: data.maidenName,
      birthDate: data.birthDate,
      driverLicense: data.driverLicense,
      address1: data.address1,
      city: data.city,
      state: data.state,
      zip: data.zip,
      emergencyContactName: data.emergencyContactName,
      criminalHistoryDisclosure: data.criminalHistoryDisclosure,
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  useEffect(() => {
    if (applicant) {
      reset(applicant);
    }
  }, [applicant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
        <Grid container spacing={2}>
          <Grid item lg={3} xs={12}>
            <Controller
              name="applicationDate"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="Application Date"
                  disabled
                  {...field}
                  defaultValue={new Date().toLocaleDateString()}
                  inputProps={{ tabIndex: -1 }}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="First Name"
                  showError={!isEmpty(errors.firstName)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="middleInitial"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="Middle Initial"
                  showError={!isEmpty(errors.middleInitial)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="Last Name"
                  showError={!isEmpty(errors.lastName)}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={3} xs={12}>
            <Controller
              name="maidenName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="Maiden Name"
                  showError={!isEmpty(errors.maidenName)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="socialSecurity"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  format="###-##-####"
                  allowEmptyFormatting
                  mask="_"
                  label="Social Security"
                  variant="outlined"
                  {...field}
                  onValueChange={({ value }) => setValue("socialSecurity", value)}
                  customInput={CustomTextField}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="birthDate"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disableFuture
                    label="Date of Birth"
                    renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="driverLicense"
              control={control}
              render={({ field }) => (
                <CustomTextField variant="outlined" label="Driver License" {...field} />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Controller
              name="address1"
              control={control}
              render={({ field }) => (
                <CustomTextField variant="outlined" label="Address" {...field} />
              )}
            />
          </Grid>
          <Grid item lg={2} xs={12} md={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="City"
                  showError={!isEmpty(errors.city)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item lg={2} xs={12} md={6}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={states}
                  name="state"
                  autoSelect
                  freeSolo
                  defaultValue={null}
                  value={field.value || ""}
                  onChange={(e, v) => {
                    setValue("state", v.toUpperCase());
                  }}
                  sx={{ maxHeight: "44px" }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="outlined"
                      type="text"
                      label="State"
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "#f0f0f0",
                          py: "5px",
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item lg={2} xs={12} md={6}>
            <Controller
              name="zip"
              control={control}
              render={({ field }) => (
                <CustomTextField variant="outlined" label="Zip Code" {...field} />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={3} xs={12}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  format="+1 (###) ### ####"
                  allowEmptyFormatting
                  mask="_"
                  variant="outlined"
                  label="Mobile Number"
                  {...field}
                  onValueChange={({ value }) => setValue("phone", value)}
                  customInput={CustomTextField}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="altPhone"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  format="+1 (###) ### ####"
                  allowEmptyFormatting
                  mask="_"
                  variant="outlined"
                  label="Alternate Number"
                  {...field}
                  onValueChange={({ value }) => setValue("altPhone", value)}
                  customInput={CustomTextField}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="emergencyContactNumber"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  format="+1 (###) ### ####"
                  allowEmptyFormatting
                  mask="_"
                  variant="outlined"
                  label="Emergency Contact Number"
                  {...field}
                  onValueChange={({ value }) => setValue("emergencyContactNumber", value)}
                  customInput={CustomTextField}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Controller
              name="emergencyContactName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  variant="outlined"
                  label="Emergency Contact Name"
                  showError={!isEmpty(errors.emergencyContactName)}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="criminalHistoryDisclosure"
          control={control}
          render={({ field }) => (
            <CustomTextField
              variant="outlined"
              label="Criminal History Disclosure"
              showError={!isEmpty(errors.criminalHistoryDisclosure)}
              {...field}
            />
          )}
        />
      </FlexWrapper>
      <FormErrors errors={errors} />
    </form>
  );
};

export default JobApplicationForm;
