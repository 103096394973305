import axios from "axios";

import { API_URL } from "config";

const updateJob = async ({ _id, data }) => {
  // eslint-disable-next-line
  if (data?._id) { delete data._id }
  const res = await axios.put(`${API_URL}/jobs/${_id}`, data);
  return res.data;
};

export default updateJob;
