import { useAppContextController } from "context/AppContext";
import { useEffect, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Icon from "@mui/material/Icon";
import ConfirmDialog from "components/ConfirmDialog";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FileDropzone from "components/Dropzone";
import moment from "moment";
// @mui material components
import LandscapeIcon from "@mui/icons-material/Landscape";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Grid,
  InputAdornment,
  Snackbar,
  Stack,
  Switch,
  // Tooltip,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import { makeStyles } from "@mui/styles";
import states from "assets/stateCodes.json";
import eventTypes from "assets/eventTypes.json";
import eventStatuses from "assets/eventStatuses.json";
import MDBox from "components/MDBox";
// import MDAvatar from "components/MDAvatar";
// import MDDropzone from "components/MDDropzone";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import IconButton from "@mui/material/IconButton";
import DataList from "components/DataList";

// NewProduct page components
import generateSlug from "utils/generateSlug";
import { API_URL, IMAGE_SERVER } from "config";
import logo from "assets/images/SP-App-Store-Icon.png";
import createEvent from "layouts/pages/events/actions/createEvent";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { NumericFormat } from "react-number-format";

import fetchEvents from "../../actions/fetchEvents";
import fetchSingleEvent from "../../actions/fetchSingleEvent";
import Attachments from "./Attachments";
import UploadFileModal from "./UploadFileModal";
import AddAditionalPositionModal from "./AddAdditionalPositionModal";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 175,
  },
  dropzone: {
    height: 170,
  },
  previewButton: {
    padding: 0,
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
  },
});

function EventsInformation({ mode = "edit", open, setOpen, setEventPreview, deleteEvent }) {
  const classes = useStyle();
  const { currentEvent, setCurrentEvent, venues, userType, currentLoggedUser } = useAppContextController();
  const [editorValue, setEditorValue] = useState(null);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [eventToCopy, setEventToCopy] = useState({});
  const [copyEventName, setCopyEventName] = useState("");

  const { createVenueSlug } = useParams();
  const [searchParams] = useSearchParams();
  const copyEventId = searchParams.get('copyEventId');
  const [disableSlug, setDisableSlug] = useState(true);
  // const [isImageOpen, setIsImageOpen] = useState(false);
  // const [imageAnchorRef, setImageAnchorRef] = useState();
  const [additionalPositionModalOpen, setAdditionalPositionModalOpen] = useState(false);
  // Confirm Modal
  const [currentPosition, setCurrentPosition] = useState(null);
  const [positionIndex, setPositionIndex] = useState(null);
  const [secondaryLocationList, setSecondaryLocationList] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [createConfirmModal, setCreateConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const openModal = () => {
    setEventPreview(currentEvent);
    setOpen(!open);
  };

  let uploadUrl = "";
  if (currentEvent) {
    uploadUrl = `${API_URL}/upload/${currentEvent.slug}/events/other/${currentEvent.slug}-eventlogo`;
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const getVenueField = (slug, field) => {
    if (venues && venues[slug]) return venues[slug][field];
    return null;
  }

  const defaultValues = {
    eventName: "",
    eventType: "Event",
    status: "Active",
    venueName: createVenueSlug ? getVenueField(createVenueSlug, "name") : null,
    secondaryLocation: "",
    address: createVenueSlug ? getVenueField(createVenueSlug, "address") : "",
    venueCity: createVenueSlug ? getVenueField(createVenueSlug, "city") : "",
    venueState: createVenueSlug ? getVenueField(createVenueSlug, "state") : "",
    zip: createVenueSlug ? getVenueField(createVenueSlug, "zip") : "",
    eventId: "",
    eventUrl: "",
    makePublicAndSendNotification: "No",
    sendConfirmationToSignUps: "No",
    waitListPercentage: "25",
    eventDate: null,
    eventEndTime: null,
    reportTimeTBD: null,
    positionsRequested: "",
    billRate: 0,
    payRate: 0,
    eventLogo: "No",
    jobLogo: "No",
    venueBanner: "No",
    googleMap: "No",
    allowPartners: "No",
    allowWaitList: "No",
    allowNonHired: "No",
    reminder48Hour: "No",
    reminder24Hour: "No",
    description: "",
    venueSlug: createVenueSlug || "",
    positions: [],
    attachments: [],
    applicants: [],
  };

  const sortVenues = (a, b) => {
    if (a > b) return 1;
    return -1;
  }

  const venueNamesList = venues ? Object.keys(venues).map((key) => venues[key].name).sort(sortVenues) : [];

  const eventName = { ...register("eventName", { required: "Event name is required" }) };
  const eventType = {
    ...register("eventType", {
      required: "Event Type is required",
      validate: {
        exist: (value) => eventTypes.includes(value) || "Insert a valid event Type",
      },
    }),
  };
  const logoUrl = register("logoUrl");
  const venueSlug = { ...register("venueSlug", { required: "Venue Slug is required" }) };
  const secondaryLocation = register("secondaryLocation");
  const address = register("address");
  const venueCity = register("venueCity");
  const venueState = register("venueState");
  const zip = register("zip");
  const eventId = register("eventId");
  const eventUrl = { ...register("eventUrl", { required: "Event URL is required" }) };
  const status = {
    ...register("status", {
      required: "status is required",
      validate: {
        exist: (value) => eventStatuses.includes(value) || "Insert a valid status",
      },
    }),
  };
  // const eventDate = { ...register("eventDate", { required: 'Event date is required' }) };
  const eventDate = { ...register("eventDate", { required: "Report date is required" }) };
  const eventEndTime = { ...register("eventEndTime", { required: "Est. End Time is required" }) };
  const reportTimeTBD = register("reportTimeTBD");
  const venueName = { ...register("venueName", { required: "Venue is required" }) };
  const positionsRequested = register("positionsRequested");
  const billRate = register("billRate");
  const payRate = register("payRate");
  const makePublicAndSendNotification = register("makePublicAndSendNotification");
  const sendConfirmationToSignUps = register("sendConfirmationToSignUps");
  const waitListPercentage = register("waitListPercentage");
  const eventLogo = register("eventLogo");
  const jobLogo = register("jobLogo");
  const venueBanner = register("venueBanner");
  const googleMap = register("googleMap");
  const allowPartners = register("allowPartners");
  const allowWaitList = register("allowWaitList");
  const allowNonHired = register("allowNonHired");
  const reminder48Hour = register("reminder48Hour");
  const reminder24Hour = register("reminder24Hour");
  const description = register("description");
  const positions = register("positions");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const dateToSlugString = (date) =>
    getValues()?.eventDate?.toString()?.split(" ")?.splice(0, 4)?.join("-")?.toLowerCase();
  const updateEventMutation = useMutation(updateEvent, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("events");
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      setToastAlert({ isShow: true, message: "Event has been updated!", status: "success" });
    },
  });

  const uploadImgMutation = useMutation(
    async (eventImage) => {
      await axios.post(
        `${API_URL}/upload/${currentEvent.venueSlug}/events/${currentEvent?.eventUrl}`,
        eventImage.form
      );
      await updateEvent({ eventId: currentEvent._id, data: eventImage.data });
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: `error: ${error}`, status: "error" }),
      onSuccess: (_, data) => {
        setCurrentEvent({ ...currentEvent, ...data.data });
        setToastAlert({ isShow: true, message: "Image been updated!", status: "success" });
      },
    }
  );

  const createEventMutation = useMutation(createEvent, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("events");
      setToastAlert({ isShow: true, message: "Event has been created!", status: "success" });
      setCurrentEvent({ ...currentEvent, ...data, [_.id.name]: _.id?.[_.id.name], _id: _.result.insertedId });
      navigate(`/events/${data.eventUrl}`);
    },
  });
  const createEventHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser._id
    if (copyEventId) data.copyEventId = copyEventId;
    await createEventMutation.mutateAsync({ ...values, billRate: parseFloat(values.billRate), payRate: parseFloat(values.payRate) });
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const resetCreateConfirmModal = () => {
    setCreateConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const resetDeleteConfirmModal = () => {
    setDeleteConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const handleWaitlistChange = (e) => {
    setValue("waitListPercentage", e.target.id);
  };

  const getEventValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentEvent?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    const url = currentEvent?.eventUrl
    reset(getEventValues());
    setEditorValue(currentEvent.description);
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes have been abandoned", status: "success" });
    navigate(`/events/${url}`)
  };

  const saveChanges = async (values) => {
    if (!currentEvent.venueSlug || !currentEvent.eventId) {
      setToastAlert({
        isShow: true,
        message: "Cannot Update Event -- No Event ID!",
        status: "error",
      });
      return;
    }

    await updateEventMutation.mutateAsync({
      venueSlug: currentEvent.venueSlug,
      eventId: currentEvent._id,
      data: { ...values, billRate: parseFloat(values.billRate), payRate: parseFloat(values.payRate) },
    });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: copyEventId ? "Copy Event" : "Data has changed!",
      bodyText: copyEventId ? "Abandon Copy Event?" : "Please confirm you want to abandon changes",
      response: false,
    });
    // if (copyEventId) navigate(`/events/venue/${currentEvent?.venueSlug}`)
  };

  const cancelCreate = () => {
    setCreateConfirmModal({
      isOpen: true,
      title: "You are leaving create mode",
      bodyText: "Please Confirm to abandon new Event",
      response: false,
    });
  }

  const onDelete = () => {
    setDeleteConfirmModal({
      isOpen: true,
      title: "Delete Event",
      bodyText: "Please confirm you want to DELETE this event!",
      response: false,
    });
  };
  const onVenueNameChange = () => {
    Object.keys(venues).forEach((key) => {
      if (venues[key].name.trim() === getValues("venueName").trim()) {

        setValue("logoUrl", venues[key].logoUrl);
        setValue("venueSlug", venues[key].slug);
        setValue("address", venues[key].address);
        setValue("venueName", venues[key].name);
        setValue("venueCity", venues[key].city);
        setValue("venueState", venues[key].state);
        setValue("zip", venues[key].zip);
        setSecondaryLocationList(venues[key].locations?.map((location) => {
          return ({ ...location, label: location.locationName })
        }))
      }
    });
  };

  const onDropImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { eventImage: file.name },
    });
  };

  const avatar = currentEvent?.eventImage ? (
    <Card>
      <img
        src={`${IMAGE_SERVER}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${currentEvent?.eventImage}`}
        alt={
          currentEvent?.eventImage ? `${currentEvent.eventName} Event Image` : "No Event Image Set"
        }
        style={{ width: "100%" }}
      />
    </Card>
  ) : (
    <LandscapeIcon sx={{ width: "5em", height: "5em" }} />
  );

  const addNewAdditionalPosition = () => {
    setAdditionalPositionModalOpen(true);
  };
  const removePosition = (idx) => {
    const temporaryPositions = getValues()?.positions;
    temporaryPositions.splice(idx, 1);
    setValue("positions", temporaryPositions);
  };

  const additionalPositionsRenderRow = (row, idx) => {
    return (
      <>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          onClick={() => {
            setCurrentPosition(row);
            setAdditionalPositionModalOpen(true);
            setPositionIndex(idx);
          }}
        >
          <Grid container>
            <Grid item sm={4}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>
                {row?.positionName} ({row?.numberPositions})
              </MDTypography>
            </Grid>
            <Grid item sm={3}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>
                {moment(row?.reportTime).format("hh:mm A")}
              </MDTypography>
            </Grid>
            <Grid item sm={2}>
              <MDTypography sx={{ fontSize: "0.9rem" }} >
                Bill: ${row.billRate}
              </MDTypography>
            </Grid>
            <Grid item sm={2}>
              <MDTypography sx={{ fontSize: "0.9rem" }} >
                Pay: ${row.payRate}
              </MDTypography>
            </Grid>

            <Grid item sm={1}>
              <MDBox textAlign="right">
                <Icon
                  color="error"
                  fontSize="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    removePosition(idx);
                  }}
                >
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  };

  useMemo(() => {
    if (mode === "create") {
      if (
        getValues()?.eventName?.length &&
        getValues()?.venueSlug?.length &&
        getValues()?.eventDate
      ) {
        (async () => {
          const { slug } = await generateSlug({
            venueSlug: getValues()?.venueSlug,
            title: getValues()?.eventName,
            suffix: dateToSlugString(getValues()?.eventDate),
            slugField: "eventUrl",
            fetchFunction: fetchEvents,
          });
          setDisableSlug(false);
          setValue("eventUrl", slug);
        })();
      } else if (
        !getValues()?.eventName?.length ||
        !getValues()?.venueSlug?.length ||
        !getValues()?.eventDate
      ) {
        setDisableSlug(true);
        setValue("eventUrl", "");
      }
    } else if (mode === "edit") {
      setDisableSlug(true);
    }
  }, [getValues().eventName, getValues().venueSlug, getValues().eventDate]);

  useEffect(() => {
    const fetchEventToCopy = async (eid) => {
      const event = await fetchSingleEvent({ eventId: eid });
      if (event) {
        setCopyEventName(event.eventName);
        event.eventName = ''
        event.reportTime = '';
        event.eventDate = '';
        event.eventEndTime = '';
        event.eventUrl = '';
        event.eventId = null;
        event.applicants = [];
        setValue("description", event.description)
        setEditorValue(event.description)
        delete event._id;
        if (!event.positions) event.positions = [];
        if (!event.attachments) event.attachments = [];
        setEventToCopy({ ...event });
      }
    };
    if (copyEventId) {
      fetchEventToCopy(copyEventId)
    }
  }, [copyEventId]);

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};

      if (copyEventId && eventToCopy !== {}) {  // we need to fetch the event we are copying from
        Object.keys(eventToCopy).forEach((key) => {
          data[key] = eventToCopy[key];
        });
      }
      else
        Object.keys(defaultValues).forEach((key) => {
          data[key] = defaultValues[key];
        });

      setCurrentEvent(data);
      reset(data);
      if (!copyEventId) setEditorValue(defaultValues.description);
    }
  }, [mode, watch, copyEventId, eventToCopy]);

  function diffObj(obj1, obj2) {

    const result = {};
    if (Object.is(obj1, obj2)) return undefined;
    if (!obj2 || typeof obj2 !== 'object') return obj2;
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {

      let val;
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) val = [obj1[key], obj2[key]];

      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {

        const value = diffObj(obj1[key], obj2[key]);
        if (value !== undefined) val = value;

      } else if (val !== undefined) {

        result[key] = val;

      }

    });
    return result;

  }

  useEffect(() => {
    if (mode === "edit") {
      if (currentEvent) {
        Object.keys(watch()).forEach((key) => {
          setValue(key, currentEvent[key] || "");
        });
        setEditorValue(currentEvent.description);
        setIsModified(false);
      }
      const subscription = watch((value) => {
        if (currentEvent) {
          setIsModified(JSON.stringify(getEventValues()) !== JSON.stringify(value));
        }
      });

      if (confirmModal.response) {
        if (copyEventId) navigate(`/events/venue/${currentEvent?.venueSlug}`)
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    } // navigate(`/events/${currentEvent?.eventUrl}`)
    return "";
  }, [watch, currentEvent, confirmModal.response]);

  useEffect(() => {
    const del = async () => {
      const slug = currentEvent?.venueSlug;
      const name = currentEvent?.eventName;
      const res = await deleteEvent(currentEvent._id, name);
      if (res.success) {
        setCurrentEvent({});
        navigate(`/events/venue/${slug}`);
      }
    }
    if (deleteConfirmModal.response) {
      resetDeleteConfirmModal();
      del();
    }
  }, [deleteConfirmModal.response])

  useEffect(() => {
    if (createConfirmModal.response) {
      resetConfirmModal();
      navigate(`/events`);
    }
  }, [createConfirmModal.response])

  const createEventSuffix = () => {
    if (copyEventName) return ` from ${copyEventName}`;
    if (createVenueSlug) return ` at ${getVenueField(createVenueSlug, "name")}`
    return '';
  }


  useEffect(() => {
    if (mode === "edit") {
      Object.keys(venues).forEach((key) => {
        if (venues[key].name === getValues("venueName")) {
          setSecondaryLocationList(venues[key].locations?.map((location) => {
            return ({ ...location, label: location.locationName })
          }))
        }
      }
      )
    }

  }, [currentEvent])

  return (
    <>
      {currentEvent && (
        <Card className={classes.box} key={currentEvent?._id}>
          <Snackbar
            open={toastAlert.isShow}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            key="top-center"
          >
            <Alert
              onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
              // severity={toastAlert.status}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {toastAlert.message}
            </Alert>
          </Snackbar>

          <form
            onSubmit={
              mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createEventHandler)
            }
          >
            <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
              <MDBox display="flex">
                {mode === "create" ? (
                  <>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="success"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      mt={-2}
                    >
                      <Icon fontSize="large">event-seat</Icon>
                    </MDBox>
                  </>
                ) : (
                  <>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="white"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      mt={-2}
                    >
                      <img
                        src={currentEvent?.logoUrl ? getValues("logoUrl") : logo}
                        width="50"
                        height="50"
                        className={classes.logoImage}
                        alt="Venue Logo"
                      />
                    </MDBox>
                  </>
                )}
                <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {mode === "edit"
                    ? `Event Information: ${currentEvent?.eventName}`
                    : `Create Event${createEventSuffix()}`}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                {mode === "edit" && !isModified && userType === "Master" &&
                  <Button
                    variant="text"
                    endIcon={<DeleteIcon />}
                    onClick={onDelete}
                    style={{ color: "red" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Delete Event
                  </Button>
                }

                {mode === "edit" && isModified &&
                  <>
                    <Button
                      variant="text"
                      startIcon={<CancelIcon />}
                      onClick={onCancel}
                      disabled={isSubmitting}
                      sx={{ padding: "0.5rem 1rem" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<SaveIcon />}
                      style={{ color: "white" }}
                      disabled={isSubmitting}
                      sx={{ padding: "0.5rem 1rem" }}
                    >
                      Save
                    </Button>
                  </>
                }
                {mode === "edit" && !isModified && currentEvent._id &&
                  <Button
                    // type="submit"
                    variant="contained"
                    endIcon={<ContentCopyIcon />}
                    onClick={() => navigate(`/events/create?copyEventId=${currentEvent._id}`)}
                    style={{ color: "white" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Copy Event
                  </Button>
                }
                {mode === "create" &&
                  <>
                    <Button
                      variant="text"
                      startIcon={<CancelIcon />}
                      onClick={cancelCreate}
                      disabled={isSubmitting}
                      sx={{ padding: "0.5rem 1rem" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<SaveIcon />}
                      style={{ color: "white" }}
                      disabled={createEventMutation.isLoading}
                      sx={{ padding: "0.5rem 1rem" }}
                    >
                      Create
                    </Button>
                  </>
                }
              </MDBox>
            </MDBox>
            <MDBox mb={-3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} />
              </Grid>
            </MDBox>
            <MDBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <MDBox mt={1}>
                    <Grid container spacing={2}>
                      <Grid item sm={9}>
                        <FormField
                          disabled={getValues().venueName === "" || getValues().venueName === null}
                          inputProps={(getValues().venueName === "" || getValues().venueName === null) ? { tabIndex: -1 } : {}}
                          key={`eventName_${currentEvent?.eventId}`}
                          type="text"
                          label="Event Name"
                          {...eventName}
                        />
                        {errors?.eventName && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.eventName.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item sm={3}>
                        <Controller
                          {...eventType}
                          control={control}
                          defaultValue={currentEvent?.eventType}
                          render={({ field }) => (
                            <Autocomplete
                              key={`autocomplete_eventType_${currentEvent?.eventId}`}
                              options={eventTypes}
                              autoSelect
                              freeSolo
                              name="Event Type "
                              defaultValue={currentEvent.eventType}
                              value={field.value}
                              onChange={(e, v) => {
                                field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                              }}
                              onBlur={() => trigger("eventTypes")}
                              renderInput={(params) => (
                                <FormField
                                  key={`eventType_${currentEvent?.eventId}`}
                                  {...params}
                                  type="text"
                                  label="Event Type"
                                />
                              )}
                            />
                          )}
                        />
                        {errors?.eventType && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.eventType.message}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                    <MDBox mt={1}>
                      <Controller
                        {...venueName}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={venueNamesList}
                            disabled={mode === "edit"}
                            inputProps={(mode === "edit") ? { tabIndex: -1 } : {}}
                            name="venueName"
                            value={field.value || null}
                            onChange={(e) => {
                              field.onChange(e.target.innerText);
                              onVenueNameChange();
                              trigger("address");

                            }}
                            renderInput={(params) => (
                              <FormField
                                key={`venueName_${currentEvent?.eventId}`}
                                {...params}
                                type="text"
                                label="Venue Name"
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.venueName && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.venueName.message}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox mt={1}>
                      <Controller
                        {...secondaryLocation}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={secondaryLocationList.length > 0 ? [{ label: "<Original Venue Location>" }, ...secondaryLocationList] : []}
                            disabled={getValues().venueName === "" || getValues().venueName === null}
                            inputProps={(getValues().venueName === "" || getValues().venueName === null) ? { tabIndex: -1 } : {}}
                            name="secondaryLocations"
                            value={field.value}
                            onChange={(e, v) => {
                              if (v.label === "<Original Venue Location>") {
                                setValue("address", currentEvent.address);
                                setValue("venueCity", currentEvent.venueCity);
                                setValue("venueState", currentEvent.venueState);
                                setValue("zip", currentEvent.zip);
                                field.onChange(v)
                              }
                              else {
                                setValue("address", v.address);
                                setValue("venueCity", v.city);
                                setValue("venueState", v.state);
                                setValue("zip", v.zip);
                                field.onChange(v)
                              }
                            }}
                            renderInput={(params) => (
                              <FormField
                                key={`secondaryLocation_${currentEvent?.eventId}`}
                                {...params}
                                type="text"
                                label="Secondary Location"
                              />
                            )}
                          />
                        )}
                      />
                    </MDBox>
                    <MDBox mt={1}>
                      <FormField
                        {...address}
                        disabled
                        inputProps={{ tabIndex: -1 }}
                        key={`address_${currentEvent?.eventId}`}
                        type="text"
                        label=" Address"
                        InputLabelProps={{ shrink: getValues().address }}
                      />
                    </MDBox>
                    <MDBox mt={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormField
                            {...venueCity}
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            key={`city_${currentEvent?.eventId}`}
                            type="text"
                            label="City"
                            InputLabelProps={{ shrink: getValues().venueCity }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <MDBox mb={3}>
                            <Controller
                              {...venueState}
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  disabled
                                  inputProps={{ tabIndex: -1 }}
                                  freeSolo
                                  options={states}
                                  defaultValue={null}
                                  name="venueState"
                                  value={field.value || states[0]}
                                  onChange={(e, v) => {
                                    field.onChange(v.toUpperCase());
                                  }}
                                  onBlur={() => trigger("state")}
                                  renderInput={(params) => (
                                    <FormField
                                      key={`state_${currentEvent?.eventId}`}
                                      {...params}
                                      type="text"
                                      label="State"
                                    />
                                  )}
                                />
                              )}
                            />
                            {errors?.state && (
                              <MDTypography className={classes.error} color="error">
                                {errors?.state.message}
                              </MDTypography>
                            )}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormField
                            {...zip}
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            key={`zipCode_${currentEvent?.eventId}`}
                            type="text"
                            label="Zip Code"
                            InputLabelProps={{ shrink: getValues().zip }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mb={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormField
                            {...eventId}
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            value={currentEvent?.eventId}
                            key={`eventId${currentEvent?.eventId}`}
                            type="text"
                            label=" Event ID"
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <FormField
                            {...eventUrl}
                            key={`eventUrl_${currentEvent?.eventId}`}
                            disabled={mode === "edit" ||
                              !getValues()?.venueSlug?.length ||
                              !getValues()?.eventDate ||
                              !getValues()?.eventName?.length}
                            inputProps={(mode === "edit" ||
                              !getValues()?.venueSlug?.length ||
                              !getValues()?.eventDate ||
                              !getValues()?.eventName?.length) ? { tabIndex: -1 } : {}}
                            type="text"
                            label=" Event URL"
                            InputLabelProps={{ shrink: getValues().eventUrl }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <MDBox>
                          <Controller
                            {...status}
                            control={control}
                            defaultValue={currentEvent?.status}
                            render={({ field }) => (
                              <Autocomplete
                                key={`autocomplete_status_${currentEvent?.eventId}`}
                                options={eventStatuses}
                                autoSelect
                                freeSolo
                                name="status"
                                defaultValue={currentEvent.status}
                                value={field.value}
                                onChange={(e, v) => {
                                  field.onChange(v.charAt(0).toUpperCase() + v.slice(1));
                                }}
                                onBlur={() => trigger("status")}
                                renderInput={(params) => (
                                  <FormField
                                    key={`status_${currentEvent?.eventId}`}
                                    {...params}
                                    type="text"
                                    label="Event Status"
                                  />
                                )}
                              />
                            )}
                          />
                          {errors?.status && (
                            <MDTypography className={classes.error} color="error">
                              {errors?.status.message}
                            </MDTypography>
                          )}
                        </MDBox>
                        <Stack direction="row" alignItems="center">
                          <Controller
                            {...makePublicAndSendNotification}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                          <MDTypography variant="body2" textTransform="capitalize">
                            Make Public and Send Notification
                          </MDTypography>
                        </Stack>

                        <Stack direction="row" alignItems="center">
                          <Controller
                            {...sendConfirmationToSignUps}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                          <MDTypography variant="body2" textTransform="capitalize">
                            Send Confirmation to Sign-Ups
                          </MDTypography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <Controller
                            {...allowPartners}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                          <MDTypography variant="body2" textTransform="capitalize">
                            Allow Partners
                          </MDTypography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDTypography variant="body2" textTransform="capitalize">
                          Waitlist
                        </MDTypography>

                        <Controller
                          {...waitListPercentage}
                          control={control}
                          render={({ field }) => (
                            <>
                              <Stack direction="row" alignItems="center">
                                <Switch
                                  id="25"
                                  checked={field.value === "25"}
                                  onChange={(e, v) => {
                                    handleWaitlistChange(e);
                                  }}
                                />
                                <MDTypography variant="body2" textTransform="capitalize">
                                  25% over
                                </MDTypography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Switch
                                  id="30"
                                  checked={field.value === "30"}
                                  onChange={(e, v) => handleWaitlistChange(e)}
                                />
                                <MDTypography variant="body2" textTransform="capitalize">
                                  30% over
                                </MDTypography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Switch
                                  id="40"
                                  checked={field.value === "40"}
                                  onChange={(e, v) => handleWaitlistChange(e)}
                                />
                                <MDTypography variant="body2" textTransform="capitalize">
                                  40% over
                                </MDTypography>
                              </Stack>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <MDTypography variant="body2">Event Reminders</MDTypography>
                        <Stack direction="row" alignItems="center">
                          <MDTypography variant="body2" textTransform="capitalize">
                            24-Hour
                          </MDTypography>

                          <Controller
                            {...reminder24Hour}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <MDTypography variant="body2" textTransform="capitalize">
                            48-Hour
                          </MDTypography>

                          <Controller
                            {...reminder48Hour}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack direction="row" alignItems="center">
                          <MDTypography variant="body2" textTransform="capitalize">
                            Google Map
                          </MDTypography>

                          <Controller
                            {...googleMap}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <MDBox mt={1}>
                    <MDTypography variant="body2" textTransform="capitalize">
                      EVENT REPORT INFO
                    </MDTypography>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} lg={9} xl={9}>
                        <Controller
                          {...eventDate}
                          control={control}
                          defaultValue={currentEvent?.eventDate}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                disabled={getValues().venueName === "" || getValues().venueName === null}
                                inputProps={(getValues().venueName === "" || getValues().venueName === null) ? { tabIndex: -1 } : {}}
                                label="Report Time"
                                value={field.value === "" ? null : field.value}
                                onChange={(date) => {
                                  setValue("eventDate", new Date(date));
                                  setValue("eventEndTime", new Date(new Date(getValues().eventDate).getTime() + 5 * 60 * 60 * 1000))
                                  clearErrors("eventDate");
                                }}
                                renderInput={(params) => <FormField {...params} />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors?.eventDate && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.eventDate.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} lg={3} xl={3}>
                        <FormField
                          {...reportTimeTBD}
                          key={`reportTimeTBD_${currentEvent?.eventId}`}
                          label="TBD/Time Text"
                          onChange={(e) => {
                            setValue("reportTimeTBD", e.target.value.toUpperCase());
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} lg={9} xl={9}>
                        <Controller
                          {...eventEndTime}
                          control={control}
                          defaultValue={currentEvent?.eventEndTime}
                          render={({ field }) => {

                            return (
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  disabled={getValues().venueName === "" || getValues().venueName === null}
                                  inputProps={(getValues().venueName === "" || getValues().venueName === null) ? { tabIndex: -1 } : {}}
                                  label="Est. End Time"
                                  value={(field.value === "" || field.value === null || field.value === undefined) ?
                                    null
                                    : field.value}
                                  onChange={(date) => {
                                    setValue("eventEndTime", new Date(date));
                                  }}
                                  renderInput={(params) => <FormField {...params} />}
                                />
                              </LocalizationProvider>
                            )
                          }}
                        />
                        {errors?.eventEndTime && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.eventEndTime.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} lg={3} xl={3} />

                    </Grid>
                    <MDBox mt={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} lg={4} xl={4}>
                          <FormField
                            key={`positionsRequested_${currentEvent?.eventId}`}
                            type="number"
                            label="Positions requested"
                            placeholder="# Positions requested"
                            {...positionsRequested}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4}>
                          <Controller
                            {...billRate}
                            key={`billRate_${currentEvent?.eventId}`}
                            control={control}
                            render={({ field }) => (
                              <NumericFormat
                                InputProps={{
                                  startAdornment: "$",
                                  inputProps: {
                                    inputMode: 'numeric',
                                  }
                                }}
                                label="Bill Rate"
                                decimalScale={2}
                                {...field}
                                customInput={FormField}
                              />
                            )}
                          />

                          {errors?.billRate && <MDTypography className={classes.error} color="error">{errors?.billRate.message}</MDTypography>}
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4}>
                          <Controller
                            {...payRate}
                            key={`payRate_${currentEvent?.eventId}`}
                            control={control}
                            render={({ field }) => (
                              <NumericFormat
                                InputProps={{
                                  startAdornment: "$",
                                  inputProps: {
                                    inputMode: 'numeric',
                                  }
                                }}
                                label="Pay Rate"
                                decimalScale={2}
                                {...field}
                                customInput={FormField}
                              />
                            )}
                          />
                          {errors?.payRate && <MDTypography className={classes.error} color="error">{errors?.payRate.message}</MDTypography>}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={1} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="bold"
                        color="info"
                      >
                        ADDITIONAL POSITIONS
                      </MDTypography>
                      <IconButton
                        className={classes.addButton}
                        color="info"
                        onClick={addNewAdditionalPosition}
                      >
                        <AddCircleRoundedIcon />
                      </IconButton>
                    </MDBox>
                    <hr />
                    <Controller
                      {...positions}
                      control={control}
                      render={({ field }) => (
                        <DataList
                          renderRow={additionalPositionsRenderRow}
                          data={field.value || []}
                          greybar
                          divider
                          scrollY
                          tableHeight={570}
                          height={600}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography variant="body2" textTransform="capitalize">
                      EVENT DESCRIPTION
                    </MDTypography>
                    <Button className={classes.previewButton} onClick={openModal}>
                      Preview
                    </Button>
                  </Stack>
                  <MDBox mb={10}>
                    <MDEditor
                      className={classes.editor}
                      {...description}
                      value={editorValue || ""}
                      onChange={(e) => {
                        setEditorValue(e);
                        setValue("description", e);
                      }}
                    />
                  </MDBox>
                  {mode === "edit" &&
                    <MDBox mt={3}>
                      <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                        EVENT IMAGE
                      </MDTypography>

                      <Grid
                        item
                        xs={6}
                        mt={2}
                        style={{ maxWidth: "25%", marginLeft: "auto", marginRight: "auto" }}
                      >
                        <FileDropzone
                          className={classes.dropzone}
                          onDrop={onDropImageHandler}
                          avatar={avatar}
                        />

                      </Grid>
                      <MDBox mt={2}>
                        <Attachments
                          setCurrentEvent={setCurrentEvent}
                          currentEvent={currentEvent}
                          setAttachmentOpen={setAttachmentOpen}
                          attachemntTitle="Applicant Files"
                        />
                      </MDBox>
                    </MDBox>
                  }
                  {/* <Button className={classes.previewButton}>View Event Messaging Options</Button> */}
                </Grid>
              </Grid>
            </MDBox>
          </form>
          {/* {JSON.stringify(currentEvent)} */}
          <UploadFileModal
            currentEvent={currentEvent}
            attachmentOpen={attachmentOpen}
            setAttachmentOpen={setAttachmentOpen}
          />
          <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
          <ConfirmDialog state={deleteConfirmModal} setState={setDeleteConfirmModal} />
          <ConfirmDialog state={createConfirmModal} setState={setCreateConfirmModal} />
          <AddAditionalPositionModal
            setValue={setValue}
            additionalPositionModalOpen={additionalPositionModalOpen}
            setAdditionalPositionModalOpen={setAdditionalPositionModalOpen}
            currentEvent={currentEvent}
            setCurrentEvent={setCurrentEvent}
            currentPosition={currentPosition}
            setCurrentPosition={setCurrentPosition}
            positionIndex={positionIndex}
            setPositionIndex={setPositionIndex}
          />
        </Card>
      )}
    </>
  );
}
export default EventsInformation;
