import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/applications/wizard/components/FormField";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import {
  Icon,
  Stack,
  IconButton,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { DateRange, Description, Email, Stadium } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    border: "1px solid",
  },
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },

  cardBox: {
    overflow: "visible",
  },
  button: {
    margin: `${pxToRem(2)}`,
  },
});

const INine = ({ currentApplicant }) => {
  const classes = useStyle();
  const navigate = useNavigate();

  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const defaultValues = {
    firstName: "",
    lastName: "",
    middleInitial: "",
    maidenName: "",
    socialSecurity: "",
    birthDate: "",
    email: "",
    phone: "",
    alienRegistrationNumber: "",
  };

  const firstName = register("firstName");
  const lastName = register("lastName");
  const middleInitial = register("middleInitial");
  const maidenName = register("maidenName");
  const socialSecurity = register("socialSecurity");
  const birthDate = register("birthDate");
  const email = register("email");
  const phone = register("phone");
  const alienRegistrationNumber = register("alienRegistrationNumber");

  const getApplicantINineValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentApplicant?.[key] || "";
    });
    return values;
  };

  useEffect(() => {
    if (currentApplicant) {
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentApplicant[key] || "");
      });
      setIsModified(false);
    }
    const subscription = watch((value) => {
      if (currentApplicant) {
        setIsModified(JSON.stringify(getApplicantINineValues()) !== JSON.stringify(value));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, currentApplicant]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...firstName}
            key={`firstName_${currentApplicant?.venueSlug}`}
            label="First Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...middleInitial}
            key={`middelInitial_${currentApplicant?.venueSlug}`}
            label="Middle Initial"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...lastName}
            key={`lastName_${currentApplicant?.venueSlug}`}
            label="Last Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...maidenName}
            key={`maidenName_${currentApplicant?.venueSlug}`}
            label="Maiden Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...birthDate}
            key={`dateOfBirth_${currentApplicant?.venueSlug}`}
            label="Date of Birth"
            type="date"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...socialSecurity}
            key={`socialSecurity_${currentApplicant?.venueSlug}`}
            label="Social Security"
            type="number"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...email}
            key={`email_${currentApplicant?.venueSlug}`}
            label="Email Address"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...phone}
            key={`mobileNumber_${currentApplicant?.venueSlug}`}
            label="Mobile Number"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
      </Grid>
      <MDTypography variant="h6">Citizenship Status:</MDTypography>
      <RadioGroup
        aria-labelledby={`citizenshipStatus_${currentApplicant?.venueSlug}`}
        defaultValue="1"
        name="radio-buttons-group"
      >
        <Grid container>
          <Grid item sm={6}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="1. A citizen of the United States"
            />
          </Grid>
          <Grid item sm={6}>
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="2. A non-citizen of the United States"
            />
          </Grid>
          <Grid item sm={12}>
            <Stack direction="row">
              <Grid item sm={6}>
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3. A lawful permanent resident: (Alien Registration Number/USCIS Number)"
                />
              </Grid>
              <Grid item sm={6}>
                <FormField
                  {...alienRegistrationNumber}
                  key={`alienRegistrationNumber_${currentApplicant?.venueSlug}`}
                  type="text"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
            </Stack>
          </Grid>
          <FormControlLabel
            value="4"
            control={<Radio />}
            label="4. An alien authorized to work until expiration date"
          />
        </Grid>
      </RadioGroup>
      <MDTypography variant="h6">
        Preparer and/or Translator Certification (chech one):
      </MDTypography>
      <RadioGroup
        aria-labelledby={`preparerOrTranslator_${currentApplicant?.venueSlug}`}
        defaultValue="1"
        name="radio-buttons-group"
      >
        <Grid container>
          <Grid item sm={6}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="1. A preparer o translator was not used while filling this form"
            />
          </Grid>
          <Grid item sm={6}>
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="2. A preparer or translator was used while filling this form"
            />
          </Grid>
        </Grid>
      </RadioGroup>

      {/* <Grid container>
                <Grid item>

                </Grid>
            </Grid> */}
    </form>
  );
};

export default INine;
