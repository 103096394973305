import axios from "axios";

import { API_URL } from "config";

const createEvent = async (data) => {
  console.log('createEvent', JSON.stringify(data))
  const res = await axios.post(`${API_URL}/events`, { eventData: data });
  return res.data;
};

export default createEvent;
