import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import { Card, Grid, Modal, Typography } from "@material-ui/core";
import { Box, Fade, Stack, IconButton, Icon, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Avatar from '@mui/material/Avatar';
import SPLogo from "assets/images/SP-App-Store-Icon.png";
import colors from "assets/theme/base/colors";
import EmailIcon from '@mui/icons-material/Email';

import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import UpcomingEvents from "layouts/pages/venues/components/UpcomingEvents";
import VenuePhotoGallery from "layouts/pages/venues/components/VenuePhotoGallery";
import VenueMapsAndPolicies from "layouts/pages/venues/components/VenueMapsAndPolicies";
import VenueTrainingVideos from "layouts/pages/venues/components/VenueTrainingVideos";

import styles from "./styles";

const { black } = colors;
const textColor = "#7c97a9";

const VenuePreviewPanel = ({
  open,
  setOpen,
  venue: { name, address, logoUrl, city, description, state, zip, slug, venueUrl,
    bannerUrl, selectContact1, selectContact2, payrollContact, videoUrls, imageUrls, stadiumPeopleInfo, otherUrls },
}) => {

  const { venues } = useAppContextController();
  const options = { fetchAll: true }
  const allOptions = { ...options, filters: { venueSlug: slug }, venues };
  const { data: events, isLoading } = useQuery(["events", allOptions], () =>
    fetchEvents(allOptions)
  );

  const handleClose = () => setOpen(false);
  function stringAvatar(contactName) {

    console.log("contactName")
    console.log(contactName)
    return {
      children: `${contactName?.split(' ')[0][0]}${contactName?.split(' ')[1][0]}`,
    };
  }
  // const previewRightData = [
  //   {
  //     title: "View venue photo gallery",
  //     description:
  //       "Checkout the venue upclose and learn why so many of our staff enjoy this venue.",
  //     icon: <Icon fontSize="large">stadium</Icon>,
  //     bgColor: "info",
  //   },
  //   {
  //     title: "View upcoming opportunities at this venue",
  //     description: "Get hired first and then you can search and work any of our upcoming events.",
  //     icon: <Icon fontSize="large">calendar_month</Icon>,
  //     bgColor: "success",
  //   },
  // ];

  const venueBanner = bannerUrl || (imageUrls?.length ? imageUrls[0] : null);
  const [showMore, setShowMore] = useState(false);


  const url = () => {
    if (showMore || venueUrl.length < 20) {
      return venueUrl
    }
    return `${venueUrl?.substring(0, 20)}...`
  }

  const searchGoogleMaps = () => {
    const search = `${address || ""}+${city || ""}+${state || ""}+${zip || ""}`;
    window.open(`http://maps.google.com/maps?q=loc:${search}`)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card>
            <Box sx={styles.modalContainer}>
              <Box style={styles.longBgImageContainer}>
                <img style={styles.longBgImage} src={venueBanner} alt="Event Staff" />
              </Box>
              <Box sx={styles.mainContentContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={8} sm={8} xl={8}>
                    <Box style={styles.leftContent}>
                      <Box justifyContent="space-between">
                        <Grid container spacing={3}>
                          <Grid item sm={1}>
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="4rem"
                              height="4rem"
                              variant="gradient"
                              bgColor="white"
                              color="white"
                              shadow="md"
                              borderRadius="xl"
                              mt={-2}
                            >
                              <img alt="logo" width="100%" src={logoUrl} />
                            </MDBox>
                          </Grid>
                          <Grid item sm={8}>
                            <Box >
                              <h3 style={{ lineHeight: 1, marginBottom: 5 }}>{name}</h3>
                              <Box style={{ color: textColor }}>
                                <Typography>{address}</Typography>
                                <Typography>
                                  {city}, {state} {zip}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={3} sx={{ textAlign: "right" }}>
                            <Box
                              width="100%"
                              style={{
                                borderBottom: `1px solid hsl(0deg 0% 80%)`,
                                marginBottom: 4,
                              }}
                            >
                              <Grid container spacing={3} alignItems="center">
                                <Grid item sm={4}>
                                  <Box onClick={() => searchGoogleMaps()}>
                                    <img alt="logo" width="30px" src="/google.png" />
                                  </Box>
                                </Grid>
                                <Grid item sm={4}>
                                  <Box>
                                    <img
                                      alt="logo"
                                      width="30px"
                                      src={logoUrl}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item sm={4}>
                                  <Box>
                                    <Icon color="warning" fontSize="large">
                                      staroutline
                                    </Icon>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box style={{ textAlign: "center" }}>
                              {venueUrl && (
                                <Tooltip title={venueUrl} >
                                  <Typography style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                    <a href={venueUrl} target="_blank" rel="noreferrer">
                                      {venueUrl}
                                    </a>
                                  </Typography>
                                </Tooltip>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={styles.descriptionContainer}>
                        <Typography
                          style={{ color: textColor }}
                          id="modal-modal-description"
                          sx={{ mt: 2 }}
                        >
                          {description ? parse(description) : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} xl={4}>
                    <Box style={styles.rightContent}>
                      <MDBox display="flex">
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="4rem"
                          height="4rem"
                          variant="gradient"
                          bgColor="white"
                          color="white"
                          shadow="md"
                          borderRadius="xl"
                          mt={-2}
                        >
                          <img
                            src={SPLogo}
                            width="50"
                            height="50"
                            alt="SPLogo"
                          />
                        </MDBox>
                        <Box mb={1} px={2}>
                          <h4>Working With Stadium People At Your Favorite Venues</h4>
                        </Box>
                      </MDBox>
                      <Box >
                        <Typography align="left">{stadiumPeopleInfo ? parse(stadiumPeopleInfo) : ""}</Typography>
                      </Box>
                      <Stack pt={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} sm={2}>
                          {selectContact1 &&
                            <Avatar sx={{ bgcolor: black.light }}{...stringAvatar(selectContact1)} />
                          }
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <MDTypography variant="body2" >
                            {selectContact1}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Box alignSelf="center">
                            <IconButton color="warning">
                              <EmailIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Stack>
                      <Stack pt={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} sm={2}>
                          {selectContact2 &&
                            <Avatar sx={{ bgcolor: black.light }}{...stringAvatar(selectContact2)} />
                          }
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <MDTypography variant="body2" >
                            {selectContact2}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Box alignSelf="center">
                            <IconButton color="warning">
                              <EmailIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Stack>

                      <Stack pt={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} sm={2}>
                          {payrollContact &&
                            <Avatar sx={{ bgcolor: black.light }} {...stringAvatar(payrollContact)} />
                          }
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <MDTypography variant="body2" >
                            {payrollContact}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Box alignSelf="center">
                            <IconButton color="warning">
                              <EmailIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Stack>

                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox style={styles.middleContent}>
                      <UpcomingEvents events={events} isLoading={isLoading} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item sm={4}>
                        <MDBox style={styles.bottomContent}>
                          <VenuePhotoGallery imageUrls={imageUrls} />
                        </MDBox>

                      </Grid>
                      <Grid item sm={4}>
                        <MDBox style={styles.bottomContent}>
                          <VenueMapsAndPolicies address={address} city={city} state={state} zip={zip} slug={slug} otherUrls={otherUrls} />
                        </MDBox>
                      </Grid>
                      <Grid item sm={4}>
                        <MDBox style={styles.bottomContent}>
                          <VenueTrainingVideos videoUrls={videoUrls} />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of VenuePreviewPanel
VenuePreviewPanel.defaultProps = {
  open: false,
  setOpen: () => { },
};

// Typechecking props of the VenuePreviewPanel
VenuePreviewPanel.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  currentJob: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    venueState: PropTypes.string,
    venueCity: PropTypes.string,
    googleMap: PropTypes.string,
    venueName: PropTypes.string,
    venueHelmID: PropTypes.string,
    venueLogoUrl: PropTypes.string,
  }).isRequired,
};

export default VenuePreviewPanel;
