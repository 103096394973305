import { useEffect, useState } from "react";
import { TableAction } from "@perimeter-software/react-common";
import { API_URL } from "config";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import StadiumIcon from '@mui/icons-material/Stadium';
import EventIcon from '@mui/icons-material/Event';
import { useAppContextController } from "context/AppContext";

const ApplicantVenueStatus = ({ id, row, venue, slugField, invalidQuery }) => {
    const { currentLoggedUser } = useAppContextController();
    const queryClient = useQueryClient();
    const [venueStatus, setVenueStatus] = useState("");
    const [color, setColor] = useState("dark");

    useEffect(() => {
        if (row?.venues?.length) {
            const venueRec = row.venues?.find(item => item[slugField] === venue)
            if (venueRec) setVenueStatus(venueRec.status);
            else setVenueStatus("")
        } else setVenueStatus("")
    }, [row?.venues, row]);

    useEffect(() => {
        if (venueStatus) {
            if (venueStatus === "StaffingPool") setColor("success")
            else if (venueStatus === "Pending") setColor("warning")
            else if (venueStatus === "Locked") setColor("error")
            else setColor("dark")
        } else setColor("dark");

    }, [venueStatus]);

    const rosterMutation = useMutation(
        async (data) => {
            // eslint-disable-next-line no-unused-expressions
            await axios.put(`${API_URL}/applicants/${data.id}`, { ...data });
        },
        {
            // eslint-disable-next-line no-return-await
            onSuccess: async () => await queryClient.invalidateQueries(invalidQuery),
            onError: (error) => alert(error.toString()),
        }
    );

    const handleUpdate = async (e) => {
        e.stopPropagation();
        let newStatus;
        if (!venueStatus) newStatus = "StaffingPool";
        else if (venueStatus === "StaffingPool") newStatus = "Pending";
        else if (venueStatus === "Pending") newStatus = "Locked";
        else if (venueStatus === "Locked") newStatus = "";

        setVenueStatus(newStatus);
        // await updateStatus(row?._id, "venues", newStatus);
        // now let's compute the new venues array for the applicant
        let newVenues = [...row.venues];
        if (!newStatus)
            newVenues = row.venues?.filter(item => item[slugField] !== venue);
        else {
            const ndx = row.venues?.findIndex(item => item[slugField] === venue);
            const venueRec = {
                venueSlug: venue,
                ...newVenues[ndx],
                status: newStatus,
                dateModified: new Date().toISOString(),
                agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
            }
            if (ndx > -1) {
                newVenues[ndx] = venueRec
            }
            else newVenues.push(venueRec);
        }

        rosterMutation.mutate({
            id: row?._id,
            venues: [...newVenues],
            dateModified: new Date(),
            agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
        });
    }


    return (
        <span>
            <Tooltip title={`${row?.firstName} ${row?.lastName} is ${venueStatus || 'Not Associated'}`}>
                {slugField?.includes('venue') ? (
                    <StadiumIcon
                        onClick={handleUpdate}
                        key={`venuestatus_${id}`}
                        color={color}
                        fontSize="large"
                    />
                ) : (
                    <EventIcon
                        key={`eventstatus_${id}`}
                        color={color}
                        fontSize="large"
                    />
                )}
            </Tooltip>
        </span>
    )
}

export default ApplicantVenueStatus;

ApplicantVenueStatus.defaultProps = {
    id: "",
};

// Typechecking props for the TableAction
ApplicantVenueStatus.propTypes = {
    id: PropTypes.string,
    // children: PropTypes.node.isRequired,
};