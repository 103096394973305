import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";

import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { i9Schema } from "data/i9";
import useYupValidationResolver from "hooks/useYupValidationResolver";

const I9Form = () => {
  const { applicant, updateApplicantAction } = useNewApplicantContext();
  const resolver = useYupValidationResolver(i9Schema);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = async ({ alienRegistrationNumber, preparerOrTranslator, citizenshipStatus }) => {
    const parsedData = {
      i9Form: {
        alienRegistrationNumber,
        preparerOrTranslator,
        citizenshipStatus,
      },
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  const handleCitizenChange = (e) => {
    setValue("citizenshipStatus", e.target.value);
  };
  const handlePreparerOrTranslatorChange = (e) => {
    setValue("preparerOrTranslator", e.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.firstName}
              variant="outlined"
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.middleInitial}
              variant="outlined"
              label="Middle Initial"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.lastName}
              variant="outlined"
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.maidenName}
              variant="outlined"
              label="Maiden Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={new Date(applicant?.birthDate).toLocaleDateString() || ""}
              variant="outlined"
              label="Date of Birth"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <PatternFormat
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.socialSecurity}
              format="###-##-####"
              allowEmptyFormatting
              mask="_"
              label="Social Security"
              variant="outlined"
              onValueChange={({ value }) => setValue("socialSecurity", value)}
              customInput={CustomTextField}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.email}
              variant="outlined"
              label="Email Address"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <PatternFormat
              disabled
              inputProps={{ tabIndex: -1 }}
              defaultValue={applicant?.phone}
              format="+1 (###) #### ###"
              allowEmptyFormatting
              mask="_"
              variant="outlined"
              label="Mobile Number"
              onValueChange={({ value }) => setValue("mobileNumber", value)}
              customInput={CustomTextField}
            />
          </Grid>
        </Grid>
        <MDTypography variant="h6">Citizenship Status:</MDTypography>

        <RadioGroup
          aria-labelledby={`citizenshipStatus_${applicant?.venueSlug}`}
          name="citizenshipStatus"
          defaultValue={applicant?.i9Form?.citizenshipStatus || "1"}
          onChange={handleCitizenChange}
        >
          <Grid container>
            <Grid item sm={6}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel value="1" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  1. A citizen of the United States{" "}
                </MDTypography>
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel value="2" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  2. A non-citizen of the United States{" "}
                </MDTypography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel value="3" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  3. A lawful permanent resident: (Alien Registration Number/USCIS Number){" "}
                </MDTypography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="alienRegistrationNumber"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    disabled={watch("citizenShipStatus") !== "3"}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack display="flex" direction="row" alignItems="center">
                <FormControlLabel value="4" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  4. An alien authorized to work until expiration date{" "}
                </MDTypography>
              </Stack>
            </Grid>
          </Grid>
        </RadioGroup>

        <MDTypography variant="h6">
          Preparer and/or Translator Certification (check one):
        </MDTypography>
        <Grid container>
          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby={`preparerOrTranslator_${applicant?.venueSlug}`}
              name="radio-buttons-group"
              defaultValue={applicant?.i9Form?.preparerOrTranslator || "1"}
              onChange={handlePreparerOrTranslatorChange}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <FormControlLabel value="1" control={<Radio />} sx={{ marginRight: "0" }} />
                    <MDTypography fontWeight="bold" fontSize="0.875rem">
                      1. A preparer or translator was not used while filling this form{" "}
                    </MDTypography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <FormControlLabel value="2" control={<Radio />} sx={{ marginRight: "0" }} />
                    <MDTypography fontWeight="bold" fontSize="0.875rem">
                      2. A preparer or translator was used while filling this form{" "}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </FlexWrapper>
      <FormErrors errors={errors} />
    </form>
  );
};

export default I9Form;
