import { useEffect, useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import VenuesFilterCards from "layouts/pages/venues/components/VenuesFilterCards";
import VenuesTable from "layouts/pages/venues/components/VenueTable";
import JobsTable from "layouts/pages/jobs/components/JobsTable";
import VenuesEventsTable from "layouts/pages/events/components/EventsTable";
import ApplicantsTable from "layouts/pages/applicants/components/ApplicantsTable";
// import VenueForm from "layouts/pages/venues/components/VenueForm";
// eslint-disable-next-line import/no-named-as-default-member
import VenueMainInfoPanel from "layouts/pages/venues/components/VenueMainInfoPanel";
import VenueSettingsPanel from "layouts/pages/venues/components/VenueSettingsPanel";
import VenueMediaGallery from "layouts/pages/venues/components/VenueMediaGallery";
import VenuePreviewPanel from "layouts/pages/venues/components/VenuePreviewPanel";

import EventsPreviewModal from "layouts/pages/events/components/EventsPreviewModal";

const venueCards = [
  { icon: "info", title: "Details", number: null },
  { icon: "work", title: "Jobs", number: null },
  { icon: "event-seat", title: "Events", number: null },
  { icon: "people", title: "Applicants", number: null },
  { icon: "public", title: "venueall", number: null },
];

function Venues({ mode = "edit" }) {
  const navigate = useNavigate();
  const { currentVenue, setCurrentVenue, currentLoggedUser, venues } = useAppContextController();
  const { slug, action } = useParams();
  const [searchParams] = useSearchParams();
  const myVenues = searchParams.get('myVenues');
  const [open, setOpen] = useState(false);
  const [venuePreview, setVenuePreview] = useState(null);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [filters, setFilters] = useState({});
  const [eventPreview, setEventPreview] = useState(null);
  const [openEventPreview, setOpenEventPreview] = useState(false);

  useEffect(() => {
    if (slug && (actionSelected || action)) setFilters({ slug });
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
      const newFilters = { status: "Active" };
      if (myVenues && currentLoggedUser?.venues?.length) newFilters.slug = currentLoggedUser.venues.join(";")
      setFilters(newFilters);
    }
    if (slug && (!currentVenue || currentVenue.slug !== slug)) {
      const venue = venues?.[slug];
      if (venue) setCurrentVenue(venue);
    }
  }, [slug, action, currentVenue]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={12} xl={12} xxl={12}>
          <VenuesFilterCards
            setFilters={setFilters}
          // setPage={setPage}
          />
          <VenuesTable
            slug={slug}
            fetchAll
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpen}
            setVenuePreview={setVenuePreview}
          />
        </Grid>
        {["venueinfo", "venueall", ""].includes(actionSelected) && (slug || mode === "create") && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <VenueMainInfoPanel
                mode={mode}
                open={open}
                setOpen={setOpen}
                setVenuePreview={setVenuePreview}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <div> </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <VenueSettingsPanel />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {["venuejobs", "venueall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <JobsTable
                title={`Venue Jobs at: ${currentVenue?.slug?.toUpperCase()}`}
                filters={currentVenue?.slug ? { venueSlug: currentVenue?.slug } : {}}
                fetchAll
                showFiltersList={false}
                showSearchBar={false}
                defaultSort="title"
              />
            </Grid>
          </>
        )}
        {["venueevents", "venueall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <VenuesEventsTable
                showFiltersList={false}
                fetchAll
                showSearchBar={false}
                title={`Venue Events at: ${currentVenue?.slug?.toUpperCase()}`}
                filters={currentVenue?.slug ? { venueSlug: currentVenue.slug, timeFrame: "Current" } : {}}
                navigateToAction="eventsinfo"
                setOpen={setOpenEventPreview}
                defaultSort="eventDate"
                setEventPreview={setEventPreview}
                parent="Venues"
              />
            </Grid>
          </>
        )}
        {["venueapplicants", "venueall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <ApplicantsTable
                showFiltersList={false}
                showSearchBar={false}
                fetchAll={false}
                filters={{ "venues.venueSlug": slug }}
                title={`Staffing Pool at: ${currentVenue?.slug?.toUpperCase()}`}
                navigateToAction="applicantinfo"
                venueSlug={slug}
                showEventStatus
                pageSize={50}
              />
            </Grid>
          </>
        )}
        {["venuemedia", "venueall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <VenueMediaGallery title="Media Gallery" />
            </Grid>
          </>
        )}
        {venuePreview && <VenuePreviewPanel open={open} setOpen={setOpen} venue={venuePreview} />}
        <EventsPreviewModal
          open={openEventPreview}
          setOpen={setOpenEventPreview}
          eventPreview={eventPreview}
        />
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Venues.defaultProps = {
  mode: "edit",
};

// Typechecking props
Venues.propTypes = {
  mode: PropTypes.string,
};

export default Venues;
