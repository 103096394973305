import { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@mui/styles";
import { Card } from "@material-ui/core";
import {
  Icon,
  Stack,
  Button,
  Grid,
  IconButton,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";

const useStyle = makeStyles({
  tableStriped: {
    "& tr": {
      "&:nth-of-type(odd)": { border: 0, background: "rgba(0, 0, 0, 0.04)" },
      "&:last-child td, &:last-child th": { border: 0 },
    },
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
  tableDivider: {
    borderBottom: "3px solid #00000070 !important",
  },
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible!important",
  },
  scroll: {
    overflowY: "scroll",
  },
  header: {
    width: "60%",
    textAlign: "end",
  },
});

const DataList = ({
  data,
  renderRow,
  onRowClick,
  dateSelected,
  icon,
  iconColor,
  title,
  limit,
  isLoading,
  greybar,
  divider,
  header,
  tableHeight,
  height,
  width,
  img,
  scrollY,
}) => {
  const classes = useStyle();

  const [limitedData, setLimitedData] = useState(data);
  useEffect(() => {
    if (data) {
      if (limit && limit < data.length) setLimitedData(data?.slice(0, limit));
      else setLimitedData(data);
    }
  }, [data]);

  // const [startTime, setStartTime] = useState(new Date());
  // const [elapsedTime, setElapsedTime] = useState(0);

  return (
    !isLoading && (
      <Card className={classes.box} style={{ height }}>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <Grid container>
              {header && (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor={img ? "white" : iconColor}
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  {dateSelected ? (
                    <Stack alignItems="center">
                      <MDTypography variant="h4" color="white">
                        {moment(dateSelected).format("DD")}
                      </MDTypography>
                      <MDTypography variant="h5" color="white">
                        {moment(dateSelected).format("MMM")}
                      </MDTypography>
                    </Stack>
                  ) : (
                    <Icon fontSize="large">{icon}</Icon>
                  )}
                </MDBox>
              )}
              {dateSelected ? (
                <Stack>
                  <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    {title}
                  </MDTypography>
                  <MDTypography variant="body2" sx={{ mb: 1, ml: 2 }}>
                    {moment(dateSelected).format("dddd, MMM Do, YYYY")}
                  </MDTypography>
                </Stack>
              ) : (
                <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {title}
                </MDTypography>
              )}
            </Grid>
          </MDBox>
          <MDBox className={classes.header}>{header}</MDBox>
        </MDBox>

        <MDBox p={3} className={scrollY && classes.scroll} sx={{ maxHeight: tableHeight }}>
          {/* {new Date().getTime() - startTime.getTime()} */}
          <Table>
            <TableBody className={greybar && classes.tableStriped}>
              {limitedData?.length ? (
                limitedData.map((row, idx) => (
                  <TableRow
                    // eslint-disable-next-line
                    key={`${row?.name}_${idx}`}
                  // className={divider && idx < limitedData.length - 1 && classes.tableDivider}
                  >
                    <TableCell>{renderRow(row, idx)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>no data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MDBox>
      </Card>
    )
  );
};

DataList.defaultProps = {
  data: [],
  limit: 0,
  dateSelected: null,
  icon: "description",
  iconColor: "dark",
  greybar: false,
  divider: true,
  renderRow: () => { },
  onRowClick: () => { },
  height: null,
  tableHeight: null,
  width: null,
  img: null,
  scrollY: false,
};

DataList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  renderRow: PropTypes.func,
  limit: PropTypes.number,
  dateSelected: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  divider: PropTypes.bool,
  greybar: PropTypes.bool,
  height: PropTypes.number,
  tableHeight: PropTypes.number,
  width: PropTypes.number,
  img: PropTypes.string,
  scrollY: PropTypes.bool,
};

export default DataList;
