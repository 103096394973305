import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Icon, Grid, IconButton, Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import PictureAvatar from "components/PictureAvatar";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BugReportIcon from '@mui/icons-material/BugReport';
import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider } from "notistack";
import GetIcons from "components/GetIcons";
import IssuesTableActions from "layouts/pages/issues/components/IssuesTableActions";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import fetchIssues from "layouts/pages/issues/actions/fetchIssues";
// import IssuesActionModal from "../IssuesActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const IssuesTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
}) => {
  const { setCurrentIssue, currentIssue, currentLoggedUser } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentIssue });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const { data: issues, isLoading } = useQuery(["issues", allOptions], () =>
    fetchIssues(allOptions)
  );

  useEffect(() => {
    if (action && filters) setPage(1);
  }, [filters]);

  const columns = [
    {
      title: "Icon",
      field: "type",
      customCell: (image, field, row) => (
        <GetIcons category={row?.category} />
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "Category",
      field: "category",
    },
    {
      title: "Date Opened",
      field: "issueDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Avatar",
      field: "createAgent",
      customCell: (createAgent, field, row) => (
        <PictureAvatar
          image={null}
          firstName={row?.userFirstName}
          lastName={row?.userLastName}
          userId={createAgent}
          size="md"
        />
      ),
    },
    {
      title: "Created By",
      field: "userFirstName",
      customCell: (id, field, row) => (
        <MDTypography variant="body">
          {row.userFirstName} {row.userLastName}
        </MDTypography>
      ),
    },
    {
      title: "Issue Title",
      field: "issueTitle",
      customCell: (subject) => (
        <MDTypography variant="body">
          {subject?.length > 20 ? `${subject.substring(0, 20)}...` : subject}
        </MDTypography>
      ),
    },
    {
      title: "Description",
      field: "issueDescription",
      customCell: (desc) => {
        const text = desc?.length > 30 ? `${desc.substring(0, 30)}...` : desc
        return (< MDTypography variant="body" >
          {text ? parse(text) : ""}
        </MDTypography >)
      },
    },
    {
      title: "Last Updated",
      field: "modifiedDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Issues Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <IssuesTableActions
          id={id}
          row={row}
          setCurrentIssue={setCurrentIssue}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/User ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
  ];

  const columnsForIssues = [
    { title: "First Name", field: "userFirstName" },
    { title: "Last Name", field: "userLastName" },
    { title: "Title", field: "title" },
    { title: "Status", field: "status" },
  ];

  const handleRowClick = (issue, e) => {
    e.stopPropagation();
    setCurrentIssue({ ...issue });
    setNavigateUrl(`/issues/${issue._id}`);
  };


  const addNew = (e) => {
    if (!currentIssue) {
      setCurrentIssue({});
    }
    setNavigateUrl("/issues/create");
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="error"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <BugReportIcon fontSize="large" />
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Issues
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchIssues}
                placeholder="Search Issues"
                columns={columnsForIssues}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters} fetchUsers
                setPage={setPage}
                searchBy={["title", "description"]}
                // filterBy="applicantId"
                filterBy="_id"
                filterByColumn="_id"
              />
            )}
            {showSearchBar && (
              <Searchbar
                fetch={fetchUsers}
                placeholder="Search Users"
                columns={columnsForSearch}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "emailAddress"]}
                filterBy="userId"
                filterByColumn="_id"
              />
            )}
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={issues}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={false}
            defaultSort="issueDate:desc"
          />
        </MDBox>
        {/* <IssuesActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
      </SnackbarProvider>
    </Card>
  );
};

// Setting default values for the props
IssuesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Issues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
IssuesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default IssuesTable;
