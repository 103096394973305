import { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Autocomplete, Button, Card, Grid, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import howDidYouHear from "assets/howDidYouHear.json";
import axios from "axios";
import FileDropzone from "components/Dropzone";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { API_URL, IMAGE_SERVER } from "config";
import FormField from "layouts/applications/wizard/components/FormField";
import fetchUserByEmail from "layouts/pages/users/actions/fetchUserByEmail";
import createApplication from "layouts/pages/web/actions/createApplication";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import ThanksForApplyingModal from "./ThanksForApplyingModal";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 170,
  },
  dropzone: {
    height: 50,
  },
  previewButton: {
    padding: 0,
  },
  error: {
    fontSize: "0.75rem",
  },
});
const Apply = ({ job }) => {
  const { loginWithRedirect } = useAuth0();

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [openAlreadyAUserModal, setOpenAlreadyAUserModal] = useState(false);
  const [openThanksForApplyingModal, setOpenThanksForApplyingModal] = useState(false);
  const [resumeExtension, setResumeExtension] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [resumeDisabled, setResumeDisabled] = useState(true);
  const [applicantCode, setApplicantCode] = useState(null);
  const classes = useStyle();

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    resumeUrl: "",
    howDidYouHearAbout: null,
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  // Input Refs
  const firstName = { ...register("firstName", { required: "First name is required" }) };
  const lastName = { ...register("lastName", { required: "Last name is required" }) };
  const email = {
    ...register("email", {
      required: "Email is required",
      validate: {
        // eslint-disable-next-line
        pattern: (value) => {
          if (value && /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value)) {
            setResumeDisabled(false);
          } else {
            setResumeDisabled(true);
            return "Invalid Email Address";
          }
        },
        aleradyUser: async (value) => {
          const alreadyUser = await fetchUserByEmail(value);
          if (alreadyUser) {
            setOpenAlreadyAUserModal(true);
          }
          return null;
        },
      },
    }),
  };
  const phoneNumber = {
    ...register("phoneNumber", {
      required: "Phone is required",
      // eslint-disable-next-line
      pattern: {
        value: /^(\+?\d{1}\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
        message: "Your mobile phone is invalid",
      },
    }),
  };
  const resumeUrl = register("resumeUrl");
  const howDidYouHearAbout = register("howDidYouHearAbout");

  const queryClient = useQueryClient();

  const createApplicationMutation = useMutation(createApplication, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, data) => {
      console.log("viene_", _);
      console.log("data", data);
      await queryClient.invalidateQueries("applications");
      // setToastAlert({ isShow: true, message: "Application has been created!", status: "success" });
      setApplicantCode(_.applicantCode);
      setOpenThanksForApplyingModal(true);
    },
  });

  const createApplicationHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    await createApplicationMutation.mutateAsync({
      data: { ...data, email: data.email.toLowerCase() },
      jobSlug: job.jobSlug,
    });
  };

  const uploadFileMutation = useMutation(
    async (resumeForm) => {
      await axios.post(
        `${API_URL}/upload/resumes/${job.jobSlug}/${encodeURIComponent(getValues().email)}`,
        resumeForm.form
      );
    },
    {
      onSuccess: (_, data) => {
        setValue(
          "resumeUrl",
          `${IMAGE_SERVER}/resumes/${job.jobSlug}/${getValues().email}/${data?.name}`
        );
        setResumeExtension(data?.extension);
        setFileName(data?.name);
        setToastAlert({ isShow: true, message: "Resume has been loaded!", status: "success" });
      },
    }
  );

  const avatar = (
    <MDBox
      onClick={() => {
        const url = `${IMAGE_SERVER}/resumes/${job.jobSlug}/${encodeURIComponent(
          getValues().email
        )}/${encodeURIComponent(fileName)}`;
        // alert(url);
        window.open(url);
      }}
    >
      {resumeExtension?.toLowerCase() === "docx" ? (
        <img
          src={`${process.env.PUBLIC_URL}/word-icon.png`}
          alt="preview"
          width={100}
          height={100}
        />
      ) : (
        resumeExtension?.toLowerCase() === "pdf" && (
          <img
            src={`${process.env.PUBLIC_URL}/pdf-icon.png`}
            alt="preview"
            width={100}
            height={100}
          />
        )
      )}
    </MDBox>
  );

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);

      uploadFileMutation.mutate({
        form,
        type: "resume",
        name: file?.name,
        extension: file?.path?.split(".").pop(),
      });
    }
  };
  const modalHeader = (
    <>
      <MDTypography variant="h3">You are already a user in Our System</MDTypography>
    </>
  );
  const modalBody = (
    <>
      <MDTypography>Press Okay and log in</MDTypography>
    </>
  );
  const modalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        onClick={() => {
          setOpenAlreadyAUserModal(false);
          loginWithRedirect();
        }}
      >
        Ok
      </Button>
      <Button
        variant="contained"
        style={{ color: "white" }}
        onClick={() => {
          setValue("email", "");
          setOpenAlreadyAUserModal(false);
        }}
      >
        Change email
      </Button>
    </>
  );

  return (
    <Card className={classes.box} p={3} shadow="md">
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <MDBox p={5}>
        <form onSubmit={handleSubmit(createApplicationHandler)}>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDBox mt={1}>
                  <MDBox mt={1}>
                    <FormField
                      key={`firstName_${job?.slug}`}
                      type="text"
                      label="First Name"
                      placeholder="First Name (Required)"
                      {...firstName}
                    />
                    {errors?.firstName && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.firstName.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`lastName${job?.slug}`}
                      type="text"
                      label="Last Name"
                      placeholder="Last Name (Required)"
                      {...lastName}
                    />
                    {errors?.lastName && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.lastName.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      key={`emailAddress_${job?.slug}`}
                      type="text"
                      label="Email Address"
                      placeholder="Email Address (Required)"
                      onBlur={() => trigger("email")}
                      {...email}
                    />
                    {errors?.email && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.email.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <FormField
                      {...phoneNumber}
                      key={`phoneNumber_${job?.slug}`}
                      label="Phone Number"
                      placeholder="Phone Number (Required)"
                      onChange={(e) => {
                        setValue("phoneNumber", e.target.value.trim());
                      }}
                    />
                    {errors?.phoneNumber && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.phoneNumber.message}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={1}>
                    <MDBox mt={1}>
                      <Controller
                        {...howDidYouHearAbout}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={howDidYouHear}
                            name="howDidYouHear"
                            onChange={(e, v) => {
                              field.onChange(v);
                            }}
                            renderInput={(params) => (
                              <FormField
                                key={`state_${job?.slug}`}
                                {...params}
                                type="text"
                                label="How did you hear about us"
                              />
                            )}
                          />
                        )}
                      />
                    </MDBox>
                    <MDBox mt={5}>
                      <MDTypography variant="body2">Resume upload</MDTypography>
                      <FileDropzone
                        disabled={resumeDisabled || errors?.email}
                        message="Drop files here to upload"
                        className={classes.dropzone}
                        onDrop={onDropHandler}
                        avatar={resumeExtension && avatar}
                      />
                    </MDBox>
                    <MDBox mt={3}>
                      <MDButton
                        type="submit"
                        variant="contained"
                        color="error"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        APPLY
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </form>
      </MDBox>
      <ThanksForApplyingModal
        applicantCode={applicantCode}
        open={openThanksForApplyingModal}
        setOpen={setOpenThanksForApplyingModal}
      />
      <GenericModal
        open={openAlreadyAUserModal}
        setOpen={setOpenAlreadyAUserModal}
        header={modalHeader}
        body={modalBody}
        buttons={modalButtons}
      />
    </Card>
  );
};

export default Apply;
