import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import ConfirmDialog from "components/ConfirmDialog";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Icon from "@mui/material/Icon";

// NewProduct page components
import { Switch, Button, Stack, Alert, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import states from "assets/stateCodes.json";
import FormField from "layouts/applications/wizard/components/FormField";

import { NumericFormat } from "react-number-format";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs"
import generateSlug from "utils/generateSlug";
import JobsModal from "../JobsModal";
import updateJob from "../../actions/updateJob";
import createJob from "../../actions/createJob";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  previewButton: {
    padding: 0,
  },
  editor: {
    height: 150,
  },
  error: {
    fontSize: "0.75rem"
  }
});

function JobsInformation({ mode = "edit", open, setOpen, setJobPreview, deleteJob }) {
  const { currentJob, setCurrentJob, currentLoggedUser, venues, userType } = useAppContextController();
  const navigate = useNavigate();

  const [disableSlug, setDisableSlug] = useState(true);
  const [jobVenue, setJobVenue] = useState(null);
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [isModified, setIsModified] = useState(false);

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const resetDeleteConfirmModal = () => {
    setDeleteConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const defaultValues = {
    jobSlug: "",
    title: "",
    venueName: null,
    venueSlug: "",
    status: "Active",
    venueThumb: "Yes",
    googleMap: "Yes",
    hideThisJob: "No",
    websitePosting: "No",
    payRate: 0,
    hidePayRate: "No",
    billRate: 0,
    hideBillRate: "No",
    description: "",
    bannerFileName: "",
    jobId: "",
    postToIndeed: "Yes",
    jobLogo: "No"
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({ mode: "onBlur", defaultValues: (mode === "edit") ? currentJob : defaultValues });

  const venueNamesList = venues ? Object.keys(venues).map((key) => ({ "label": venues[key].name, "id": venues[key].slug })) : [];
  const venueName = { ...register("venueName", { required: "Venue is required" }) };
  const venueSlug = register("venueSlug");

  const jobSlug = {
    ...register("jobSlug", {
      required: 'Job URL is required',
      // eslint-disable-next-line
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      validate: {
        // eslint-disable-next-line
        // lowerCase: (value) => value && /^[a-z0-9][a-z0-9]+$/.test(value) || "Only lowercase letters are allowed",
        // // eslint-disable-next-line
        // startNumber: (value) => value && /^[a-zA-Z][a-zA-Z0-9.,$;]+$/.test(value) || "the slug can't start with a number",
        repeated: async (value) => {
          if (mode === "create") {
            const unique = await fetchJobs({ filters: { "jobSlug": value }, fetchAll: true })
            return !(unique?.data) || "Job URL already exists"
          }
          return null
        },
      },
    }),
  };

  const title = {
    ...register("title", {
      required: 'Name is required',
      // eslint-disable-next-line
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      validate: {
        // eslint-disable-next-line
        // eslint-disable-next-line
        // startNumber: (value) => value && /^[a-zA-Z][a-zA-Z0-9.,$;]+$/.test(value) || "the Job Title can't start with a number",
        repeated: (value) => (/^[a-zA-Z\s]+$/.test(value) || "Invalid Job Name")
      }
    })
  };

  const payRate = register("payRate");
  const jobId = register("jobId");

  const postToIndeed = register("postToIndeed");
  const jobLogo = register("jobLogo");
  const venueThumb = register("venueThumb");
  const googleMap = register("googleMap");
  const hideThisJob = register("hideThisJob");
  const description = register("description");
  const queryClient = useQueryClient();

  const updateJobMutation = useMutation(updateJob, {
    onError: (err) => setToastAlert({ isShow: true, message: `Something went wrong!  ${err.toString()}`, status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("jobs");
      setCurrentJob({ ...currentJob, ...data });
      setToastAlert({ isShow: true, message: "Job has been updated!", status: "success" });
    },
  });

  const createJobMutation = useMutation(createJob, {
    onError: (err) => setToastAlert({ isShow: true, message: `Something went wrong!  ${err.toString()}`, status: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("jobs")
      setToastAlert({ isShow: true, message: "Job has been created!", status: "success" })
      setCurrentJob({ ...currentJob, ...data, _id: _.insertedId, [_.id.name]: _.id[_.id.name] })
      navigate(`/jobs/${data?.jobSlug}`)
    }
  })

  const classes = useStyle();

  const openModal = () => {
    setJobPreview(currentJob);
    setOpen(!open);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getJobValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentJob?.[key] || "";
    });
    return values;
  };


  const cancelChanges = () => {
    reset(getJobValues());
    setEditorValue(currentJob.description);
    if (venues) {
      Object.keys(venues).forEach((key) => {
        if (venues[key].name === getJobValues().venueSlug) {
          setJobVenue(venues[key])
        }
      }
      )
    }
    setJobVenue()
    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes have been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentJob._id) {
      setToastAlert({ isShow: true, message: "Cannot Update Job -- No ID Present!", status: "error" });
      return;
    }
    await updateJobMutation.mutateAsync({
      _id: currentJob._id,
      data: { ...values, payRate: parseFloat(values.payRate) },
    });
  };

  const createJobHandler = async (values) => {
    await createJobMutation.mutateAsync({ data: { ...values, payRate: parseFloat(values.payRate), createAgent: currentLoggedUser._id } });
  }

  const onCancel = () => {
    setIsModified(false);
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confrim to abandon Changes",
      response: false,
    });
  };


  const onJobNameChange = (v) => {
    if (venues) {
      Object.keys(venues).forEach((key) => {
        if (venues[key].name.trim() === getValues("venueName").trim()) {
          setJobVenue(venues[key])
          setValue("venueSlug", venues[key].slug);
        }

      }
      )
    }
  };
  const onDelete = () => {
    setDeleteConfirmModal({
      isOpen: true,
      title: "Delete Job",
      bodyText: "Please confirm you want to DELETE this Job!",
      response: false,
    });
  };

  const copyJob = () => {

    const newJob = { ...currentJob }
    newJob.title += ' Copy';
    newJob.jobSlug += '-copy';

    setCurrentJob(newJob)
    // reset({ ...defaultValues });
    setEditorValue(newJob.description);
    setIsModified(false);
  }


  useEffect(() => {
    if (currentJob) {
      reset(currentJob, { keepDirty: false, keepDirtyValues: false });
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentJob?.[key] || "");
      });
      if (venues) {
        Object.keys(venues).forEach((key) => {
          if (venues[key].slug === currentJob.venueSlug) {
            setJobVenue(venues[key])
          }
        })
      }
      setEditorValue(currentJob.description);
      setIsModified(false);
    }
  }, [currentJob]);




  useEffect(() => {
    if (mode === "edit") {
      if (currentJob) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentJob[key] || "");
        });
        if (venues) {
          Object.keys(venues).forEach((key) => {
            if (venues[key].slug === currentJob.venueSlug) {
              setJobVenue(venues[key])
            }
          })
        }
        setEditorValue(currentJob.description);
        setIsModified(false);
      }

      const subscription = watch((value) => {
        if (currentJob) {
          setIsModified(JSON.stringify(getJobValues()) !== JSON.stringify(value));
        }
      });

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
    return null;

  }, [watch, currentJob, confirmModal.response]);
  // Executes on modal response!
  useEffect(() => {
    if (confirmModal.response) {
      resetConfirmModal();
      cancelChanges();
    }
  }, [confirmModal.response]);

  useEffect(() => {
    clearErrors()
    if (mode === "create") {
      const data = {};
      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentJob(data)
      reset({ ...defaultValues });
      setJobVenue(null)
      setEditorValue(defaultValues.description);
      //      setSlugDisable(false)
    } else {
      //      setSlugDisable(true)
    }
  }, [mode]);


  useEffect(() => {
    const del = async () => {
      const deleteJobTitle = currentJob?.title;
      const res = await deleteJob(currentJob._id, deleteJobTitle);
      if (res.success) {
        setCurrentJob(null);
        navigate(`/jobs`);
      }
    }
    if (deleteConfirmModal.response) {
      resetDeleteConfirmModal();
      del();
    }
  }, [deleteConfirmModal.response])

  // generates the slug
  useMemo(() => {
    if (mode === "create") {
      if (getValues()?.title?.length && getValues()?.venueSlug?.length) {
        (async () => {
          const { slug } = await generateSlug({
            venueSlug: getValues()?.venueSlug, title: getValues().title, slugField: "jobSlug", fetchFunction: fetchJobs
          });
          setDisableSlug(false);
          setValue('jobSlug', slug)
        })();
      }
      else if (!getValues()?.title?.length || !getValues()?.venueSlug?.length) {
        setValue('jobSlug', '')
        setDisableSlug(true);
      }
    }
    else if (mode === "edit") {
      setDisableSlug(true);
    }
  }, [getValues().title, getValues().venueSlug])

  return (
    currentJob && (
      <Card key={currentJob?._id}>
        <Snackbar
          open={toastAlert.isShow}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          key="top-center"
        >
          <Alert
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            // severity={toastAlert.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastAlert.message}
          </Alert>
        </Snackbar>

        <form onSubmit={(mode === "edit") ? handleSubmit(saveChanges) : handleSubmit(createJobHandler)}>
          <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
            <MDBox display="flex">
              {
                (mode === "create") ?
                  <>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="warning"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      mt={-2}
                    >
                      <Icon fontSize="large">work</Icon>
                    </MDBox>
                  </>
                  :
                  <>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="white"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      mt={-2}
                    >
                      <img
                        src={venues?.[currentJob?.venueSlug]?.logoUrl || `${process.env.REACT_APP_IMAGE_SERVER}/stadiumpeople/SP-App-Store-Icon.png`}
                        width="50"
                        height="50"
                        className={classes.logoImage}
                        alt="Venue Logo"
                      />
                    </MDBox>
                  </>
              }
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {(mode === "create") ? "Create Job" : `Job Information: ${currentJob?.title}`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              {mode === "edit" && !isModified && userType === "Master" &&
                <Button
                  variant="text"
                  endIcon={<DeleteIcon />}
                  onClick={onDelete}
                  style={{ color: "red" }}
                  disabled={isSubmitting}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Delete Job
                </Button>
              }
              {(isModified || mode === "create") && (
                <MDBox display="flex" alignItems="center">
                  <Button
                    variant="text"
                    startIcon={<CancelIcon />}
                    onClick={onCancel}
                    disabled={isSubmitting}
                  >
                    Discard
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </MDBox>
              )}
              {mode === "edit" && !isModified && (
                <MDBox display="flex" alignItems="center">
                  <Button
                    variant="text"
                    startIcon={<ContentCopyIcon />}
                    onClick={copyJob}
                    disabled={isSubmitting}
                  >
                    Copy Job
                  </Button>
                </MDBox>
              )}
            </MDBox>
          </MDBox>

          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <MDBox mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={7}>

                      <FormField
                        disabled={mode === "create" && !(getValues()?.venueSlug?.length)}
                        inputProps={(mode === "create" && !(getValues()?.venueSlug?.length)) ? { tabIndex: -1 } : {}}
                        key={`jobtitle_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                        type="text"
                        label="Name"
                        {...title}
                      />
                      {errors?.title && <MDTypography className={classes.error} color="error">{errors?.title.message}</MDTypography>}

                      <MDBox mt={2}>
                        <Controller
                          {...venueName}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              options={venueNamesList}
                              disabled={mode === "edit"}
                              inputProps={mode === "edit" ? { tabIndex: -1 } : {}}
                              tabIndex="-1"
                              name="venueSlug"
                              value={field.value || ""}
                              onChange={(e, v) => {
                                field.onChange(e.target.innerText);
                                onJobNameChange(v.label);
                              }}
                              renderInput={(params) => (
                                <FormField
                                  key={`venueSlug_${currentJob?.venueSlug}`}
                                  {...params}
                                  type="text"
                                  label="Venue Name" />
                              )}
                            />
                          )
                          }
                        />
                      </MDBox>
                      <MDBox mt={2}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item sm={6}>
                            <MDBox>
                              <Controller
                                {...payRate}
                                key={`payRate_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                                control={control}
                                render={({ field }) => (
                                  <NumericFormat
                                    InputProps={{
                                      startAdornment: "$",
                                      inputProps: {
                                        inputMode: 'numeric',
                                      }
                                    }}
                                    label="Pay Rate"
                                    decimalScale={2}
                                    {...field}
                                    customInput={FormField}
                                  />
                                )}
                              />
                              {errors?.payRate && <MDTypography className={classes.error} color="error">{errors?.payRate.message}</MDTypography>}
                            </MDBox>
                          </Grid>

                          <Grid item sm={6}>
                            <MDBox>
                              <FormField
                                disabled
                                inputProps={{ tabIndex: -1 }}
                                key={`jobId_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                                type="text"
                                label="Job Id"
                                {...jobId}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <Stack mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4} alignItems="center">
                            <MDBox>
                              <FormField type="text"
                                disabled
                                inputProps={{ tabIndex: -1 }}
                                label="City"
                                value={jobVenue?.city || ""}
                                InputLabelProps={{ shrink: jobVenue?.city }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDBox mb={3}>
                              <FormField type="text"
                                disabled
                                inputProps={{ tabIndex: -1 }}
                                label="State"
                                value={jobVenue?.state || ""}
                                InputLabelProps={{ shrink: jobVenue?.state }}
                              />

                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={4} alignItems="center">
                            <MDBox>
                              <FormField
                                disabled
                                inputProps={{ tabIndex: -1 }}
                                key={`venueHelmID_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                                type="text"
                                label=" Venue HelmID"
                                value={jobVenue?.venueHelmID || ""}
                                InputLabelProps={{ shrink: jobVenue?.venueHelmID }}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <MDBox>
                        <FormField
                          disabled={disableSlug || mode === "edit"}
                          inputProps={(disableSlug || mode === "edit") ? { tabIndex: -1 } : {}}
                          key={`jobSlug_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                          type="text"
                          label="Job URL"
                          InputLabelProps={{ shrink: getValues().jobSlug }}
                          {...jobSlug}
                        />
                        {errors?.jobSlug && <MDTypography className={classes.error} color="error">{errors?.jobSlug.message}</MDTypography>}
                      </MDBox>

                      <Grid item xs={12} sm={12} mt={2}>
                        <Stack
                          my={0.5}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="body2">Post on Indeed</MDTypography>
                          <Controller
                            {...postToIndeed}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                        <Stack
                          my={0.5}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="body2">Job Logo</MDTypography>
                          <Controller
                            {...jobLogo}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                        <Stack
                          my={0.5}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="body2">Venue Banner</MDTypography>
                          <Controller
                            {...venueThumb}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                        <Stack
                          my={0.5}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="body2">Google Map</MDTypography>
                          <Controller
                            {...googleMap}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                disabled
                                inputProps={{ tabIndex: -1 }}
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                        <Stack
                          my={0.5}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="body2">Hide this Job</MDTypography>
                          <Controller
                            {...hideThisJob}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>



              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MDTypography component="label" variant="button" fontWeight="regular">
                    JOB DESCRIPTION
                  </MDTypography>
                  <Button className={classes.previewButton} onClick={openModal}>
                    Preview
                  </Button>
                </Stack>

                <MDBox height="100%">
                  <MDEditor
                    className={classes.editor}
                    {...description}
                    value={editorValue || ""}
                    onChange={(e) => {
                      setEditorValue(e);
                      setValue("description", e);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox >
        </form >
        {/* {JSON.stringify(data)} */}

        <ConfirmDialog state={deleteConfirmModal} setState={setDeleteConfirmModal} />
        <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
      </Card >
    )
  );
}

export default JobsInformation;

// // Setting default values for the props of BaseLayout
// JobsInformation.defaultProps = {
//   pageName: "Jobs",
// };

// // Typechecking props for BaseLayout
// JobsInformation.propTypes = {
//   pageName: PropTypes.string,
// };
