import axios from "axios";

import { API_URL } from "config";

const fetchPartners = async (page, limit) => {
  const res = await axios.get(`${API_URL}/partners?page=${page}&limit=${limit}`);
  return res.data;
};

export default fetchPartners;
