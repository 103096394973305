import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/applications/wizard/components/FormField";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Icon, Stack, IconButton, Button } from "@mui/material";
import { DateRange, Description, Email, Stadium } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles({
  stack: {
    margin: 10,
  },
  box: {
    border: "1px solid",
  },
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },

  cardBox: {
    overflow: "visible",
  },
  button: {
    margin: `${pxToRem(2)}`,
  },
});

const Personal = ({ currentApplicant }) => {
  const classes = useStyle();
  const navigate = useNavigate();

  const [isModified, setIsModified] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const defaultValues = {
    applyDate: "",
    firstName: "",
    lastName: "",
    middleInitial: "",
    maidenName: "",
    socialSecurity: "",
    birthDate: "",
    driverLicense: "",
    streetAddress: "",
    unitNumber: "",
    city: "",
    state: null,
    zip: "",
    phone: "",
    alternatePhone: "",
    emergencyContactNumber: "",
    emergencyContactName: "",
    criminalHistoryDisclosure: "",
  };

  const applyDate = register("applyDate");
  const firstName = register("firstName");
  const lastName = register("lastName");
  const middleInitial = register("middleInitial");
  const maidenName = register("maidenName");
  const socialSecurity = register("socialSecurity");
  const birthDate = register("birthDate");
  const driverLicense = register("driverLicense");
  const streetAddress = register("streetAddress");
  const unitNumber = register("unitNumber");
  const city = register("city");
  const state = register("state");
  const zip = register("zip");
  const phone = register("phone");
  const alternatePhone = register("alternatePhone");
  const emergencyContactNumber = register("emergencyContactNumber");
  const emergencyContactName = register("emergencyContactName");
  const criminalHistoryDisclosure = register("criminalHistoryDisclosure");

  const getApplicantPersonalValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentApplicant?.[key] || "";
    });
    return values;
  };

  useEffect(() => {
    if (currentApplicant) {
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentApplicant[key] || "");
      });
      setIsModified(false);
    }
    const subscription = watch((value) => {
      if (currentApplicant) {
        setIsModified(JSON.stringify(getApplicantPersonalValues()) !== JSON.stringify(value));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, currentApplicant]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...applyDate}
            key={`applicationDate_${currentApplicant?.venueSlug}`}
            label="Application Date"
            type="date"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...firstName}
            key={`firstName_${currentApplicant?.venueSlug}`}
            label="First Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...middleInitial}
            key={`middelInitial_${currentApplicant?.venueSlug}`}
            label="Middle Initial"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...lastName}
            key={`lastName_${currentApplicant?.venueSlug}`}
            label="Last Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...maidenName}
            key={`maidenName_${currentApplicant?.venueSlug}`}
            label="Maiden Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...socialSecurity}
            key={`socialSecurity_${currentApplicant?.venueSlug}`}
            label="Social Security"
            type="number"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...birthDate}
            key={`dateOfBirth_${currentApplicant?.venueSlug}`}
            label="Date of Birth"
            type="date"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...driverLicense}
            key={`driversLicense_${currentApplicant?.venueSlug}`}
            label="Driver License"
            type="number"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4}>
          <FormField
            {...streetAddress}
            key={`streetAdress_${currentApplicant?.venueSlug}`}
            label="Street Address"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2} xl={2}>
          <FormField
            {...unitNumber}
            key={`unitNumber_${currentApplicant?.venueSlug}`}
            label="Unit#"
            type="number"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2} xl={2}>
          <FormField
            {...city}
            key={`city_${currentApplicant?.venueSlug}`}
            label="City"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2} xl={2}>
          <FormField
            {...state}
            key={`state_${currentApplicant?.venueSlug}`}
            label="State"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2} xl={2}>
          <FormField
            {...zip}
            key={`zipCode_${currentApplicant?.venueSlug}`}
            label="Zip Code"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...phone}
            key={`mobileNumber_${currentApplicant?.venueSlug}`}
            label="Mobile Number"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...alternatePhone}
            key={`alternateNumber_${currentApplicant?.venueSlug}`}
            label="Alternate Number"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...emergencyContactNumber}
            key={`emergencyContactNum_${currentApplicant?.venueSlug}`}
            label="Emergency Contact Num"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3} xl={3}>
          <FormField
            {...emergencyContactName}
            key={`emergencyContactName_${currentApplicant?.venueSlug}`}
            label="Emergency Contact Name"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={12} xl={12}>
          <FormField
            {...criminalHistoryDisclosure}
            key={`criminalHistoryDisclosure_${currentApplicant?.venueSlug}`}
            label="Criminal History Disclosure"
            type="text"
            variant="filled"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Personal;
