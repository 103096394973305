import axios from "axios";

import { API_URL } from "config";

const createUser = async (data) => {
  const res = await axios.post(`${API_URL}/users`, data);
  return res.data;
};

export default createUser;
