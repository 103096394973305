// Creates an optionally unique slug for jobs, events, etc.
const generateSlug = async ({ venueSlug = "choctaw", title = "", suffix = "", mustBeUnique = true, slugField, fetchFunction }) => {
  // console.log('generateSlug 1', venueSlug, title, suffix, mustBeUnique, slugField)
  const useVenueSlug = !(title && title.trimStart().toLowerCase().substring(0, venueSlug.length) === venueSlug?.toLowerCase());
  const slugToTry = `${(useVenueSlug && venueSlug) ? `${venueSlug.toLowerCase()}-` : ''}${title.trimStart().toLowerCase().replaceAll('  ', ' ').replaceAll(' ', '-')}${(suffix && !title.toLowerCase().includes(suffix.toLowerCase()) ? `-${suffix.toLowerCase()}` : '')}`
  // console.log('generateSlug 2', slugToTry)

  let slug = slugToTry.replace(/&/g, "-and-").replace(/\./g, ' ').replace(/-/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/--/g, '-').replace(/ {2}/g, ' ').replace(/ /g, '-').replace(/--/g, '-');
  try {

    const result = await fetchFunction({ page: 1, limit: 1, filters: { [slugField]: slug } })
    if (!result?.data || !result?.data.length) return { unique: true, slug }

    slug = `${slugToTry}-2`;
    const result2 = await fetchFunction({ page: 1, limit: 1, filters: { [slugField]: slug } })
    if (result2 && result2.message === "Request failed with status code 404") { return { unique: true, slug } }
    return { unique: false, slug }

  } catch (err) {
    return { unique: true, slug }
  }

}

export default generateSlug;
