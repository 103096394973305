import FilterCardBar from "components/FilterCardBar";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

const TimeRosterFilterCards = ({ setFilters, setPage }) => {
  const cards = [
    {
      color: "info",
      icon: <InfoIcon fontSize="large" color="info" />,
      title: "All",
      number: 0,
      filterField: "filt",
      filterValue: "All",
    },
    {
      color: "success",
      icon: <CheckCircleIcon fontSize="large" color="success" />,
      title: "On Roster",
      number: 0,
      filterField: "filt",
      filterValue: "Roster",
    },
    {
      color: "warning",
      icon: <CheckCircleIcon fontSize="large" color="warning" />,
      title: "Request",
      number: 0,
      filterField: "filt",
      filterValue: "Request",
    },
    {
      color: "secondary",
      icon: <HourglassTopIcon fontSize="large" color="secondary" />,
      title: "Waitlist",
      number: 0,
      filterField: "filt",
      filterValue: "Waitlist",
    },
    {
      color: "error",
      icon: <CancelIcon fontSize="large" color="error" />,
      title: "Not on Roster",
      number: 0,
      filterField: "filt",
      filterValue: "Not Roster",
    },
  ];

  const setTimeFilters = (filter) => setFilters(filter);

  return (
    <FilterCardBar
      cards={cards}
      bgColor="primary"
      setFilters={setTimeFilters}
      // setPage={setPage}
    />
  );
};

export default TimeRosterFilterCards;
