import * as yup from "yup";

export const directDepositSchema = yup.object().shape({
  account1: yup.string().required("Bank One Account Number is required"),
  accountType1: yup.string().default("").required("Bank One Account Type is required"),
  amountPercentage1: yup.string().default("").required("Bank One Account % is required"),
  bankName1: yup.string().default("").required("Bank One Bank Name"),
  routing1: yup.string().default("").required("Bank One Routing # is required"),
  bankName2: yup.string(),
  routing2: yup.string().when("bankName2", {
    is: (val) => val?.length,
    then: yup.string().required("Bank Two Routing # is required"),
    otherwise: yup.string().notRequired(),
  }),
  account2: yup.string().when(["bankName2", "routing2"], {
    is: (bankName, routing) => bankName?.length || routing?.length,
    then: yup.string().required("Bank Two Account # is required"),
    otherwise: yup.string().notRequired(),
  }),
  accountType2: yup.string().when(["bankName2", "routing2", "account2"], {
    is: (bankName, routing, account) => bankName?.length || routing?.length || account?.length,
    then: yup.string().required("Bank Two Account Type is required"),
    otherwise: yup.string().notRequired(),
  }),
  amountPercentage2: yup.string().when(["bankName2", "routing2", "account2", "accountType2"], {
    is: (bankName, routing, account, accountType) =>
      bankName?.length || routing?.length || account?.length || accountType?.length,
    then: yup.string().required("Bank Two Account % is required"),
    otherwise: yup.string().notRequired(),
  }),
  date: yup.date().default(new Date()),
  checkStubOptions: yup.string().default("").required("Please select Stub Options"),
  paymentType: yup.string().default("").required("Please select Payment Type"),
  // email: yup.string().default("").required("Email is required"),
  // employeeName: yup.string().default("").required("Name is required"),
  // socialSecurity: yup.string().default("").required("Social Security is required"),
});
