import { Box, Paper, Typography } from "@mui/material"

const MessageSection = () => {
  return (
    <Paper elevation={3} sx={{ px: "60px", py: "30px", mt: '20px' }}>
      <Box display="flex" flexDirection="column" rowGap="10px">
        <Typography fontWeight={700} fontSize="18px">Welcome to Stadium People. You are just a few steps away from working your first event!</Typography>
        <Typography fontSize="14px" fontWeight={500} color="#939393">To complete the hiring process and being working events, you must complete the forms below and complete a background check. You may save your progress along the way and come back to the portal to complete the process, and upon your next login you will be required to provide a two-step verification to protect your security.</Typography>
        <Typography fontSize="14px" fontWeight={500} color="#939393">if you have any questions along the way, you may contact your recruiter by phone or email our payroll department for assistance by clicking here.</Typography>
      </Box>
    </Paper>
  )
}

export default MessageSection