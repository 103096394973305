import { ArrowBack, ArrowForward, Save, Settings } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";

import CustomCard from "components/CustomCard";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useEffect, useMemo } from "react";
import NewApplicantForms from "../NewApplicantForms";

const FormContainer = ({ onboardingStep = 1 }) => {
  const {
    onNextStep,
    onPreviousStep,
    applicant,
    getActiveRegistrationStep,
    activeStepId,
    registrationSteps,
  } = useNewApplicantContext();

  useEffect(() => {
    console.log(applicant, "from Parent component, FormContainer");
  }, [applicant]);

  const activeStep = useMemo(() => getActiveRegistrationStep(), [getActiveRegistrationStep]);

  return (
    <CustomCard
      icon={activeStep.icon}
      cardSx={{ mt: "35px" }}
      cardTitle={activeStep.label}
      cardActions={
        <FlexWrapper justifyContent="end">
          <Button
            form="current-form"
            type="submit"
            variant="contained"
            color="primary"
            sx={(theme) => ({
              mr: "15px",
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            })}
            endIcon={<Save />}
          >
            Save
          </Button>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("lg")]: {
                mt: "20px",
                display: "flex",
                width: "100%",
              },
            })}
          >
            {onboardingStep !== 0 && (
              <Button
                type="button"
                variant="contained"
                color="error"
                sx={(theme) => ({
                  mr: "5px",
                  [theme.breakpoints.down("lg")]: {
                    display: "flex",
                    flex: 1,
                  },
                })}
                startIcon={<ArrowBack />}
                onClick={onPreviousStep}
                disabled={activeStepId <= 1}
              >
                Previous
              </Button>
            )}
            <Button
              type="button"
              variant="contained"
              color="success"
              endIcon={<ArrowForward />}
              onClick={onNextStep}
              sx={(theme) => ({
                [theme.breakpoints.down("lg")]: {
                  display: "flex",
                  flex: 1,
                },
              })}
              disabled={activeStepId >= registrationSteps.length}
            >
              Next
            </Button>
          </Box>
        </FlexWrapper>
      }
    >
      <Box sx={{ border: "1.5px solid black", p: "10px", position: "relative" }}>
        <IconButton
          aria-label="settings"
          sx={{
            position: "absolute",
            bottom: "-20px",
            right: "-20px",
            background: "white",
          }}
        >
          <Settings />
        </IconButton>
        <NewApplicantForms />
        <Button
          form="current-form"
          type="submit"
          variant="contained"
          color="primary"
          sx={(theme) => ({
            mt: "20px",
            float: "right",
            [theme.breakpoints.up("lg")]: {
              display: "none",
            },
            [theme.breakpoints.down("md")]: {
              display: "flex",
              width: "100%",
            },
          })}
          endIcon={<Save />}
        >
          Save
        </Button>
      </Box>
    </CustomCard>
  );
};

export default FormContainer;
