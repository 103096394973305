
import { useEffect } from "react"
import { useAppContextController } from "context/AppContext";
import { useNavigate } from "react-router-dom";
import MDBox from 'components/MDBox'
import DataList from "components/DataList"
import { makeStyles } from "@mui/styles";
import { Card } from '@material-ui/core'
import { Icon, Stack, Button, Grid, IconButton } from "@mui/material";
import { useQuery, useMutation } from "react-query";
import useSort from "utils/useSort";
import { ArrowDropDown } from "@mui/icons-material";
import fetchNotifications from "layouts/pages/dashboard/actions/fetchNotifications";
import Notification from "./components/Notification";

const useStyle = makeStyles({
    card: {
        height: 350,
    },
    stack: {
        padding: 10,
    },
    editor: {
        height: 100,
    },
    box: {
        overflow: "visible",
    },
    button: {
        fontSize: "40px!important",
    }
});

const MyNotifications = ({ fetchAll, currentLoggedUser, filters }) => {
    const classes = useStyle();
    const navigate = useNavigate();
    const { setUnreadNotifications } = useAppContextController();
    const options = fetchAll ? { fetchAll } : { orderBy: "sendTime", order: "desc" };
    // console.log('currentLoggedUser', currentLoggedUser);
    const allOptions = { ...options, filters: { ...filters, userId: currentLoggedUser?._id }, setUnreadNotifications };
    const { data: notifications, isLoading } = useQuery(["notifications", allOptions], () => fetchNotifications(allOptions));
    const viewAll = () => navigate(`/notifications/userId/${currentLoggedUser._id}`);
    const renderRow = (row) => <Notification row={row} />

    const header = (
        <Stack>
            <Grid mt={2}>
                <Button variant="text" color="primary" onClick={viewAll}>
                    View All
                </Button>
                |
                <Button variant="text" color="primary" disabled>
                    Add New
                </Button>
            </Grid>
        </Stack>
    )

    return (<>

        <DataList
            renderRow={renderRow}
            data={notifications?.data}
            onRowClick={() => { }}
            icon="notifications"
            iconColor="dark"
            title="My Notifications"
            isLoading={isLoading}
            greybar
            divider
            header={header}
            tableHeight={420}
            height={500}
            scrollY
        />
    </>
    )
}

export default MyNotifications