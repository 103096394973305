// import { TableAction } from "@perimeter-software/react-common";
import { useState, useEffect } from "react";
import { API_URL } from "config";
import { useMutation } from "react-query";
import axios from "axios";

import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";

const EventRosterCheck = ({ id, row, eventApplicants = [], toggleReadRoster }) => {
  //  const status = isOnRoster(id);

  const { currentLoggedUser } = useAppContextController();

  const eaRecord = eventApplicants ? eventApplicants?.find((item) => item.id === id) : null;
  const [status, setStatus] = useState(eaRecord?.status || "Not Roster");
  const roster = (eaRecord && eaRecord.status === "Roster") || false;
  const waitlisted = (eaRecord && eaRecord.status === "Waitlist") || false;
  const requested = (eaRecord && eaRecord.status === "Request") || false;

  useEffect(() => {
    const rec = eventApplicants ? eventApplicants.find((item) => item.id === id) : null;
    if (rec) setStatus(rec.status);
  }, [eventApplicants]);

  const { eventUrl } = useParams();

  const rosterMutation = useMutation(
    async (data) => {
      // eslint-disable-next-line no-unused-expressions
      data.status === "Not Roster"
        ? await axios.delete(`${API_URL}/events/url/${eventUrl}/roster?id=${data.id}`, {
          headers: { Authorization: "***" },
        })
        : await axios.put(`${API_URL}/events/url/${eventUrl}/roster`, { ...data, eventUrl });
    },
    {
      // eslint-disable-next-line no-return-await
      onSuccess: (_, { data }) => {
        console.log('getRoster onSuccess', data)
        toggleReadRoster()
      },
      onError: (error) => alert(error.toString()),
    }
  );

  const statusCycles = ["Roster", "Request", "Waitlist", "Not Roster"];

  const nextStatus = (st) => {
    let ndx = statusCycles.findIndex((item) => item === st);
    if (ndx === -1) ndx = 4;
    const newNdx = (ndx + 1) % 4;
    const newStatus = statusCycles[newNdx];
    return newStatus;
  };
  const setAndRequery = (e, stat) => {
    e.stopPropagation();
    const newStatus = nextStatus(status);
    setStatus(newStatus);

    rosterMutation.mutate({
      id,
      status: newStatus,
      dateModified: new Date(),
      note: "",
      timeIn: null,
      timeOut: null,
      agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
      createAgent: currentLoggedUser?._id,
    });
  };

  const showIcon = () => {
    if (status === "Roster")
      return <CheckIcon key={`venuestatus_${id}`} color="success" fontSize="large" />;
    if (status === "Request")
      return <CheckIcon key={`requested_${id}`} color="warning" fontSize="large" />;
    if (status === "Waitlist")
      return <HourglassTopIcon key={`waitlisted_${id}`} color="secondary" fontSize="large" />;
    return <CancelIcon key={`cancelIcon_${id}`} color="error" fontSize="large" />;
  };

  return (
    <span>
      <MDBox onClick={setAndRequery}>
        <Tooltip placement="right" title={`Set ${row?.firstName} ${row?.lastName} to: ${nextStatus(status)}`}>
          {showIcon()}
        </Tooltip>
      </MDBox>
    </span>
  );
};

export default EventRosterCheck;

EventRosterCheck.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
EventRosterCheck.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
