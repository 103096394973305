import { Avatar, Box, Paper, Step, StepLabel, Stepper, styled, Typography } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { useEffect, useMemo, useState } from "react";

import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useParams } from "react-router-dom";
import { NEW_APPLICANT_STEPS } from "utils/constants/applicant";
import { getApplicantImageSrc } from "utils/helpers/applicant";

const StyledImg = styled("img")(() => ({
  borderRadius: "15px",
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    marginTop: "30px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
  background: "transparent",
  boxShadow: "none",
  "& .MuiStepLabel-iconContainer": {
    "& .MuiBox-root": {
      borderRadius: "100%",
      padding: "10px",
      zIndex: 1,
      backgroundColor: "#cac8c9",
    },
  },
  "& .Mui-active, .Mui-completed": {
    "& .MuiBox-root": {
      backgroundColor: "#ec293b",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#ec293b",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#ec293b",
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#cac8c9",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "red",
    borderRadius: 1,
  },
}));

const NewApplicantHeader = () => {
  const { applicantCode } = useParams();
  const [user, setUser] = useState({ name: "Linda Allen", src: "" });
  const { activeStepId, initializeApplicant, applicant, setActiveStep } = useNewApplicantContext();

  useEffect(() => {
    initializeApplicant(applicantCode);
  }, [applicantCode]);

  const fullName = useMemo(
    () => `${applicant.firstName || ""} ${applicant.lastName || ""}`,
    [applicant]
  );

  return (
    <Box position="relative">
      <StyledImg src="/images/png/applicants/new-applicant-banner.png" alt="" width="100%" />
      <Paper elevation={3} sx={{ px: "40px", py: "20px" }}>
        <FlexWrapper
          alignItems={{ xs: "normal", lg: "center" }}
          justifyContent={{ xs: "center", lg: "space-between" }}
          flexDirection={{ xs: "column", lg: "row" }}
        >
          <FlexWrapper
            columnGap="20px"
            justifyContent={{ xs: "center", md: "flex-start" }}
            sx={(theme) => ({
              flex: "0.3",
              [theme.breakpoints.down("lg")]: {
                flex: 1,
              },
            })}
          >
            <Avatar
              alt={fullName}
              src={getApplicantImageSrc(applicant)}
              sx={{ height: "75px", width: "75px" }}
            />
            <Box>
              <Typography>{fullName}</Typography>
              <Typography>Applicant User</Typography>
            </Box>
          </FlexWrapper>
          <Box
            maxWidth="100%"
            sx={(theme) => ({
              overflowY: "auto",
              flex: 0.7,
              display: "flex",
              [theme.breakpoints.down("lg")]: {
                flex: 1,
              },
            })}
          >
            <StyledStepper
              activeStep={activeStepId - 1}
              alternativeLabel
              connector={<StyledConnector />}
            >
              {NEW_APPLICANT_STEPS.map(({ label, icon, id }) => {
                const isActive = id <= activeStepId;
                return (
                  <Step
                    key={label}
                    sx={{ width: "100%", ...(isActive && { cursor: "pointer" }) }}
                    onClick={() => isActive && setActiveStep(id)}
                  >
                    <Typography
                      align="center"
                      className="MuiStepper-alternativeLabel"
                      color={isActive ? "#ec293b" : "#cac8c9"}
                      variant="subtitle2"
                      fontSize="0.75rem"
                      sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
                    >
                      {label}
                    </Typography>
                    <StepLabel StepIconComponent={() => <FlexWrapper>{icon}</FlexWrapper>} />
                  </Step>
                );
              })}
            </StyledStepper>
          </Box>
        </FlexWrapper>
      </Paper>
    </Box>
  );
};

export default NewApplicantHeader;
