import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const LateralSignUp = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <MDBox px={5}>
      <MDBox my={5} mx={{ xs: 0, sDesktop: 5 }} mb={0}>
        <MDTypography variant="h2" textAlign="center" sx={{ color: "#FFF" }}>
          {" "}
          Sign in today with your Stadium People account.
        </MDTypography>
      </MDBox>
      <MDBox my={5} mx={{ xs: 0, sDesktop: 5 }}>
        <MDTypography fontWeight="medium" variant="h5" textAlign="center" sx={{ color: "#FFF" }}>
          Stadium People employees enjoy the benefits of our new event sign up and time management
          software.
          <br />
          <br />
          Search your favorite venues, clock-in for events, track time and more.
        </MDTypography>
      </MDBox>
      <MDBox m={5} mb={0} display="flex" justifyContent="space-evenly">
        <MDButton size="large" color="info" onClick={() => loginWithRedirect()}>
          Have an account already? sign in now
        </MDButton>
      </MDBox>
      <MDBox display="flex" justifyContent="space-evenly">
        <Link href="/" sx={{ fontSize: "1rem", color: "#FFE338", fontWeight: "800" }}>
          Forgot your Password?
        </Link>
      </MDBox>
      {/* <MDBox m={5} display="flex" justifyContent="space-evenly">
        <MDButton size="large" color="white">
          create my account today
        </MDButton>
      </MDBox> */}
      <MDBox my={5} mx={{ xs: 0, sDesktop: 5 }} px={{ xs: 0, sDesktop: 10 }}>
        <MDTypography variant="h4" textAlign="center" sx={{ color: "#FFF" }}>
          Don&apos;t have the Mobile App on your phone?
        </MDTypography>
      </MDBox>
      <MDBox my={5} mx={{ xs: 0, sDesktop: 5 }} px={{ xs: 0, sDesktop: 10 }}>
        <MDTypography variant="body1" textAlign="center" sx={{ color: "#FFF" }}>
          Download the Stadium People mobile app today to manage event work on the go!
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center">
        <MDBox
          component="img"
          sx={{ height: "4em" }}
          src="/appleStore.png"
          onClick={console.log("Apple Store")}
          my={-8}
        />
        <MDBox
          component="img"
          sx={{ height: "8em" }}
          src="/googlePlay.png"
          onClick={console.log("Google Store")}
          my={-8}
        />
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center" mt={2}>
        <MDBox
          component="img"
          sx={{ height: "4em" }}
          src="/favicon.png"
          onClick={console.log("Stadium People")}
        />
      </MDBox>
      <MDBox my={5} mx={{ xs: 0, sDesktop: 5 }} px={{ xs: 0, sDesktop: 10 }}>
        <MDTypography variant="body2" textAlign="center" sx={{ color: "#FFF" }}>
          Currently providing part-time event gigs in the following areas:
          <br />
          Texas * Oklahoma * Alabama * Georgia * Florida * Michigan
          <br />
          <br />
          More locations coming soon...
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default LateralSignUp;
