import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "react-query";

import axios from "axios";
import colors from "assets/theme/base/colors";
import { useAppContextController } from "context/AppContext";
import states from "assets/stateCodes.json";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
// @mui material components
import { Grid, Card, Stack, Button, Autocomplete, Alert, Snackbar, Avatar, Icon, Checkbox } from "@mui/material";
import LandscapeIcon from "@mui/icons-material/Landscape";
// Material Dashboard 2 PRO React components
import FileDropzone from "components/Dropzone";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import { makeStyles } from "@mui/styles";
import FormField from "layouts/applications/wizard/components/FormField";
import pxToRem from "assets/theme/functions/pxToRem";
import userTypes from "assets/userTypes.json";
import { API_URL, IMAGE_SERVER } from "config";

const useStyle = makeStyles({
    logoImage: {
        objectFit: "contain",
    },
    editor: {
        height: 170,
    },
    dropzone: {
        minHeight: 70,
    },
    previewButton: {
        padding: 0,
    },
    words: {
        wordWrap: "break-word",
    },
    input: {
        fontSize: `${pxToRem(16)}`,
        backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
        border: "solid 1px black",
        borderRadius: "10px",
    },
    error: {
        fontSize: "0.75rem",
    }
});

const ChangeMyW4 = ({ setCurrentApplicant, currentApplicant, userPage, setOpen }) => {
    const classes = useStyle();
    const [toastAlert, setToastAlert] = useState({
        isShow: false,
        message: "",
    });
    const [isModified, setIsModified] = useState(false);

    // If there is no current user, let's route the user to login
    const navigate = useNavigate();
    const { currentLoggedUser, setCurrentLoggedUser, isLoggedIn } = useAppContextController();

    // Confirm Modal
    const [confirmModal, setConfirmModal] = useState({
        modalFor: "",
        isOpen: false,
        title: "",
        bodyText: "",
        response: false,
    });

    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        setValue,
        control,
        watch,
        getValues,
        trigger,
        formState: { errors, isSubmitting },
    } = useForm({ mode: "onBlur" });

    const defaultValues = {
        firstName: "",
        lastName: "",
        streetAddress: "",
        unit: "",
        city: "",
        state: null,
        zip: "",
        singleMarriedorHeadOfHousehold: "single"

    };


    const firstName = register("firstName");
    const lastName = register("lastName");
    const streetAddress = register("streetAddress");
    const unit = register("unit");
    const city = register("city");
    const state = {
        ...register("state", {
            validate: {
                exist: (value) => {
                    return states.includes(value) || value === "" || value === null || "Insert a valid state";
                },
            },
        }),
    };
    const zip = {
        ...register("zip", {
            // eslint-disable-next-line
            pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Your zip number is invalid" },
        }),
    };
    const singleMarriedorHeadOfHousehold = register("singleMarriedorHeadOfHousehold");


    const handleSingleMarriedChange = (e) => {
        setValue("singleMarriedorHeadOfHousehold", e.target.id);
    };


    return (
        <>
            <Card className={classes.cardBox}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <MDTypography variant="h5">
                        CHANGE MY W-4 WITHHOLDINGS
                    </MDTypography>
                    {/* {currentApplicant && (
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 2, ml: 2 }}>
                                                {`${currentApplicant?.lastName} ${currentApplicant?.firstName}`}
                                            </MDTypography>
                                            <Button>{`${currentApplicant?.status}`}</Button>
                                            <MDTypography variant="h6" color="dark" sx={{ mt: 3, mb: 1, ml: 2 }}>
                                                {(currentApplicant.modifiedDate) &&
                                                    `Last Updated: ${new Date(
                                                        currentApplicant?.modifiedDate
                                                    ).toLocaleDateString()} ${new Date(
                                                        currentApplicant?.modifiedDate
                                                    ).toLocaleTimeString()}`
                                                }
                                            </MDTypography>
                                        </Stack>
                                    )
                                    } */}

                    <MDBox display="flex" >
                        <Button
                            variant="text"
                            startIcon={<CancelIcon />}
                            // onClick={onCancel}
                            disabled={isSubmitting}
                            sx={{ padding: "0.5rem 1rem" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<SaveIcon />}
                            style={{ color: "white" }}
                            disabled={isSubmitting}
                            sx={{ padding: "0.5rem 1rem" }}
                        >
                            Save
                        </Button>
                    </MDBox>
                </MDBox>
                <MDBox p={2}>

                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    {...firstName}
                                    key={`firstName_${currentApplicant?.venueSlug}`}
                                    label="First Name"
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    {...lastName}
                                    key={`lastName_${currentApplicant?.venueSlug}`}
                                    label="Last Name"
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    {...streetAddress}
                                    key={`streetAddress_${currentApplicant?.venueSlug}`}
                                    label="Street Address"
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    {...unit}
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    label="Unit#"
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    {...city}
                                    key={`city_${currentApplicant?.venueSlug}`}
                                    label="City"
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Controller
                                    {...state}
                                    control={control}
                                    defaultValue={currentApplicant?.state}
                                    render={({ field }) => (
                                        <Autocomplete
                                            key={`autocomplete_state_${currentApplicant?._id}`}
                                            options={states}
                                            autoSelect
                                            freeSolo
                                            name="state"
                                            variant="filled"
                                            defaultValue={null}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value.toUpperCase());
                                            }}
                                            onBlur={() => trigger("state")}
                                            renderInput={(params) => (
                                                <FormField
                                                    key={`state_${currentApplicant?._id}`}
                                                    {...params}
                                                    variant="filled"
                                                    type="text"
                                                    label="State"
                                                    InputProps={{
                                                        className: classes.input,
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                {errors?.state && (
                                    <MDTypography className={classes.error} color="error">
                                        {errors?.state.message}
                                    </MDTypography>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    key={`zipCode_${currentApplicant?._id}`}
                                    label="Zip Code"
                                    variant="filled"
                                    type="text"
                                    {...zip}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                                {errors?.zip && (
                                    <MDTypography className={classes.error} color="error">
                                        {errors?.zip.message}
                                    </MDTypography>
                                )}
                            </Grid>
                        </Grid>
                        <Controller
                            {...singleMarriedorHeadOfHousehold}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Checkbox
                                            id="single"
                                            checked={field.value === "single"}
                                            icon={<CircleOutlinedIcon />}
                                            checkedIcon={<CircleIcon />}
                                            onChange={(e, v) => handleSingleMarriedChange(e)}
                                        />
                                        <MDTypography fontWeight="bold" variant="body2"> Single or Married filing separately</MDTypography>
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Checkbox
                                            id="married"
                                            checked={field.value === "married"}
                                            icon={<CircleOutlinedIcon />}
                                            checkedIcon={<CircleIcon />}
                                            onChange={(e, v) => handleSingleMarriedChange(e)}
                                        />
                                        <MDTypography fontWeight="bold" variant="body2">Married filing jointly (or Qualifying widow(er))</MDTypography>
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Checkbox
                                            id="headHousehold"
                                            checked={field.value === "headHousehold"}
                                            icon={<CircleOutlinedIcon />}
                                            checkedIcon={<CircleIcon />}
                                            onChange={(e, v) => handleSingleMarriedChange(e)}
                                        />
                                        <MDTypography fontWeight="bold" variant="body2">Head of household (Check only if you&apos;re unmarried and pay more than half the costs of keeping up home for yourself and a qualifying individual) </MDTypography>
                                    </Stack>

                                </>
                            )
                            }
                        />
                        <MDTypography variant="h6"> Step 3: Claim Dependents</MDTypography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="body2">Multiply the number of qualifying children under age 17 by $2000</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="body2">Multiply the number of other dependents by $500</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="body2">Add the amounts above and enter the total here</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <MDTypography variant="h6"> Step 4(optional): Other Adjustments</MDTypography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="body2">(a) Other income (not form jobs). If you want tax withheld for other income you expect this year that won´t have withholding, enter the amount of other income here. This may include interest, dividends, and retirement income</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="body2">(b) Deductions. If you expect to claim deductions other than the standard deduction and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter the result here</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="body2">(c) Extra withholding. Enter any additional tax you want withheld each pay period</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    key={`unit_${currentApplicant?.venueSlug}`}
                                    type="text"
                                    variant="filled"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </MDBox>
            </Card >
        </>
    )
}

export default ChangeMyW4