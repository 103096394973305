const IMG_SERVER_URL = `${
  process.env.REACT_APP_IMAGE_SERVER || "http://images.stadiumpeople.com/sp"
}`;

export const getActiveFilterTitle = (filter) => {
  const filterList = {
    "Not Roster": "Not on Roster",
    Waitlist: "Waitlist",
    Request: "Request",
    Roster: "On Roster",
    All: "All",
  };
  return filterList[filter] || null;
};

export const filterApplicants = ({ applicants = [], filter, rosterIds }) => {
  let filteredApplicants = [];
  switch (filter) {
    case "Not Roster":
      filteredApplicants = applicants.filter((item) => !rosterIds.includes(item._id));
      break;
    case "Waitlist":
    case "Request":
      break;
    case "Roster":
      filteredApplicants = applicants.filter((item) => rosterIds.includes(item._id));
      break;
    default:
      filteredApplicants = applicants;
      break;
  }
  return filteredApplicants;
};

export const getApplicantImageSrc = (applicant) =>
  encodeURI(`${IMG_SERVER_URL}/users/${applicant?.userId}/photo/${applicant?.profileImg}`);
