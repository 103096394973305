import { Box } from '@mui/material'
import { useState } from 'react'

import GenericModal from 'components/GenericModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import SendMessage from 'components/SendMessage'
import { getActiveFilterTitle } from 'utils/helpers/applicant'
import { ApplicantsTable } from './ApplicantsTable'

export const BulkMessagingModal = ({ applicantFilter, isOpen, toggleModal }) => {
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  console.log(selectedRecipients)
  return (
    <>
      <GenericModal
        open={isOpen}
        setOpen={toggleModal}
        body={
          <Box>
            <SendMessage
              recipient=""
              setOpen={toggleModal}
              text={text}
              setText={setText}
              email={email}
              setEmail={setEmail}
              isButtonDisabled={!selectedRecipients.length}
            />
            <ApplicantsTable filter={applicantFilter} setSelectedRecipients={setSelectedRecipients} />
          </Box>
        }
        header={
          <Box display="flex">
            <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
              <MDTypography variant="h5" color="dark">
                Send Message to {getActiveFilterTitle(applicantFilter.filt)}
              </MDTypography>
              {email && (
                <MDTypography variant="body2" color="info" fontWeight="bold">
                  E-mail:
                </MDTypography>
              )}
              {text && (
                <MDTypography variant="body2" color="info" fontWeight="bold">
                  Phone:
                </MDTypography>
              )}
            </MDBox>
          </Box>
        }
      />
    </>
  )
}
