import axios from "axios";

import { API_URL } from "config";

const deleteEmailTemplate = async (data) => {
  const { _id: controlId } = data;
  if (!controlId) throw new Error("Template id required");
  try {
    const res = await axios.delete(`${API_URL}/control/emailTemplates/${controlId}`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default deleteEmailTemplate;
