import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Icon, Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useQuery, useMutation } from "react-query";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "components/DataTable";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";

import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchEventsCurrent from "layouts/pages/events/actions/fetchEventsCurrent";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";
import EventDate from "components/EventDate";

import useSort from "utils/useSort";
import Searchbar from "components/Searchbar";
import FiltersList from "components/FiltersList";
import VenueIcon from "components/VenueIcon";
import ListIcon from "@mui/icons-material/List";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventCalendar from "layouts/pages/events/components/EventCalendar";
import EventsTableActions from "layouts/pages/events/components/EventsTableActions";
import VenueEventsTableActions from "layouts/pages/events/components/VenueEventsTableActions"

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const EventsTable = ({
  // eventUrl,
  navigateToAction,
  title = "Events",
  filters,
  setFilters,
  fetchAll,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  showEventStatus = false,
  setOpen,
  setEventPreview,
  defaultSort,
  setOpenEventPreview,
  parent = "Events"
}) => {
  const classes = useStyle();
  const { venues, currentEvent, setCurrentEvent, setStateVar, currentApplicant } =
    useAppContextController();
  const navigate = useNavigate();
  const [calendarListView, setCalendarListView] = useState("List");
  const [showEventModal, setShowEventModal] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters, venues };

  const { data: events, isLoading } = useQuery(["events", allOptions], () => {
    console.log('event allOptions', allOptions.filters);
    return fetchEvents(allOptions)
  }
  );

  const { action, eventUrl } = useParams();

  const sortByEventDate = (a, b) => {
    if (new Date(a.eventDate) > new Date(b.eventDate)) return 1;
    return -1;
  };

  useEffect(() => {
    if (!isLoading && events?.data?.length && eventUrl) {
      const ndx = events.data.findIndex((item) => item.eventUrl === eventUrl);
      if (ndx > -1) {
        setCurrentEvent(events.data[ndx]);
      }
    }
    if (action && eventUrl) setFilters(prev => ({ ...prev, eventUrl }))
  }, [isLoading, eventUrl, action]);

  useEffect(() => {
    if (action && filters) setPage(1);
  }, [filters]);

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => (
          <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />
        ),
      },
      ...(showEventStatus && currentApplicant
        ? [
          {
            title: "Event",
            field: "eventUrl",
            customCell: (vslug, field, row) => (
              <ApplicantVenueStatus
                id={row?._id}
                venue={vslug}
                slugField="eventUrl"
                row={row}
                invalidQuery={["events", allOptions]}
              />
            ),
          },
        ]
        : []),
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      // { title: "Event Url", field: "eventUrl" },
      { title: "Event", field: "eventName" },
      {
        title: "Event Date/Report Time",
        field: "eventDate",
        customCell: (date, field, row) => (
          <EventDate date={date} reportTimeTBD={row?.reportTimeTBD} />
        ),
      },
      // {
      //   title: "Report Time",
      //   field: "eventDate",
      //   customCell: (time) => moment(time).format("hh:mm a"),
      // },

      { title: "Status", field: "status" },
      {
        title: "# Requested",
        field: "positionsRequested",
        customCell: (number) => (
          <div style={{ textAlign: "right", paddingRight: "20px" }}>{number}</div>
        ),
      },
      {
        title: "Event Actions",
        field: "eventUrl",
        customCell: parent === "Events"
          ? (url, field, row) => (
            <><EventsTableActions
              setNavigateUrl={navigate}
              eventUrl={`${url}`}
              row={row}
              setFilters={setFilters}
              setActionSelected={setActionSelected}
              setOpen={setOpen}
              setEventPreview={setEventPreview}
              setCurrentEvent={setCurrentEvent}
            /></>)
          : (url, field, row) => (<VenueEventsTableActions
            setNavigateUrl={navigate}
            eventUrl={`${url}`}
            row={row}
            setFilters={setFilters}
            setActionSelected={setActionSelected}
            setOpen={setOpen}
            setEventPreview={setEventPreview}
            setCurrentEvent={setCurrentEvent}
          />
          ),
      },
    ],
    [currentEvent]
  );

  const venueColumns = useMemo(
    () => [
      {
        title: "Logo",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => (
          <VenueIcon logoUrl={logoUrl} slug={row?.slug} name={row?.name} />
        ),
      },
      {
        title: "Venue",
        field: "slug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      {
        title: "Venue Name",
        field: "name",
      },
      {
        title: "City",
        field: "city",
      },
      {
        title: "State",
        field: "state",
      },
      { title: "Status", field: "status" },
    ],
    []
  );

  // const columnsVenueSearch = [
  //   {
  //     title: "Venue ID",
  //     field: "slug",
  //     customCell: (thisSlug) => <span>{thisSlug?.toUpperCase()}</span>,
  //   },
  //   { title: "Venue Name", field: "name" },
  //   { title: "Venue URL", field: "venueUrl" },
  //   { title: "City", field: "city" },
  //   { title: "State", field: "state" },
  // ];

  const addNew = (e) => {
    if (!currentEvent) {
      setCurrentEvent({});
    }
    const url = new URL(window.location).pathname;
    const { pathname } = url;
    const nodes = url.split('/');
    if (nodes.length === 4 && nodes[1] === "events" && nodes[2] === "venue") {
      navigate(`/events/create/${nodes[3]}`);
    }
    else if (nodes.length === 3 && nodes[1] === "events" && nodes[2]) {
      const parts = nodes[2].split('-');
      navigate(`/events/create/${parts[0]}`);
    }
    else navigate("/events/create");
    e.stopPropagation();
  };

  const onRowClick = (row, e) => {
    e.stopPropagation();
    setCurrentEvent(row);
    const basePath = new URL(window.location).pathname;
    const nodes = basePath.split('/');
    if (nodes?.length >= 4 && nodes[2] === 'venue') {
      navigate(`/events/venue/${row?.venueSlug}/${row?.eventUrl}`);
    }
    else
      navigate(`/events/${row?.eventUrl}${navigateToAction ? `/action/${navigateToAction}` : ""}`);
  };

  const handleViewClick = () =>
    setCalendarListView((prev) => (prev === "List" ? "Calendar" : "List"));

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item sm={6}>
          <MDBox display="flex" flexWrap="wrap">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="success"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">event-seat</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }} whiteSpace="nowrap">
              {title} - {calendarListView} View{" "}
              {`${events?.data?.length ? `- ${events.data.length} Event${events.data?.length > 1 ? 's' : ''}` : ""}`}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={12}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item sm={6} alignItems="center">
          <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
            {showSearchBar && (
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Searchbar
                      fetch={fetchEvents}
                      fetchAll
                      placeholder="Search Event"
                      columns={columns.slice(1, columns.length - 1)}
                      queryCharacterLimit={1}
                      resultsLimit={25}
                      setFilters={setFilters}
                      setPage={setPage}
                      searchBy={["eventUrl", "eventName"]}
                      filterBy="eventUrl"
                      idField="_id"
                      scrollLimit={200}
                      onRowClick={(event) => {
                        setCurrentEvent(null);
                        navigate(`/events/${event.eventUrl}/action/eventsinfo`)
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Searchbar
                      fetch={fetchVenues}
                      fetchAll
                      placeholder="Search Venue/City"
                      columns={venueColumns}
                      queryCharacterLimit={1}
                      resultsLimit={10}
                      setFilters={setFilters}
                      setPage={setPage}
                      searchBy={["slug", "name", "city"]}
                      filterBy="venueSlug"
                      filterByColumn="slug"
                      idField="slug"
                      onRowClick={(venue) => {
                        setCurrentEvent(null);
                        navigate(`/events/venue/${venue.slug}`)
                      }
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item sm={showSearchBar ? 4 : 10} alignItems="flex-end" flexWrap="nowrap">
              {/* // eslint-disable-next-line react/jsx-no-comment-textnodes 
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <Grid container onClick={handleViewClick} justifyContent="center" flexWrap="nowrap">
                <Grid item>
                  {calendarListView === "Calendar" ? (
                    <ListIcon mt={1} color="primary" fontSize="large" />
                  ) : (
                    <DateRangeIcon color="success" fontSize="large" />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip title="Click to Toggle Calendar/List View" justifyContent="right">
                    <MDTypography
                      variant="h5"
                      color="dark"
                      sx={{ ml: 1, mt: 1 }}
                      whiteSpace="nowrap"
                    >
                      {`Show ${calendarListView === "Calendar" ? "List" : "Calendar"} View`}
                    </MDTypography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="flex" sm={1} justifyContent="center" px={2}>
              <MDBox>
                <IconButton className={classes.addButton} color="info" onClick={addNew}>
                  <AddCircleRoundedIcon />
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        {calendarListView === "List" ? (
          <DataTable
            columns={columns}
            data={events}
            onRowClick={(row, e) => onRowClick(row, e)}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            defaultSort={defaultSort}
            fetchAll={fetchAll}
            isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
            greybar
            divider
            idField="_id"
          />
        ) : (
          <Grid container alignItems="center">
            <Grid item xs={12} style={{ maxHeight: 780, overflow: "scroll" }}>
              <EventCalendar
                events={events}
                showEventModal={showEventModal}
                setShowEventModal={setShowEventModal}
                isLoading={isLoading}
                sortByEventDate={sortByEventDate}
                calendarListView={calendarListView}
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
    </Card>
  );
};
EventsTable.defaultProps = {
  title: "Events",
  showFiltersList: true,
};

// Typechecking props
EventsTable.propTypes = {
  title: PropTypes.string,
  showFiltersList: PropTypes.bool,
};

export default EventsTable;
