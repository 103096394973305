import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import DataList from "components/DataList"
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import Vimeo from '@u-wave/react-vimeo';
import MDTypography from 'components/MDTypography';
import { getVideos } from 'layouts/pages/venues/actions/getVideos';



const VenueTrainingVideos = ({ videoUrls }) => {

  const [videos, setVideos] = useState(null)

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center" mt={0} mb={0}>
        <Grid item xs={12} sm={6} m={0} p={0}>
          {row.url.toLowerCase().indexOf('vimeo.com') > -1 ?
            <Vimeo
              video={row.url.toLowerCase().replace('https://vimeo.com/manage/videos/', '')}
              showTitle
              width="200px" height="100px"
            /> :
            <ReactPlayer url={row.url} width="120px" height="60px" />
          }
        </Grid>
        {row.title ?
          <Grid item xs={12} sm={6} m={0} p={0}>
            <MDTypography>{row.title}</MDTypography>
          </Grid>
          : null}
      </Grid>
    </>
  )

  useEffect(() => {
    getVideos(videoUrls).then(data => setVideos(data));

  }, [videoUrls])


  const header = "";

  return (
    videos &&
    <MDBox style={{ maxHeight: '400px', overflow: 'scroll' }}>
      <MDBox pt={3} >
        <h3 style={{ lineHeight: 1, marginBottom: 2, textAlign: "center" }}>Training Videos</h3>
      </MDBox>
      <DataList
        renderRow={renderRow}
        data={videos}
        onRowClick={() => { }}
        icon="stadium"
        iconColor="info"
        limit={5}
        divider
        header={header}
      />
    </MDBox>
  );
}

export default VenueTrainingVideos;
