import { TableAction } from "@perimeter-software/react-common";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import AvTimerIcon from '@mui/icons-material/AvTimer';

const MyEventsActions = ({ eventUrl, setNavigateUrl, isFavorite, setFavorite }) => {

    const onClickHandler = (action, e) => {
        e.stopPropagation();
        setNavigateUrl(`/events/${eventUrl}/action/${action}`)
    }

    return (
        <MDBox display="flex" justifyContent="end">
            <TableAction
                color="error"
                icon="people"
                showBadge
                clickAction={(e) => onClickHandler('eventsapplicants', e)}
                tooltip="Event Applicants"
            />
            &nbsp;&nbsp;
            <TableAction
                color="secondary"
                icon="handshake"
                clickAction={(e) => onClickHandler('eventsall', e)}
                tooltip="Event Partners"
            />
            &nbsp;&nbsp;
            <TableAction
                color="secondary"
                icon="edit"
                clickAction={(e) => onClickHandler('eventsinfo', e)}
                tooltip="Edit Event"
            />
            &nbsp;&nbsp;
            <TableAction
                color="success"
                icon={<AvTimerIcon />}
                clickAction={(e) => onClickHandler('eventstime', e)}
                tooltip="Event time"
            />
        </MDBox>
    );
};

MyEventsActions.defaultProps = {
    eventUrl: "",
    isFavorite: false,
};


MyEventsActions.propTypes = {
    eventUrl: PropTypes.string,
    isFavorite: PropTypes.bool,
};

export default MyEventsActions;