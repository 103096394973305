import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import deleteJob from "layouts/pages/jobs/actions/deleteJob";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import JobsTable from "layouts/pages/jobs/components/JobsTable";
import JobsInformation from "layouts/pages/jobs/components/JobsInformation";
import JobsEventsTable from "layouts/pages/events/components/EventsTable";
import ApplicantsTable from "layouts/pages/applicants/components/ApplicantsTable";
import JobsModal from "layouts/pages/jobs/components/JobsModal";
import { Alert, Snackbar } from "@mui/material";

function Jobs({ mode = "edit" }) {

  const navigate = useNavigate();

  const { currentJob, setCurrentJob, venues } = useAppContextController();
  const { jobSlug, action } = useParams();
  const [open, setOpen] = useState(false)
  const [jobPreview, setJobPreview] = useState(null)
  const [actionSelected, setActionSelected] = useState(action || "");
  const [filters, setFilters] = useState({});

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const queryClient = useQueryClient();

  const deleteJobHandler = async (_id, jobTitle) => {
    const res = await deleteJob({ _id });

    if (res?.status === 200) {
      await queryClient.invalidateQueries("jobs");

      setToastAlert({
        isShow: true,
        message: `Job ${jobTitle} has been deleted`,
        status: "success",
      });
      return { success: true };
    }

    setToastAlert({
      isShow: true,
      message: `Something went wrong -- ${res?.data?.message}`,
      status: "error",
    });
    return { success: false, message: res?.data?.message };
  };

  useEffect(async () => {
    if (jobSlug && (actionSelected || action)) {
      setFilters({ jobSlug })
    }
    if (action) { // && action !== actionSelected) {
      setActionSelected(action)
    } else {
      setActionSelected("");
      setFilters({})
    }

    if (jobSlug && (!currentJob || currentJob.jobSlug !== jobSlug)) {
      const jobs = await fetchJobs({ filters: { jobSlug }, venues });
      if (jobs) setCurrentJob(jobs.data[0]);
    }
  }, [jobSlug, action, currentJob]);

  const setNavigateUrl = url => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <JobsTable
            jobSlug={jobSlug}
            fetchAll
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Jobs"
            setOpen={setOpen}
            setJobPreview={setJobPreview}
          />
        </Grid>
        {["jobinfo", "joball", ""].includes(actionSelected) && (jobSlug || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <JobsInformation mode={mode} setOpen={setOpen} setJobPreview={setJobPreview} deleteJob={deleteJobHandler} />
          </Grid>
        )}
        {["jobevents", "joball"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <JobsEventsTable
                showFiltersList={false}
                showSearchBar={false}
                filters={currentJob?.venueSlug ? { venueSlug: currentJob?.venueSlug } : {}}
                title={`Events for Job: ${currentJob?.title
                  } at ${currentJob?.venueSlug?.substring(0, 1).toUpperCase()}${currentJob?.venueSlug?.substring(1)}`}
              />
            </Grid>
          </>
        )}
        {["jobapplicants", "joball"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <ApplicantsTable
                showFiltersList={false}
                showSearchBar={false}
                filters={currentJob?.jobId ? { "jobs.jobId": currentJob.jobId } : {}}
                title={`Applicants for Job: ${currentJob?.title
                  } at ${currentJob?.venueSlug?.substring(0, 1).toUpperCase()}${currentJob?.venueSlug?.substring(1)}`}
              />
            </Grid>
          </>
        )}
      </Grid>
      {jobPreview && (
        < JobsModal open={open} setOpen={setOpen} jobPreview={jobPreview} />
      )}
    </DashboardLayout>
  );
}

// Setting default values for the props
Jobs.defaultProps = {
  mode: "edit",
};

// Typechecking props
Jobs.propTypes = {
  mode: PropTypes.string,
};
export default Jobs;
