import { useState, useEffect, useMemo } from "react";
import { useAppContextController } from "context/AppContext";
import Calendar from "examples/Calendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import EventsPreviewModal from "../EventsPreviewModal";
import CalendarDayModal from "../CalendarDayModal";

const EventCalendar = ({
  events,
  showEventModal,
  setShowEventModal,
  isLoading,
  sortByEventDate,
  calendarListView,
}) => {
  const { currentEvent, setCurrentEvent } = useAppContextController();

  const [showDayModal, setShowDayModal] = useState(false);
  const [dayEvents, setDayEvents] = useState([]);
  const [dateSelected, setDateSelected] = useState("");
  const setDateToFirst = (date) => new Date(new Date(date).setDate(1)).toISOString().split("T")[0];
  const [startingDate, setStartingDate] = useState(setDateToFirst(new Date()));

  const calendarEventsData = useMemo(() => {
    if (!isLoading && events?.data?.length) {
      let eventDayIndex = 0;
      let previousDate = null;
      let earliestDate = new Date();
      const retData = events.data.sort(sortByEventDate).map((item) => {
        const currentDate = new Date(item.eventDate).getDate();
        if (new Date(item.eventDate).getDate() !== previousDate) {
          eventDayIndex = 0;
        } else {
          eventDayIndex += 1;
        }
        previousDate = currentDate;
        if (!earliestDate && currentDate > new Date()) earliestDate = new Date(currentDate);
        if (
          earliestDate &&
          new Date(earliestDate).toISOString().split("T")[0] >
            new Date(currentDate).toISOString().split("T")[0]
        )
          earliestDate = new Date(currentDate);

        return {
          title: `${item.eventName}${item.reportTimeTBD ? " (TBD)" : ""}`,
          start: item.reportTimeTBD
            ? new Date(item.eventDate).toISOString().split("T")[0]
            : new Date(item.eventDate).toISOString(),
          eventId: item.eventId,
          ...(item.reportTimeTBD ? { timeText: item.reportTimeTBD } : {}),
          allDay: item.reportTimeTBD,
          className: ["info", "error", "success", "primary", "warning", "dark"][eventDayIndex % 6],
        };
      });
      if (earliestDate.getTime() < new Date().getTime())
        setStartingDate(setDateToFirst(new Date()));
      else setStartingDate(setDateToFirst(new Date(earliestDate)));
      return retData;
    }
    return [];
  }, [events]);

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b> - <i>{eventInfo.event.title}</i>
      </>
    );
  };

  const handleDateClick = (d) => {
    // at bottom where you instantiate the EventDayModal:
    //   make date, dayEvents - state vars give it a date parameter    "2022-07-15"
    function addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    }
    const dateSel = d.dateStr;
    if (dateSel) setDateSelected(dateSel);
    // alert(addDays(new Date(dateSel), 1).toLocaleDateString())
    const devents = events.data.filter((item) => {
      return (
        new Date(item.eventDate).toLocaleDateString() ===
        addDays(new Date(dateSel), 1).toLocaleDateString()
      );
    });
    if (devents?.length && dateSel) {
      setDayEvents(devents);
      setShowDayModal(true);
    }
    // console.log('dateobject', devents?.length, dateSel, d);
  };
  const handleEventClick = (d) => {
    // console.log("eventClick")
    const eventId = d.event?.extendedProps?.eventId;
    if (eventId) {
      const eventPreviewEvent = events.data.find((item) => item.eventId === eventId);
      setCurrentEvent(eventPreviewEvent);
      setShowEventModal(true);
    }
    // set up following parameter
    // if (events) setCurrentEvent(events.data[0]);
    // alert(d.event.extendedProps?.eventId)
    // console.log('dateobject', d);
  };

  // const eventCalendar = useMemo(() => (

  // ),
  //     [calendarEventsData]
  // );

  // render section
  return (
    <>
      <Calendar
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        initialDate={startingDate}
        eventClick={handleEventClick}
        events={calendarEventsData}
        dateClick={handleDateClick}
        defaultView="dayGridMonth"
        header={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        plugins={[dayGridPlugin, timeGridPlugin]}
        selectable
        // eslint-disable-next-line react/jsx-no-bind
        eventContent={renderEventContent}
        // editable
      />
      {showEventModal && calendarListView === "Calendar" && (
        <EventsPreviewModal
          open={showEventModal}
          setOpen={setShowEventModal}
          eventPreview={currentEvent}
        />
      )}
      {showDayModal && calendarListView === "Calendar" && (
        <CalendarDayModal
          showDayModal={showDayModal}
          setShowDayModal={setShowDayModal}
          dateSelected={dateSelected}
          dayEvents={dayEvents}
        />
      )}
      <div>&nbsp;</div>
    </>
  );
};

export default EventCalendar;
