import Activities from 'components/Activities'
import { useAppContextController } from "context/AppContext";

const ApplicantActivities = () => {
    const { currentApplicant } = useAppContextController();

    return (
        <Activities activitiesParent={currentApplicant} height={550} />
    )
}

export default ApplicantActivities