import React from "react";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PaperItem from "./Paper";

function FilterCard({
  color,
  bgColor,
  icon,
  title,
  number,
  selectedIndex,
  index,
  filterField,
  filterValue,
  onSelectHandler,
  customFilterFunction,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const elevation = selectedIndex === index ? "16" : "2";
  return (
    <PaperItem elevation={elevation} key={`paperitem_${title}`}>
      <Card sx={{ overflow: "hidden" }} onClick={() => onSelectHandler(filterField, filterValue, index, customFilterFunction)}>
        <MDBox
          sx={({ palette: { background } }) => ({
            background: darkMode && background.card,
          })}
        >
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <MDBox
                margin={1}
                padding={2}
                bgColor="white"
                // color="white"
                width="3rem"
                height="3rem"
                borderRadius="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
              // shadow="md"
              >
                <Icon fontSize="large" color={color}>
                  {icon}
                </Icon>
              </MDBox>
            </Grid>
            <Grid item xs={4}>
              <MDBox ml={0} mr={-3} lineHeight={1} textAlign="left">
                <MDTypography
                  variant="h6"
                  // color={color}
                  color="dark"
                  opacity={0.7}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={4}>
              <MDBox ml={2} mr={2} lineHeight={1} textAlign="right">
                <MDTypography
                  variant="h6"
                  // color={color}
                  color="dark"
                  opacity={0.7}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  {number}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </PaperItem>
  );
}

// Setting default values for the props of FilterCard
FilterCard.defaultProps = {
  color: "white",
  bgColor: "info",
  selectedIndex: 0,
  number: null,
  onSelectHandler: () => { },
  filterField: "",
  filterValue: "",
};

// Typechecking props for the FilterCard
FilterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "white",
  ]),
  bgColor: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  number: PropTypes.number,
  selectedIndex: PropTypes.number,
  filterField: PropTypes.string,
  filterValue: PropTypes.string,
  onSelectHandler: PropTypes.func,
};

export default FilterCard;
