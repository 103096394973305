import { NEW_APPLICANT_STEPS } from "utils/constants/applicant";

export const newApplicantInitialState = {
  applicant: {},
  i9Form: {},
  registrationSteps: [...NEW_APPLICANT_STEPS],
  activeStepId: 1,
  error: {},
};

const newApplicantReducer = (state, action) => {
  switch (action.type) {
    case "SET_REGISTRATION_STEP":
      return {
        ...state,
        activeStepId: action.data,
      };
    case "CREATE_APPLICANT":
      return {
        ...state,
        applicant: { ...state.applicant, ...action.data },
      };
    case "CREATE_I_9_FORM":
      return {
        ...state,
        i9Form: { ...state.i9Form, ...action.data },
      };
    case "UPDATE_APPLICANT":
      return {
        ...state,
        applicant: { ...state.applicant, ...action.data },
        error: {},
      };
    case "UPDATE_APPLICANT_ERROR":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default newApplicantReducer;
