// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useState } from "react";



import BaseLayout from "layouts/pages/partners/components/BaseLayout";
// import JobFormFormik from "layouts/pages/partners/components/JobFormFormik";

import PartnersTable from "layouts/pages/partners/components/PartnersTable";
import PartnersInformation from "layouts/pages/partners/components/PartnersInformation";

import { useAppContextController } from "context/AppContext";
// prop-types is a library for typechecking of props


const partnersCards = [
    { icon: "info", title: "Details", number: null },
    { icon: "people", title: "Partners", number: null },
    { icon: "public", title: "All", number: null },
];

function Partners({ pageName }) {


    const { currentJob, setCurrentJob, setStateVar } = useAppContextController();

    return (

        <BaseLayout stickyNavbar>
            <h1>{pageName}</h1>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <PartnersTable />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <PartnersInformation />
                </Grid>


            </Grid>
        </BaseLayout>

    );
}

// Setting default values for the props of BaseLayout
Partners.defaultProps = {
    pageName: "Partners",
};

// Typechecking props for BaseLayout
Partners.propTypes = {
    pageName: PropTypes.string,
};

export default Partners;
