import axios from "axios";

import { API_URL } from "config";

const updateEmailTemplate = async (id, body) => {
  try {
    const res = await axios.put(`${API_URL}/control/emailTemplates/${id}`, body);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateEmailTemplate;
