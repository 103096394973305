/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import Auth0ProviderWithHistory from "./auth/auth0Provider";
import { AppContextProvider } from "./context/AppContext";

import App from "./App";

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <QueryClientProvider client={queryClient}>
      <Auth0ProviderWithHistory>
        <AppContextProvider>
          <App />
        </AppContextProvider>
        {window.location.href.indexOf('localhost') > -1 ? <ReactQueryDevtools /> : null}
        </Auth0ProviderWithHistory>
      </QueryClientProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
