/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function MDDropzone({ options, apiUrl, className, onDrop, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dropzoneRef = useRef();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    const dz = createDropzone();

    // dz.on('addedfile', file => onDrop(file));

    return () => removeDropzone();
  }, [options]);

  return (
    <MDDropzoneRoot
      component="form"
      action={apiUrl}
      ref={dropzoneRef}
      className={`form-control dropzone ${className}`}
      ownerState={{ darkMode }}
      enctype="multipart/form-data"
      method="POST"
    >
      <MDBox className="fallback" bgColor="transparent">
        <MDBox {...rest} component="input" name="file" type="file" multiple />
      </MDBox>
    </MDDropzoneRoot>
  );
}
MDDropzone.defaultProps = {
  className: "",
};
// Typechecking props for the MDDropzone
MDDropzone.propTypes = {
  // options: PropTypes.objectOf(PropTypes.any),
  apiUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MDDropzone;
