import parse from "html-react-parser";

import { Card, Grid, Modal, Typography } from "@material-ui/core";
import { Box, Fade } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { IMAGE_SERVER } from "config";

import PropTypes from "prop-types";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 1170,
    bgcolor: "none",
    overflow: "hidden",
    borderRadius: 5,
    px: 4,
    py: 6,
  },

  wideBgImageContainer: {
    width: "100%",
    height: "200px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
    overflow: "hidden",
    borderRadius: 5,
  },
  wideBgImage: { width: "100%", overflowY: "auto", height: "100%" },

  modalBody: {
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    mt: 6,
    px: 6,
    py: 6,
  },
};

const JobsModal = ({ open, setOpen, jobPreview }) => {
  const {
    venues,
  } = useAppContextController();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const textColor = "#7c97a9";
  const venue = venues?.[jobPreview?.venueSlug];


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card>
            <Box sx={styles.container}>
              <Box style={styles.wideBgImageContainer}>
                <img style={styles.wideBgImage}
                  src={(jobPreview?.venueSlug && venues) ? `${venues[jobPreview?.venueSlug]?.bannerUrl}` : ``}
                  alt={`${jobPreview?.title} Job Preview`} />
              </Box>
              <Box sx={styles.modalBody}>
                <Box sx={{ borderBottom: `1px solid ${textColor}`, pb: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item sm={9}>
                      <h3 style={{ lineHeight: 1, marginBottom: 5 }}>{jobPreview?.venueName}</h3>
                      <Box style={{ color: textColor }}>
                        <Typography>{jobPreview?.title}</Typography>
                        <Typography>{venue?.address}</Typography>
                        <Typography>{`${venue?.city} ${venue?.state} ${venue?.zip}`}</Typography>
                        <Typography>
                          <a href={venue?.venueUrl} target="_blank" rel="noreferrer">
                            {venue?.venueUrl}
                          </a>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={3}>
                      <Box textAlign="right">
                        {jobPreview?.jobLogo === "Yes" ?
                          <img
                            alt="logo"
                            height="86px"
                            src={`${IMAGE_SERVER}/${jobPreview?.venueSlug}/jobs/${jobPreview?.venueSlug}-jobslogo.png`}
                          />
                          :
                          <img
                            alt="logo"
                            height="86px"
                            src={venue?.logoUrl || `${process.env.REACT_APP_IMAGE_SERVER}/stadiumpeople/SP-App-Store-Icon.png`}
                          />

                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ overflowY: "scroll", "max-height": "40vh", mt: 2 }}>
                  <Typography
                    style={{ color: textColor }}
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  >
                    {jobPreview?.description ? parse(jobPreview?.description) : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of JobsModal
JobsModal.defaultProps = {
  open: false,
  setOpen: () => { },
};

// Typechecking props of the JobsModal
JobsModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  currentJob: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    venueState: PropTypes.string,
    venueCity: PropTypes.string,
    googleMap: PropTypes.string,
    venueName: PropTypes.string,
    venueHelmID: PropTypes.string,
    venueLogoUrl: PropTypes.string,
  }).isRequired,
};

export default JobsModal;
