import { Modal, Fade, Card, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import AddANote from "components/AddANote";
import noteTypes from "assets/noteTypes.json";

import { Snackbar } from "@material-ui/core";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
});
const AddANoteModal = ({ open, setOpen, toastAlert, setToastAlert, saveNote }) => {
  const classes = useStyle();
  const handleClose = () => setOpen(false);

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
          style={{
            backgroundColor: ((toastAlert.status === "success") ? "#4caf4f" : "#f44336")
          }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card className={classes.container}>
            <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }} key="text_addNote">
              Add a Note
            </MDTypography>
            <AddANote noteTypes={noteTypes} saveNote={saveNote} setOpen={setOpen} />
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default AddANoteModal;
