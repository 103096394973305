import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
// @mui material components
import { Grid, Card, Stack, Button, Autocomplete, Alert, Snackbar, Avatar, Icon, Checkbox, Switch } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@mui/styles";
import FormField from "layouts/applications/wizard/components/FormField";
import pxToRem from "assets/theme/functions/pxToRem";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import CustomTextField from "components/Form/CustomTextField";
import { PatternFormat } from "react-number-format";

const useStyle = makeStyles({
    logoImage: {
        objectFit: "contain",
    },
    editor: {
        height: 170,
    },
    dropzone: {
        minHeight: 70,
    },
    previewButton: {
        padding: 0,
    },
    words: {
        wordWrap: "break-word",
    },
    input: {
        fontSize: `${pxToRem(16)}`,
        backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
        border: "solid 1px black",
        borderRadius: "10px",
    },
    error: {
        fontSize: "0.75rem",
    }
});

const DirectDepositRequest = ({ setBottomPannel, currentApplicant, setCurrentApplicant, }) => {
    const classes = useStyle();
    const [toastAlert, setToastAlert] = useState({
        isShow: false,
        message: "",
    });

    const [editorValue, setEditorValue] = useState(null);
    const [isModified, setIsModified] = useState(false);

    // If there is no current user, let's route the user to login
    const navigate = useNavigate();
    const { currentLoggedUser, setCurrentLoggedUser, isLoggedIn } = useAppContextController();

    // Confirm Modal
    const [confirmModal, setConfirmModal] = useState({
        modalFor: "",
        isOpen: false,
        title: "",
        bodyText: "",
        response: false,
    });

    const {

        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors, isSubmitting },
    } = useForm({ mode: "onBlur" });

    const handleTypeOfChange = (e) => {
        setValue("typeOfChange", e.target.id);
    };

    const handlePhoneChange = (e, v) => {
        console.log("v", v)
    }


    return (
        <>
            <Card >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <MDTypography variant="h5">
                        DIRECT DEPOSIT CHANGE REQUEST
                    </MDTypography>

                </MDBox>
                <MDBox p={2}>
                    <MDBox mb={2}>
                        <MDTypography variant="body2">
                            For security purposes, we do not allow direct deposit changes online, so please submit this form to request that a payroll representative contact you for changes.
                        </MDTypography>
                    </MDBox>
                    <form>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <MDTypography fontWeight="bold" variant="body2"> Primary Phone</MDTypography>
                            <MDTypography variant="body2">{currentApplicant?.phone}</MDTypography>
                        </Stack>
                        <Controller
                            name="primaryPhone"
                            control={control}
                            render={({ field }) => (
                                <PatternFormat
                                    format="+1 (###) ### ####"
                                    allowEmptyFormatting
                                    mask="_"
                                    defaultValue={currentApplicant?.primaryPhone}
                                    {...field}
                                    onValueChange={(e, v) => handlePhoneChange(e, v)}
                                    customInput={FormField}
                                />
                            )}
                        />

                        <Grid container spacing={2} mt={2}>
                            <Grid item sm={2}>
                                <MDTypography fontWeight="bold" variant="body2">Type of change:</MDTypography>
                            </Grid>
                            <Controller
                                name="typeOfChange"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Grid item sm={4}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Switch
                                                    id="directDeposit"
                                                    checked={field.value === "directDeposit"}

                                                    onChange={(e, v) => handleTypeOfChange(e)}
                                                />
                                                <MDTypography variant="body2"> Single or Married filing separately</MDTypography>
                                            </Stack>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Switch
                                                    id="payrollDebit"
                                                    checked={field.value === "payrollDebit"}

                                                    onChange={(e, v) => handleTypeOfChange(e)}
                                                />
                                                <MDTypography variant="body2">Married filing jointly (or Qualifying widow(er))</MDTypography>
                                            </Stack>
                                        </Grid>
                                    </>
                                )
                                }
                            />
                        </Grid>
                        <MDBox mb={6}>

                            <MDTypography fontWeight="bold" variant="body2" textTransform="capitalize">
                                Other comments or notes:
                            </MDTypography>
                            <MDEditor
                                className={classes.editor}
                                value={editorValue || ""}
                                onChange={(e) => {
                                    setEditorValue(e);
                                    setValue("otherCommentsOrNotes", e);
                                }}
                            />
                        </MDBox>
                        <MDBox p={3}>
                            <Grid container spacing={3}>
                                <Grid item sm={3}>
                                    <MDButton color="info" variant="contained" fullWidth onClick={() => { setBottomPannel(null) }}>
                                        <MDTypography variant="body2" color="white">
                                            Send Message
                                        </MDTypography>
                                    </MDButton>
                                </Grid>
                                <Grid item sm={3}>
                                    <MDButton color="error" variant="contained" fullWidth onClick={() => { setBottomPannel(null) }}>
                                        <MDTypography variant="body2" color="white">
                                            Cancel
                                        </MDTypography>
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </form>
                </MDBox>
            </Card >
        </>
    )
}

export default DirectDepositRequest