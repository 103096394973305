import { useEffect, useState } from 'react'
import { TableAction } from "@perimeter-software/react-common";
import MDBox from "components/MDBox";

const MyEventActions = ({ slug, setStatusRef, startStatus }) => {

  const stateToColor = {
    None : "dark",
    StaffingPool : "success",
    Pending: "warning",
    Locked: "error"
  }

  const statuses = ["None", "StaffingPool", "Pending", "Locked"]
  const [statusIdx, setStatusIdx] = useState(statuses.indexOf(startStatus(slug)) !== -1 ? statuses.indexOf(startStatus(slug)) : 0)
  const status = startStatus(slug) || "None";
  const color = stateToColor[startStatus(slug)] || "dark";

  useEffect(() => setStatusIdx(statuses.indexOf(startStatus(slug)) !== -1 ? statuses.indexOf(startStatus(slug)) : 0))

  const statusChangeHandler = () => {
    const newIdx = (statusIdx + 1) % statuses.length
    setStatusIdx(newIdx)
    setStatusRef(statuses[newIdx], slug)
  }

  return (
    <MDBox display="flex" justifyContent="end">
      {/* {`${statusIdx}-${status}-${color}`} */}
      <TableAction 
        key={`event_status_${slug}`}
        color={color}
        value={status} 
        clickAction={statusChangeHandler}
        icon="event-seat" 
      />
    </MDBox>
  );
};
export default MyEventActions;