import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Icon, Grid, IconButton, Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider } from "notistack";
import UsersTableActions from "layouts/pages/users/components/UserTableActions";
import PictureAvatar from "components/PictureAvatar";
import fetchUsers from "../../actions/fetchUsers";
import UserActionModal from "../UserActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const UsersTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
}) => {
  const { setCurrentUser, currentUser } = useAppContextController();

  const navigate = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentUser });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const { data: users, isLoading } = useQuery(["users", allOptions],
    () => fetchUsers(allOptions));

  useEffect(() => {
    if (action && filters) setPage(1);
  }, [filters]);

  const columns = [
    {
      title: "Avatar",
      field: "profileImg",
      customCell: (image, field, row) => (
        <PictureAvatar
          image={row?.profileImg}
          firstName={row?.firstName}
          lastName={row?.lastName}
          userId={row?._id}
          size="md"
        />
      ),
    },
    {
      title: "Email/Name ID",
      field: "emailAddress",
      customCell: (email) => <div style={{ paddingLeft: "2rem" }}>{email}</div>,
    },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "User Type", field: "userType" },
    {
      title: "User Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <UsersTableActions
          id={id}
          row={row}
          setCurrentUser={setCurrentUser}
          setModalInfo={setModalInfo}
          setNavigateUrl={navigate}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/Name ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
  ];

  const handleRowClick = (user, e) => {
    e.stopPropagation();
    setCurrentUser({ ...user });
    navigate(`/users/${user._id}`);
  };

  const addNew = (e) => {
    if (!currentUser) {
      setCurrentUser({});
    }
    navigate("/users/create");
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="info"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <AccountCircleIcon fontSize="large" />
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Users
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchUsers}
                fetchAll
                placeholder="Search Users"
                columns={columnsForSearch}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "emailAddress"]}
                filterBy="_id"
                onRowClick={(user, e) => {
                  setCurrentUser(user);
                  //                  alert(JSON.stringify(user))
                  navigate(`/users/${user._id}/action/userinfo`)
                  e.stopPropagation();
                }}
              />
            )}
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={users}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={fetchAll}
            defaultSort="lastName"
          />
        </MDBox>
        <UserActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        />
      </SnackbarProvider>
    </Card>
  );
};

// Setting default values for the props
UsersTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Users",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
UsersTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default UsersTable;
