import { TableAction } from "@perimeter-software/react-common";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import MessageIcon from '@mui/icons-material/Message';

const MyNotificationsActions = ({ row }) => {

    const navigate = useNavigate();
    const handleClickMessageRead = (e) => {
        e.stopPropagation();
        // eslint-disable-next-line no-param-reassign
        row.status = row.status === 'read' ? 'unread' : 'read'
    }

    return (
        <MDBox display="flex">
            <TableAction
                color={row.status === 'read' ? "success" : "info"}
                icon={row.status === 'read' ? <MarkUnreadChatAltIcon /> : <MessageIcon />}
                // showBadge
                // clickAction={handleClickMessageRead}
                tooltip={row.status}
            />
            &nbsp;
            <TableAction
                color="dark"
                icon="delete-forever"
                clickAction={() => alert("delete will happen here")}
            />
        </MDBox>
    );
};
export default MyNotificationsActions;

