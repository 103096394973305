import { useEffect, useState } from "react";
import GenericModal from "components/GenericModal";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { useAppContextController } from "context/AppContext";
import { Button, Icon } from "@mui/material";
import Grid from '@mui/material/Grid';
import { useQuery } from "react-query";
import Box from '@mui/material/Box';
import MDTypography from "components/MDTypography";
import ChatIcon from '@mui/icons-material/Chat';
import SendMessage from "components/SendMessage";
import AddANote from "components/AddANote";
import templateTypes from "assets/templateTypes.json";
import noteTypes from "assets/noteTypes.json";
import MDBox from "components/MDBox";

const UserActionModal = ({ modalInfo, open, setOpen, setToastAlert }) => {

    const [filters, setFilters] = useState({ _id: "bogus" });
    const options = { fetchAll: false };
    const allOptions = { ...options, filters };
    const [text, setText] = useState(false);
    const [email, setEmail] = useState(true);


    const { data: applicant, isLoading } = useQuery(["applicants", allOptions], () =>
        fetchApplicants(allOptions)
    );

    useEffect(() => {
        setFilters({ _id: `${modalInfo?.data?.applicantId}` });
    }, [modalInfo]);

    let modalBody
    let header;
    let buttons;

    if (applicant?.data && modalInfo.type === "usermail") {
        header = (
            <Box display="flex" key={`box_sendMessage_${modalInfo.data.id}`}>
                <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
                    <MDTypography variant="h5" color="dark" >
                        Send Message to {applicant?.data[0].lastName} {applicant?.data[0].firstName}
                    </MDTypography>
                    {email && <MDTypography variant="body2" color="info" fontWeight="bold">
                        {`<${applicant?.data[0].email}>`}
                    </MDTypography>}
                    {text && <MDTypography variant="body2" color="info" fontWeight="bold">
                        {`<${applicant?.data[0].phone}>`}
                    </MDTypography>}
                </MDBox>
            </Box>
        )
        modalBody = (
            <Box>

                {applicant?.data ?
                    <SendMessage
                        recipient={applicant?.data[0]}
                        setToastAlert={setToastAlert}
                        setOpen={setOpen}
                        text={text}
                        setText={setText}
                        email={email}
                        setEmail={setEmail}
                    />
                    : <MDTypography>No Applicant Id </MDTypography>
                }
            </Box>
        );

    } else if (modalInfo.type === "usercomments") {
        header = (
            <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }} key={`text_addNote_${modalInfo.data.id}`} >
                Add a Note
            </MDTypography>
        )
        modalBody = (<AddANote noteTypes={noteTypes} />)
    }





    return (
        <>
            <GenericModal
                open={open}
                setOpen={setOpen}
                body={modalBody}
                header={header}
                buttons={buttons}
            />
        </>
    )
}

export default UserActionModal;