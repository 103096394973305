import { useEffect, useState } from "react";
import GenericModal from "components/GenericModal";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { useAppContextController } from "context/AppContext";
import { Button, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import ChatIcon from "@mui/icons-material/Chat";
import SendMessage from "components/SendMessage";
import AddANote from "components/AddANote";
import templateTypes from "assets/templateTypes.json";
import noteTypes from "assets/noteTypes.json";
import MDBox from "components/MDBox";

const ApplicantActionModal = ({ modalInfo, open, setOpen, setToastAlert, currentApplicant }) => {
  const [filters, setFilters] = useState({});
  const options = { fetchAll: true };
  const allOptions = { ...options, filters };
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  // const { data: applicant, isLoading } = useQuery(["applicants", allOptions], () =>
  //     fetchApplicants(allOptions)
  // );

  let modalBody;
  let header;
  let buttons;

  if (modalInfo.type === "applicantmail") {
    header = (
      <Box display="flex" key={`box_sendMessage_${modalInfo.data.id}`}>
        <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
          <MDTypography variant="h5" color="dark">
            Send Message to {modalInfo.data.lastName} {modalInfo.data.firstName}
          </MDTypography>
          {email && (
            <MDTypography variant="body2" color="info" fontWeight="bold">
              {`E-mail: ${modalInfo.data.email}`}
            </MDTypography>
          )}
          {text && (
            <MDTypography variant="body2" color="info" fontWeight="bold">
              {`Phone: ${modalInfo.data.phone}`}
            </MDTypography>
          )}
        </MDBox>
      </Box>
    );
    modalBody = (
      <Box>
        <SendMessage
          recipient={modalInfo.data}
          setToastAlert={setToastAlert}
          setOpen={setOpen}
          text={text}
          setText={setText}
          email={email}
          setEmail={setEmail}
          applicant={currentApplicant}
        />
      </Box>
    );
  } else if (modalInfo.type === "usercomments") {
    header = (
      <MDTypography
        variant="h5"
        color="dark"
        sx={{ mt: 2, mb: 1, ml: 2 }}
        key={`text_addNote_${modalInfo.data.id}`}
      >
        Add a Note
      </MDTypography>
    );
    modalBody = <AddANote noteTypes={noteTypes} />;
  }

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
      />
    </>
  );
};

export default ApplicantActionModal;
