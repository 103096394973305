import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-router components
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import NewProfile from "layouts/pages/profile";

// Material Dashboard 2 PRO React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React contexts
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";
import { useAppContextController } from "context/AppContext";
// Images
import brandWhite from "assets/images/SP-App-Store-Icon.png";
import brandDark from "assets/images/sp2.jpg";
import PictureAvatar from "components/PictureAvatar";
import Activities from "layouts/pages/activities";
import Applicants from "layouts/pages/applicants";
import Events from "layouts/pages/events";
import Issues from "layouts/pages/issues";
import Jobs from "layouts/pages/jobs";
import Users from "layouts/pages/users";
import Venues from "layouts/pages/venues";
import JobApply from "layouts/pages/web/JobApply";
// import SignInBasic from "layouts/authentication/sign-in/basic";
import ProtectedRoute from "components/ProtectedRoute";

// Material Dashboard 2 PRO React routes
import NewApplicantPage from "layouts/pages/applicants/new";
import Homepage from "layouts/pages/web/home";
import routes from "./routes";

export default function App() {
  const { userType, currentLoggedUser } = useAppContextController();
  const { isLoading, user } = useAuth0();
  const currentUserRoute = {
    type: "collapse",
    name: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
    key: `${currentLoggedUser?.firstName}-${currentLoggedUser?.lastName}`,
    icon: (
      <PictureAvatar
        image={
          user?.picture && user.email.indexOf("@gmail.com") > -1
            ? user.picture
            : currentLoggedUser?.profileImg
        }
        firstName={currentLoggedUser?.firstName}
        lastName={currentLoggedUser?.lastName}
        userId={currentLoggedUser?._id}
        size="lg"
      />
    ),
    userType: ["Master", "Admin", "User"],
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/profile/my-profile",
        component: <NewProfile />,
      },
    ],
  };

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // useEffect(() => {
  //   alert(JSON.stringify(user));
  // }, [user])

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<ProtectedRoute isAllowed={!!user}>{route.component}</ProtectedRoute>}
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const filterRoutes = (route) => {
    if (!route.userType) return true;
    return route.userType.includes(userType);
  };

  if (isLoading) return <></>;

  if (!user) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" index element={<Homepage />} key="jobs_home" />
          <Route exact path="/jobs/apply/:jobSlug" element={<JobApply />} key="job_apply" />
          <Route exact path="/applicants/code/:applicantCode" element={<NewApplicantPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && user && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Stadium People EMS"
            routes={[currentUserRoute, ...routes.filter(filterRoutes)]}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && user && <Configurator />}
      {user && (
        <Routes>
          {getRoutes([currentUserRoute])}
          {getRoutes(routes.filter(filterRoutes))}
          <Route
            exact
            path="/venues/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Venues mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/venues/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Venues mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/venues/:slug"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Venues mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/venues/:slug/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Venues mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/jobs/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Jobs mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/jobs/:jobSlug"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Jobs mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/jobs/:jobSlug/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Jobs mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/issues/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Issues mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/issues/:issueId"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Issues mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/issues/:issueId/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Issues mode="edit" />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/events/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/create/:createVenueSlug"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="create" />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/events/:eventUrl"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/venue/:venueSlug"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/venue/:venueSlug/:eventUrl"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/venue/:venueSlug/:eventUrl/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/:eventUrl/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Events mode="edit" />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/applicants/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Applicants mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/applicants/:id"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Applicants mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/applicants/:id/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Applicants mode="edit" />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/users/create"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Users mode="create" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/users/:id"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Users mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/users/:id/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Users mode="edit" />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/activities/:id"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Activities mode="edit" />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/activities/:id/action/:action"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Activities mode="edit" />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="*"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Navigate to="/dashboards/analytics" />
              </ProtectedRoute>
            }
          />
        </Routes>
      )}
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
}
