import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { w4Schema } from "data/w4form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import FormField from "layouts/applications/wizard/components/FormField";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },
});

const W4TaxForm = () => {
  const { applicant, updateApplicantAction } = useNewApplicantContext();
  const resolver = useYupValidationResolver(w4Schema);

  const classes = useStyle();
  const [childrensMultiplied, setChildrensMultiplied] = useState(null);
  const [dependentsMultiplied, setDependentsMultiplied] = useState(null);
  const [addedChildrensAndDependents, setAddedChildrensAndDependents] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ resolver, mode: "onBlur" });

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.id);
  };

  const onSubmit = async (data) => {
    const parsedData = {
      w4Tax: data,
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  useEffect(() => {
    setAddedChildrensAndDependents(childrensMultiplied + dependentsMultiplied);
  }, [childrensMultiplied, dependentsMultiplied]);

  useEffect(() => {
    if (applicant.w4Tax) {
      reset(applicant.w4Tax);
    }
  }, [applicant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            defaultValue={applicant?.firstName}
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            defaultValue={applicant?.lastName}
            label="Last Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            defaultValue={applicant?.address1}
            label="Address"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            defaultValue={applicant?.city}
            label="City"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            defaultValue={applicant?.state}
            label="State"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CustomTextField
            variant="outlined"
            disabled
            inputProps={{ tabIndex: -1 }}
            defaultValue={applicant?.zip}
            label="Zip Code"
          />
        </Grid>
        <MDBox p={3}>
          <Controller
            name="filingStatus"
            control={control}
            render={({ field }) => (
              <>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Checkbox
                    id="Single"
                    checked={field.value === "Single"}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    {" "}
                    Single or Married filing separately
                  </MDTypography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Checkbox
                    id="Married"
                    checked={field.value === "Married"}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    Married filing jointly (or Qualifying widow(er))
                  </MDTypography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Checkbox
                    id="Head of Household"
                    checked={field.value === "Head of Household"}
                    onChange={(e, v) => handleFilingStatusChange(e)}
                  />
                  <MDTypography fontWeight="bold" variant="body2">
                    Head of household (Check only if you&apos;re unmarried and pay more than half
                    the costs of keeping up home for yourself and a qualifying individual){" "}
                  </MDTypography>
                </Stack>
              </>
            )}
          />
          <MDTypography variant="h6">Step 3: Claim Dependents</MDTypography>
          <Grid container spacing={1}>
            <Grid item sm={10}>
              <Grid container spacing={1}>
                <Grid item sm={10}>
                  <MDTypography>
                    {" "}
                    Multiply the number of qualifying children under age 17 by $2000
                  </MDTypography>
                </Grid>
                <Grid item sm={2}>
                  <Controller
                    name="numberOfChildren"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        InputLabelProps={{ className: classes.label }}
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            inputMode: "numeric",
                            type: "number",
                            step: 1,
                            min: 0,
                            max: 25,
                          },
                        }}
                        sx={{ input: { textAlign: "end" } }}
                        variant="filled"
                        label="Children"
                        decimalScale={0}
                        onValueChange={(e) => {
                          setChildrensMultiplied(Math.floor(Number(e.value) * 2000));
                          setValue("numberOfChildren", e.value);
                        }}
                        customInput={FormField}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <FormField
                type="number"
                disabled
                variant="filled"
                value={childrensMultiplied}
                sx={{ input: { textAlign: "end" } }}
                InputProps={{
                  inputProps: { tabIndex: -1 },
                  className: classes.input,
                  startAdornment: "$",
                  defaultValue: 0,
                }}
              />
              {errors?.numberOfChildren && (
                <MDTypography className={classes.error} color="error">
                  {errors?.numberOfChildren.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={10}>
              <Grid container spacing={1}>
                <Grid item sm={10}>
                  <MDTypography> Multiply the number of other dependents by $500</MDTypography>
                </Grid>
                <Grid item sm={2}>
                  <Controller
                    name="otherDependents"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        InputLabelProps={{ className: classes.label }}
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            inputMode: "numeric",
                            type: "number",
                            step: 1,
                            min: 0,
                            max: 25,
                          },
                        }}
                        sx={{ input: { textAlign: "end" } }}
                        variant="filled"
                        label="Dependents"
                        decimalScale={0}
                        onValueChange={(e) => {
                          setDependentsMultiplied(Math.floor(Number(e.value) * 500));
                          setValue("otherDependents", e.value);
                        }}
                        customInput={FormField}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <FormField
                type="number"
                disabled
                variant="filled"
                value={dependentsMultiplied}
                sx={{ input: { textAlign: "end" } }}
                InputProps={{
                  className: classes.input,
                  startAdornment: "$",
                  inputProps: {
                    type: "number",
                    defaultValue: 0,
                  },
                }}
              />
              {errors?.otherDependents && (
                <MDTypography className={classes.error} color="error">
                  {errors?.otherDependents.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={10}>
              <MDTypography> Add the amounts above and enter the total here</MDTypography>
            </Grid>
            <Grid item sm={2}>
              <FormField
                type="number"
                disabled
                value={addedChildrensAndDependents}
                sx={{ input: { textAlign: "end" } }}
                variant="filled"
                InputProps={{
                  startAdornment: "$",
                  className: classes.input,
                  inputProps: {
                    type: "number",
                    defaultValue: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
          <MDTypography variant="h6">Step 4 (optional): Other Adjustments</MDTypography>
          <Grid container>
            <Grid item sm={10}>
              (a) Other income (not from jobs). If you want tax withheld for other income you expect
              this year that won&lsquo;t have withholding, enter the amount of other income here.
              This may include interest, dividends, and retirement income.
            </Grid>
            <Grid item sm={2}>
              <Controller
                name="otherIncome"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      className: classes.input,
                      inputProps: {
                        inputMode: "numeric",
                        type: "number",
                        step: 0.01,
                        min: 0,
                        max: 25,
                      },
                    }}
                    sx={{ input: { textAlign: "end" } }}
                    variant="filled"
                    decimalScale={2}
                    customInput={FormField}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={10}>
              (b) Deductions. If you expect to claim deductions other than the standard deduction
              and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter
              the result here
            </Grid>
            <Grid item sm={2}>
              <Controller
                name="deductions"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      className: classes.input,
                      inputProps: {
                        inputMode: "numeric",
                        type: "number",
                        step: 0.01,
                        min: 0,
                        max: 25,
                      },
                    }}
                    sx={{ input: { textAlign: "end" } }}
                    variant="filled"
                    decimalScale={2}
                    customInput={FormField}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={10}>
              (c) Extra withholding. Enter any additional tax you want withheld each pay period.
            </Grid>
            <Grid item sm={2}>
              <Controller
                name="extraWithholding"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      className: classes.input,
                      inputProps: {
                        inputMode: "numeric",
                        type: "number",
                        step: 0.01,
                        min: 0,
                        max: 25,
                      },
                    }}
                    sx={{ input: { textAlign: "end" } }}
                    variant="filled"
                    decimalScale={2}
                    customInput={FormField}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
      <FormErrors errors={errors} />
    </form>
  );
};

export default W4TaxForm;
