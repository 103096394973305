import axios from "axios";

import { API_URL } from "config";

const createIssue = async (data) => {
  const res = await axios.post(`${API_URL}/issues`, data);
  // console.log('return from create', res);
  return res.data;
};

export default createIssue;
