import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, Grid, Icon, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// import DataTable from "components/DataTable";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { useAppContextController } from "context/AppContext";
// eslint-disable-next-line import/no-unresolved
import { useRosterApplicants } from "hooks/useRosterApplicants";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import EventRosterCheck from "layouts/pages/events/components/EventRosterCheck";
import EventRosterTable from "layouts/pages/events/components/EventRosterTable";
import PartnerEventTimeClockTable from "layouts/pages/events/components/PartnerEventTimeClockTable";
import TimeRosterFilterCards from "layouts/pages/events/components/TimeRosterFilterCards";
import useSort from "utils/useSort";

import ApplicantVenueStatus from "../ApplicantVenueStatus";
import { BulkMessagingModal } from "../BulkMessagingModal";
import EventRosterActions from "../EventRosterActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
  },
  button: {
    fontSize: 40,
  },
});

const EventRosterAndTime = ({
  id,
  actionSelected,
  setActionSelected,
  setFilters,
  filters,
  showFiltersList,
  setExportFieldModalOpen,
  setApplicantsData,
  setRosterData,
}) => {
  const queryClient = useQueryClient();
  const classes = useStyle();

  const { eventUrl } = useParams();

  const { currentEvent, venues } = useAppContextController();
  const [applicantPoolSize, setApplicantPoolSize] = useState(0);
  const d = new Date();
  d.setDate(d.getDate() + 2);
  const today = d.toISOString().split("T")[0];

  const eventDay = new Date(currentEvent?.eventDate).getTime() <= new Date(today).getTime();
  const [roster, setRoster] = useState("Signups");
  const [rosterIds, setRosterIds] = useState([]);
  const [rosterFilters, setRosterFilters] = useState({ filt: "All" });
  const [readRoster, setReadRoster] = useState(false);
  const [readApps, setReadApps] = useState(false);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false)

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const { order, orderBy, toggleSort } = useSort();
  const options = { fetchAll: true, page, limit, order, orderBy };

  const applicantOptions = {
    ...options,
    filters: { ...filters, "venues.venueSlug": currentEvent?.venueSlug },
  };

  const {
    data: rosterApplicants,
    isLoading: rosterLoading,
    isRefetching,
    refetch: requeryRoster,
  } = useRosterApplicants({
    options: {
      onSuccess: (data) => {
        if (data.result) {
          setRosterIds(
            data?.result?.map((item) => item.id) // .filter((item) => item.status === "Roster")?
          );
          setRosterData(data?.result.filter((item) => ["Roster"].includes(item.status)));
        } else {
          setRosterIds([]);
        }
      },
    }
  })

  const toggleReadRoster = () => {
    console.log("toggleReadRoster");
    // return queryClient.invalidateQueries(["rosterApplicants", eventUrl]);
    requeryRoster();
  };

  const {
    data: applicants,
    isLoading: appLoading,
    isRefetching: isRefetchingApps,
    refetch: requeryApplicants,
  } = useQuery(["applicants", applicantOptions], () => fetchApplicants(applicantOptions), {
    onSuccess: (retValue) => {
      console.log("object keys", Object.keys(retValue));
      toggleReadRoster();
    },
  });

  const toggleReadApps = () => requeryApplicants(); // queryClient.invalidateQueries(["applicant", applicantOptions]);

  useEffect(() => {
    toggleReadApps();
    toggleReadRoster();
  }, []);

  const isOnRoster = (applId, i) => {
    const returnValue = rosterIds.findIndex((item) => item === applId) > -1;
    return returnValue;
  };

  const columns = useMemo(
    () => [
      {
        title: "Roster",
        field: "_id",
        customCell: (check, row, field) => (
          <EventRosterCheck
            id={row._id}
            row={row}
            eventApplicants={rosterApplicants?.result}
            toggleReadRoster={toggleReadRoster}
          />
        ),
      },
      {
        title: "Venue",
        field: "venues",
        customCell: (venue, field, row) => (
          <ApplicantVenueStatus
            id={row?._id}
            row={field}
            venue={currentEvent?.venueSlug}
            slugField="venueSlug"
            invalidQuery={["rosterApplicants", eventUrl]}
          />
        ),
      },
      { title: "Last Name", field: "lastName" },
      { title: "First Name", field: "firstName" },
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      { title: "Email", field: "email" },
      {
        title: "Position",
        field: "primaryPosition",
      },
    ],
    [rosterIds, rosterApplicants]
  );

  const navigate = useNavigate();
  const addNew = () => {
    navigate("/partners/create");
  };

  const onCancel = () => {
    console.log("cancel");
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  return (
    <Card className={classes.box}>
      <Stack display="flex" flexDirection="row">
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item sm={6}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="error"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">people</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Event Roster - {roster}
                {` - Applicant Pool Size: ${applicantPoolSize || 0}`}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item sm={3.5} display="flex" alignItems="center" mt={2}>
            <Searchbar
              fetch={fetchApplicants}
              placeholder="Add Members"
              columns={columns}
              queryCharacterLimit={2}
              resultsLimit={20}
              scrollLimit={200}
              setFilters={setFilters}
              sortFunction={sortLastFirst}
              setPage={setPage}
              searchBy={["firstName", "lastName", "email"]}
              filterBy="email"
            />
          </Grid>
          <Grid item sm={2.5} display="flex" alignItems="right" mt={2} mr={0} pr={0}>
            <EventRosterActions
              id={id}
              setRoster={setRoster}
              setFilters={setFilters}
              currentEvent={currentEvent}
              setExportFieldModalOpen={setExportFieldModalOpen}
              toggleBulkMessageModal={toggleBulkMessageModal}
            />
          </Grid>
        </Grid>
      </Stack>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={3}>
        <TimeRosterFilterCards setFilters={setRosterFilters} />
      </Box>
      <MDBox>
        <>
          {(roster === "Signups" || roster === "") && (
            <Grid item xs={12} sm={12}>
              <EventRosterTable
                name="Events Time Roster"
                fetchAll
                // filters={{ "venues.venueSlug": currentEvent?.venueSlug }}
                rosterFilters={rosterFilters}
                showEventStatus
                setApplicantPoolSize={setApplicantPoolSize}
                eventDay={eventDay}
                applicants={applicants}
                rosterApplicants={rosterApplicants}
                rosterLoading={rosterLoading}
                appLoading={appLoading}
                defaultSort="lastName"
                rosterIds={!rosterLoading ? rosterIds : []}
                isOnRoster={isOnRoster}
                setApplicantsData={setApplicantsData}
                readRoster={readRoster}
                setReadRoster={setReadRoster}
                readApps={readApps}
                setReadApps={setReadApps}
                toggleReadApps={toggleReadApps}
                toggleReadRoster={toggleReadRoster}
              />
            </Grid>
          )}
          {roster === "Partners" && (
            <Grid item xs={12} sm={12}>
              <PartnerEventTimeClockTable
                setActionSelected={setActionSelected}
                showFiltersList
                filters={filters}
                setFilters={setFilters}
              />
            </Grid>
          )}
        </>
      </MDBox>
      {isBulkMessageModalOpen && <BulkMessagingModal applicantFilter={rosterFilters} isOpen={isBulkMessageModalOpen} toggleModal={toggleBulkMessageModal} />}
    </Card>
  );
};
EventRosterAndTime.defaultProps = {
  showFiltersList: 0,
};

// Typechecking props
EventRosterAndTime.propTypes = {
  showFiltersList: PropTypes.bool,
};

export default EventRosterAndTime;
