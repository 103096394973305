import { useState, useContext } from "react";
import { Card } from "@mui/material";
import PropTypes from "prop-types";

import { useQuery, useMutation } from "react-query";

import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import { useAppContextController } from "context/AppContext";
import fetchPartners from "layouts/pages/partners/actions/fetchPartners";
import deletePartner from "layouts/pages/partners/actions/deletePartner";

// import moment from "moment";

const PartnersTable = () => {
  const { setCurrentPartner, setStateVar } = useAppContextController();

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const { data: partners } = useQuery(["partners", page, limit], () => fetchPartners(page, limit));
  const columns = [];

  return (


    <Card>
      <MDBox pt={2} pb={2}>
        {partners ? (
          <DataTable
            columns={columns}
            data={partners}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            onRowClick={(partner) => (setCurrentPartner(partner))}
          />
        ) : (
          <h4 style={{ textAlign: "center" }}>No partners found!</h4>
        )}
      </MDBox>
    </Card>
  );
};


export default PartnersTable;
