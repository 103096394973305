import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useQuery, useMutation, useQueryClient } from "react-query";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import useSort from "utils/useSort";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import PictureAvatar from "components/PictureAvatar";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import EventsTimeActions from "layouts/pages/events/components/EventsTimeActions";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";
import EventRosterCheck from "layouts/pages/events/components/EventRosterCheck";
import RosterPosition from "layouts/pages/events/components/RosterPosition";
import fetchEventApplicants from "layouts/pages/events/actions/fetchEventApplicants";
import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";
import EventActionModal from "layouts/pages/events/components/EventActionModal";
import axios from "axios";
import { API_URL } from "config";
import TimeInOut from "../TimeInOut";
// import { Alert, Snackbar } from "@mui/material";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
  },
  addButton: {
    fontSize: 40,
  },
});

const EventsTimeRoster = ({
  filters,
  rosterFilters,
  setFilters,
  fetchAll,
  showFiltersList,
  name,
  setApplicantPoolSize,
  setApplicantsData,
  eventDay,
  defaultSort = "lastName",
  showEventStatus = false,
  readRoster, setReadRoster,
  readApps, setReadApps,
  toggleReadApps,
  toggleReadRoster,
  applicants,
  appLoading,
  rosterApplicants,
  rosterLoading,
  rosterIds,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // const classes = useStyle();
  const { venues, setCurrentEvent, currentEvent } = useAppContextController();
  const [venueSlug, setVenueSlug] = useState(currentEvent ? currentEvent.venueSlug : null);
  const [mongoId, setMongoId] = useState(currentEvent ? currentEvent._id : null);
  const { eventUrl } = useParams();
  const [modalInfo, setModalInfo] = useState({ data: currentEvent });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [showTimeColumns, setShowTimeColumns] = useState(true);

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [apps, setApps] = useState({});
  // const [applicants, setApplicants] = useState(null);
  // const [appLoading, setAppLoading] = useState(false);

  const { order, orderBy, toggleSort } = useSort();
  const [toastAlert, setToastAlert] = useState(false);

  const isOnRoster = (applId, i) => {
    if (rosterApplicants?.result) {
      const returnValue = rosterApplicants.result?.findIndex((item) => item.id === applId && item.status === "Roster");
      return (returnValue > -1);
    }
    return false;
  };

  setApplicantPoolSize(applicants?.data?.length);

  const rosterStatus = (id, status) =>
    rosterApplicants?.result.findIndex((item) => item.id === id && item.status === status) > -1 ||
    false;

  useEffect(() => {
    const getFilteredApp = () => {
      //      console.log('rosterFilters', rosterFilters, rosterIds)
      if (rosterFilters?.filt && !appLoading && applicants?.data) {
        if (rosterFilters.filt === "All") setApps({ data: applicants.data });
        else if (rosterFilters.filt !== "Not Roster")
          setApps({
            data: applicants.data?.filter(
              (app) => rosterIds.includes(app._id) && rosterStatus(app._id, rosterFilters.filt)
            ),
          });
        else if (rosterFilters.filt === "Not Roster")
          setApps({ data: applicants.data?.filter((app) => !rosterIds.includes(app._id)) });
      }
    };
    if (!appLoading && !rosterLoading && applicants?.data?.length) {
      setApplicantsData(applicants.data);
      setShowTimeColumns(["All", "Roster"].includes(rosterFilters.filt));
      //      setApps(applicants.data);
      getFilteredApp();
      setPage(1)
    }
  }, [appLoading, rosterFilters, applicants, readApps]);

  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);

  useEffect(() => {
    if (currentEvent?._id && currentEvent?.eventDate) {
      setVenueSlug(currentEvent.venueSlug);
      setMongoId(currentEvent._id);
      const gmtDate = new Date(currentEvent.eventDate);
      const offset = gmtDate.getTimezoneOffset();
      const MS_PER_MINUTE = 60000;
      const localDate = new Date(gmtDate - offset * MS_PER_MINUTE)
      console.log('localDate', localDate, currentEvent.eventDate);
      if (localDate) setEventDate(localDate?.toISOString().split('T')[0]);
      if (gmtDate) setEventTime(gmtDate?.toISOString().split('T')[1]);
    }
  }, [currentEvent]);

  const deleteRosterMutation = useMutation(
    async (data) => {
      // eslint-disable-next-line no-unused-expressions
      await axios.delete(`${API_URL}/events/url/${eventUrl}/roster?id=${data.id}`, {
        headers: { Authorization: "***" },
      });
    },
    {
      // eslint-disable-next-line no-return-await
      // onSuccess: async () => await queryClient.invalidateQueries(["rosterApplicants", eventUrl]),
      onSuccess: () => toggleReadRoster(),
      onError: (error) => alert(error.toString()),
    }
  );

  const insertRosterMutation = useMutation(updateEventRoster,
    {
      // eslint-disable-next-line
      onSuccess: async (_, { data }) => {
        // await queryClient.invalidateQueries("rosterApplicants")
        console.log('onSuccess insertRosterMut', data);
        const newApps = currentEvent?.applicants
        if (newApps?.length) {
          const ndx = newApps.findIndex(item => item.id === data.id);
          if (ndx > -1) {
            newApps[ndx] = data;
            // console.log('newApps', newApps);
            setCurrentEvent({ ...currentEvent, applicants: newApps });
          }
        }
        toggleReadRoster()
      },
      onError: (error) => alert(error.toString()),
    }
  );

  const clockInOut = async (e, id, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    }
    else newRoster[field] = newRoster[field] || new Date();
    await insertRosterMutation.mutateAsync({ id: currentEvent?._id, data: newRoster });
  };

  const getSignupDate = id => {
    if (rosterIds.includes(id)) {
      const rosterRec = rosterApplicants?.result?.find(item => item.id === id);
      if (rosterRec) return rosterRec.dateModified;
    }
    return null;
  }

  // console.log('stuff', eventDay, showTimeColumns)
  const columns = useMemo(
    () => [
      {
        title: "Roster",
        field: "venues",
        customCell: (check, field, row) => (
          <EventRosterCheck id={row._id} row={row}
            eventApplicants={rosterApplicants?.result}
            toggleReadRoster={toggleReadRoster}
          />
        ),
      },
      {
        title: "Avatar",
        field: "profileImg",
        customCell: (image, field, row) => (
          <PictureAvatar
            image={row?.profileImg}
            firstName={row?.firstName}
            lastName={row?.lastName}
            userId={row?._id}
          />
        ),
      },
      ...(showEventStatus
        ? [
          {
            title: "Venue",
            field: "venueSlug",
            customCell: (vslug, field, row) => (
              <ApplicantVenueStatus
                id={row._id}
                venue={venueSlug}
                slugField="venueSlug"
                row={row}
                invalidQuery={["rosterApplicants", eventUrl]}
              />
            ),
          },
        ]
        : []),
      {
        title: "Last Name",
        field: "lastName",
        customCell: (last, field, row) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div onClick={() => navigate(`/applicants/${row._id}/action/applicantinfo`)}>{last}</div>
        ),
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (first, field, row) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div onClick={() => navigate(`/applicants/${row._id}/action/applicantinfo`)}>{first}</div>
        ),
      }, { title: "Phone", field: "phone" },
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      {
        title: "Signup Date",
        field: "hireDate",
        customCell: (date, field, row) => {
          const dt = getSignupDate(row?._id);
          if (dt) return moment(dt).format("MM/DD/YYYY")
          return ""
        },
      },
      {
        title: "Position",
        field: "primaryPosition",
        customCell: (position, field, row) => (
          <>
            <RosterPosition
              id={row?._id}
              eventApplicants={rosterApplicants?.result}
              row={field}
              currentEvent={currentEvent}
              toggleReadRoster={toggleReadRoster}
            />
          </>
        ),
      },
      ...(eventDay && showTimeColumns
        ? [
          {
            title: "Time in",
            field: "timeIn",
            customCell: (date, field, row) => (
              <>
                <MDBox width="7rem">
                  <TimeInOut
                    id={row?._id}
                    field="timeIn"
                    currentEvent={currentEvent}
                    eventDate={eventDate}
                    eventTime={eventTime}
                    rosterObj={
                      // !rosterLoading &&
                      rosterApplicants?.result?.find((item) => item.id === row._id)
                    }
                    disabled={!isOnRoster(row._id)}
                    timeZone={currentEvent?.timeZone}
                    clockInOut={(e, rosterObj) => clockInOut(e, row._id, field, rosterObj)}
                  />
                </MDBox>
              </>
            ),
          },
        ]
        : []),
      ...(eventDay && showTimeColumns
        ? [
          {
            title: "Time out",
            field: "timeOut",
            customCell: (date, field, row) => (
              <>
                <MDBox width="7rem">
                  {/* <Tooltip title="Clock Out"> */}
                  <TimeInOut
                    id={row?._id}
                    field="timeOut"
                    currentEvent={currentEvent}
                    eventDate={eventDate}
                    eventTime={eventTime}
                    rosterObj={
                      // !rosterLoading &&
                      rosterApplicants?.result?.find((item) => item.id === row._id)
                    }
                    // checks if there is a time in value before enabling
                    disabled={
                      !(
                        isOnRoster(row._id) &&
                        rosterApplicants?.result?.find((item) => item.id === row._id)?.timeIn
                      )
                    }
                    timeZone={currentEvent?.timeZone}
                    clockInOut={(e, rosterObj) => clockInOut(e, row._id, field, rosterObj)}
                  />
                  {/* </Tooltip> */}
                </MDBox>
              </>
            ),
          },
        ]
        : []),
      {
        title: "Actions",
        field: "action",
        customCell: (id, field, row) => (
          <EventsTimeActions
            id={id}
            row={row}
            currentEvent={currentEvent}
            setCurrentEvent={setCurrentEvent}
            setModalInfo={setModalInfo}
            // setNavigateUrl={setNavigateUrl}
            setGenericModalOpen={setGenericModalOpen}
          />
        ),
      },
    ],
    [eventUrl, currentEvent, appLoading, rosterApplicants?.result, rosterIds, showTimeColumns]
  );

  return (
    <>
      {" "}
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={{ data: apps?.data, count: apps?.data?.length }}
            // onRowClick={(event) => setCurrentEvent(event)}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll
            defaultSort={defaultSort}
            isLoading={appLoading} // Extract isLoading from useQuery then pass here (required)
            greybar
            divider
            idField="email"
          />
          {/* {JSON.stringify(rosterApplicants?.result?.map(item =>
            ({ id: item.id, timeIn: item.timeOut })))} */}
        </MDBox>
        <EventActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setModalInfo={setModalInfo}
          setToastAlert={setToastAlert}
          toggleReadApps={toggleReadApps}
        />
      </SnackbarProvider>
    </>
  );
};
EventsTimeRoster.defaultProps = {
  name: "partners",
  showFiltersList: 0,
};

// Typechecking props
EventsTimeRoster.propTypes = {
  name: PropTypes.string,
  showFiltersList: PropTypes.bool,
};

export default EventsTimeRoster;
